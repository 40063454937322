import * as Sentry from '@sentry/browser';
import { getCookie } from 'utils/cookie';
import { COOKIE_DEBUG_CONSOLE_LOG } from 'consts/config';

import { consoleLog, type LogType } from './utils';

// enrich sentry info
export function setUser(username: string) {
  Sentry.configureScope((scope) => {
    scope.setUser({ username });
  });
}

type LogObject = {
  type: Lowercase<LogType>;
  origin: string;
  message: string;
  info?: Record<string, unknown> | string;
};

export default function log(logObj: LogObject, toSentry = true) {
  const logToConsole = getCookie(COOKIE_DEBUG_CONSOLE_LOG);

  if (
    logToConsole &&
    (import.meta.env.VITE_DEPLOYMENT_TYPE === 'enterprise' || !window.location.hostname.includes('mixmode.ai'))
  ) {
    consoleLog(logObj.type.toUpperCase() as Uppercase<LogType>, logObj);
    return;
  }

  if (import.meta.env.DEV && logToConsole) {
    consoleLog(logObj.type.toUpperCase() as Uppercase<LogType>, logObj);
  }

  if (import.meta.env.PROD && toSentry) {
    Sentry.withScope((scope) => {
      scope.setLevel(logObj.type);
      scope.setTag('hostname', window.location.hostname);
      // Sentry 5.x
      scope.setExtras(logObj);
      // Sentry 4.x code
      // Object.keys(logObj).forEach(key => {
      //   scope.setExtra(key, logObj[key]);
      // });
      Sentry.captureEvent({ message: logObj.message });
    });
  }
}

export function logGraphqlError({
  error,
  origin,
  variables,
}: {
  error: Error & { graphQLErrors: unknown[]; networkError: unknown };
  origin: string;
  variables?: Record<string, unknown>;
}) {
  log({
    type: 'error',
    message: error.message,
    origin,
    info: {
      graphqlErrors: error.graphQLErrors,
      networkError: error.networkError,
      ...(variables ? { variables } : {}),
    },
  });
}

export function logRQGraphqlError({
  error,
  origin,
  variables,
}: {
  error: Error | Error[];
  origin: string;
  variables?: Record<string, unknown>;
}) {
  log({
    type: 'error',
    message: Array.isArray(error) ? error.map((e) => e.message).join(', ') : error.message,
    origin,
    info: {
      error,
      ...(variables ? { variables } : {}),
    },
  });
}

export { consoleLog } from './utils';

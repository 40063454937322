import styled from 'styled-components';

import { EXPANDED_WIDTH, COLLAPSED_WIDTH } from 'services/menu/config';

export const Container = styled.div`
  display: flex;
  background: ${({ theme }) =>
    `linear-gradient(to left bottom, ${theme.colors.background.df1} 0%, ${theme.colors.background.df2} 100%)`};
  min-height: 100%;
`;

// overflow: hidden; needed for sidebar
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  width: 100%;
  width: ${({ $width }) => $width}px;
  min-width: ${({ $width }) => $width}px;
  margin-left: ${({ $expanded }) => ($expanded ? EXPANDED_WIDTH : COLLAPSED_WIDTH)}px;
  position: relative;
  overflow: hidden;
  transition: all 200ms ease-in-out;
  transition-delay: 300ms;
  transform: translate3d(0, 0, 0);
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  a {
    text-decoration: none;
  }
`;

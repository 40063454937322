import React, { useState, useCallback, useEffect, useMemo } from 'react';
import _debounce from 'lodash/debounce';
import FlatInput from 'components/common/FlatInput';

const SearchInput = ({
  collection,
  value,
  onChange,
  size = 'small',
  placeholder = 'search...',
  className,
  debounceTime = 200,
}) => {
  const [uncontrolledValue, setUncontrolledValue] = useState('');
  const isControlled = value !== undefined;

  const debouncedOnChange = useCallback(
    (inputValue) => {
      if (onChange) {
        onChange(inputValue);
      }
    },
    [onChange],
  );

  const debouncedCallback = useMemo(
    () => _debounce(debouncedOnChange, debounceTime),
    [debouncedOnChange, debounceTime],
  );

  useEffect(() => {
    return () => {
      debouncedCallback.cancel();
    };
  }, [debouncedCallback]);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (!isControlled) {
      setUncontrolledValue(inputValue);
    }
    debouncedCallback(inputValue);
  };

  const actualValue = isControlled ? value : uncontrolledValue;

  if (collection && collection.length > 0) {
    return (
      <FlatInput
        data-test-id="page-search-input"
        size={size}
        placeholder={placeholder}
        onChange={handleChange}
        className={className}
        value={actualValue}
      />
    );
  }

  return null;
};

export default SearchInput;

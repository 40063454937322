import { consoleLog } from 'services/log/utils';
import { transformStringToKey } from 'utils/pql';

const transform = transformStringToKey;

const addQuery = (registry, groupKey, query) => {
  const tQuery = transform(query);
  const tKey = transform(groupKey);
  if (registry[tKey] && registry[tKey].includes(tQuery)) {
    return registry;
  }
  return {
    ...registry,
    [tKey]: [...(registry[tKey] || []), tQuery],
  };
};

const removeQuery = (registry, groupKey, query) => {
  const tQuery = transform(query);
  const tKey = transform(groupKey);
  if (!registry[tKey] || !registry[tKey].includes(tQuery)) {
    return registry;
  }
  return {
    ...registry,
    [tKey]: registry[tKey].filter((q) => q !== tQuery),
  };
};

// tracks ongoing searches
const createSearchRegistry = (set, get) => ({
  registry: {},

  registerComponent: (groupKey, pqlQuery) => {
    if (!groupKey || !pqlQuery) {
      consoleLog('ERROR', 'searchRegistry.registerComponent: invalid arguments', {
        groupKey,
        pqlQuery,
      });
      return;
    }
    set((prev) => ({
      searchRegistry: {
        ...prev.searchRegistry,
        registry: addQuery(prev.searchRegistry.registry, groupKey, pqlQuery),
      },
    }));
  },

  deregisterComponent: (groupKey, pqlQuery) => {
    if (!groupKey || !pqlQuery) {
      consoleLog('ERROR', 'searchRegistry.deregisterComponent: invalid arguments', {
        groupKey,
        pqlQuery,
      });
      return;
    }
    set((prev) => ({
      searchRegistry: {
        ...prev.searchRegistry,
        registry: removeQuery(prev.searchRegistry.registry, groupKey, pqlQuery),
      },
    }));
  },

  clearRegistry: () => {
    set((prev) => ({ searchRegistry: { ...prev.searchRegistry, registry: [] } }));
  },

  isAnySearchInProgress: (groupKey) => get().searchRegistry.registry[transform(groupKey)]?.length > 0,
});

export default createSearchRegistry;

import styled, { css } from 'styled-components';

import ButtonGroupOrig from 'components/common/ButtonGroup';

export const Container = styled.div`
  display: flex;
  position: relative;
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  z-index: 1;

  &:hover {
    background: ${({ theme }) => theme.colors.background.default};
  }

  ${({ $active }) =>
    $active &&
    css`
      background: ${({ theme }) => theme.colors.background.alt1};
      z-index: 101;

      &:hover {
        background: ${({ theme }) => theme.colors.background.alt1};
      }
    `}
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Timestamp = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.subtle};
`;

export const Count = styled.div`
  font-size: 16px;
  margin-top: -2px;
  color: ${({ theme }) => theme.palette.danger[0]};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 6px;
  min-height: 24px;
`;

export const ButtonGroup = styled(ButtonGroupOrig)`
  margin-right: 0px;
`;

import React, { useState, useEffect, useContext } from 'react';

import { MenuStateProvider, MenuState } from 'services/menu';
import { EXPANDED_WIDTH, COLLAPSED_WIDTH } from 'services/menu/config';

import { useDebouncedWindowSize, useConstant } from 'utils/hooks';
import { getWindowWidth } from 'utils/window';
import PageHeader from './PageHeader';

import MainMenu from './MainMenu';
import InitialBootstrap from './InitialBootstrap';
import { Container, Content, Body } from './index.styled';

const DEBOUNCE_TIME = 333;

const FullAppLayout = React.memo((props) => {
  const {
    state: { expanded },
    setExpanded,
  } = useContext(MenuState);

  const windowSize = useDebouncedWindowSize(DEBOUNCE_TIME);
  const navWidth = expanded ? EXPANDED_WIDTH : COLLAPSED_WIDTH;

  // menu starts expanded on very large screens
  const [bodyWidth, setBodyWidth] = useState(
    useConstant(() => getWindowWidth() - (expanded ? EXPANDED_WIDTH : COLLAPSED_WIDTH)),
  );

  useEffect(() => {
    setBodyWidth(getWindowWidth() - navWidth);
  }, [windowSize.width, navWidth]);

  return (
    <Container>
      <MainMenu expanded={expanded} setExpanded={setExpanded} />
      <Content $expanded={expanded} $width={bodyWidth}>
        <PageHeader />
        <Body>{props.children}</Body>
      </Content>
    </Container>
  );
});

const FullAppLayoutWrapper = (props) => {
  return (
    <MenuStateProvider>
      <InitialBootstrap />
      <FullAppLayout {...props} />
    </MenuStateProvider>
  );
};

export default FullAppLayoutWrapper;

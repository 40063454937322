import { PAGES } from 'consts/pages';
import type { Page } from 'consts/pages';
import type { Milliseconds } from 'types/time';

export const TIME_IN_DAY_FORMAT_WITHOUT_SECONDS = 'HH:mm';
export const PQL_TIME_IN_DAY_FORMAT_WITHOUT_SECONDS = 'hh:mmaa';
export const US_FULL_DATE = 'MM/dd/yyyy hh:mm aa';
export const US_TIME_IN_DAY = 'hh:mm aa';
export const TIME_IN_DAY_FORMAT = 'HH:mm:ss';
export const DAY_IN_WEEK_SHORT_FORMAT = 'dd';
export const DAY_IN_YEAR_FORMAT = 'MM/dd/yyyy';
export const DAY_IN_YEAR_PQL_FORMAT = 'M/d/yyyy';
export const DAY_IN_YEAR_SHORT_FORMAT = 'MM/dd/yy';
export const DAY_MONTH_ABBR_FORMAT = 'MMM do';
export const DAY_MONTH_FORMAT = 'MMMM do';
export const DAY_MONTH_YEAR_ABBR_FORMAT = `${DAY_MONTH_ABBR_FORMAT}, yyyy`;
export const MONTH_YEAR_FULL_FORMAT = 'MMMM yyyy';
export const MONTH_YEAR_SHORT_FORMAT = 'MM/dd';
export const FULL_DATE_FORMAT = `${DAY_IN_YEAR_FORMAT} ${TIME_IN_DAY_FORMAT_WITHOUT_SECONDS}`;
export const PQL_FULL_DATE_FORMAT = `${DAY_IN_YEAR_FORMAT} ${PQL_TIME_IN_DAY_FORMAT_WITHOUT_SECONDS}`;
export const RECORD_HEADER_FORMAT = `MMMM dd, yyyy, EEEE, ${TIME_IN_DAY_FORMAT}`;
export const SHORT_DAY_NAME_FORMAT = 'EEEEEE';

export const MIN_INTERVAL: { [key in Page]?: Milliseconds } & { default: Milliseconds } = {
  [PAGES.events]: (60 * 60 * 1000) as Milliseconds, // 1 hour in ms
  default: (60 * 1000) as Milliseconds,
};

export const MONTHS: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DAYS_IN_WEEK: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

type Timezone = {
  utc: string[];
};
export const TIMEZONE: Timezone[] = [
  {
    utc: ['Etc/GMT+12'],
  },
  {
    utc: ['Etc/GMT+11', 'Pacific/Midway', 'Pacific/Niue', 'Pacific/Pago_Pago'],
  },
  {
    utc: ['Etc/GMT+10', 'Pacific/Honolulu', 'Pacific/Johnston', 'Pacific/Rarotonga', 'Pacific/Tahiti'],
  },
  {
    utc: ['America/Anchorage', 'America/Juneau', 'America/Nome', 'America/Sitka', 'America/Yakutat'],
  },
  {
    utc: ['America/Santa_Isabel'],
  },
  {
    utc: ['America/Dawson', 'America/Los_Angeles', 'America/Tijuana', 'America/Vancouver', 'America/Whitehorse'],
  },
  {
    utc: [
      'America/Dawson',
      'America/Los_Angeles',
      'America/Tijuana',
      'America/Vancouver',
      'America/Whitehorse',
      'PST8PDT',
    ],
  },
  {
    utc: ['America/Creston', 'America/Dawson_Creek', 'America/Hermosillo', 'America/Phoenix', 'Etc/GMT+7'],
  },
  {
    utc: ['America/Chihuahua', 'America/Mazatlan'],
  },
  {
    utc: [
      'America/Boise',
      'America/Cambridge_Bay',
      'America/Denver',
      'America/Edmonton',
      'America/Inuvik',
      'America/Ojinaga',
      'America/Yellowknife',
      'MST7MDT',
    ],
  },
  {
    utc: [
      'America/Belize',
      'America/Costa_Rica',
      'America/El_Salvador',
      'America/Guatemala',
      'America/Managua',
      'America/Tegucigalpa',
      'Etc/GMT+6',
      'Pacific/Galapagos',
    ],
  },
  {
    utc: [
      'America/Chicago',
      'America/Indiana/Knox',
      'America/Indiana/Tell_City',
      'America/Matamoros',
      'America/Menominee',
      'America/North_Dakota/Beulah',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/Rainy_River',
      'America/Rankin_Inlet',
      'America/Resolute',
      'America/Winnipeg',
      'CST6CDT',
    ],
  },
  {
    utc: ['America/Bahia_Banderas', 'America/Cancun', 'America/Merida', 'America/Mexico_City', 'America/Monterrey'],
  },
  {
    utc: ['America/Regina', 'America/Swift_Current'],
  },
  {
    utc: [
      'America/Bogota',
      'America/Cayman',
      'America/Coral_Harbour',
      'America/Eirunepe',
      'America/Guayaquil',
      'America/Jamaica',
      'America/Lima',
      'America/Panama',
      'America/Rio_Branco',
      'Etc/GMT+5',
    ],
  },
  {
    utc: [
      'America/Detroit',
      'America/Havana',
      'America/Indiana/Petersburg',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Iqaluit',
      'America/Kentucky/Monticello',
      'America/Louisville',
      'America/Montreal',
      'America/Nassau',
      'America/New_York',
      'America/Nipigon',
      'America/Pangnirtung',
      'America/Port-au-Prince',
      'America/Thunder_Bay',
      'America/Toronto',
      'EST5EDT',
    ],
  },
  {
    utc: ['America/Indiana/Marengo', 'America/Indiana/Vevay', 'America/Indianapolis'],
  },
  {
    utc: ['America/Caracas'],
  },
  {
    utc: ['America/Asuncion'],
  },
  {
    utc: [
      'America/Glace_Bay',
      'America/Goose_Bay',
      'America/Halifax',
      'America/Moncton',
      'America/Thule',
      'Atlantic/Bermuda',
    ],
  },
  {
    utc: ['America/Campo_Grande', 'America/Cuiaba'],
  },
  {
    utc: [
      'America/Anguilla',
      'America/Antigua',
      'America/Aruba',
      'America/Barbados',
      'America/Blanc-Sablon',
      'America/Boa_Vista',
      'America/Curacao',
      'America/Dominica',
      'America/Grand_Turk',
      'America/Grenada',
      'America/Guadeloupe',
      'America/Guyana',
      'America/Kralendijk',
      'America/La_Paz',
      'America/Lower_Princes',
      'America/Manaus',
      'America/Marigot',
      'America/Martinique',
      'America/Montserrat',
      'America/Port_of_Spain',
      'America/Porto_Velho',
      'America/Puerto_Rico',
      'America/Santo_Domingo',
      'America/St_Barthelemy',
      'America/St_Kitts',
      'America/St_Lucia',
      'America/St_Thomas',
      'America/St_Vincent',
      'America/Tortola',
      'Etc/GMT+4',
    ],
  },
  {
    utc: ['America/Santiago', 'Antarctica/Palmer'],
  },
  {
    utc: ['America/St_Johns'],
  },
  {
    utc: ['America/Sao_Paulo'],
  },
  {
    utc: [
      'America/Argentina/La_Rioja',
      'America/Argentina/Rio_Gallegos',
      'America/Argentina/Salta',
      'America/Argentina/San_Juan',
      'America/Argentina/San_Luis',
      'America/Argentina/Tucuman',
      'America/Argentina/Ushuaia',
      'America/Buenos_Aires',
      'America/Catamarca',
      'America/Cordoba',
      'America/Jujuy',
      'America/Mendoza',
    ],
  },
  {
    utc: [
      'America/Araguaina',
      'America/Belem',
      'America/Cayenne',
      'America/Fortaleza',
      'America/Maceio',
      'America/Paramaribo',
      'America/Recife',
      'America/Santarem',
      'Antarctica/Rothera',
      'Atlantic/Stanley',
      'Etc/GMT+3',
    ],
  },
  {
    utc: ['America/Godthab'],
  },
  {
    utc: ['America/Montevideo'],
  },
  {
    utc: ['America/Bahia'],
  },
  {
    utc: ['America/Noronha', 'Atlantic/South_Georgia', 'Etc/GMT+2'],
  },
  {
    utc: [],
  },
  {
    utc: ['America/Scoresbysund', 'Atlantic/Azores'],
  },
  {
    utc: ['Atlantic/Cape_Verde', 'Etc/GMT+1'],
  },
  {
    utc: ['Africa/Casablanca', 'Africa/El_Aaiun'],
  },
  {
    utc: ['America/Danmarkshavn', 'Etc/GMT'],
  },
  {
    utc: ['Europe/Isle_of_Man', 'Europe/Guernsey', 'Europe/Jersey', 'Europe/London'],
  },
  {
    utc: ['Europe/Isle_of_Man', 'Europe/Guernsey', 'Europe/Jersey', 'Europe/London'],
  },
  {
    utc: ['Atlantic/Canary', 'Atlantic/Faeroe', 'Atlantic/Madeira', 'Europe/Dublin', 'Europe/Lisbon'],
  },
  {
    utc: [
      'Africa/Abidjan',
      'Africa/Accra',
      'Africa/Bamako',
      'Africa/Banjul',
      'Africa/Bissau',
      'Africa/Conakry',
      'Africa/Dakar',
      'Africa/Freetown',
      'Africa/Lome',
      'Africa/Monrovia',
      'Africa/Nouakchott',
      'Africa/Ouagadougou',
      'Africa/Sao_Tome',
      'Atlantic/Reykjavik',
      'Atlantic/St_Helena',
    ],
  },
  {
    utc: [
      'Arctic/Longyearbyen',
      'Europe/Amsterdam',
      'Europe/Andorra',
      'Europe/Berlin',
      'Europe/Busingen',
      'Europe/Gibraltar',
      'Europe/Luxembourg',
      'Europe/Malta',
      'Europe/Monaco',
      'Europe/Oslo',
      'Europe/Rome',
      'Europe/San_Marino',
      'Europe/Stockholm',
      'Europe/Vaduz',
      'Europe/Vatican',
      'Europe/Vienna',
      'Europe/Zurich',
    ],
  },
  {
    utc: [
      'Europe/Belgrade',
      'Europe/Bratislava',
      'Europe/Budapest',
      'Europe/Ljubljana',
      'Europe/Podgorica',
      'Europe/Prague',
      'Europe/Tirane',
    ],
  },
  {
    utc: ['Africa/Ceuta', 'Europe/Brussels', 'Europe/Copenhagen', 'Europe/Madrid', 'Europe/Paris'],
  },
  {
    utc: ['Europe/Sarajevo', 'Europe/Skopje', 'Europe/Warsaw', 'Europe/Zagreb'],
  },
  {
    utc: [
      'Africa/Algiers',
      'Africa/Bangui',
      'Africa/Brazzaville',
      'Africa/Douala',
      'Africa/Kinshasa',
      'Africa/Lagos',
      'Africa/Libreville',
      'Africa/Luanda',
      'Africa/Malabo',
      'Africa/Ndjamena',
      'Africa/Niamey',
      'Africa/Porto-Novo',
      'Africa/Tunis',
      'Etc/GMT-1',
    ],
  },
  {
    utc: ['Africa/Windhoek'],
  },
  {
    utc: ['Asia/Nicosia', 'Europe/Athens', 'Europe/Bucharest', 'Europe/Chisinau'],
  },
  {
    utc: ['Asia/Beirut'],
  },
  {
    utc: ['Africa/Cairo'],
  },
  {
    utc: ['Asia/Damascus'],
  },
  {
    utc: [
      'Asia/Nicosia',
      'Europe/Athens',
      'Europe/Bucharest',
      'Europe/Chisinau',
      'Europe/Helsinki',
      'Europe/Kiev',
      'Europe/Mariehamn',
      'Europe/Nicosia',
      'Europe/Riga',
      'Europe/Sofia',
      'Europe/Tallinn',
      'Europe/Uzhgorod',
      'Europe/Vilnius',
      'Europe/Zaporozhye',
    ],
  },
  {
    utc: [
      'Africa/Blantyre',
      'Africa/Bujumbura',
      'Africa/Gaborone',
      'Africa/Harare',
      'Africa/Johannesburg',
      'Africa/Kigali',
      'Africa/Lubumbashi',
      'Africa/Lusaka',
      'Africa/Maputo',
      'Africa/Maseru',
      'Africa/Mbabane',
      'Etc/GMT-2',
    ],
  },
  {
    utc: [
      'Europe/Helsinki',
      'Europe/Kiev',
      'Europe/Mariehamn',
      'Europe/Riga',
      'Europe/Sofia',
      'Europe/Tallinn',
      'Europe/Uzhgorod',
      'Europe/Vilnius',
      'Europe/Zaporozhye',
    ],
  },
  {
    utc: ['Europe/Istanbul'],
  },
  {
    utc: ['Asia/Jerusalem'],
  },
  {
    utc: ['Africa/Tripoli'],
  },
  {
    utc: ['Asia/Amman'],
  },
  {
    utc: ['Asia/Baghdad'],
  },
  {
    utc: ['Europe/Kaliningrad', 'Europe/Minsk'],
  },
  {
    utc: ['Asia/Aden', 'Asia/Bahrain', 'Asia/Kuwait', 'Asia/Qatar', 'Asia/Riyadh'],
  },
  {
    utc: [
      'Africa/Addis_Ababa',
      'Africa/Asmera',
      'Africa/Dar_es_Salaam',
      'Africa/Djibouti',
      'Africa/Juba',
      'Africa/Kampala',
      'Africa/Khartoum',
      'Africa/Mogadishu',
      'Africa/Nairobi',
      'Antarctica/Syowa',
      'Etc/GMT-3',
      'Indian/Antananarivo',
      'Indian/Comoro',
      'Indian/Mayotte',
    ],
  },
  {
    utc: ['Europe/Kirov', 'Europe/Moscow', 'Europe/Simferopol', 'Europe/Volgograd'],
  },
  {
    utc: ['Europe/Astrakhan', 'Europe/Samara', 'Europe/Ulyanovsk'],
  },
  {
    utc: ['Asia/Tehran'],
  },
  {
    utc: ['Asia/Dubai', 'Asia/Muscat', 'Etc/GMT-4'],
  },
  {
    utc: ['Asia/Baku'],
  },
  {
    utc: ['Indian/Mahe', 'Indian/Mauritius', 'Indian/Reunion'],
  },
  {
    utc: ['Asia/Tbilisi'],
  },
  {
    utc: ['Asia/Yerevan'],
  },
  {
    utc: ['Asia/Kabul'],
  },
  {
    utc: [
      'Antarctica/Mawson',
      'Asia/Aqtau',
      'Asia/Aqtobe',
      'Asia/Ashgabat',
      'Asia/Dushanbe',
      'Asia/Oral',
      'Asia/Samarkand',
      'Asia/Tashkent',
      'Etc/GMT-5',
      'Indian/Kerguelen',
      'Indian/Maldives',
    ],
  },
  {
    utc: ['Asia/Yekaterinburg'],
  },
  {
    utc: ['Asia/Karachi'],
  },
  {
    utc: ['Asia/Kolkata'],
  },
  {
    utc: ['Asia/Colombo'],
  },
  {
    utc: ['Asia/Kathmandu'],
  },
  {
    utc: [
      'Antarctica/Vostok',
      'Asia/Almaty',
      'Asia/Bishkek',
      'Asia/Qyzylorda',
      'Asia/Urumqi',
      'Etc/GMT-6',
      'Indian/Chagos',
    ],
  },
  {
    utc: ['Asia/Dhaka', 'Asia/Thimphu'],
  },
  {
    utc: ['Asia/Rangoon', 'Indian/Cocos'],
  },
  {
    utc: [
      'Antarctica/Davis',
      'Asia/Bangkok',
      'Asia/Hovd',
      'Asia/Jakarta',
      'Asia/Phnom_Penh',
      'Asia/Pontianak',
      'Asia/Saigon',
      'Asia/Vientiane',
      'Etc/GMT-7',
      'Indian/Christmas',
    ],
  },
  {
    utc: ['Asia/Novokuznetsk', 'Asia/Novosibirsk', 'Asia/Omsk'],
  },
  {
    utc: ['Asia/Hong_Kong', 'Asia/Macau', 'Asia/Shanghai'],
  },
  {
    utc: ['Asia/Krasnoyarsk'],
  },
  {
    utc: [
      'Asia/Brunei',
      'Asia/Kuala_Lumpur',
      'Asia/Kuching',
      'Asia/Makassar',
      'Asia/Manila',
      'Asia/Singapore',
      'Etc/GMT-8',
    ],
  },
  {
    utc: ['Antarctica/Casey', 'Australia/Perth'],
  },
  {
    utc: ['Asia/Taipei'],
  },
  {
    utc: ['Asia/Choibalsan', 'Asia/Ulaanbaatar'],
  },
  {
    utc: ['Asia/Irkutsk'],
  },
  {
    utc: ['Asia/Dili', 'Asia/Jayapura', 'Asia/Tokyo', 'Etc/GMT-9', 'Pacific/Palau'],
  },
  {
    utc: ['Asia/Pyongyang', 'Asia/Seoul'],
  },
  {
    utc: ['Australia/Adelaide', 'Australia/Broken_Hill'],
  },
  {
    utc: ['Australia/Darwin'],
  },
  {
    utc: ['Australia/Brisbane', 'Australia/Lindeman'],
  },
  {
    utc: ['Australia/Melbourne', 'Australia/Sydney'],
  },
  {
    utc: [
      'Antarctica/DumontDUrville',
      'Etc/GMT-10',
      'Pacific/Guam',
      'Pacific/Port_Moresby',
      'Pacific/Saipan',
      'Pacific/Truk',
    ],
  },
  {
    utc: ['Australia/Currie', 'Australia/Hobart'],
  },
  {
    utc: ['Asia/Chita', 'Asia/Khandyga', 'Asia/Yakutsk'],
  },
  {
    utc: [
      'Antarctica/Macquarie',
      'Etc/GMT-11',
      'Pacific/Efate',
      'Pacific/Guadalcanal',
      'Pacific/Kosrae',
      'Pacific/Noumea',
      'Pacific/Ponape',
    ],
  },
  {
    utc: ['Asia/Sakhalin', 'Asia/Ust-Nera', 'Asia/Vladivostok'],
  },
  {
    utc: ['Antarctica/McMurdo', 'Pacific/Auckland'],
  },
  {
    utc: [
      'Etc/GMT-12',
      'Pacific/Funafuti',
      'Pacific/Kwajalein',
      'Pacific/Majuro',
      'Pacific/Nauru',
      'Pacific/Tarawa',
      'Pacific/Wake',
      'Pacific/Wallis',
    ],
  },
  {
    utc: ['Pacific/Fiji'],
  },
  {
    utc: ['Asia/Anadyr', 'Asia/Kamchatka', 'Asia/Magadan', 'Asia/Srednekolymsk'],
  },
  {
    utc: ['Asia/Kamchatka'],
  },
  {
    utc: ['Etc/GMT-13', 'Pacific/Enderbury', 'Pacific/Fakaofo', 'Pacific/Tongatapu'],
  },
  {
    utc: ['Pacific/Apia'],
  },
];

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';

import { truncate } from 'utils/string';
import { NetworkIcon, ActivityIcon } from 'components/common/Icons';
import Sidebar from 'components/common/Sidebar';

import { AssignButton, TSCountWrapper } from './index.styled';
import SidebarAssignment from './SidebarAssignment';

const resolveAssignment = (assignedObject) => {
  if (!assignedObject.Tenants || !assignedObject.Sensors) {
    return null;
  }
  if (assignedObject.Tenants.length === 0 && assignedObject.Sensors.length === 0) {
    return 'Everything';
  }
  // single tenant
  if (assignedObject.Tenants.length === 1 && assignedObject.Sensors.length === 0) {
    return truncate(assignedObject.Tenants[0].name, 18);
  }
  // single sensor
  if (assignedObject.Tenants.length === 0 && assignedObject.Sensors.length === 1) {
    return truncate(assignedObject.Sensors[0].name, 18);
  }
  // else
  const tenantCount = assignedObject.Tenants.length;
  const sensorCount = assignedObject.Sensors.length;

  return (
    <TSCountWrapper>
      {tenantCount > 0 && (
        <div>
          {tenantCount}x <NetworkIcon />
        </div>
      )}
      {sensorCount > 0 && (
        <div>
          {sensorCount}x <ActivityIcon />
        </div>
      )}
    </TSCountWrapper>
  );
};

const SensorAssignmentButton = ({ disabled, assignedObject, resource, onClose }) => {
  const queryClient = useQueryClient();
  const assignedOn = resolveAssignment(assignedObject);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    if (disabled) {
      return;
    }
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    queryClient.invalidateQueries(resource);
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <>
      <AssignButton variant="text" disabled={disabled} onClick={handleClick} data-test-id="assign-sensor-button">
        {assignedOn ? (
          <>
            <span>Assigned on:</span>
            <div>{assignedOn}</div>
          </>
        ) : (
          <>
            <span>Assign</span>
            <span>tenants / sensors </span>
          </>
        )}
      </AssignButton>
      <Sidebar onClose={handleClose} visible={isOpen}>
        {isOpen && assignedObject && assignedObject.id && (
          <SidebarAssignment resource={resource} draft={assignedObject} />
        )}
      </Sidebar>
    </>
  );
};

SensorAssignmentButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  assignedObject: PropTypes.object.isRequired,
  resource: PropTypes.oneOf(['tasks', 'enrichments', 'indicatoroverrides', 'integrations']).isRequired,
  onClose: PropTypes.func,
};

export default SensorAssignmentButton;

import React, { useReducer } from 'react';
import clsx from 'clsx';
import { Theme } from '@radix-ui/themes';
import { ThemeProvider } from 'styled-components';

import { useKeyboardShortcut } from 'utils/hooks';
import { LS_KEYS, setLocalItem, getLocalItem } from 'utils/localStorage';
import GlobalStyle from 'components/styled/GlobalStyle';

import lightTheme from './lightTheme';
import darkTheme from './darkTheme';

const initialState = {
  themeName: getLocalItem(LS_KEYS.theme.key) || 'light',

  /** @function
   * @param {'light'|'dark'} themeName;
   * @returns {void}
   */
  changeTheme: (
    // eslint-disable-next-line no-unused-vars
    themeName,
  ) => {},
};

export const ThemeState = React.createContext({
  ...initialState,
});

export const getTheme = (themeName) => {
  return themeName.toLowerCase() === 'light' ? lightTheme : darkTheme;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'THEME_CHANGE':
      return {
        ...state,
        themeName: action.payload,
      };
    default:
      return state;
  }
};

export default function ThemeStateProvider({ forceTheme, children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const changeTheme = (themeName) => {
    setLocalItem(LS_KEYS.theme.key, themeName);
    dispatch({ type: 'THEME_CHANGE', payload: themeName });
  };

  const value = {
    ...state,
    changeTheme,
  };

  useKeyboardShortcut(
    () => {
      changeTheme(state.themeName === 'light' ? 'dark' : 'light');
    },
    {
      key: 't',
      shiftKey: true,
      metaKey: true,
      ctrlKey: true,
    },
  );

  // used for storybook
  const currentTheme = forceTheme ?? state.themeName;

  return (
    <Theme
      scaling="100%"
      accentColor="orange"
      grayColor="gray"
      className={clsx('flex min-h-full leading-[normal]', currentTheme === 'dark' && 'dark')}
      appearance={currentTheme || 'light'}
    >
      <ThemeState.Provider value={value}>
        <ThemeProvider theme={getTheme(currentTheme)}>
          <>
            <GlobalStyle />
            {children}
          </>
        </ThemeProvider>
      </ThemeState.Provider>
    </Theme>
  );
}

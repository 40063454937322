import _countBy from 'lodash/countBy';
import _toPairs from 'lodash/toPairs';
import _flatten from 'lodash/flatten';
import _remove from 'lodash/remove';
import _findIndex from 'lodash/findIndex';
import _cloneDeep from 'lodash/cloneDeep';
import _min from 'lodash/min';
import _max from 'lodash/max';
import _sortBy from 'lodash/sortBy';

import { getRecordTs } from 'utils/records';
import type { AnyRecord } from 'types/records';

import getType from './type';

export function _identity<T>(x: T): T {
  return x;
}

export function last<T extends unknown[]>(x: T): T[number] {
  return x[x.length - 1];
}

// concats two arrays, much faster than native implementation
// MUTATES THE ARRAY
export function concat<T, K>(arr1: (T | K)[], arr2: K[]): (T | K)[] {
  const arr1Length = arr1.length;
  const arr2Length = arr2.length;

  // Pre allocate size
  arr1.length = arr1Length + arr2Length;

  // Add arr2 items to arr1
  for (let i = 0; i < arr2Length; i++) {
    arr1[arr1Length + i] = arr2[i];
  }
  return arr1;
}

function _getPropType<T>(itemKey: string, collection: Record<string, T>[]): string | undefined {
  const colCount = collection.length;
  for (let i = 0; i < colCount; i++) {
    if (collection[i]) {
      return getType(collection[i][itemKey]);
    }
  }
  return undefined;
}

export function countByPropertyAny<T>(
  itemKey: string,
  collection: Record<string, T>[],
  comparator: (a: [string, number], b: [string, number]) => number,
) {
  if (_getPropType(itemKey, collection) === 'Array') {
    return countByPropertyDeep(itemKey, collection, comparator);
  }
  return countByPropertySortBy(itemKey, collection, comparator);
}

// count results by prop key, use comparator to sort
export function countByPropertySortBy<T>(
  itemKey: string,
  collection: Array<Record<string, T>>,
  comparator: (a: [string, number], b: [string, number]) => number,
): Array<[string, number]> {
  return _toPairs(_countBy(collection, (x) => x[itemKey])).sort(comparator);
}

// diggs inside arrays and flattens it before counting
export function countByPropertyDeep<T>(
  itemKey: string,
  collection: Record<string, T>[],
  comparator: (a: [string, number], b: [string, number]) => number,
) {
  const colCount = collection.length;
  const tempArray: T[] = [];
  let y = 0;
  for (let i = 0; i < colCount; i++) {
    const item = collection[i][itemKey];
    if (item && Array.isArray(item) && item.length > 0) {
      tempArray[y] = item;
      y++;
    }
  }

  return _toPairs(_countBy(_flatten(tempArray), _identity)).sort(comparator);
}

export function movingAverage(list: number[], range: number): string[] {
  const res: string[] = [];

  for (let i = range; i < list.length + 1; i++) {
    let sum = 0;
    for (let j = i - range; j < i; j++) {
      sum += list[j];
    }

    const average = sum / range;
    res.push(average.toFixed(2));
  }

  return res;
}

export function updateItemInCollection<T extends Record<string, number | string>>(
  item: T,
  collection: T[],
  idKey: string = 'id',
) {
  const index = _findIndex(collection, (x) => x[idKey].toString() === item[idKey].toString());
  if (index > -1) {
    collection.splice(index, 1, item);
  }
  // to avoid shallow equality and force a rerender
  return _cloneDeep(collection);
}

// groups collection entries in time buckets (mostly for charting purposes)
// period = time bucket in seconds
// options = other params such as fixed t1 and t2, etc.
export function prepareTimeseries<T extends AnyRecord>(
  collection: T[],
  period: number,
  options?: {
    t1?: number;
    t2?: number;
  },
  tsGetter?: (arg: T) => number,
) {
  const getTimestamp = tsGetter || getRecordTs;
  if (collection.length === 0) {
    return null;
  }
  const t1 = options?.t1 || _min(collection.map(getTimestamp));
  const t2 = options?.t2 || _max(collection.map(getTimestamp));

  let idxTs = t1!;
  // prepare the object with timepoints
  const objPeriod: Record<string, { items: T[] }> = {};
  const bucketKeys = [];
  while (t2! >= idxTs) {
    const key = idxTs / period;
    bucketKeys.unshift(key);
    objPeriod[key] = {
      items: [],
    };
    idxTs = idxTs + period;
  }

  // group collection items to proper timepoints
  for (let i = 0; i < collection.length; i++) {
    const record = collection[i];
    const ts = getTimestamp(record) / period;
    const objKey = bucketKeys.find((bucketKey) => ts >= bucketKey)!;
    if (objPeriod[objKey]) {
      objPeriod[objKey].items.push(record);
    }
  }
  return objPeriod;
}

// returns a sum of counts in an array
export function sumCounts(collection: { count: number }[]): number | null {
  if (!Array.isArray(collection)) {
    return null;
  }
  return collection.reduce((acc, cur) => {
    return cur.count ? acc + cur.count : acc;
  }, 0);
}

export function areIntersecting<T>(array1: T[], array2: T[]): boolean {
  return array1.some((item) => array2.includes(item));
}

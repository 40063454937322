import React from 'react';
import styled from 'styled-components';

export const ErrorMessageComponent = styled.div`
  margin-top: 2px;
  color: ${({ theme }) => theme.palette.danger[0]};
`;

export const ErrorMessage = ({ touched, errors, submitted, name, className, dataTestId }) => {
  if (!touched[name] && !submitted) {
    return <ErrorMessageComponent className={className} data-test-id={dataTestId} />;
  }

  return (
    <ErrorMessageComponent className={className} data-test-id={dataTestId}>
      {errors[name]}
    </ErrorMessageComponent>
  );
};

export default ErrorMessage;

import React, { Fragment, useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { MenuState } from 'services/menu';
import { useSensorList } from 'services/sensor';
import { useFeatureFlag } from 'services/debug';
import { areIntersecting } from 'utils/array';
import { SCENE } from 'consts/scenes';
import { PAGES } from 'consts/pages';
import { IDENTITY_PROFILE_TYPES } from 'consts/sensors';

import { Container, Separator, GroupTitle } from './index.styled';
import MenuItem from './MenuItem';
import Logo from './Logo';

import { maybeShowMenuItems } from './utils';

const MainMenu = ({ expanded, setExpanded }) => {
  const location = useLocation();
  const {
    state: { menuConfig },
    setMenuConfig,
  } = useContext(MenuState);
  const shouldShowAIOverview = useFeatureFlag('showAIOverview');
  const { profilesList, activeSensors, sensorList, getSensorListByProfileTypes, getActiveSensorsByProfileTypes } =
    useSensorList();

  const isPathEnabled = useCallback(
    (path) => {
      const profileTypes = Object.values(SCENE).find((scene) => scene.path === path)?.profileTypes;

      if (profileTypes == null || !activeSensors || activeSensors.length === 0) {
        return true;
      }

      const activeSensorsByProfileTypes = getActiveSensorsByProfileTypes(profileTypes);

      return activeSensorsByProfileTypes ? activeSensorsByProfileTypes.length > 0 : true;
    },
    [activeSensors, getActiveSensorsByProfileTypes],
  );

  useEffect(() => {
    const hasOnlyIDMSensors = getSensorListByProfileTypes(IDENTITY_PROFILE_TYPES)?.length === sensorList?.length;

    // will enable these menu items if they pass a check
    setMenuConfig(
      maybeShowMenuItems(menuConfig, [
        {
          path: SCENE.aiOverview.path,
          check: areIntersecting(profilesList, SCENE.aiOverview.profileTypes) && shouldShowAIOverview,
        },
        {
          path: SCENE.cloudapi.path,
          check: areIntersecting(profilesList, SCENE.cloudapi.profileTypes),
        },
        {
          path: SCENE.flowlogs.path,
          check: areIntersecting(profilesList, SCENE.flowlogs.profileTypes),
        },
        {
          path: SCENE.identityManagement.path,
          check: areIntersecting(profilesList, SCENE.identityManagement.profileTypes),
        },
        {
          path: SCENE.indicators.path,
          check: areIntersecting(profilesList, SCENE.indicators.profileTypes),
        },
        {
          path: SCENE.investigator.path,
          check: areIntersecting(profilesList, SCENE.investigator.profileTypes),
        },
        {
          path: SCENE.packetCaptures.path,
          check: areIntersecting(profilesList, SCENE.packetCaptures.profileTypes),
        },
        {
          path: SCENE.baseline.path,
          check: areIntersecting(profilesList, SCENE.baseline.profileTypes),
        },
        {
          path: SCENE[PAGES.executiveSummary].path,
          check: !hasOnlyIDMSensors && areIntersecting(profilesList, SCENE[PAGES.executiveSummary].profileTypes),
        },
      ]),
    );
    // eslint-disable-next-line
  }, [profilesList, sensorList, shouldShowAIOverview]);

  const menuGroups = Object.values(menuConfig);

  const toggleMenu = () => {
    setExpanded(!expanded);
  };

  return (
    <Container $expanded={expanded}>
      <div className="flex flex-col">
        <Logo expanded={expanded} onClick={toggleMenu} alt="MixMode.ai logo" />
        {menuGroups.map((group, i) => {
          const areAllHidden = group.items.every((item) => item.hidden);

          if (areAllHidden) {
            return null;
          }

          return (
            <Fragment key={group.alias}>
              {expanded ? <GroupTitle>{group.name}</GroupTitle> : <Separator $transparent={i === 0} />}
              {group.items.map((item) => {
                if (item.hidden) {
                  return null;
                }

                const to = item.to || `/${group.alias}/${item.alias}`;
                const isActive = location.pathname.includes(to);
                const isEnabled = isPathEnabled(to);

                return (
                  <MenuItem
                    key={item.alias}
                    name={`MainMenu item: ${item.alias}`}
                    to={to}
                    item={item}
                    expanded={expanded}
                    isActive={isActive}
                    isEnabled={isEnabled}
                  />
                );
              })}
            </Fragment>
          );
        })}
      </div>
      <MenuItem
        key="settings"
        name="MainMenu item: Settings"
        to="/settings/tasks"
        item={{
          alias: 'settings',
          name: 'Settings',
          icon: 'SettingsIcon',
        }}
        expanded={expanded}
        isActive={location.pathname.includes('/settings')}
      />
    </Container>
  );
};

MainMenu.propTypes = {
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
};

export default React.memo(MainMenu);

import { forwardRef, type ReactNode } from 'react';
import styled, { css } from 'styled-components';

type GridPropsStyled = {
  children: ReactNode;
  $templateColumns?: string;
  $templateRows?: string;
  $autoRows?: string;
  $columnGap?: string;
  $rowGap?: string;
  $gap?: string;
  $justifyItems?: string;
};

const GridStyled = styled.div<GridPropsStyled>`
  display: grid;
  grid-column-gap: ${({ $columnGap, $gap }) => $gap || $columnGap || '40px'};
  grid-row-gap: ${({ $rowGap, $gap }) => $gap || $rowGap || '40px'};

  grid-template-columns: ${({ $templateColumns }) => $templateColumns || 'auto auto'};
  grid-template-rows: ${({ $templateRows }) => $templateRows || 'unset'};
  grid-auto-rows: ${({ $autoRows }) => $autoRows || 'unset'};
  justify-items: ${({ $justifyItems }) => $justifyItems || 'unset'};
`;

type GridProps = {
  children: ReactNode;
  templateColumns?: string;
  templateRows?: string;
  autoRows?: string;
  columnGap?: string;
  rowGap?: string;
  gap?: string;
  justifyItems?: string;
};

export const Grid = ({
  children,
  templateColumns,
  templateRows,
  autoRows,
  columnGap,
  rowGap,
  gap,
  justifyItems,
  ...props
}: GridProps) => (
  <GridStyled
    {...props}
    $templateColumns={templateColumns}
    $templateRows={templateRows}
    $autoRows={autoRows}
    $columnGap={columnGap}
    $rowGap={rowGap}
    $gap={gap}
    $justifyItems={justifyItems}
  >
    {children}
  </GridStyled>
);

type DividerPropsStyled = {
  $vertical?: boolean;
  $width?: string;
  $height?: string;
};

const DividerStyled = styled.div<DividerPropsStyled>`
  ${({ $vertical, $width, $height }) =>
    $vertical
      ? css`
          height: 100%;
          width: ${$width || '20px'};
        `
      : css`
          width: 100%;
          height: ${$height || '40px'};
        `}
`;

type DividerProps = {
  vertical?: boolean;
  width?: string;
  height?: string;
};

export const Divider = ({ vertical, width, height }: DividerProps) => (
  <DividerStyled $vertical={vertical} $width={width} $height={height} />
);

// divider with hr
export const Separator = styled.hr`
  border-color: currentColor;
  border-style: solid;
  opacity: 0.2;
  margin: 1em 0;
  height: 0;
  border-width: 1px 0 0 0;
`;
export const VerticalSeparator = styled.div`
  height: 100%;
  border-color: currentColor;
  border-style: solid;
  opacity: 0.2;
  border-width: 0 1px 0 0;
`;

// TODO add theme type
export const BorderBottom = styled.div`
  border-bottom: 1px solid
    ${
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      ({ theme }) => theme.colors.border.default
    };
`;

type FlexPropsStyled = {
  $column?: boolean;
  $justifyContent?: string;
  $alignItems?: string;
  $flex?: string;
};

export const FlexStyled = styled.div<FlexPropsStyled>`
  display: flex;

  ${({ $column }) =>
    $column &&
    css`
      flex-direction: column;
    `}

  ${({ $justifyContent }) =>
    $justifyContent &&
    css`
      justify-content: ${$justifyContent};
    `}

  ${({ $alignItems }) =>
    $alignItems &&
    css`
      align-items: ${$alignItems};
    `}

  ${({ $flex }) =>
    $flex &&
    css`
      flex: ${$flex};
    `}
`;

type FlexProps = {
  column?: boolean;
  justifyContent?: string;
  alignItems?: string;
  flex?: string;
};

export const Flex = forwardRef<HTMLDivElement, FlexProps>(
  ({ column, justifyContent, alignItems, flex, ...props }, ref) => (
    <FlexStyled
      ref={ref}
      {...props}
      $column={column}
      $justifyContent={justifyContent}
      $alignItems={alignItems}
      $flex={flex}
    />
  ),
);

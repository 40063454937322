import { useQuery } from '@tanstack/react-query';

import { useApi } from 'services/api';

/**
 * @param {'id'|'pql} type - Type of bookmarks - Can be ID based or PQL
 */
const useBookmarks = (type = 'id') => {
  const api = useApi();

  return useQuery(
    ['bookmarks', type],
    () => {
      return api.get(`/bookmarks/${type}`).then((response) => {
        return response.items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      });
    },
    {
      enabled: !!api,
    },
  );
};

export default useBookmarks;

import { required } from './shared';

class NopeArray {
  constructor() {
    this.validationRules = [];
    this.itemValidator = null;
  }

  required(message = 'This field is required') {
    this.validationRules.push(required(message));
    return this;
  }

  min(min, message = `Array must have at least ${min} items`) {
    this.validationRules.push((value) => value.length < min && message);
    return this;
  }

  max(max, message = `Array must have at most ${max} items`) {
    this.validationRules.push((value) => value.length > max && message);
    return this;
  }

  of(validator) {
    this.itemValidator = validator;
    return this;
  }

  validate(entry, context) {
    if (!Array.isArray(entry)) {
      return 'Validation target must be an array.';
    }

    for (let rule of this.validationRules) {
      const error = rule(entry, context);
      if (error) {
        return error;
      }
    }

    if (this.itemValidator && entry.length > 0) {
      const itemErrors = entry
        .map((item) => this.itemValidator.validate(item, context))
        .filter((error) => error !== undefined);

      if (itemErrors.length > 0) {
        return itemErrors[0];
      }
    }

    return undefined;
  }
}

export default NopeArray;

import { useCallback, useContext } from 'react';
import _groupBy from 'lodash/groupBy';
import _forEach from 'lodash/forEach';
import _uniq from 'lodash/uniq';

import { SensorsApiContext } from 'services/sensor/SensorsProvider';
import useStore from 'services/store';

const getTenantList = (sensorList) => {
  if (!sensorList) {
    return [];
  }

  const groupedSensorList = _groupBy(sensorList, (s) => s.Tenant.id);
  let tenants = [];
  _forEach(groupedSensorList, (sensorList) => {
    const tmpTenant = { ...sensorList[0].Tenant };
    tmpTenant.sensors = sensorList.map((sensor) => ({ ...sensor }));
    tenants.push(tmpTenant);
  });
  return tenants;
};

const useSensorList = () => {
  const sensorContext = useContext(SensorsApiContext);
  const activeSensors = useStore((state) => state.searchQuery.activeSensors);
  const sensorList = sensorContext.state.sensors;

  const sensorIds = sensorList ? sensorList.map((s) => s.id) : [];
  const tenantList = getTenantList(sensorList);

  const getActiveSensorsByProfileTypes = useCallback(
    (profileTypes) => {
      if (!sensorList) {
        return;
      }
      if (!profileTypes || profileTypes.length === 0) {
        return activeSensors.map((sid) => sensorList.find((s) => s.id === sid));
      }
      return sensorList.filter((s) => profileTypes.includes(s?.Profile?.profileType) && activeSensors.includes(s.id));
    },
    [activeSensors, sensorList],
  );

  /** @function
   * takes array of profile types
   * @param {string[]} profileTypes
   * @returns {any[]}
   */
  const getSensorListByProfileTypes = (profileTypes) => {
    if (!sensorList) {
      return;
    }
    if (!profileTypes || profileTypes.length === 0) {
      return sensorList;
    }
    return sensorList.filter((s) => profileTypes.includes(s?.Profile?.profileType));
  };

  // is profile type in the list of currently active sensors
  const isProfileTypeActive = useCallback(
    (profileType) => {
      const activeSensorsByProfile = getActiveSensorsByProfileTypes([profileType]);
      return activeSensorsByProfile && activeSensorsByProfile.length > 0;
    },
    [getActiveSensorsByProfileTypes],
  );

  const areProfileTypesActive = useCallback(
    (profileTypes) => {
      const activeSensorsByProfile = getActiveSensorsByProfileTypes(profileTypes);
      return activeSensorsByProfile && activeSensorsByProfile.length > 0;
    },
    [getActiveSensorsByProfileTypes],
  );

  return {
    // array of sensors
    sensorList,
    sensorIds,
    // sensors grouped by tenant
    tenantList,
    // unique list of profile types across sensors
    profilesList: _uniq(sensorList?.map((s) => s.Profile?.profileType)),
    //
    findSensorById: (id) => sensorList?.find((x) => parseInt(x.id, 10) === parseInt(id, 10)),
    // sadly we need to rely on label to know which data source sensor belongs to
    findSensorsWithLabel: (label) => sensorList?.filter((x) => x.labels && x.labels.includes(label)),
    getSensorProfileType: (id) => {
      return sensorContext.state.sensors?.find((x) => x.id === id)?.Profile?.profileType || null;
    },

    getTenantIdBySensorId: (sensorId) => {
      return sensorList?.find((x) => x.id === sensorId)?.Tenant?.id || null;
    },
    // active sensors
    activeSensors,
    getActiveSensorsByProfileTypes,
    isProfileTypeActive,
    areProfileTypesActive,
    getSensorListByProfileTypes,
  };
};

export default useSensorList;

import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import { useApi } from 'services/api';
import { useCurrentUser } from 'services/auth';
import { extractErrorMessage } from 'utils/api';

export const UserShape = PropTypes.shape({
  username: PropTypes.string.isRequired,
  createTimestamp: PropTypes.string.isRequired,
  modifyTimestamp: PropTypes.string.isRequired,
  pwdReset: PropTypes.bool.isRequired,
  verified: PropTypes.bool.isRequired,
});

const initialState = {
  users: null,
  userPermissions: null,
  roles: null,
  loading: false,
  loadError: null,
  loadingRoles: false,
  settingRole: {},
  creating: false,
  createError: null,
  disabling: false,
  disableError: null,
  deleting: false,
  deleteError: null,
  loadingPermissions: false,
  loadPermissionsError: null,
  allPermissionsLoading: false,
  allPermissions: null,
  loadAllPermissionsError: null,
  settingPermission: {},
  assigningRolesUsers: {},
  setPermissionError: null,
  permaToken: null,
};

export const UsersState = React.createContext({
  state: {
    ...initialState,
  },
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'GET_USERS':
      return { ...state, loading: true };
    case 'GET_USERS_SUCCESS':
      return {
        ...state,
        loading: false,
        users: action.users,
      };
    case 'GET_USERS_FAIL':
      return {
        ...state,
        loading: false,
        users: [],
        loadError: action.error,
      };
    case 'GET_ROLES':
      return {
        ...state,
        loadingRoles: true,
      };
    case 'GET_ROLES_SUCCESS':
      return {
        ...state,
        loadingRoles: false,
        roles: action.roles,
      };
    case 'GET_ROLES_FAIL':
      return {
        ...state,
        loadingRoles: false,
        roles: [],
        loadRolesError: action.error,
      };
    case 'EDIT_ROLES':
      return {
        ...state,
        editingRole: true,
        editRoleError: null,
      };
    case 'EDIT_ROLES_SUCCESS':
      return {
        ...state,
        editingRole: false,
        roles: state.roles.map((role) => {
          if (role.id === action.role.id) {
            return {
              ...action.role,
              Permissions: action.permissions,
            };
          }

          return role;
        }),
      };
    case 'EDIT_ROLES_FAIL':
      return {
        ...state,
        editingRole: false,
        editRoleError: action.error,
      };
    case 'DELETE_ROLE':
      return {
        ...state,
        deletingRole: true,
        deleteRoleError: null,
      };
    case 'DELETE_ROLE_SUCCESS':
      return {
        ...state,
        deletingRole: false,
        roles: state.roles.filter((role) => role.id !== action.role.id),
      };
    case 'DELETE_ROLE_FAIL':
      return {
        ...state,
        deletingRole: false,
        deleteRoleError: action.error,
      };
    case 'CREATE_ROLE':
      return {
        ...state,
        creatingRole: true,
        createRoleError: null,
      };
    case 'CREATE_ROLE_SUCCESS':
      return {
        ...state,
        creatingRole: false,
        roles: state.roles.concat({ ...action.role, Permissions: action.permissions }),
      };
    case 'CREATE_ROLE_FAIL':
      return {
        ...state,
        creatingRole: false,
        createRoleError: action.error,
      };
    case 'GET_USER_ROLES':
      return {
        ...state,
        userRoles: null,
        loadingUserRoles: true,
        loadUserRolesError: null,
      };
    case 'GET_USER_ROLES_SUCCESS':
      return {
        ...state,
        userRoles: action.userRoles,
        loadingUserRoles: false,
        loadUserRolesError: null,
      };
    case 'GET_USER_ROLES_FAIL':
      return {
        ...state,
        userRoles: null,
        loadingUserRoles: false,
        loadUserRolesError: action.error,
      };
    case 'SET_USER_ROLE':
      return {
        ...state,
        settingRole: {
          ...state.settingRole,
          [action.roleId]: true,
        },
        setRoleError: null,
      };
    case 'SET_USER_ROLE_SUCCESS': {
      const userRoles = state.userRoles?.map((role) => {
        if (role.id === action.roleId) {
          return { ...role, hasRole: action.hasRole };
        }
        return role;
      });
      return {
        ...state,
        userRoles,
        settingRole: {
          ...state.settingRole,
          [action.roleId]: false,
        },
        setRoleError: null,
      };
    }

    case 'SET_USER_ROLE_FAIL':
      return {
        ...state,
        settingRole: {
          ...state.settingRole,
          [action.roleId]: false,
        },
        setRoleError: action.error,
      };

    case 'ASSIGN_USER_TO_ROLE':
      return {
        ...state,
        assigningRolesUsers: {
          ...state.assigningRolesUsers,
          [action.username]: true,
        },
        assignUserToRoleError: null,
      };
    case 'ASSIGN_USER_TO_ROLE_SUCCESS': {
      const users = state.users.map((user) => {
        if (user.username !== action.username) {
          return user;
        }

        const hasRole = user.Roles.find((g) => g.id === action.roleId);

        if (hasRole) {
          return {
            ...user,
            Roles: user.Roles.filter((g) => g.id !== action.roleId),
          };
        }

        return {
          ...user,
          Roles: user.Roles.concat(state.roles.find((g) => g.id === action.roleId)),
        };
      });

      return {
        ...state,
        users,
        assigningRolesUsers: {
          ...state.assigningRolesUsers,
          [action.username]: false,
        },
      };
    }

    case 'ASSIGN_USER_TO_ROLE_FAIL':
      return {
        ...state,
        assigningRolesUsers: {
          ...state.assigningRolesUsers,
          [action.username]: false,
        },
        assignUserToRoleError: action.error,
      };

    case 'INVITE_USER':
      return {
        ...state,
        creating: true,
        createError: null,
      };
    case 'INVITE_USER_SUCCESS':
      return {
        ...state,
        creating: false,
        createError: null,
      };
    case 'INVITE_USER_FAIL':
      return {
        ...state,
        creating: false,
        createError: action.error,
      };
    case 'DISABLE_USER':
      return {
        ...state,
        disabling: true,
        disableError: null,
      };
    case 'DISABLE_USER_SUCCESS':
      return {
        ...state,
        disabling: false,
        disableError: null,
      };
    case 'DISABLE_USER_FAIL':
      return {
        ...state,
        disabling: false,
        disableError: action.error,
      };
    case 'DELETE_USER':
      return {
        ...state,
        deleting: true,
        deleteError: null,
      };
    case 'DELETE_USER_SUCCESS':
      return {
        ...state,
        deleting: false,
        deleteError: null,
      };
    case 'DELETE_USER_FAIL':
      return {
        ...state,
        deleting: false,
        deleteError: action.error,
      };
    case 'GET_USER_PERMISSIONS':
      return {
        ...state,
        userPermissions: null,
        loadingPermissions: true,
        loadPermissionsError: null,
      };
    case 'GET_USER_PERMISSIONS_SUCCESS':
      return {
        ...state,
        userPermissions: action.userPermissions,
        loadingPermissions: false,
        loadPermissionsError: null,
      };
    case 'GET_USER_PERMISSIONS_FAIL':
      return {
        ...state,
        userPermissions: null,
        loadingPermissions: false,
        loadPermissionsError: action.error,
      };
    case 'GET_ALL_PERMISSIONS':
      return {
        ...state,
        allPermissionsLoading: true,
        allPermissions: null,
        loadAllPermissionsError: null,
      };
    case 'GET_ALL_PERMISSIONS_SUCCESS':
      return {
        ...state,
        allPermissionsLoading: false,
        allPermissions: action.permissions,
      };
    case 'GET_ALL_PERMISSIONS_FAIL':
      return {
        ...state,
        allPermissionsLoading: false,
        loadAllPermissionsError: action.error,
      };
    case 'SET_USER_PERMISSION':
      return {
        ...state,
        settingPermission: {
          [action.permissionId]: true,
        },
        setPermissionError: null,
      };
    case 'SET_USER_PERMISSION_SUCCESS': {
      const userPermissions = state.userPermissions?.map((userPermission) => {
        if (userPermission.id === action.permissionId) {
          return { ...userPermission, hasPermission: action.hasPermission };
        }
        return userPermission;
      });
      return {
        ...state,
        userPermissions,
        settingPermission: {
          [action.permissionId]: false,
        },
        setPermissionError: null,
      };
    }

    case 'SET_USER_PERMISSION_FAIL':
      return {
        ...state,
        settingPermission: {
          [action.permissionId]: false,
        },
        setPermissionError: action.error,
      };

    case 'CLEAR_ERRORS':
      return {
        ...state,
        loadError: null,
        createError: null,
        disableError: null,
        deleteError: null,
        loadPermissionsError: null,
        setPermissionError: null,
        createRoleError: null,
        editRoleError: null,
        deleteRoleError: null,
        loadUserRolesError: null,
        setRoleError: null,
        assignUserToRoleError: null,
      };
    default:
      return state;
  }
};

export function UsersApiProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const api = useApi();
  const { refetchUserDetails } = useCurrentUser();

  const getUsers = () => {
    dispatch({ type: 'GET_USERS' });

    return api
      .get('/users', {})
      .then(({ items }) => {
        dispatch({ type: 'GET_USERS_SUCCESS', users: items });
      })
      .catch((error) => {
        return dispatch({ type: 'GET_USERS_FAIL', error: extractErrorMessage(error) });
      });
  };

  const getRoles = () => {
    dispatch({ type: 'GET_ROLES' });

    return api
      .get('/roles')
      .then(({ items }) => {
        dispatch({ type: 'GET_ROLES_SUCCESS', roles: items.sort((a, b) => a.id - b.id) });
      })
      .catch((error) => {
        dispatch({ type: 'GET_ROLES_FAIL', error });
      });
  };

  const createRole = (role) => {
    dispatch({ type: 'CREATE_ROLE' });

    return api
      .post(`/roles`, role)
      .then((createdRole) => {
        dispatch({
          type: 'CREATE_ROLE_SUCCESS',
          role: createdRole,
          permissions: role.permissions,
        });
      })
      .catch((error) => {
        dispatch({ type: 'CREATE_ROLE_FAIL', error: extractErrorMessage(error) });

        return Promise.reject();
      });
  };

  const editRole = (role) => {
    dispatch({ type: 'EDIT_ROLES' });

    return api
      .post(`/roles/${role.id}`, role)
      .then((upsertedRole) => {
        dispatch({
          type: 'EDIT_ROLES_SUCCESS',
          role: upsertedRole,
          permissions: role.permissions,
        });
      })
      .catch((error) => {
        dispatch({ type: 'EDIT_ROLES_FAIL', error: extractErrorMessage(error) });

        return Promise.reject();
      });
  };

  const deleteRole = (role) => {
    dispatch({ type: 'DELETE_ROLE' });

    return api
      .delete(`/roles/${role.id}`, role)
      .then(() => {
        dispatch({ type: 'DELETE_ROLE_SUCCESS', role });
      })
      .catch((error) => {
        dispatch({ type: 'DELETE_ROLE_FAIL', error: extractErrorMessage(error) });
      });
  };

  const setUserRole = (username, roleId, hasRole) => {
    dispatch({ type: 'SET_USER_ROLE', roleId });

    const promise = hasRole
      ? api.post(`/roles/${roleId}/users/${username}`)
      : api.delete(`/roles/${roleId}/users/${username}`);

    return promise
      .then(() => {
        dispatch({ type: 'SET_USER_ROLE_SUCCESS', roleId, hasRole });
        refetchUserDetails();
      })
      .catch((error) => {
        dispatch({
          type: 'SET_USER_ROLE_FAIL',
          roleId,
          error: extractErrorMessage(error),
        });

        return Promise.reject(error);
      });
  };

  const assignUserToRole = (username, roleId, hasRole) => {
    dispatch({ type: 'ASSIGN_USER_TO_ROLE', roleId, username });

    const promise = hasRole
      ? api.post(`/roles/${roleId}/users/${username}`)
      : api.delete(`/roles/${roleId}/users/${username}`);

    return promise
      .then(() => {
        dispatch({ type: 'ASSIGN_USER_TO_ROLE_SUCCESS', roleId, username, hasRole });
        refetchUserDetails();
      })
      .catch((error) => {
        dispatch({
          type: 'ASSIGN_USER_TO_ROLE_FAIL',
          roleId,
          username,
          error: extractErrorMessage(error),
        });
      });
  };

  const getUserRoles = (username) => {
    dispatch({ type: 'GET_USER_ROLES' });

    return Promise.all([api.get(`/users/user/${username}/roles`, {}), api.get('/roles')])
      .then(([{ items: userRoles }, { items: roles }]) => {
        const mergedRoles = roles.map((g) => ({
          ...g,
          hasRole: Boolean(userRoles.find((ug) => ug.id === g.id)),
        }));
        return dispatch({ type: 'GET_USER_ROLES_SUCCESS', userRoles: mergedRoles });
      })
      .catch((error) => {
        dispatch({ type: 'GET_USER_ROLES_FAIL', error: extractErrorMessage(error) });
      });
  };

  const inviteUser = (email, roles) => {
    dispatch({ type: 'INVITE_USER' });

    return api
      .post('/users', {
        emailAddress: email,
        username: email,
        roles: roles.map((r) => {
          const { labels, ...role } = r;

          return role;
        }),
      })
      .then(() => {
        return dispatch({ type: 'INVITE_USER_SUCCESS' });
      })
      .catch((error) => {
        dispatch({ type: 'INVITE_USER_FAIL', error: extractErrorMessage(error) });
        return Promise.reject(error);
      });
  };

  const disableUser = (username) => {
    dispatch({ type: 'DISABLE_USER' });

    return api
      .post(`/users/user/${username}/disable`)
      .then(() => {
        return dispatch({ type: 'DISABLE_USER_SUCCESS' });
      })
      .catch((error) => {
        dispatch({ type: 'DISABLE_USER_FAIL', error: extractErrorMessage(error) });
        return Promise.reject(error);
      });
  };

  const deleteUser = (username) => {
    dispatch({ type: 'DELETE_USER' });

    return api
      .delete(`/users/user/${username}`)
      .then(() => {
        return dispatch({ type: 'DELETE_USER_SUCCESS' });
      })
      .catch((error) => {
        dispatch({ type: 'DELETE_USER_FAIL', error: extractErrorMessage(error) });
        return Promise.reject(error);
      });
  };

  const getUserPermissions = (username) => {
    dispatch({ type: 'GET_USER_PERMISSIONS' });

    const getUserPermissionsPromise = api.get(`/users/user/${username}/permissions`, {});
    const getAllPermissionsPromise = api.get('/permissions', {});

    return Promise.all([getUserPermissionsPromise, getAllPermissionsPromise])
      .then(([{ items: userPermissions }, { items: allPermissions }]) => {
        const mergedPermissions = allPermissions.map((permission) => {
          const userHasPermission = Boolean(
            userPermissions.find((userPermission) => userPermission.id === permission.id),
          );
          return {
            ...permission,
            hasPermission: userHasPermission,
          };
        });
        return dispatch({
          type: 'GET_USER_PERMISSIONS_SUCCESS',
          userPermissions: mergedPermissions,
        });
      })
      .catch((error) => {
        dispatch({ type: 'GET_USER_PERMISSIONS_FAIL', error: extractErrorMessage(error) });
        return Promise.reject(error);
      });
  };

  const getAllPermissions = () => {
    dispatch({ type: 'GET_ALL_PERMISSIONS' });

    return api
      .get('/permissions', {})
      .then(({ items }) => {
        dispatch({ type: 'GET_ALL_PERMISSIONS_SUCCESS', permissions: items });
      })
      .catch((error) => {
        dispatch({ type: 'GET_ALL_PERMISSIONS_FAIL', error: extractErrorMessage(error) });
        return Promise.reject(error);
      });
  };

  const setUserPermission = (username, permissionId, hasPermission) => {
    dispatch({ type: 'SET_USER_PERMISSION', permissionId });

    const promise = hasPermission
      ? api.post(`/permissions/${permissionId}/users/${username}`)
      : api.delete(`/permissions/${permissionId}/users/${username}`);

    return promise
      .then(() => {
        return dispatch({
          type: 'SET_USER_PERMISSION_SUCCESS',
          permissionId,
          hasPermission,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'SET_USER_PERMISSION_FAIL',
          permissionId,
          error: extractErrorMessage(error),
        });
        return Promise.reject(error);
      });
  };

  const clearErrors = () => dispatch({ type: 'CLEAR_ERRORS' });

  const value = {
    state,
    getUsers,
    getRoles,
    createRole,
    editRole,
    deleteRole,
    setUserRole,
    assignUserToRole,
    getUserRoles,
    getUserPermissions,
    getAllPermissions,
    setUserPermission,
    inviteUser,
    disableUser,
    deleteUser,
    clearErrors,
  };

  return <UsersState.Provider value={value}>{children}</UsersState.Provider>;
}

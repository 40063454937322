import defaultTheme from './reakit-theme-default';
import commonTheme from './commonTheme';

const theme = {
  name: 'Light',
  ...defaultTheme,
  mapboxMapStyle: 'mapbox://styles/mapbox/light-v10',
  base: commonTheme.base,
  palette: commonTheme.palette,
  recharts: commonTheme.recharts,
  colors: {
    ...commonTheme.colors,
    subtle: '#d3d3d3',
    default: '#7d7d7d',
    debug: '#ef00ef',

    text: {
      primary: '#504e4e',
      subtle: '#a3a3a3',
    },
    background: {
      // default gradient from to
      df2: '#dfdcdc',
      df1: '#f3f4ea',
      //
      default: '#e7e7e7',
      modal: '#ffffff',
      modalInput: '#efefef',
      selected: '#d4d4d4',
      alt1: '#ffffff',
      alt1Light: '#f8f8f6',
      alt2: '#f3f3f3',
      selectedPrimary: '#fff2ca80',
      selectedSecondary: '#bfdfff2e',
      lightSuccess: '##ccffbf2e',
      alertSource: '#e5737314',
      alertTarget: '#00a6ff0f',
      alertSelected: '#fff4dc',
    },

    border: {
      contrast: '#787878',
      default: '#d3d3d3',
      light: '#ececec',
      alt1: '#a5a5a5',
      button: '#7d7d7d',
    },

    boxShadow: {
      light: '0px 2px 6px 0px rgb(0, 0, 0, 0.2)',
      topInset: 'inset 0px 7px 9px -7px rgb(126, 126, 126, 0.2)',
    },

    letterPress: {
      text: '#cacaca',
      shadow: 'rgba(250, 250, 250, 0.8)',
    },

    scrollShadow: {
      main: 'rgba(0, 0, 0, 0.15)',
      contrast: 'rgba(0, 0, 0, 0)',
    },

    pqlDropdownQuery: '#304de8',

    chart: {
      normal: '#787fcc',
      important: '#e67373',
      nondirectional: '#e6c321',
      userActivity: '#59c3d1',
      aggregatedPlatform: '#f8b761',
      trendLine: '#292929',
      inbound: '#de7cdd',
      outbound: '#8884d8',
      local: '#66bb6a',
      'fl-ingress': '#d682b6',
      'fl-egress': '#436bb6',
      'fl-rejected': '#df5779',
      'fl-bytes': '#e6d221',
      focusedOut: '#a3a3a3',
      'initial-access': '#b494d4',
      'user-management': '#b08080',
      'ai-s3-data-cloudtrail': '#6cc435',
      'ai-userevents-okta': '#ffa500',
      'ai-logingeo-okta': '#ff69b4',
      'ai-applicationaccess-okta': '#33b1f1',
    },

    recordStatus: {
      open: '#7d7d7d',
      under_review: '#7697e7',
      acknowledged: '#a3a3a3',
      closed: '#4caf50',
    },

    badge: {
      submodule: '#d0d0d0',
    },
  },
};

export default theme;

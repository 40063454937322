const toArray = (arg) => (Array.isArray(arg) ? arg : [arg]);

const clamp = (number, min, max) => {
  if (number < min) {
    return min;
  }
  if (number > max) {
    return max;
  }

  return number;
};

export const palette = (keyOrTone, toneOrDefaultValue, defaultValue) => (props) => {
  const key = typeof keyOrTone === 'string' ? keyOrTone : props.palette;
  const tone =
    typeof keyOrTone === 'number'
      ? keyOrTone
      : typeof toneOrDefaultValue === 'number'
        ? toneOrDefaultValue
        : props.tone || 0;
  const finalDefaultValue = toneOrDefaultValue !== tone ? toneOrDefaultValue : defaultValue;

  if (!props.theme.palette || !props.theme.palette[key]) {
    return finalDefaultValue;
  }

  const tones = toArray(props.theme.palette[key]);

  if (tone < 0) {
    return tones[clamp(tones.length + tone, 0, tones.length - 1)];
  }

  return tones[clamp(tone, 0, tones.length - 1)];
};

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    color-scheme: ${({ theme }) => theme.name};
    --app-height: 100vh;
  }

  ${
    ''
    /*
      Needed because of a bug in mobile safari - https://stackoverflow.com/q/37112218

      Works ok for modern browsers, but in case we need to support older
      mobile browsers this could be converted to be a more programmatic solution
      (i.e. listening to resize events and setting the value of --app-height to be window.innerHeight)

      When we need to use vh we should double check if we need to use --app-height instead
    */
  }
  @supports(height: 100dvh) {
    :root {
      --app-height: 100dvh;
    }
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${({ theme }) => theme.base.fontText};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.scroll-lock {
      overflow: hidden;
    }

    &.block-horizontal-overscroll {
      overscroll-behavior-x: none;
    }

    a {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  h1, h2, h3, h4, h5, .font-title {
    font-family: ${({ theme }) => theme.base.fontHeader} !important;
  }

  .font-text {
    font-family: ${({ theme }) => theme.base.fontText};
  }

  code {
    font-family: ${({ theme }) => theme.base.fontCode};
  }

  .font-code {
    font-family: ${({ theme }) => theme.base.fontCode};
  }

  .color-primary {
    color: ${({ theme }) => theme.colors.primary};
  }
  .color-secondary {
    color: ${({ theme }) => theme.colors.secondary};
  }
  .color-text-primary {
    color: ${({ theme }) => theme.colors.text.primary};
  }
  .color-text-subtle {
    color: ${({ theme }) => theme.colors.text.subtle};
  }
  .color-text-success {
    color: ${({ theme }) => (theme.name === 'Light' ? theme.palette.success[0] : theme.palette.success[2])};
  }
  .color-danger {
    color: ${({ theme }) => theme.palette.danger[2]};
  }
  .color-alert {
    color: ${({ theme }) => theme.palette.alert[0]};
  }

  .border-default {
    border-color: ${({ theme }) => theme.colors.border.default};
  }
`;

export default GlobalStyle;

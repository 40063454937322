import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import startOfToday from 'date-fns/startOfToday';
import startOfYesterday from 'date-fns/startOfYesterday';
import endOfYesterday from 'date-fns/endOfYesterday';
import subHours from 'date-fns/subHours';
import subDays from 'date-fns/subDays';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import isSameDay from 'date-fns/isSameDay';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';

import { maybePlural } from 'utils/string';
import { DAY_IN_YEAR_FORMAT, US_FULL_DATE, MIN_INTERVAL } from 'consts/datetime';

import type { Milliseconds, Seconds } from 'types/time';
import type { Page } from 'consts/pages';

export const formatUTC = (date: Date, f: string): string => {
  return format(addMinutes(date, date.getTimezoneOffset()), f);
};

export const formatRechart = (...args: Parameters<typeof format>): ReturnType<typeof format> => {
  try {
    return format(...args);
  } catch (err) {
    return '';
  }
};

type TimeRange = {
  t0: Seconds | null;
  t1: Seconds | null;
};

export const qRangeToTs = (qString: string): TimeRange => {
  if (!qString) {
    return { t0: null, t1: null };
  }
  const now = new Date();
  const nowSeconds: Seconds = Math.floor(now.getTime() / 1000) as Seconds;
  let t1: Seconds = nowSeconds;
  let t0: Seconds | null = null;

  switch (qString.toLowerCase()) {
    case 'today':
      t0 = Math.floor(startOfToday().getTime() / 1000) as Seconds;
      break;
    case 'yesterday':
      t1 = Math.floor(endOfYesterday().getTime() / 1000) as Seconds;
      t0 = Math.floor(startOfYesterday().getTime() / 1000) as Seconds;
      break;
    case 'last 2 hours':
      t0 = Math.floor(subHours(now, 2).getTime() / 1000) as Seconds;
      break;
    case 'last 12 hours':
      t0 = Math.floor(subHours(now, 12).getTime() / 1000) as Seconds;
      break;
    case 'last 24 hours':
      t0 = Math.floor(subHours(now, 24).getTime() / 1000) as Seconds;
      break;
    case 'last 7 days':
      t0 = Math.floor(subDays(now, 7).getTime() / 1000) as Seconds;
      break;
    case 'last 30 days':
      t0 = Math.floor(subDays(now, 30).getTime() / 1000) as Seconds;
      break;
    case 'last 90 days':
      t0 = Math.floor(subDays(now, 90).getTime() / 1000) as Seconds;
      break;
    case 'last hour':
      t0 = (nowSeconds - 3600) as Seconds;
      break;
    default:
      t0 = (nowSeconds - 3600) as Seconds;
  }

  return {
    t0: t0 !== null ? ((Math.floor(t0 / 60) * 60) as Seconds) : null,
    t1: t1 !== null ? ((Math.floor(t1 / 60) * 60) as Seconds) : null,
  };
};

export const dateToEpoch = (date: Date) => {
  return Math.floor(date.getTime() / 1000) as Seconds;
};
export const roundDownToNearestMinute = (epochTs: Seconds) => {
  return (epochTs - (epochTs % 60)) as Seconds;
};

type AdjustedInterval = {
  roundedFrom: Seconds;
  roundedTo: Seconds;
};

// adjust from and to to pick a range in 5 minute intervals
export const adjustTo5MinuteIntervals = (fromEpoch: Seconds, toEpoch: Seconds): AdjustedInterval => {
  const interval = 300;
  const now = Math.floor(new Date().getTime() / 1000);

  const testFromIntervals = Math.floor(fromEpoch / interval);
  const testToIntervals = Math.floor(toEpoch / interval);

  let testFrom = testFromIntervals * interval;
  let testTo = testToIntervals * interval;

  if (testTo - testFrom < interval) {
    if (testTo > now) {
      // If the 'to' time is in the future, adjust both 'from' and 'to' back one interval
      testTo -= interval;
      testFrom -= interval;
    } else {
      // If the range is less than one interval, extend 'to' to the next interval
      testTo += interval;
    }
  }

  return {
    roundedFrom: testFrom as Seconds,
    roundedTo: testTo as Seconds,
  };
};

type TimeDifference = {
  weeks: number;
  days: number;
  hours: number;
  minutes: number;
};

export const getDifference = (tBase: Date | Milliseconds, t1: Date | Milliseconds): TimeDifference => {
  const tBaseDate = tBase instanceof Date ? tBase : new Date(tBase);
  const t1Date = t1 instanceof Date ? t1 : new Date(t1);

  const weeks = differenceInWeeks(t1Date, tBaseDate);
  const days = differenceInDays(t1Date, tBaseDate);
  const hours = differenceInHours(t1Date, tBaseDate);
  const minutes = differenceInMinutes(t1Date, tBaseDate);

  return {
    weeks,
    days,
    hours,
    minutes,
  };
};

export const _maybePrefix = (value: number): string | number => (value > 0 ? `+${value}` : value);

export const _getString = (value: number, label: string): string =>
  `${_maybePrefix(value)} ${maybePlural(value, label)}`;

export const getDifferenceLoose = (tBase: Date | Milliseconds, t1: Date | Milliseconds): string | undefined => {
  const diff = getDifference(tBase, t1);
  if (diff.weeks) {
    return _getString(diff.weeks, 'week');
  }
  if (diff.days) {
    return _getString(diff.days, 'day');
  }
  if (diff.hours) {
    return _getString(diff.hours, 'hour');
  }
  if (diff.minutes) {
    return _getString(diff.minutes, 'minute');
  }
  return undefined;
};

const ALLOWED_INTERVALS: Milliseconds[] = [
  (60 * 1000) as Milliseconds, // minute
  (2 * 60 * 1000) as Milliseconds,
  (5 * 60 * 1000) as Milliseconds,
  (10 * 60 * 1000) as Milliseconds,
  (15 * 60 * 1000) as Milliseconds,
  (20 * 60 * 1000) as Milliseconds,
  (30 * 60 * 1000) as Milliseconds,
  (60 * 60 * 1000) as Milliseconds, // hour
  (2 * 60 * 60 * 1000) as Milliseconds,
  (4 * 60 * 60 * 1000) as Milliseconds,
  (12 * 60 * 60 * 1000) as Milliseconds,
  (24 * 60 * 60 * 1000) as Milliseconds, // day
  (7 * 24 * 60 * 60 * 1000) as Milliseconds, // week
  (14 * 24 * 60 * 60 * 1000) as Milliseconds, // 2 weeks
  (30 * 24 * 60 * 60 * 1000) as Milliseconds, // 30 days
];

export const getChartInterval = (
  minInterval: Milliseconds,
  diff: Milliseconds,
  targetNumberOfBuckets: number = 50,
): Milliseconds => {
  return (
    ALLOWED_INTERVALS.filter((a) => a >= minInterval)
      .sort((a, b) => b - a)
      .find((interval) => {
        const div = diff / interval;
        if (div > 1 && div >= targetNumberOfBuckets) {
          return true;
        }
        return false;
      }) || minInterval // fallback to minimum
  );
};

export const formatTimerange = (query: string, t0: Seconds, t1: Seconds): string => {
  if (query) {
    const lowerCaseQ = query.toLowerCase();
    return ['today', 'yesterday'].includes(lowerCaseQ) ? lowerCaseQ : `in the ${lowerCaseQ}`;
  }

  const t0Millis = (t0 * 1000) as Milliseconds;
  const t1Millis = (t1 * 1000) as Milliseconds;
  const t0Date = new Date(t0Millis);
  const t1Date = new Date(t1Millis);

  if (
    // if its a full date, i.e. 02/02/2021 from 00:00:00 to 23:59:59
    t0Millis === startOfDay(t0Date).getTime() &&
    t1Millis === endOfDay(t1Date).getTime() - 999 &&
    isSameDay(t0Date, t1Date)
  ) {
    return `on ${format(t0Date, DAY_IN_YEAR_FORMAT)}`;
  } else {
    // if its a time range, i.e. 02/02/2021 00:00:00 to 02/02/2021 23:59:59
    return `between ${format(t0Date, US_FULL_DATE)} and ${format(t1Date, US_FULL_DATE)}`;
  }
};

export const decideChartPointInterval = (page: Page, diff: Milliseconds): number | null => {
  // early exit if there's no point in charting
  if (!diff || !page) {
    return null;
  }

  return getChartInterval(MIN_INTERVAL[page] || MIN_INTERVAL.default, diff);
};

export const getSevenDaysAgo = () => {
  const date = new Date();
  date.setDate(date.getDate() - 7);
  return startOfDay(date);
};

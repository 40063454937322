import React from 'react';

import { iconMap } from 'components/common/Icons';
import Tooltip from 'components/common/Tooltip';

import { ItemContainer, ItemWrapperLink, ItemText } from './MenuItem.styled';

const MenuItem = ({ to, isActive, isEnabled = true, item, expanded }) => {
  const ItemIcon = iconMap[item.icon];

  return (
    <ItemContainer $isActive={isActive} $isEnabled={isEnabled} data-test-id={`menu-item-${item.name}`}>
      <Tooltip
        mouseEnterDelay={0.1}
        align={{
          offset: [12],
        }}
        overlay={item.name}
        placement="right"
        trigger={expanded ? [] : ['hover']}
      >
        <ItemWrapperLink
          to={isEnabled ? to : undefined}
          $isEnabled={isEnabled}
          aria-disabled={isEnabled ? undefined : true}
          aria-label={expanded ? undefined : item.name}
          aria-current={isActive ? 'page' : undefined}
        >
          <ItemIcon aria-label={item.name} aria-hidden />
          <ItemText $expanded={expanded}>{item.name}</ItemText>
        </ItemWrapperLink>
      </Tooltip>
    </ItemContainer>
  );
};

export default React.memo(MenuItem);

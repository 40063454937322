import React, { useState } from 'react';

import { getWindowWidth } from 'utils/window';
import { useConstant } from 'utils/hooks';
import { breakpoints } from 'consts/config';

import getConfig from './config';

const initialState = getConfig();

export const MenuState = React.createContext({
  state: { ...initialState },
  setMenuConfig: () => {},
});

export const MenuStateProvider = ({ children }) => {
  const [menuConfig, setMenuConfig] = useState(() => getConfig());
  const [expanded, setExpanded] = useState(useConstant(() => getWindowWidth() >= breakpoints.largeDesktop));

  const value = {
    state: { menuConfig, expanded },
    setMenuConfig,
    setExpanded,
  };

  return <MenuState.Provider value={value}>{children}</MenuState.Provider>;
};

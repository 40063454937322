import PropTypes from 'prop-types';

import { Container, FakeInput, Switch, Slider } from './ToggleSwitch.styled';

const ToggleSwitch = ({ id, checked, onChange, disabled, dataTestId, ...props }) => {
  return (
    <Container {...props} data-test-id={dataTestId}>
      <FakeInput
        id={id}
        name={id}
        type="checkbox"
        value={checked}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />

      <Switch htmlFor={id} disabled={disabled}>
        <Slider />
      </Switch>
    </Container>
  );
};

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default ToggleSwitch;

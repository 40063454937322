import React, { useReducer, useContext } from 'react';
import _findIndex from 'lodash/findIndex';
import _differenceWith from 'lodash/differenceWith';
import _isEqual from 'lodash/isEqual';

import { PAGES } from 'consts/pages';

import * as searchUtils from './utils';
import { PqlStateProvider } from './PqlProvider';
import { SearchQueryStateProvider } from './SearchQueryProvider';

const initialDashboardState = {
  raw: [],
  filtered: [],
  aiPrioritized: true,
  aiPrioritizedCount: null,
  filterList: [],
  excludeList: [],
  loading: false,
  reqSource: null, // axios src for cancel token
  error: null,
  pql: null,
  timerangePql: null,
  t1: null,
  t2: null,
};

const initialState = {
  [PAGES.events]: initialDashboardState,
  [PAGES.indicators]: initialDashboardState,
  [PAGES.investigator]: initialDashboardState,
};

export const SearchState = React.createContext({
  ...initialState,
});

const AiState = React.createContext({
  prioritized: initialState.aiPrioritized,
});

const reducer = (state, action) => {
  let tmp = null;
  let filtered = null;

  switch (action.type) {
    case 'FILTER_ADD':
      if (_findIndex(state[action.page].filterList, action.filterObj) !== -1) {
        return state;
      }
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          filterList: state[action.page].filterList.concat(action.filterObj),
          filtered: searchUtils.applyNewFilter(action.filterObj, state[action.page].filtered),
        },
      };
    case 'FILTER_REMOVE':
      tmp = _differenceWith(state[action.page].filterList, [action.filterObj], _isEqual); // new filterList

      filtered = searchUtils.applyAllFilters(
        state[action.page].raw,
        tmp,
        state[action.page].aiPrioritized && action.page === PAGES.events,
      );

      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          filterList: tmp,
          filtered,
        },
      };
    case 'FILTER_RESET':
      filtered = searchUtils.applyAllFilters(
        state[action.page].raw,
        [],
        state[action.page].aiPrioritized && action.page === PAGES.events,
      );
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          filterList: [],
          filtered,
        },
      };
    case 'AI_TOGGLE':
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          aiPrioritized: !state[action.page].aiPrioritized,
        },
      };
    case 'AI_PRIORITIZE':
      filtered = searchUtils.applyAllFilters(
        state[action.page].raw,
        state[action.page].filterList,
        state[action.page].aiPrioritized,
      );

      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          excludeList: [],
          filtered,
          aiPrioritizedCount: countWithoutReputation(filtered),
          reputationCount: countReputation(filtered),
        },
      };
    default:
      return state;
  }
};

// we dont want to include reputation events that will be passed through regardless of AI
const countWithoutReputation = (filtered) => {
  return filtered.filter((item) => !searchUtils.reputationSeverityTen(item)).length;
};

const countReputation = (filtered) => {
  return filtered.filter(searchUtils.isReputationRecord).length;
};

export const SearchStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const findRecordById = (type, id) => {
    const idKey = type === PAGES.investigator ? '_id' : 'id';
    return state[type].raw.find((r) => r[idKey].toString() === id.toString());
  };

  const handleAiToggle = () => {
    dispatch({ type: 'AI_TOGGLE', page: PAGES.events });
  };

  const value = {
    ...state,
    dispatch,
    findRecordById,
  };

  const aiState = {
    aiOn: state.events.aiPrioritized,
    toggleAi: handleAiToggle,
  };

  return (
    <SearchQueryStateProvider>
      <PqlStateProvider>
        <SearchState.Provider value={value}>
          <AiState.Provider value={aiState}>{children}</AiState.Provider>
        </SearchState.Provider>
      </PqlStateProvider>
    </SearchQueryStateProvider>
  );
};

export const useAiState = () => {
  return useContext(AiState);
};

import { PAGES } from 'consts/pages';

// config
export const SEARCH_TYPE = {
  [PAGES.multistream]: 'O_INDICATORS',
  [PAGES.events]: 'O_EVENTS',
  [PAGES.indicators]: 'O_INDICATORS',
  [PAGES.investigator]: 'O_SENSOR',
  [PAGES.notifications]: 'O_NOTIFICATIONS',
  [PAGES.cloudapi]: 'O_INDICATORS',
} as const;

import React from 'react';
import ReactDOM from 'react-dom';

import { generateRandomId } from 'utils/string';

class Portal extends React.Component {
  constructor() {
    super();
    const randomId = generateRandomId();
    this.randomId = randomId;
    this.el = document.createElement('div');
    this.el.setAttribute('data-portal-id', randomId);
  }

  componentDidMount = () => {
    document.body.appendChild(this.el);
  };

  componentWillUnmount = () => {
    // check if the element is still in the DOM
    const el = document.querySelector(`[data-portal-id="${this.randomId}"]`);

    if (el) {
      el.remove();
    }
  };

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default Portal;

import React, { useRef } from 'react';
import { useOnClickOutside } from 'utils/hooks';

const ClickOutside = ({ children, onClickOutside }) => {
  const ref = useRef(null);

  useOnClickOutside(ref, onClickOutside);

  return <div ref={ref}>{children}</div>;
};

export default ClickOutside;

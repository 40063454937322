import React from 'react';
import { Link as ReactLink } from 'react-router-dom';

/**
 * "Universal" <Link /> that decides whether to use react-router <Link /> or <a>
 * Export is a styled component that allows easier overwrite of color
 **/

const parseTo = (to: string) => {
  const parser = document.createElement('a');
  parser.href = to;
  return parser;
};

const isInternal = (to: string) => {
  if (typeof to === 'object') {
    return true;
  }

  if ((to || '').indexOf('//') === -1) {
    return true;
  }

  const toLocation = parseTo(to);
  return window.location.host === toLocation.host;
};

type LinkProps = {
  to: string;
  forceExternal?: boolean;
  children: React.ReactNode;
  className?: string;
};

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { to, forceExternal, children, ...rest } = props;
  if (!forceExternal && isInternal(to)) {
    return (
      <ReactLink to={to} {...rest} ref={ref}>
        {children}
      </ReactLink>
    );
  }
  return (
    <a href={to} target="_blank" rel="noopener noreferrer" {...rest} ref={ref}>
      {children}
    </a>
  );
});

export default Link;

import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useApi } from 'services/api';

const BASE_URL = {
  tasks: 'rules',
  enrichments: 'enrichments',
  indicatoroverrides: 'indicatoroverrides',
  integrations: 'integrations',
};

const RESOURCE_KEY = {
  tasks: 'RuleId',
  enrichments: 'EnrichmentId',
  indicatoroverrides: 'IndicatorOverrideId',
  integrations: 'IntegrationId',
};

const addOrRemoveFromCollection = (data, id) => {
  let items = data.items;
  if (items.find((x) => x.id === id)) {
    return { ...data, items: items.filter((x) => x.id !== id) };
  } else {
    return { ...data, items: [...items, { id }] };
  }
};

const useApplyOnSensors = ({ resource, id }) => {
  const queryClient = useQueryClient();
  const baseUrl = BASE_URL[resource];
  const api = useApi();
  const [currentlyLoading, setCurrentlyLoading] = useState({
    sensorId: null,
    tenantId: null,
  });

  if (!baseUrl) {
    // eslint-disable-next-line no-console
    console.error("Can't find base url for resource", resource);
  }

  const assignedTenants = useQuery([baseUrl, id, 'tenants'], () => {
    return api.get(`/${baseUrl}/${id}/tenants`);
  });
  const assignedSensors = useQuery([baseUrl, id, 'sensors'], () => {
    return api.get(`/${baseUrl}/${id}/sensors`);
  });

  const clearMutation = useMutation(
    (id) => {
      return api.post(`/${baseUrl}/${id}/clear`);
    },
    {
      onSuccess: () => {
        // update the cache
        queryClient.setQueryData([baseUrl, id, 'tenants'], { items: [] });
        queryClient.setQueryData([baseUrl, id, 'sensors'], { items: [] });
      },
    },
  );

  const assignTenantMutation = useMutation(
    ({ id, tenantId }) => {
      return api({
        method: isTenantAssigned(tenantId) ? 'DELETE' : 'POST',
        url: `/${baseUrl}/${id}/tenants/${tenantId}`,
        data: {
          TenantId: tenantId,
          [RESOURCE_KEY[resource]]: id,
        },
      });
    },
    {
      onMutate: ({ tenantId }) => setCurrentlyLoading({ tenantId }),
      onSuccess: (_, { tenantId }) => {
        const data = queryClient.getQueryData([baseUrl, id, 'tenants']);
        const newData = addOrRemoveFromCollection(data, tenantId);
        queryClient.setQueryData([baseUrl, id, 'tenants'], newData);
        setCurrentlyLoading({ tenantId: null });
        assignedTenants.refetch();
      },
    },
  );

  const assignSensorMutation = useMutation(
    ({ id, sensorId }) => {
      return api({
        method: isSensorAssigned(sensorId) ? 'DELETE' : 'POST',
        url: `/${baseUrl}/${id}/sensors/${sensorId}`,
        data: {
          SensorId: sensorId,
          [RESOURCE_KEY[resource]]: id,
        },
      });
    },
    {
      onMutate: ({ sensorId }) => setCurrentlyLoading({ sensorId }),
      onSuccess: (_, { sensorId }) => {
        const data = queryClient.getQueryData([baseUrl, id, 'sensors']);
        const newData = addOrRemoveFromCollection(data, sensorId);
        queryClient.setQueryData([baseUrl, id, 'sensors'], newData);
        setCurrentlyLoading({ sensorId: null });
        assignedSensors.refetch();
      },
    },
  );

  const isLoading = assignedTenants.isLoading || assignedSensors.isLoading;
  const assigned = {
    tenants: assignedTenants.data?.items,
    sensors: assignedSensors.data?.items,
  };

  const isAssignedToEverything =
    assigned.tenants && assigned.tenants.length === 0 && assigned.sensors && assigned.sensors.length === 0;

  const isTenantAssigned = (tenantId) => assigned.tenants && assigned.tenants.find((x) => x.id === tenantId);
  const isSensorAssigned = (sensorId) => assigned.sensors && assigned.sensors.find((x) => x.id === sensorId);

  return {
    isLoading,
    assigned,
    isAssignedToEverything,
    resetAssignments: clearMutation.mutate,
    isResetting: clearMutation.isLoading,
    isTenantAssigned,
    isSensorAssigned,
    toggleTenant: assignTenantMutation.mutate,
    toggleSensor: assignSensorMutation.mutate,
    isCurrentlyLoadingTenant: (tenantId) => currentlyLoading.tenantId === tenantId,
    isCurrentlyLoadingSensor: (sensorId) => currentlyLoading.sensorId === sensorId,
  };
};

export default useApplyOnSensors;

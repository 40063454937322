import { useMutation, useQueryClient } from '@tanstack/react-query';
import isSameDay from 'date-fns/isSameDay';

import useStore from 'services/store';
import { useApi } from 'services/api';

import { getQueryFromGroupId } from './utils';

const useAcknowledgeNotificationGroup = (config) => {
  const queryClient = useQueryClient();
  const api = useApi();
  const timezone = useStore((state) => state.searchQuery.timezone);

  return useMutation({
    ...config,
    mutationFn: async ({ groupId, count }) => {
      await api.post(
        `/search/bulk/acknowledge`,
        {},
        {
          params: {
            query: getQueryFromGroupId(groupId),
            context: [],
            searchType: 'O_NOTIFICATIONS',
            timezone,
          },
        },
      );

      queryClient.setQueriesData(['notifications'], (oldData) => {
        return oldData ? oldData.filter((x) => x.groupId !== groupId) : oldData;
      });

      const [day] = groupId.split('-');

      if (isSameDay(new Date(day), new Date())) {
        queryClient.setQueriesData(['newNotifications'], (oldData) => {
          return {
            ...(oldData || {}),
            count: (oldData?.count || 0) - count,
          };
        });
      }
    },
  });
};

export default useAcknowledgeNotificationGroup;

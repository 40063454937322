export const PAGES = {
  multistream: 'multistream',
  cloudapi: 'cloudapi',
  flowlogs: 'flowlogs',
  indicators: 'indicators',
  investigator: 'investigator',
  baseline: 'baseline',
  executiveSummary: 'executive-summary',
  usage: 'usage',
  events: 'events',
  identityManagement: 'identityManagement',
  notifications: 'notifications',
  alerts: 'alerts',
  detections: 'detections',
} as const;

export type Page = keyof typeof PAGES;

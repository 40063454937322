import React from 'react';
import { AlertTriangleIcon } from 'components/common/Icons';
import styled from 'styled-components';

import isSameDay from 'date-fns/isSameDay';
import format from 'date-fns/format';
import { TIME_IN_DAY_FORMAT_WITHOUT_SECONDS, DAY_MONTH_ABBR_FORMAT } from 'consts/datetime';

const Wrapper = styled.div`
  min-width: 160px;
  color: #4aa4eb;
  line-height: 100%;
  text-align: center;

  svg {
    margin-right: 4px;
    vertical-align: bottom;
  }
`;

const MessageEta = styled.div`
  margin-top: 2px;
  font-size: 11px;
  line-height: 100%;
`;

const InTrainingIndication = ({ informedDate }) => {
  if (!informedDate) {
    return null;
  }

  const today = new Date();
  // this can potentially happen if sensor is in learning because of the baseline reset
  // while informedDate is createdAt of the sensor
  const isInThePast = new Date(informedDate).getTime() < today.getTime();

  const message = isSameDay(today, informedDate)
    ? `today at ${format(informedDate, TIME_IN_DAY_FORMAT_WITHOUT_SECONDS)}`
    : `on ${format(informedDate, DAY_MONTH_ABBR_FORMAT)}`;

  return (
    <Wrapper>
      <AlertTriangleIcon /> Initial AI learning
      {!isInThePast && <MessageEta>completes {message}</MessageEta>}
    </Wrapper>
  );
};
export default InTrainingIndication;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const getHeight = ({ $size }) => {
  switch ($size) {
    case 'small':
      return 40;
    case 'medium':
      return 80;
    case 'large':
      return 120;
    default:
      return 40;
  }
};

const TextareaStyled = styled.textarea`
  color: ${({ theme }) => theme.colors.text.primary};
  border: ${({ $borderless, theme }) => ($borderless ? 'none' : `1px solid ${theme.colors.border.default}`)};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.background.alt2};
  padding: 4px;
  width: 100%;
  height: ${getHeight}px;
  resize: vertical;
  ${({ $minHeight }) =>
    $minHeight &&
    css`
      min-height: ${$minHeight};
    `}
`;

const Textarea = React.forwardRef(({ borderless, minHeight, size, ...props }, ref) => {
  return <TextareaStyled {...props} $borderless={borderless} $minHeight={minHeight} $size={size} ref={ref} />;
});

Textarea.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  borderless: PropTypes.bool,
};

export default Textarea;

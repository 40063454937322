import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import _hasIn from 'lodash/hasIn';

import { PAGES } from 'consts/pages';
import { retry } from 'utils/functions';
import Loader from 'components/common/Loader';
import SensorCheckWrapper from 'components/common/SensorCheckWrapper';

import { BadUrlPage } from './ErrorPages';

const ApiPage = React.lazy(() => retry(() => import('./ApiPage')));
const DataRecorderPage = React.lazy(() => retry(() => import('./DataRecorderPage')));
const MultistreamPage = React.lazy(() => retry(() => import('./MultistreamPage')));
const OverviewPage = React.lazy(() => retry(() => import('./OverviewPage')));
const DetectionsPage = React.lazy(() => retry(() => import('./DetectionsPage')));
const NotificationsPage = React.lazy(() => retry(() => import('./NotificationsPage')));
const DashboardChunk = React.lazy(() => retry(() => import('./DashboardChunk')));
const CloudDashboardsChunk = React.lazy(() => retry(() => import('./CloudDashboardsChunk')));
const SettingsPage = React.lazy(() => retry(() => import('./SettingsPage')));
const BaselineChunk = React.lazy(() => retry(() => import('./BaselineChunk')));
const IdentityManagementPage = React.lazy(() => retry(() => import('./IdentityManagementPage')));
const UserSettingsPage = React.lazy(() => retry(() => import('./UserSettingsPage')));

const routes = {
  alerting: {
    default: () => <Navigate to="/alerting/overview" />,
    'intel-events': () => <Navigate to="/alerting/detections?view=intel-events" />,
    'ai-overview': MultistreamPage,
    overview: () => <OverviewPage />,
    detections: () => <DetectionsPage />,
    cloudtrail: () => <Navigate to="/alerting/cloudapi" />, // 2022-09-09 deprecated
    cloudapi: (props) => <CloudDashboardsChunk page={PAGES.cloudapi} {...props} />,
    flowlogs: (props) => <CloudDashboardsChunk page={PAGES.flowlogs} {...props} />,
    'identity-management': (props) => <IdentityManagementPage {...props} />,
  },
  investigation: {
    default: () => <Navigate to="/investigation/indicators" />,
    indicators: (props) => <DashboardChunk page={PAGES.indicators} {...props} />,
    logs: (props) => <DashboardChunk page={PAGES.investigator} {...props} />,
    'packet-captures': DataRecorderPage,
  },
  reporting: {
    default: () => <Navigate to="/reporting/baseline" />,
    baseline: () => <BaselineChunk page={PAGES.baseline} />,
    'executive-summary': () => <BaselineChunk page={PAGES.executiveSummary} />,
  },
  settings: {
    '*': () => <SettingsPage />,
  },
  api: {
    default: <Navigate to="/api/mql-search" />,
    'mql-search': () => <ApiPage tab="mql-search" />,
    endpoints: () => <ApiPage tab="endpoints" />,
    documentation: () => <ApiPage tab="documentation" />,
    'mm-tool': () => <ApiPage tab="mm-tool" />,
    clients: () => <ApiPage tab="clients" />,
  },
  usage: {
    default: () => <BaselineChunk page={PAGES.usage} />,
  },
  notifications: {
    default: NotificationsPage,
    documentation: NotificationsPage,
  },
  // LEGACY CATEGORIES
  dashboards: {
    default: () => <Navigate to="/alerting/ai-overview" />,
    overview: () => <Navigate to="/alerting/ai-overview" />,
    indicators: () => <Navigate to="/investigation/indicators" />,
    investigator: () => <Navigate to="/investigation/logs" />,
    multistream: MultistreamPage,
  },
  trends: {
    default: () => <Navigate to="/reporting/baseline" />,
  },
  baseline: {
    default: () => <Navigate to="/reporting/baseline" />,
  },
  'network-monitoring': {
    default: () => <Navigate to="/settings/sensors" />,
    sensors: () => <Navigate to="/settings/sensors" />,
    'data-recorder': () => <Navigate to="/investigation/packet-captures" />,
    profiles: <Navigate to="/settings/profiles" />,
    rules: () => <Navigate to="/settings/tasks" />, // legacy
    tasks: <Navigate to="/settings/tasks" />,
  },
  admin: {
    default: () => <Navigate to="/settings/tenants" />,
    tenants: () => <Navigate to="/settings/tenants" />,
    users: <Navigate to="/settings/users" />,
  },
  'user-settings': {
    default: () => <UserSettingsPage />,
  },
};

const resolveComponent = (category, pageAlias, routes) => {
  // fallback to category default
  const page = (pageAlias || 'default').split('?')[0];

  // 404 if path doesnt exist
  if (!_hasIn(routes, [category, page])) {
    if (_hasIn(routes, [category, '*'])) {
      return routes[category]['*'];
    }
    return BadUrlPage;
  }

  return routes[category][page];
};

const Main = () => {
  const { category, page, action } = useParams();

  // memoize to prevent defering already loaded page
  const Page = React.useMemo(() => resolveComponent(category, page, routes), [category, page]);

  return (
    <React.Suspense fallback={<Loader inside="page" />}>
      <SensorCheckWrapper>
        <Page action={action} />
      </SensorCheckWrapper>
    </React.Suspense>
  );
};

export default React.memo(Main);

import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { parseQueryString } from 'utils/url';
import { useForm } from 'utils/hooks';
import { AuthState } from 'services/auth';
import { Paragraph } from 'components/styled/Typography';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import ErrorMessage, { ErrorMessageComponent } from 'components/common/ErrorMessage';

import AuthWrapper from './AuthWrapper';

import { CenteredErrorMessage, AuthLink } from './common.styled';

const URL_TOKEN = 'x-mixmode-api-token';

const getToken = (location) => {
  return parseQueryString(location.search)[URL_TOKEN];
};

const validate = ({ password, confirmPassword }) => {
  const errors = {};
  // eslint-disable-next-line
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$!)(%*?&\-+\[\]])(.{8,})/;

  if (!passwordRegex.test(password)) {
    errors.password =
      'Password must be at least 8 characters long and contain lowercase, uppercase, and non alphanumeric characters (@#$!)(%*?&).';
  }

  if (password !== confirmPassword) {
    errors.confirmPassword = 'Passwords must match.';
  }

  return errors;
};

const ResetPasswordPage = () => {
  const {
    updatePassword,
    state: { passwordUpdateError: error },
  } = useContext(AuthState);
  const location = useLocation();
  const navigate = useNavigate();

  const urlToken = getToken(location);

  const form = useForm({
    initialValues: { password: '', confirmPassword: '' },
    validate,
    onSubmit: ({ password }, { setIsSubmitting }) => {
      setIsSubmitting(true);

      const urlToken = getToken(location);

      return updatePassword(urlToken, password)
        .then(() => {
          setIsSubmitting(false);
          return navigate('/');
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    },
  });

  if (!urlToken) {
    return (
      <AuthWrapper>
        <CenteredErrorMessage>Please provide a valid password reset token!</CenteredErrorMessage>
      </AuthWrapper>
    );
  }

  return (
    <AuthWrapper>
      <div className="flex flex-col items-center mt-6">
        <Paragraph>
          Create a password for this account.
          <br /> The password should contain lowercase, uppercase and non alphanumeric characters.
        </Paragraph>
        <div className="flex flex-col w-full">
          <label htmlFor="password" className="mb-1">
            Password
          </label>
          <Input
            id="password"
            data-test-id="reset-password-password1-input"
            onChange={form.handleChange}
            value={form.values.password}
            name="password"
            type="password"
            autoComplete="new-password"
          />
        </div>
        <ErrorMessage {...form} name="password" className="w-full" />
        <div className="flex flex-col w-full">
          <label htmlFor="password-again" className="mb-1">
            Password again
          </label>
          <Input
            id="password-again"
            data-test-id="reset-password-password2-input"
            onChange={form.handleChange}
            value={form.values.confirmPassword}
            type="password"
            name="confirmPassword"
            autoComplete="new-password"
          />
        </div>
        <ErrorMessage {...form} name="confirmPassword" className="w-full" />
        <div className="flex justify-between w-full">
          <AuthLink className="mt-4" data-test-id="back-to-login-link" to="/login">
            Back to login
          </AuthLink>
          <Button
            data-test-id="reset-password-submit-button"
            className="mt-4"
            color="primary"
            variant="contained"
            type="submit"
            disabled={form.isSubmitting}
            onClick={form.handleSubmit}
          >
            Reset password
          </Button>
        </div>
      </div>

      {error && <ErrorMessageComponent>{error}</ErrorMessageComponent>}
    </AuthWrapper>
  );
};

export default ResetPasswordPage;

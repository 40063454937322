import _has from 'lodash/has';
import _isNil from 'lodash/isNil';
import type { AxiosError, AxiosResponse } from 'axios';

export function extractErrorMessage(err: string | AxiosError | null): string | null {
  if (_isNil(err)) {
    return err;
  } else if (typeof err === 'string') {
    return err;
  } else if (err?.response && (err?.response as AxiosResponse & { statusType: number }).statusType === 5) {
    return err?.response.statusText || 'Server Error';
  } else if (_has(err, ['response', 'data', '0', 'message'])) {
    return (err?.response?.data as { message: string }[])[0].message;
  } else if (_has(err, 'response.data.data')) {
    const res = err.response?.data as { data: string };
    return res.data;
  } else if (err?.message) {
    return err?.message;
  }

  return JSON.stringify(err);
}

// import { BUILD_FLAGS } from 'consts/config';
// export const hasFeature = (feature) => import.meta.env[BUILD_FLAGS[feature]] === 'true';

/**
 * We cant do this as above with Vite, as in production env variables are statically replaced:
 * https://vitejs.dev/guide/env-and-mode.html
 *
 * So, we'll read each flag directly
 */

export const hasFeature = (feature: 'debug' | Omit<string, 'debug'>) => {
  switch (feature) {
    case 'debug':
      return import.meta.env.VITE_DEBUG === 'true';
    default:
      return false;
  }
};

import { css } from 'styled-components';

import { palette as p } from './utils';

export const palette = {
  grayscale: [p('black'), '#414141', '#616161', '#9e9e9e', '#bdbdbd', '#e0e0e0', '#f5f5f5', p('white')],
  grayscaleText: [p('white'), p('white'), p('white'), p('black'), p('black'), p('black'), p('black'), p('black')],

  background: [p('grayscale', -4), p('grayscale', -3), p('grayscale', -2), p('grayscale', -1)],
  backgroundText: p('black'),

  shadow: [
    'rgba(0, 0, 0, 0.9)',
    'rgba(0, 0, 0, 0.7)',
    'rgba(0, 0, 0, 0.5)',
    'rgba(0, 0, 0, 0.3)',
    'rgba(0, 0, 0, 0.15)',
    'rgba(0, 0, 0, 0.075)',
  ],
  shadowText: [p('white'), p('white'), p('white'), p('black'), p('black'), p('black')],

  transparent: 'transparent',
  transparentText: p('black'),

  border: p('shadow', -2),
};

export const Field = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  label {
    padding-bottom: 0.5em;
  }
  > *:not(label):not(:last-child) {
    margin-bottom: 0.5em;
  }
`;

export const GroupItem = css`
  border: 1px solid ${p('border')};
  border-radius: 0.25em;
`;

export const Link = css`
  display: inline-grid;
  grid-gap: 0.25em;
  align-items: center;
  grid-auto-flow: column;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const List = css`
  list-style: none;

  li {
    margin-bottom: 0.35em;
  }
`;

export const Overlay = css`
  padding: 1em;
  border-radius: 0.25em;
  box-shadow:
    0 0 0 1px ${p('shadow', -2)},
    0 4px 8px ${p('shadow', -2)},
    0 16px 48px ${p('shadow', -2)};
`;

export const Sidebar = css`
  border-radius: 0;
`;

export const Tooltip = css`
  text-transform: none;
  pointer-events: none;
  white-space: nowrap;
  font-size: 0.875em;
  text-align: center;
  box-shadow: none;
  border-radius: 0.25em;
  padding: 0.75em 1em;
`;

export const TooltipArrow = css`
  & .stroke {
    fill: none;
  }
`;

const reakitThemeDefault = {
  palette,
  Field,
  GroupItem,
  Link,
  List,
  Overlay,
  Sidebar,
  Tooltip,
  TooltipArrow,
};

export default reakitThemeDefault;

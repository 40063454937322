import React from 'react';

/**
 * @typedef {import('axios').AxiosInstance} AxiosInstance
 *
 * @typedef {React.Context<AxiosInstance>} ApiState
 *
 * @type {ApiState}
 */
const ApiState = React.createContext();

export default ApiState;

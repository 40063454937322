import * as React from 'react';
import * as Sentry from '@sentry/browser';

import { CrashAndBurnPage } from 'components/scenes/ErrorPages';

/**
 * Takes care of unhandled exceptions.
 * Logs to Sentry
 */

class ErrorBoundary extends React.Component {
  state = {
    error: null,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    if (import.meta.env.PROD && import.meta.env.VITE_DEPLOYMENT_TYPE !== 'enterprise') {
      Sentry.withScope((scope) => {
        scope.setTag('hostname', window.location.hostname);
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });

        Sentry.captureException(error);
      });
    } else {
      // eslint-disable-next-line no-console
      console.log('ERROR', error);
    }
  }

  render() {
    const { error } = this.state;
    const { children, ErrorComponent } = this.props;

    if (error) {
      return <ErrorComponent {...this.props} />;
    } else {
      return children;
    }
  }
}

ErrorBoundary.defaultProps = {
  ErrorComponent: CrashAndBurnPage,
};

export default ErrorBoundary;

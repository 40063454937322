import React from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import format from 'date-fns/format';

import { SEARCH_TYPE_LINKS } from 'consts/scenes';
import { DAY_IN_YEAR_FORMAT } from 'consts/datetime';
import { OBJECT_ID_TIMESTAMP_DELIMITER } from 'consts/bookmarks';
import { useDeleteBookmark, useEditBookmark } from 'services/bookmark';
import darkTheme from 'services/theme/darkTheme';
import { useToggle } from 'utils/hooks';
import { updateQueryStringMultiple, parseQueryString } from 'utils/url';

import Button from 'components/common/Button';
import ButtonsWrapper from 'components/common/ButtonsWrapper';
import BookmarkForm from 'components/common/BookmarkForm';
import { EditIcon, DeleteIcon } from 'components/common/Icons';
import { ItemContainer } from 'components/common/PageList';

const Item = styled(ItemContainer)`
  color: ${({ $sidebar, theme }) => ($sidebar ? darkTheme.colors.text.primary : theme.colors.text.primary)};
  cursor: ${({ $editingBookmark }) => ($editingBookmark ? 'default' : 'pointer')};
  background: ${({ $sidebar, theme }) => ($sidebar ? 'rgba(50, 50, 50, 0.5)' : theme.colors.alt1)};

  ${({ $open, theme }) =>
    $open &&
    css`
      border: 1px solid ${theme.colors.primary};

      &:hover {
        border: 1px solid ${theme.colors.primary};
      }
    `}
`;

const NoResultsWrapper = styled(ItemContainer)`
  flex-direction: column;
  align-items: flex-start;
  color: ${({ $sidebar, theme }) => ($sidebar ? darkTheme.colors.text.primary : theme.colors.text.primary)};
`;

const attributeCss = css`
  max-width: ${({ $sidebar }) => ($sidebar ? 100 : 95)}%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  ${attributeCss}
`;

const Description = styled.div`
  font-size: 14px;
  min-height: 16px;
  ${attributeCss}
`;

const Labels = styled.div`
  font-size: 14px;
  font-weight: 600;
  min-height: 16px;
  color: ${({ theme }) => theme.colors.secondary};
  ${attributeCss}
`;

const CreatedAt = styled.div`
  margin-right: 8px;
  flex: 1;
  max-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DeleteBookmarkAlert = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 0px;
  margin-top: 4px;
  max-height: 100px;
`;

const getBookmarkHref = (location, bookmark, type) => {
  if (type === 'pql') {
    return updateQueryStringMultiple(
      { q: bookmark.query, view: 'intel-events' },
      SEARCH_TYPE_LINKS[bookmark.searchType],
    );
  }

  const DASHBOARD_PATHS = [
    '/alerting/overview',
    '/alerting/intel-events',
    '/alerting/cloudapi',
    '/investigation/indicators',
    '/investigation/logs',
  ];
  if (bookmark.searchType === 'O_ALERTS') {
    const queryParams = {
      r: undefined,
      rts: undefined,
      alert: bookmark.objectId,
    };

    const isInDashboard = DASHBOARD_PATHS.some((p) => location.pathname.includes(p));
    const searchParams = new URLSearchParams(location.search);
    if (!isInDashboard) {
      queryParams.view = searchParams.get('view') === 'intel-events' ? 'intel-events' : 'all';
    }

    return updateQueryStringMultiple(queryParams, isInDashboard ? undefined : SEARCH_TYPE_LINKS[bookmark.searchType]);
  }

  const queryParams = {
    r: bookmark.objectId,
    rts: undefined,
    alert: undefined,
  };

  if (bookmark.searchType === 'O_SENSOR') {
    const [objectId, recordTs] = bookmark.objectId.split(OBJECT_ID_TIMESTAMP_DELIMITER);
    queryParams.r = objectId;
    queryParams.rts = recordTs;
  }

  if (bookmark.searchType === 'O_EVENTS') {
    const [objectId, recordTs] = bookmark.objectId.split(OBJECT_ID_TIMESTAMP_DELIMITER);
    queryParams.r = objectId;
    queryParams.rts = recordTs;
    queryParams.view = 'intel-events';
    return updateQueryStringMultiple(queryParams, SEARCH_TYPE_LINKS[bookmark.searchType]);
  }

  return updateQueryStringMultiple(
    queryParams,
    DASHBOARD_PATHS.some((p) => location.pathname.includes(p)) ? undefined : SEARCH_TYPE_LINKS[bookmark.searchType],
  );
};

const BookmarkItem = React.memo(({ bookmark, type = 'id', openRecordName = null, sidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const bookmarkHref = getBookmarkHref(location, bookmark, type);
  const editingBookmark = useToggle();
  const deletingBookmark = useToggle();
  const deleteBookmark = useDeleteBookmark();
  const editBookmark = useEditBookmark();
  const queryParams = parseQueryString(location.search);

  const isOpen = openRecordName === (bookmark.objectId?.split(OBJECT_ID_TIMESTAMP_DELIMITER)?.[0] || '');

  const handleEditClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    editingBookmark.setOn();
    deletingBookmark.setOff();
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    editingBookmark.setOff();
    deletingBookmark.setOn();
  };

  const handleEditBookmark = (values) => {
    editBookmark
      .mutateAsync({ values, bookmark, type })
      .then(() => {
        editingBookmark.setOff();
      })
      .catch(() => {});
  };

  const handleDeleteBookmark = (e) => {
    e.stopPropagation();
    e.preventDefault();

    deleteBookmark
      .mutateAsync({ bookmark, type })
      .then(() => {
        deletingBookmark.setOff();
      })
      .catch(() => {});
  };

  const handleBookmarkItemClick = () => {
    const [pathname, query] = bookmarkHref.split('?');

    navigate(`${pathname}${query ? `?${query}` : ''}`, {
      state: {
        previousRecordName: queryParams.r || queryParams.alert,
      },
    });
  };

  return (
    <Item
      $sidebar={sidebar}
      onClick={handleBookmarkItemClick}
      $editingBookmark={editingBookmark.isOn || deletingBookmark.isOn}
      $open={isOpen}
      data-test-id={`bookmark-item-${bookmark.name}`}
    >
      <div className="flex flex-col w-full">
        <div className="flex overflow-hidden">
          <div className="flex-1 overflow-hidden">
            <Name $sidebar={sidebar}>{bookmark.name}</Name>
            {bookmark.description && <Description $sidebar={sidebar}>{bookmark.description}</Description>}
            {bookmark.labels && bookmark.labels.length > 0 && (
              <Labels $sidebar={sidebar}>{bookmark.labels.join(', ')}</Labels>
            )}
          </div>

          {!sidebar && (
            <>
              <CreatedAt>
                <div className="old-text-xs">Created at</div>
                <div className="old-text-sm color-text-subtle">
                  {format(new Date(bookmark.createdAt), DAY_IN_YEAR_FORMAT)}
                </div>
              </CreatedAt>

              <div className="flex items-center">
                <Button variant="text" onClick={handleEditClick}>
                  <EditIcon />
                </Button>

                <Button variant="text" color="danger" onClick={handleDeleteClick}>
                  <DeleteIcon />
                </Button>
              </div>
            </>
          )}
        </div>

        {editingBookmark.isOn && (
          <BookmarkForm
            type={type}
            bookmark={bookmark}
            error={editBookmark.error}
            loading={editBookmark.isLoading}
            onClose={editingBookmark.setOff}
            onSubmit={handleEditBookmark}
          />
        )}

        {deletingBookmark.isOn && (
          <DeleteBookmarkAlert>
            Are you sure you want to delete this bookmark?
            <ButtonsWrapper className="mt-2 justify-end">
              <Button
                variant="text"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  deletingBookmark.setOff();
                }}
              >
                Cancel
              </Button>
              <Button
                color="danger"
                variant="contained"
                isLoading={deleteBookmark.isLoading}
                onClick={handleDeleteBookmark}
              >
                Delete
              </Button>
            </ButtonsWrapper>
          </DeleteBookmarkAlert>
        )}
      </div>
    </Item>
  );
});

export const NoBookmarks = ({ title, description, sidebar }) => {
  return (
    <NoResultsWrapper sidebar={sidebar}>
      <Name>{title}</Name>
      {description && <Description sidebar={sidebar}>{description}</Description>}
    </NoResultsWrapper>
  );
};

export default BookmarkItem;

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from 'services/api';

const useDeleteBookmark = () => {
  const api = useApi();
  const client = useQueryClient();

  return useMutation(
    async ({ bookmark, type }) => {
      const records = client.getQueryData(['bookmarks', type]);

      await api.delete(`/bookmarks/${type}/${bookmark.id}`);

      client.setQueryData(
        ['bookmarks', type],
        records.filter((r) => r.id !== bookmark.id),
      );
    },
    {
      enabled: !!api,
    },
  );
};

export default useDeleteBookmark;

import _debounce from 'lodash/debounce';

export const debounceHandler = <T extends (...args: any[]) => ReturnType<T>>(fn: T, time = 300) => {
  const debounced = _debounce(fn, time);
  return function (...args: Parameters<T>) {
    const isEvent = (arg: unknown): arg is React.SyntheticEvent => {
      return !!arg && (arg as React.SyntheticEvent).persist != null;
    };

    if (isEvent(args[0])) {
      args[0].persist();
    }

    return debounced(...args);
  };
};

// used for lazy loaded imports
export function retry<T extends (...args: any[]) => Promise<Awaited<ReturnType<T>>>>(
  fn: T,
  retriesLeft = 2,
  interval = 500,
): Promise<Awaited<ReturnType<T>>> | ReturnType<T> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(() => {
        setTimeout(() => {
          if (retriesLeft <= 1) {
            // lets just reload the page if we cant fetch the chunk
            window.location.reload();

            // reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'components/styled/Layout';
import Textarea from 'components/common/Textarea';

const RuntimeDetails = ({ details, token }) => {
  return (
    <Grid className="mb-6" templateColumns="120px auto" gap="8px">
      {details.map((detail, idx) => (
        <React.Fragment key={detail.name + idx}>
          <div className="font-semibold">{detail.name}:</div>
          <div className="break-all">{detail.value}</div>
        </React.Fragment>
      ))}
      <div className="font-semibold">Token:</div>
      <Textarea id="token" size="small" value={token} disabled />
    </Grid>
  );
};

RuntimeDetails.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  token: PropTypes.string.isRequired,
};

export default RuntimeDetails;

import { required, resolveNopeRefs } from './shared';

const min = (minNumber, message) => (value, context) => {
  const resolvedValue = resolveNopeRefs(value, context);

  return resolvedValue <= minNumber && message;
};

const max = (maxNumber, message) => (value, context) => {
  const resolvedValue = resolveNopeRefs(value, context);

  return resolvedValue >= maxNumber && message;
};

const includes = (options, message) => (value, context) => {
  const resolvedOptions = resolveNopeRefs(options, context);
  const resolvedValue = resolveNopeRefs(value, context);

  return !resolvedOptions.includes(resolvedValue) && message;
};

class NopeNumber {
  validationRules = [];

  required(message = 'This field is required') {
    this.validationRules.push(required(message));

    return this;
  }

  max(length, message = `Input can't be longer than ${length} characters`) {
    this.validationRules.push(max(length, message));

    return this;
  }

  min(length, message = `Input can't be shorter than ${length} characters`) {
    this.validationRules.push(min(length, message));

    return this;
  }

  oneOf(options, message = `Input must be one of ${options.join(', ')}`) {
    this.validationRules.push(includes(options, message));

    return this;
  }

  validate(entry, context, objectShape) {
    for (let fn of this.validationRules) {
      const error = fn(entry, context, objectShape);

      if (error) {
        return error;
      }
    }
  }
}

export default NopeNumber;

import styled, { css } from 'styled-components';

import Table from 'components/styled/Table';
import FlatInput from 'components/common/FlatInput';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Container = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};

  &:not(:first-of-type) {
    padding-top: 20px;
  }
`;

const SensorPickerTable = styled(Table)`
  width: 100%;
  font-size: 14px;

  thead {
    font-size: 16px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.border.default};

    td {
      ${({ $disabled, theme }) =>
        $disabled &&
        css`
          color: ${theme.colors.text.subtle};
          cursor: not-allowed;
          pointer-events: none;
        `}
    }
  }

  tr {
    border: none;
  }
`;

const disabledShadow = `inset 0 0 999em rgba(0, 0, 0, 0.20)`;

const SelectableRow = styled.tr`
  color: ${({ theme }) => theme.colors.text.subtle};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  box-shadow: ${({ $disabled }) => ($disabled ? disabledShadow : 'none')};

  &:hover {
    box-shadow: ${({ $disabled }) => ($disabled ? disabledShadow : 'inset 0 0 999em rgba(0, 0, 0, 0.05)')};
  }
`;

const SensorToggleCell = styled.td`
  width: 32px;

  svg {
    color: ${({ $selected, theme }) => ($selected ? theme.palette.success[0] : theme.colors.text.subtle)};
  }

  &.sensor {
    ${({ $disabled, theme }) =>
      $disabled &&
      css`
        svg {
          color: ${theme.colors.text.subtle};
        }
      `}
  }
`;

const SmallNSubtle = styled.div`
  font-size: 12px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.text.subtle};
  margin-bottom: 4px;
`;

const InfoCell = styled.td`
  width: 250px;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  color: ${({ $selected, theme }) => ($selected ? theme.colors.text.primary : theme.colors.text.subtle)};
`;

const SelectOnlyCell = styled.td`
  font-size: 12px;
  text-align: right;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    text-decoration: underline;
  }
`;

const SelectAll = styled.div`
  font-size: 14px;
  align-self: flex-end;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 8px 0 18px;

  &:hover {
    text-decoration: underline;
  }
`;

const SensorLegend = styled.div`
  display: flex;
  font-size: 14px;
  margin: 14px 0px;
`;

const DisabledMessageWrapper = styled.div`
  h4 {
    margin: 24px 0 2px;
  }

  p {
    margin: 0 0 -12px;
    font-size: 14px;

    &::before {
      content: '* ';
      color: ${({ theme }) => theme.palette.danger[1]};
    }
  }
`;

const SearchInput = styled(FlatInput)`
  font-size: 14px;
`;

const InfoCellWrapper = styled.div`
  margin-top: 5px;
  padding-left: 12px;
  min-height: 40px;

  border-left: 4px solid
    ${({ theme, $selected, $color, $disabled }) =>
      $selected && !$disabled && $color ? $color : theme.colors.text.subtle} !important;
`;

export {
  HeaderWrapper,
  Container,
  SensorPickerTable,
  SensorToggleCell,
  SelectableRow,
  SmallNSubtle,
  InfoCell,
  SelectOnlyCell,
  SelectAll,
  SensorLegend,
  DisabledMessageWrapper,
  SearchInput,
  InfoCellWrapper,
};

import React from 'react';
import styled from 'styled-components';

import log from 'services/log';
import { useConstant } from 'utils/hooks';

import { AlertTriangleIcon } from 'components/common/Icons';
import supportedBrowsersRegex from 'consts/supportedBrowsers';

const Container = styled.div`
  margin-top: 24px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  color: ${({ theme }) => theme.colors.text.primary};
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.primary};

  svg {
    margin-right: 4px;
  }
`;

const UnsupportedBrowserAlert = () => {
  const browserSupported = useConstant(() => supportedBrowsersRegex.test(navigator.userAgent));

  if (browserSupported) {
    return null;
  }

  log({
    type: 'warning',
    origin: 'UnsupportedBrowserAlert',
    message: `Unsupported browser detected - ${navigator.userAgent}`,
    info: navigator,
  });

  return (
    <Container>
      <Heading>
        <AlertTriangleIcon /> Unsupported browser
      </Heading>
      <br />
      <div className="mt-1">
        Thanks for using MixMode!
        <br />
        <br />
        You are seeing this message because we detected that you are using an unsupported browser version.
        <br />
        <br />
        To ensure an optimum experience, please use a modern browser.
      </div>
    </Container>
  );
};

export default UnsupportedBrowserAlert;

import NopeString from './string';
import NopeObject from './object';
import NopeNumber from './number';
import NopeDate from './date';
import NopeArray from './array';
import NopeReference from './ref';

const Nope = {
  string: () => new NopeString(),
  object: () => new NopeObject(),
  number: () => new NopeNumber(),
  date: () => new NopeDate(),
  array: () => new NopeArray(),
  ref: (key) => new NopeReference(key),
};

export default Nope;

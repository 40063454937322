import styled from 'styled-components';
import PropTypes from 'prop-types';
import { fastSpinAnimation } from 'components/styled/animations';

const SpinnerStyled = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 3px solid ${({ $color }) => $color};
  border-right-color: transparent;
  border-radius: 50%;
  animation: ${fastSpinAnimation};
`;

const Spinner = ({ color, ...props }) => <SpinnerStyled {...props} $color={color} />;

Spinner.propTypes = {
  color: PropTypes.oneOfType([
    PropTypes.oneOf(['default', 'primary', 'secondary', 'danger', 'warning', 'success']),
    PropTypes.string,
  ]),
};

Spinner.defaultProps = {
  color: 'currentColor',
};

export default Spinner;

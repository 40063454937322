import { useEffect, useReducer, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import axios from 'axios';
import queryString from 'query-string';

import * as tokenUtils from 'utils/token';
import { extractErrorMessage } from 'utils/api';
import { getTokenFromUrl, removeKeyFromQueryString } from 'utils/url';
import { getApiHttpPath, logout } from 'services/api';
import { setUser } from 'services/log';

import ApiState from './ApiState';
import AuthState, { initialState } from './AuthState';

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTHENTICATE':
      return {
        ...state,
        token: action.token,
        // we need to trigger fetching /users/current to trigger another lifecycle
        // user: { username: action.username },
        authenticationError: null,
      };
    case 'AUTHENTICATE_FAIL':
      return {
        ...state,
        token: null,
        user: null,
        authenticationError: action.error,
        isForceResetError: action.isForceResetError,
      };
    case 'CLEAR_AUTHENTICATE_ERROR':
      return {
        ...state,
        authenticationError: null,
      };
    case 'SET_USER_DETAILS':
      return {
        ...state,
        user: action.user,
        userDetailsError: null,
      };
    case 'SET_USER_DETAILS_FAIL':
      return {
        ...state,
        user: null,
        userDetailsError: action.error,
      };
    case 'RESET_PASSWORD':
      return {
        ...state,
        token: null,
        user: null,
        passwordResetError: null,
      };
    case 'RESET_PASSWORD_FAIL':
      return {
        ...state,
        token: null,
        user: null,
        passwordResetError: action.error,
      };
    case 'UPDATE_PASSWORD':
      return {
        token: null,
        user: null,
        passwordUpdateError: null,
      };
    case 'UPDATE_PASSWORD_SUCCESS':
      return {
        token: null,
        user: null,
        passwordUpdateError: null,
      };
    case 'UPDATE_PASSWORD_FAIL':
      return {
        token: null,
        user: null,
        passwordUpdateError: action.error,
      };
    default:
      return state;
  }
};

/**
 * Provides state and behavior related to API consuming.
 * Authentication, API client, token, authenticated user details.
 */
export const AuthStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const apiRef = useRef();
  const loggingInRef = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (state.token && !apiRef.current) {
      apiRef.current = axios.create({
        baseURL: getApiHttpPath(),
        timeout: 10 * 60 * 1000,
        headers: {
          'Content-Type': 'application/json',
          'x-mixmode-api-token': state.token,
          From: 'ui@mixmode.ai',
        },
        paramsSerializer: queryString.stringify,
      });

      apiRef.current.interceptors.request.use((config) => {
        // console.log('config', config);
        return config;
      });

      apiRef.current.interceptors.response.use(
        (response) => {
          return response.data;
        },
        (error) => {
          // console.log(error.response);
          if (error.response && error.response.status === 401) {
            // 401 may be received for other reasons too
            const errorMessage = _get(error, ['response', 'data', '0', 'message']);
            if (
              ['invalid token', 'jwt expired', 'jwt malformed', 'invalid signature'].some((error) =>
                _isEqual(error, errorMessage),
              )
            ) {
              unauthenticate({ skipTokenInvalidation: true });
            }
          }

          return Promise.reject(error);
        },
      );
    }

    if (state.token && !state.user) {
      getUserDetails();
    }
    // eslint-disable-next-line
  }, [state.token, state.user]);

  // read token from GET parameter
  const urlToken = getTokenFromUrl(location.search);
  useEffect(() => {
    if (urlToken) {
      // console.log('atob', atob(urlToken));
      tokenUtils.persistToken(urlToken);
      dispatch({ type: 'AUTHENTICATE', token: urlToken, username: 'unknown-entity@gmail.com' });
      getUserDetails();
      navigate(location.pathname + '?' + removeKeyFromQueryString('token'));
    }
    // eslint-disable-next-line
  }, [urlToken]);

  /**
   * Pull authenticated user details from the API.
   */
  const getUserDetails = () => {
    if (!apiRef.current || loggingInRef.current) {
      return;
    }

    loggingInRef.current = true;

    apiRef.current
      .get('/users/current')
      .then((user) => {
        loggingInRef.current = false;
        setUser(user.username);
        dispatch({ type: 'SET_USER_DETAILS', user: { ...user } });
      })
      .catch((error) => {
        loggingInRef.current = false;
        dispatch({ type: 'SET_USER_DETAILS_FAIL', error });
      });
  };

  /**
   * Obtain a token based on the provided credentials
   */
  const authenticate = ({ username, password }) => {
    const basePath = getApiHttpPath();

    return axios
      .post(`${basePath}/users/login`, {
        username,
        password,
      })
      .then(({ data: { token } }) => {
        tokenUtils.persistToken(token);
        setUser(username);
        dispatch({ type: 'AUTHENTICATE', token, username });
        getUserDetails();
      })
      .catch((error) => {
        dispatch({
          type: 'AUTHENTICATE_FAIL',
          error: extractErrorMessage(error),
          isForceResetError: error?.response?.status === 403,
        });
      });
  };

  /**
   * Clear the token from state and local storage.
   */
  const unauthenticate = () => {
    // always clear the token from storage
    tokenUtils.clearToken();

    if (!state.token) {
      return;
    }

    logout(state.token);
  };

  const resetPassword = (username) => {
    dispatch({ type: 'RESET_PASSWORD' });

    return axios
      .post(`${getApiHttpPath()}/users/forgot`, { username })
      .then(() => {
        return Promise.resolve(true);
      })
      .catch((error) => {
        dispatch({ type: 'RESET_PASSWORD_FAIL', error: extractErrorMessage(error) });
        return Promise.reject(error);
      });
  };

  const updatePassword = (token, password) => {
    dispatch({ type: 'UPDATE_PASSWORD' });

    return axios
      .post(`${getApiHttpPath()}/users/reset?x-mixmode-api-token=${token}`, { password })
      .then(() => {
        dispatch({ type: 'UPDATE_PASSWORD_SUCCESS' });
        return Promise.resolve(true);
      })
      .catch((error) => {
        dispatch({ type: 'UPDATE_PASSWORD_FAIL', error: extractErrorMessage(error) });
        return Promise.reject(error);
      });
  };

  const clearAuthenticateError = () => {
    dispatch({ type: 'CLEAR_AUTHENTICATE_ERROR' });
  };

  const value = {
    state,
    authenticate,
    unauthenticate,
    resetPassword,
    updatePassword,
    clearAuthenticateError,
    getUserDetails,
  };

  return (
    <AuthState.Provider value={value}>
      <ApiState.Provider value={apiRef.current}>{children}</ApiState.Provider>
    </AuthState.Provider>
  );
};

import defaultTheme from './reakit-theme-default';
import commonTheme from './commonTheme';

const theme = {
  name: 'Dark',
  ...defaultTheme,
  mapboxMapStyle: 'mapbox://styles/mapbox/dark-v10',
  base: commonTheme.base,
  palette: commonTheme.palette,
  recharts: commonTheme.recharts,
  colors: {
    ...commonTheme.colors,
    subtle: '#cccccc',
    default: '#e2e2e2',
    debug: '#f562f5',

    text: {
      primary: '#e2dada',
      subtle: '#a3a3a3',
    },
    background: {
      // default gradient from to
      df1: '#3c3c39',
      df2: '#262525',
      //
      default: '#2f2f2f',
      modal: '#444444',
      modalInput: '#2f2f2f',
      selected: '#111111',
      alt1: '#1b1b1b',
      alt1Light: '#262626',
      alt2: '#4e4e4e',
      selectedPrimary: '#8b7b4c80',
      selectedSecondary: '#bfdfff1c',
      lightSuccess: '##ccffbf2e',
      alertSource: '#e5737314',
      alertTarget: '#00a6ff0f',
      alertSelected: '#38260d',
    },

    border: {
      contrast: '#b8b8b8',
      default: '#8b8b8b',
      light: '#4e4e4e',
      alt1: '#898989',
      button: '#959595',
    },

    boxShadow: {
      light: '0px 1px 4px 2px rgb(155, 155, 155, 0.35)',
      topInset: 'inset 0px 7px 9px -7px rgb(0, 0, 0, 0.7)',
    },

    letterPress: {
      text: '#232323',
      shadow: 'rgba(70, 70, 70, 0.8)',
    },

    scrollShadow: {
      main: 'rgba(255, 255, 255, 0.7)',
      contrast: 'rgba(255, 255, 255, 0.1)',
    },

    pqlDropdownQuery: '#00ff41',

    chart: {
      normal: '#8b92dd',
      important: '#e67373',
      nondirectional: '#e6c321',
      userActivity: '#6bdae9',
      aggregatedPlatform: '#f8b761',
      trendLine: '#fff',
      inbound: '#de7cdd',
      outbound: '#8884d8',
      local: '#66bb6a',
      'fl-ingress': '#f796d2',
      'fl-egress': '#436bb6',
      'fl-rejected': '#f16f90',
      'fl-bytes': '#f5df7c',
      focusedOut: '#a3a3a3',
      'initial-access': '#b494d4',
      'user-management': '#b08080',
      'ai-s3-data-cloudtrail': '#6cc435',
      'ai-userevents-okta': '#ffa500',
      'ai-logingeo-okta': '#ff69b4',
      'ai-applicationaccess-okta': '#33b1f1',
    },

    recordStatus: {
      open: '#e2e2e2',
      under_review: '#7697e7',
      acknowledged: '#a3a3a3',
      closed: '#4caf50',
    },

    badge: {
      submodule: '#5d5d5d',
    },
  },
};

export default theme;

import { useState } from 'react';
import styled from 'styled-components';

import Label from 'components/common/Label';
import MultiBadgeSelect from 'components/common/MultiBadgeSelect';
import {
  ACTIVITYLOG_PROFILE_TYPES,
  FLOWLOGS_PROFILE_TYPES,
  PROFILE_TYPE,
  ZEEK_SENSOR_PROFILE_TYPES,
} from 'consts/sensors';

const Container = styled.div`
  max-width: max-content;
  min-width: 300px;
`;

const PROFILE_TYPES_DEBUG_OPTIONS = [
  {
    label: 'Zeek (group)',
    value: ZEEK_SENSOR_PROFILE_TYPES,
  },
  {
    label: 'Activity Logs (group)',
    value: ACTIVITYLOG_PROFILE_TYPES,
  },
  {
    label: 'Flow Logs (group)',
    value: FLOWLOGS_PROFILE_TYPES,
  },
  {
    label: 'AWS CloudTrail Log',
    value: [PROFILE_TYPE.awsCloudtrail],
  },
  {
    label: 'AWS Flow Log',
    value: [PROFILE_TYPE.awsFlowlogs],
  },
  {
    label: 'Azure Activity Logs',
    value: [PROFILE_TYPE.azureActivitylog],
  },
  {
    label: 'Azure Flow Log',
    value: [PROFILE_TYPE.azureFlowlogs],
  },
  {
    label: 'Cloud API Monitoring',
    value: [PROFILE_TYPE.cloudtrail],
  },
  {
    label: 'Cloud Flow Log Monitoring',
    value: [PROFILE_TYPE.flowlogs],
  },
  {
    label: 'Okta IDM',
    value: [PROFILE_TYPE.oktaLogs],
  },
  {
    label: 'Crowdstrike API Log',
    value: [PROFILE_TYPE.crowdstrikeApi],
  },
  {
    label: 'Crowdstrike FDR Log',
    value: [PROFILE_TYPE.crowdstrikeV2],
  },
  {
    label: 'Zeek v2',
    value: [PROFILE_TYPE.zeekV2],
  },
  {
    label: 'Kafka Connector',
    value: [PROFILE_TYPE.kafkaConnector],
  },
  {
    label: 'AWS API GW',
    value: [PROFILE_TYPE.awsApiGatewayLogs],
  },
  {
    label: 'Microsoft Graph API Log',
    value: [PROFILE_TYPE.msGraphLogs],
  },
  {
    label: 'Microsoft Graph API Log - Kafka',
    value: [PROFILE_TYPE.kafkaAzureAD],
  },
];

const getSelectedOptions = (savedProfileTypes = []) => {
  // the option should be selected if all of its profile types are in the profiles list
  return PROFILE_TYPES_DEBUG_OPTIONS.filter((o) => o.value.every((v) => savedProfileTypes.includes(v)));
};

const STORAGE_KEY = 'mm_debugProfileTypes';

const getDebugProfileTypes = () => {
  const debugProfileTypes = sessionStorage.getItem(STORAGE_KEY);

  try {
    return JSON.parse(debugProfileTypes) || [];
  } catch (e) {
    return [];
  }
};

const SelectProfileTypes = () => {
  const [debugProfileTypes, setDebugProfileTypes] = useState(() => getDebugProfileTypes());

  const selectedOptions = getSelectedOptions(debugProfileTypes);

  const handleChange = (options) => {
    const profileTypes = options.map((o) => o.value).flat();
    setDebugProfileTypes(profileTypes);
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(profileTypes));
  };

  return (
    <Container>
      <Label className="mb-0.5">Select sensor types</Label>
      <MultiBadgeSelect
        options={PROFILE_TYPES_DEBUG_OPTIONS}
        value={selectedOptions}
        placeholder="All"
        onChange={handleChange}
      />
      <div className="color-text-subtle mt-0.5 old-text-xs">* Only the selected sensor types will be loaded</div>
    </Container>
  );
};

export default SelectProfileTypes;

import React from 'react';
import styled, { css } from 'styled-components';

const SBackdrop = styled.div`
  position: fixed;
  top: ${({ $top }) => $top}px;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 990;
  background-color: #000000;
  transition:
    all 200ms ease-in-out,
    top 0ms;

  ${({ $visible }) =>
    $visible
      ? css`
          opacity: 0.75;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
`;

const Backdrop = ({ top, visible, ...props }) => {
  return <SBackdrop {...props} $top={top} $visible={visible} />;
};

export default Backdrop;

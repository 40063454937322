import styled from 'styled-components';

const BUTTON_GROUP_BORDER_RADIUS = '4px';
const ButtonGroup = styled.div`
  display: flex;
  margin-right: 8px;

  button:first-of-type {
    border-top-left-radius: ${BUTTON_GROUP_BORDER_RADIUS};
    border-bottom-left-radius: ${BUTTON_GROUP_BORDER_RADIUS};
  }

  button:last-of-type {
    border-top-right-radius: ${BUTTON_GROUP_BORDER_RADIUS};
    border-bottom-right-radius: ${BUTTON_GROUP_BORDER_RADIUS};
  }

  button:not(:first-of-type) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  button:not(:last-of-type) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export default ButtonGroup;

import * as React from 'react';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';

import { PAGES } from 'consts/pages';
import { FACETS } from 'consts/logProperties';
import { DAY_IN_YEAR_PQL_FORMAT } from 'consts/datetime';

const yesterday = format(subDays(new Date(), 1), DAY_IN_YEAR_PQL_FORMAT);

const initialPageState = {
  // used for aggregated search
  lastPqlQuery: null, // read only if url does not have a query set
  lastSearchTimerange: null,
  lastSearchTs: null,
  lastSearch: {},
  t1: null,
  t2: null,
  //
  validating: false,
  verifiedTimerange: false,
  timerange: false, // current query timerange
  lastAttribute: null,
  history: {},
  suggestions: [],
  cancelSrc: null,
  timeranges: [
    'last 24 hours',
    'last hour',
    'last monday',
    'today',
    'yesterday',
    `from ${yesterday} 9:00pm to ${yesterday} 5:00pm`,
  ],
};

export const initialState = {
  [PAGES.events]: {
    ...initialPageState,
    possibleKeys: FACETS.events,
  },
  [PAGES.indicators]: {
    ...initialPageState,
    possibleKeys: FACETS.indicators,
  },
  [PAGES.investigator]: {
    ...initialPageState,
    possibleKeys: FACETS.investigator,
  },
  [PAGES.multistream]: {
    ...initialPageState,
    possibleKeys: [],
  },
  [PAGES.notifications]: {
    ...initialPageState,
    possibleKeys: [],
  },
  [PAGES.cloudapi]: {
    ...initialPageState,
    possibleKeys: [],
  },
  [PAGES.flowlogs]: {
    ...initialPageState,
    possibleKeys: [],
  },
  [PAGES.identityManagement]: {
    ...initialPageState,
    possibleKeys: [],
  },
};

const PqlState = React.createContext({ ...initialState });

export default PqlState;

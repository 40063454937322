import { useCallback, useContext } from 'react';

import AuthState from './AuthState';

export const useCurrentUser = () => {
  const { state, unauthenticate, getUserDetails } = useContext(AuthState);

  const refetchUserDetails = getUserDetails;

  const hasPermission = (permissionName: string) => {
    return (
      state.user &&
      state.user.permissions &&
      !!state.user.permissions.find((permission) => permission.name === permissionName)
    );
  };

  const hasAnyTenantAdminPermission = useCallback(() => {
    return (
      state.user &&
      state.user.permissions &&
      !!state.user.permissions.find((permission) => permission.name.startsWith('TenantAdmin'))
    );
  }, [state.user]);

  return {
    user: state.user,
    token: state.token,
    hasPermission,
    hasAnyTenantAdminPermission,
    refetchUserDetails,
    unauthenticate,
  };
};

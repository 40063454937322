import React, { useReducer } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import _set from 'lodash/fp/set';
import _omit from 'lodash/omit';
import _merge from 'lodash/merge';
import _cloneDeep from 'lodash/cloneDeep';

import { useApi } from 'services/api';
import { updateItemInCollection } from 'utils/array';
import { extractErrorMessage } from 'utils/api';

const initialTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const initialDraft = {
  name: 'New task',
  description: '',
  labels: [],
  threshold: 0,
  threshold_operator: 'greater_than',
  email_recipients: null,
  email_send: false,
  enabled: true,
  data: {
    query: {
      query: 'last 2 hours',
      timezone: initialTimezone,
      searchType: 'O_SENSOR',
      context: [],
    },
    schedule: {
      cron: '0 */1 * * *',
      timezone: initialTimezone,
    },
    templates: {
      IndicatorTemplate: {
        data: {},
      },
    },
  },
};

const initialAssigned = {
  tenants: null,
  sensors: null,
};

const initialState = {
  tasks: [],
  loading: false,
  error: null,
  sidebarOpen: false, // oneOf(["edit", "assign"])
  draft: _cloneDeep(initialDraft),
  validating: false,
  resultCount: null,
  noisyQuery: false,
  queryError: null,
  updating: false,
  updateError: null,
  executing: [],
  loadingAssigned: false,
  assigning: {
    all: false,
    tenants: [],
    sensors: [],
  },
  assigned: initialAssigned,
};

export const TasksState = React.createContext({ ...initialState });

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_TASKS_START':
      return { ...state, loading: true };
    case 'FETCH_TASKS_SUCCESS':
      return { ...state, loading: false, tasks: action.payload, error: null };
    case 'FETCH_TASKS_FAIL':
      return { ...state, loading: false, tasks: [], error: action.error };
    case 'UPDATE_TASK_START':
      return { ...state, updating: action.taskId || true };
    case 'CREATE_TASK_SUCCESS':
      return {
        ...state,
        updating: false,
        tasks: [...state.tasks, action.payload],
        updateError: null,
        sidebarOpen: false,
      };
    case 'UPDATE_TASK_SUCCESS':
      return {
        ...state,
        updating: false,
        tasks: updateItemInCollection(action.payload, state.tasks),
        updateError: null,
        sidebarOpen: false,
      };
    case 'DELETE_TASK_SUCCESS':
      return {
        ...state,
        updating: false,
        tasks: state.tasks.filter((r) => r.id !== state.updating),
        updateError: null,
        sidebarOpen: false,
      };
    case 'UPDATE_TASK_FAIL':
      return { ...state, updating: false, updateError: action.error };
    case 'EXECUTE_TASK_START':
      return { ...state, executing: [...state.executing, action.taskId] };
    case 'EXECUTE_TASK_SUCCESS':
    case 'EXECUTE_TASK_FAIL':
      return { ...state, executing: state.executing.filter((value) => value !== action.taskId) };
    case 'OPEN_SIDEBAR':
      return {
        ...state,
        sidebarOpen: action.sidebarType,
        sidebarType: action.payload === null ? 'create' : 'edit',
        draft: _merge(action.payload || _cloneDeep(initialDraft), action.defaultValues || {}),
      };
    case 'CLOSE_SIDEBAR':
      return { ...state, sidebarOpen: false, assigned: initialAssigned };
    case 'SET_DRAFT':
      return { ...state, draft: action.draft, updateError: null };
    case 'RESET_DRAFT':
      return { ...state, draft: null };
    case 'VALIDATE_QUERY_START':
      return { ...state, validating: true };
    case 'VALIDATE_QUERY_SUCCESS':
      return {
        ...state,
        validating: false,
        resultCount: action.resultCount,
        queryError: null,
        noisyQuery: action.noisyQuery,
      };
    case 'VALIDATE_QUERY_FAIL':
      return {
        ...state,
        validating: false,
        resultCount: null,
        queryError: action.error,
        noisyQuery: false,
      };
    case 'CLEAR_VALIDATION':
      return {
        ...state,
        validating: false,
        resultCount: null,
        noisyQuery: false,
        queryError: null,
      };
    case 'DUPLICATE_TASK':
      // setting result count as query is already valid
      return { ...state, draft: action.payload, resultCount: 0, sidebarOpen: 'edit' };
    case 'FETCH_ASSIGNED_START':
      return { ...state, loadingAssigned: true };
    case 'FETCH_ASSIGNED_SUCCESS':
      return { ...state, assigned: action.payload, loadingAssigned: false };
    case 'FETCH_ASSIGNED_FAIL':
      return { ...state, assigned: null, loadingAssigned: false };
    case 'ASSIGN_START':
      return {
        ...state,
        assigning: {
          ...state.assigning,
          [action.level]: action.level === 'all' ? true : [...state.assigning[action.level], action.id],
        },
      };
    case 'ASSIGN_SUCCESS':
      return {
        ...state,
        assigning: {
          ...state.assigning,
          [action.level]: state.assigning[action.level].filter((x) => x !== action.id),
        },
        assigned: {
          ...state.assigned,
          [action.level]: [...state.assigned[action.level], action.id],
        },
      };
    case 'UNASSIGN_SUCCESS':
      return {
        ...state,
        assigning: {
          ...state.assigning,
          [action.level]: state.assigning[action.level].filter((x) => x !== action.id),
        },
        assigned: {
          ...state.assigned,
          [action.level]: state.assigned[action.level].filter((x) => x !== action.id),
        },
      };
    case 'ASSIGN_RESET':
      // reset to *all selected*, not default context state
      const resetObject = { tenants: [], sensors: [] };
      return { ...state, assigning: resetObject, assigned: resetObject };
    case 'ASSIGN_FAIL': {
      return {
        ...state,
        assigning: {
          ...state.assigning,
          [action.level]: action.level === 'all' ? false : state.assigning[action.level].filter((x) => x !== action.id),
        },
      };
    }
    case 'SET_INDICATOR_TEMPLATE':
      return {
        ...state,
        draft: {
          ...state.draft,
          data: {
            ...state.draft.data,
            templates: {
              ...state.draft.data.templates,
              IndicatorTemplate: action.template,
            },
          },
        },
      };
    case 'REMOVE_INDICATOR_TEMPLATE': {
      const { IndicatorTemplate, AlertTemplate, ...templates } = state.draft.data.templates;
      const shouldSendEmail = !!state.draft.data.templates.ReportTemplate;

      return {
        ...state,
        draft: {
          ...state.draft,
          email_send: shouldSendEmail,
          data: {
            ...state.draft.data,
            templates,
          },
        },
      };
    }
    case 'SET_ALERT_TEMPLATE':
      return {
        ...state,
        draft: {
          ...state.draft,
          data: {
            ...state.draft.data,
            templates: {
              ...state.draft.data.templates,
              AlertTemplate: action.template,
            },
          },
        },
      };
    case 'REMOVE_ALERT_TEMPLATE': {
      const { AlertTemplate, ...templates } = state.draft.data.templates;
      return {
        ...state,
        draft: {
          ...state.draft,
          data: {
            ...state.draft.data,
            templates,
          },
        },
      };
    }
    case 'TOGGLE_INDICATOR_TEMPLATE_EMAIL':
      return {
        ...state,
        draft: {
          ...state.draft,
          email_send: !!state.draft.data.templates.ReportTemplate || action.flag,
          email_recipients: state.draft.email_recipients || action.user.user.username,
        },
      };
    case 'SET_REPORT_TEMPLATE':
      return {
        ...state,
        draft: {
          ...state.draft,
          email_send: true,
          email_recipients: state.draft.email_recipients || action.user.user.username,
          data: {
            ...state.draft.data,
            templates: {
              ...state.draft.data.templates,
              ReportTemplate: { data: {} },
            },
          },
        },
      };
    case 'REMOVE_REPORT_TEMPLATE':
      const { ReportTemplate, ...otherTemplates } = state.draft.data.templates;

      return {
        ...state,
        draft: {
          ...state.draft,
          email_send: false,
          data: {
            ...state.draft.data,
            templates: otherTemplates,
          },
        },
      };
    case 'SET_REPORT_ATTACHMENT_TYPE':
      return {
        ...state,
        draft: {
          ...state.draft,
          data: {
            ...state.draft.data,
            templates: {
              ...state.draft.data.templates,
              ReportTemplate: {
                ...state.draft.data.templates.ReportTemplate,
                data: {
                  ...state.draft.data.templates.ReportTemplate.data,
                  attachmentType: action.attachmentType,
                },
              },
            },
          },
        },
      };
    default:
      return state;
  }
};

const mapToApiFormat = (task) => {
  let result = task;

  if (result.data.query.searchType !== 'O_SENSOR') {
    result = _omit(result, 'data.templates.IndicatorTemplate');
    result = _omit(result, 'data.templates.AlertTemplate');
  }

  if (result.data.templates.AlertTemplate && result.data.templates.IndicatorTemplate) {
    result.data.templates.AlertTemplate.name = result.name;
    result.data.templates.AlertTemplate.description = result.description;
    result.data.templates.AlertTemplate.data = result.data.templates.IndicatorTemplate.data;
    result.data.templates.AlertTemplate.severity = result.data.templates.IndicatorTemplate.severity;
  }

  return result;
};

const TasksProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();
  const api = useApi();

  const fetchTasks = () => {
    dispatch({ type: 'FETCH_TASKS_START' });
    fetchTasksInBackground();
  };

  const fetchTasksInBackground = () => {
    return api
      .get('/rules')
      .then((result) => {
        dispatch({ type: 'FETCH_TASKS_SUCCESS', payload: result.items });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_TASKS_FAIL', error: extractErrorMessage(error) });
      });
  };

  const createTask = () => {
    dispatch({ type: 'UPDATE_TASK_START' });
    return api
      .post('/rules', mapToApiFormat(state.draft))
      .then((result) => {
        const success = () => {
          dispatch({ type: 'CREATE_TASK_SUCCESS', payload: result });
          // clears location state so that new rule doesn't get assigned on sensors in history state
          navigate(location.pathname, {
            replace: true,
          });
        };

        const ctx = location.state?.context;

        if (ctx) {
          Promise.all(ctx.map((id) => api.post(`/rules/${result.id}/sensors/${id}`)))
            .then(success)
            // no easy way to refresh the list of assigned tenants/sensors
            .then(fetchTasks)
            .catch(success);
        } else {
          success();
        }
      })
      .catch((error) => {
        dispatch({ type: 'UPDATE_TASK_FAIL', error: extractErrorMessage(error) });
      });
  };

  const updateTask = (task = null) => {
    const draft = mapToApiFormat(task || state.draft);

    dispatch({ type: 'UPDATE_TASK_START', taskId: draft.id });
    return api
      .post(`/rules/${draft.id}`, draft)
      .then(() => {
        // api client returns blank export
        dispatch({ type: 'UPDATE_TASK_SUCCESS', payload: draft });
      })
      .catch((error) => {
        dispatch({ type: 'UPDATE_TASK_FAIL', error: extractErrorMessage(error) });
      });
  };

  const duplicateTask = (task) => {
    const draft = _omit(task, ['id', 'createdAt', 'updatedAt', 'Sensors', 'Tenants']);
    draft.name = draft.name + ' - copy';
    dispatch({ type: 'DUPLICATE_TASK', payload: draft });
  };

  const deleteTask = (taskId) => {
    dispatch({ type: 'UPDATE_TASK_START', taskId });
    return api
      .delete(`/rules/${taskId}`)
      .then(() => {
        dispatch({ type: 'DELETE_TASK_SUCCESS' });
      })
      .catch((error) => {
        dispatch({ type: 'UPDATE_TASK_FAIL', error: extractErrorMessage(error) });
      });
  };

  const executeTask = (taskId) => {
    dispatch({ type: 'EXECUTE_TASK_START', taskId });
    return api
      .post(`/rules/${taskId}/execute`)
      .then(() => {
        dispatch({ type: 'EXECUTE_TASK_SUCCESS', taskId });
      })
      .catch((error) => {
        dispatch({ type: 'EXECUTE_TASK_FAIL', taskId, error: extractErrorMessage(error) });
      });
  };

  const setDraft = (draft) => {
    dispatch({ type: 'SET_DRAFT', draft });
  };

  const setDraftProp = (path, value) => {
    // allows setting deep paths
    setDraft(_set(path, value, { ...state.draft }));
  };

  const resetDraft = () => {
    dispatch({ type: 'RESET_DRAFT' });
  };

  const validateQuery = (query, defaultSearchType, defaultContext) => {
    if (query.length === 0) {
      return;
    }
    const VALIDATE_LIMIT = 1500;

    dispatch({ type: 'VALIDATE_QUERY_START' });

    const searchType = defaultSearchType || state.draft.data.query.searchType;
    const context = defaultContext || state.draft.Sensors?.map((s) => s.id) || [];

    const opts = {
      context,
      searchType,
    };

    // the countbys and limit is added to predict whether or not a task is considered noisy (produces large sets of data)
    let pql = `${query}`;
    // dont try to pipe already piped queries
    if (!query.includes('|')) {
      if (searchType === 'O_INDICATORS' || searchType === 'O_EVENTS') {
        pql += ` | countby tenantId, sensorId limit ${VALIDATE_LIMIT}`;
      } else if (searchType === 'O_SENSOR') {
        pql += ` | countby tenantId, sensorId, src_ip, src_geo, dest_ip, dest_geo limit ${VALIDATE_LIMIT}`;
      }
    }

    return api
      .get('/search/query', {
        params: { query: pql, timezone: state.draft.data.query.timezone, ...opts },
      })
      .then((res) => {
        let resCount = 0;

        for (const x of res.results) {
          resCount += x.doc_count || x.count;
        }

        const noisyQuery = res.results.length === VALIDATE_LIMIT;

        dispatch({ type: 'VALIDATE_QUERY_SUCCESS', resultCount: resCount, noisyQuery });
      })
      .catch((error) => {
        dispatch({ type: 'VALIDATE_QUERY_FAIL', error: extractErrorMessage(error) });
      });
  };

  const clearValidation = () => {
    dispatch({ type: 'CLEAR_VALIDATION' });
  };

  const fetchAssigned = (taskId) => {
    dispatch({ type: 'FETCH_ASSIGNED_START' });
    return Promise.all([api.get(`/rules/${taskId}/tenants`), api.get(`/rules/${taskId}/sensors`)])
      .then((result) => {
        const tenants = result[0].items.map((x) => x.id);
        const sensors = result[1].items.map((x) => x.id);
        dispatch({ type: 'FETCH_ASSIGNED_SUCCESS', payload: { tenants, sensors } });
      })
      .catch(() => {
        dispatch({ type: 'FETCH_ASSIGNED_FAIL' });
      });
  };

  const assignTenant = (taskId, tenantId) => {
    dispatch({ type: 'ASSIGN_START', level: 'tenants', id: tenantId });
    api
      .post(`/rules/${taskId}/tenants/${tenantId}`)
      .then(() => {
        dispatch({ type: 'ASSIGN_SUCCESS', level: 'tenants', id: tenantId });
      })
      .catch(() => {
        dispatch({ type: 'ASSIGN_FAIL', level: 'tenants', id: tenantId });
      });
  };

  const unassignTenant = (taskId, tenantId) => {
    dispatch({ type: 'ASSIGN_START', level: 'tenants', id: tenantId });
    api
      .delete(`/rules/${taskId}/tenants/${tenantId}`)
      .then(() => {
        dispatch({ type: 'UNASSIGN_SUCCESS', level: 'tenants', id: tenantId });
      })
      .catch(() => {
        dispatch({ type: 'ASSIGN_FAIL', level: 'tenants', id: tenantId });
      });
  };

  const assignSensor = (taskId, sensorId) => {
    dispatch({ type: 'ASSIGN_START', level: 'sensors', id: sensorId });
    api
      .post(`/rules/${taskId}/sensors/${sensorId}`)
      .then(() => {
        dispatch({ type: 'ASSIGN_SUCCESS', level: 'sensors', id: sensorId });
      })
      .catch(() => {
        dispatch({ type: 'ASSIGN_FAIL', level: 'sensors', id: sensorId });
      });
  };

  const unassignSensor = (taskId, sensorId) => {
    dispatch({ type: 'ASSIGN_START', level: 'sensors', id: sensorId });
    api
      .delete(`/rules/${taskId}/sensors/${sensorId}`)
      .then(() => {
        dispatch({ type: 'UNASSIGN_SUCCESS', level: 'sensors', id: sensorId });
      })
      .catch(() => {
        dispatch({ type: 'ASSIGN_FAIL', level: 'sensors', id: sensorId });
      });
  };

  const assignReset = (taskId) => {
    // reusing assigning flags
    dispatch({ type: 'ASSIGN_START', level: 'all', id: true });
    api
      .post(`/rules/${taskId}/clear`)
      .then(() => {
        dispatch({ type: 'ASSIGN_RESET' });
      })
      .catch(() => {
        dispatch({ type: 'ASSIGN_FAIL', level: 'all', id: null });
      });
  };

  const toggleIndicatorTemplate = (turnOn, template, user) => {
    if (turnOn) {
      dispatch({ type: 'SET_INDICATOR_TEMPLATE', template, user });
    } else {
      dispatch({ type: 'REMOVE_INDICATOR_TEMPLATE' });
    }
  };

  const toggleAlertTemplate = (turnOn, template, user) => {
    if (turnOn) {
      dispatch({ type: 'SET_ALERT_TEMPLATE', template, user });
    } else {
      dispatch({ type: 'REMOVE_ALERT_TEMPLATE' });
    }
  };

  const toggleIndicatorEmail = (flag, user) => {
    dispatch({ type: 'TOGGLE_INDICATOR_TEMPLATE_EMAIL', flag, user });
  };

  const toggleReportTemplate = (turnOn, user) => {
    if (turnOn) {
      dispatch({ type: 'SET_REPORT_TEMPLATE', user });
    } else {
      dispatch({ type: 'REMOVE_REPORT_TEMPLATE' });
    }
  };

  const setReportAttachmentType = (attachmentType) => {
    if (!state.draft.data.templates.ReportTemplate) {
      dispatch({ type: 'SET_REPORT_TEMPLATE' });
    }

    dispatch({ type: 'SET_REPORT_ATTACHMENT_TYPE', attachmentType });
  };

  const value = {
    ...state,
    dispatch,
    fetchTasks,
    fetchTasksInBackground,
    createTask,
    updateTask,
    deleteTask,
    executeTask,
    setDraft,
    setDraftProp,
    resetDraft,
    validateQuery,
    clearValidation,
    duplicateTask,
    fetchAssigned,
    assignTenant,
    unassignTenant,
    assignSensor,
    unassignSensor,
    assignReset,
    toggleIndicatorTemplate,
    toggleAlertTemplate,
    toggleIndicatorEmail,
    toggleReportTemplate,
    setReportAttachmentType,
  };

  return <TasksState.Provider value={value}>{children}</TasksState.Provider>;
};

export default TasksProvider;

import { PRESET_OPTIONS } from 'consts/dashboard';

import { CONFIG_VERSION } from './config';

export const MIGRATIONS = {
  [1]: (config) => {
    if (config.overview) {
      config.events = config.overview;
      delete config.overview;
    }

    config.presets = {};

    for (const page in PRESET_OPTIONS) {
      const oldConfig = config[page];
      config.presets[page] = PRESET_OPTIONS[page].reduce((acc, preset) => {
        const config = preset.value === 'custom' && oldConfig?.length ? oldConfig : preset.defaultWidgets;

        return {
          ...acc,
          [preset.value]: config,
        };
      }, {});

      delete config[page];
    }

    delete config.loadedConfig;
    delete config.currentDashboard;
    delete config.cloudapi;
    delete config.identityManagement;
    delete config.multistream;

    config.version = 1;

    return config;
  },
  [2]: (config) => {
    const { log, ...rest } = config.columnsConfig || {};

    for (const page of Object.keys(rest)) {
      config.columnsConfig[page] = config.columnsConfig[page].map((c) => (c === 'risk_score' ? 'deviation_score' : c));
    }

    for (const logType of Object.keys(log || {})) {
      config.columnsConfig.log[logType] = config.columnsConfig.log[logType].map((c) =>
        c === 'risk_score' ? 'deviation_score' : c,
      );
    }

    for (const pages of Object.keys(config.presets)) {
      for (const preset of Object.keys(config.presets[pages])) {
        config.presets[pages][preset] = config.presets[pages][preset].map((c) =>
          c === 'risk_score' ? 'deviation_score' : c,
        );
      }
    }

    config.version = 2;

    return config;
  },
};

if (import.meta.env.DEV && !MIGRATIONS[CONFIG_VERSION]) {
  alert(`No migration found for config version ${CONFIG_VERSION}`);
}

export const migrate = (config) => {
  if (!config.version) {
    config.version = 0;
  }

  if (config.version === CONFIG_VERSION) {
    return config;
  }

  for (let i = config.version + 1; i <= CONFIG_VERSION; i++) {
    config = MIGRATIONS[i](config);
  }

  return config;
};

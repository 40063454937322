import styled from 'styled-components';

const Box = styled.div`
  border: unset;
  box-sizing: border-box;
  /* margin: unset; */
  background: unset;
  font: unset;
`;

export default Box;

import React, { useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { AuthState } from 'services/auth';
import { DebugState } from 'services/debug';
import { logout } from 'services/api';

import { injectZendesk, openSupportChat } from 'utils/zendesk';

import Button from 'components/common/Button';
import {
  CreditCardIcon,
  FeatureToggleIcon,
  TerminalIcon,
  LifeBuoyIcon,
  LogoutIcon,
  UserSettingsIcon,
} from 'components/common/Icons';
import Dropdown from 'components/common/Dropdown';
import { Divider } from 'components/styled/Layout';

const MenuItemButton = styled(Button)`
  padding: 8px 16px;
  border-radius: 2px;
  width: 100%;
  justify-content: flex-start;

  svg {
    margin-right: 16px;
  }
`;

const VersionNumber = styled.div`
  font-size: 12px;
  padding: 8px 16px;
  font-style: italic;
  display: flex;
  justify-content: flex-end;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const UserMenu = () => {
  const navigate = useNavigate();
  const {
    state: { user, token },
  } = useContext(AuthState);
  const { debug } = useContext(DebugState);

  const handleSupportOpen = () => {
    injectZendesk(() => {
      openSupportChat(user?.username);
    });
  };

  return (
    <Dropdown
      top={50}
      right={10}
      width={265}
      arrow="right"
      align="left"
      trigger={
        <Button variant="text" data-test-id="user-menu-trigger">
          {user && user.username}
        </Button>
      }
    >
      <VersionNumber data-test-id="version-number">Version: {import.meta.env.VITE_RELEASE_VERSION}</VersionNumber>
      <MenuItemButton
        variant="text"
        onClick={() => {
          navigate('/user-settings');
        }}
        data-test-category="menu-button"
        data-test-id="menu-button-user-settings"
      >
        <UserSettingsIcon /> User Settings
      </MenuItemButton>
      <MenuItemButton
        variant="text"
        onClick={() => {
          navigate('/usage');
        }}
        data-test-category="menu-button"
        data-test-id="menu-button-usage"
      >
        <CreditCardIcon aria-hidden /> Usage
      </MenuItemButton>
      <MenuItemButton
        variant="text"
        onClick={() => {
          navigate('/api/mql-search');
        }}
        data-test-category="menu-button"
        data-test-id="menu-button-api"
      >
        <TerminalIcon aria-hidden /> API
      </MenuItemButton>
      <MenuItemButton
        variant="text"
        onClick={handleSupportOpen}
        data-test-category="menu-button"
        data-test-id="menu-button-chat"
      >
        <LifeBuoyIcon aria-hidden /> Support / Feature Requests
      </MenuItemButton>
      {debug && (
        <>
          <Divider height="8px" />
          <MenuItemButton
            variant="contained"
            color="danger"
            onClick={() => {
              navigate('/debug');
            }}
            data-test-category="menu-button"
            data-test-id="menu-button-debug"
          >
            <FeatureToggleIcon aria-hidden /> Debug
          </MenuItemButton>
        </>
      )}
      <Divider height="8px" />
      <MenuItemButton
        variant="text"
        onClick={() => {
          logout(token);
        }}
        data-test-category="menu-button"
        data-test-id="menu-button-logout"
      >
        <LogoutIcon aria-hidden /> Sign Out
      </MenuItemButton>
    </Dropdown>
  );
};

export default UserMenu;

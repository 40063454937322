import React from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/common/Spinner';
import PageLoader from './PageLoader';
import SidebarLoader from './SidebarLoader';
import ModalLoader from './ModalLoader';
import MixModeLogoLoader from './MixModeLogoLoader';

/**
 * @param {{inside: 'page'|'button'|'sidebar'|'modal'|'block'}} props
 */
const Loader = ({ inside, ...props }) => {
  switch (inside) {
    case 'page':
      return <PageLoader {...props} />;
    case 'button':
      return <Spinner {...props} />;
    case 'sidebar':
      return <SidebarLoader {...props} />;
    case 'modal':
      return <ModalLoader {...props} />;
    case 'block':
      return <MixModeLogoLoader {...props} />;
    default:
      return null;
  }
};

Loader.propTypes = {
  inside: PropTypes.oneOf(['page', 'button', 'sidebar', 'modal', 'block']),
  subtle: PropTypes.bool,
};

export default Loader;

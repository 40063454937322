import { type ComponentProps } from 'react';
import RcTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

import './style.css';

export type PlacementOptions =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'leftTop'
  | 'leftBottom'
  | 'rightTop'
  | 'rightBottom';

type TooltipProps = ComponentProps<typeof RcTooltip> & {
  isEnabled?: boolean;
  placement: PlacementOptions;
};

const Tooltip = (props: TooltipProps) => {
  const { isEnabled = true, children, ...rest } = props;

  if (isEnabled) {
    return <RcTooltip {...rest}>{children}</RcTooltip>;
  }

  return children;
};

export default Tooltip;

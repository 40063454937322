import { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router';
import { useTheme } from 'styled-components';

import PageLoader from 'components/common/Loader/PageLoader';
import UnsupportedBrowserAlert from 'components/common/UnsupportedBrowserAlert';
import { AuthState } from 'services/auth';
import * as urlUtils from 'utils/url';
import { DEFAULT_VIEW_MAP } from 'consts/scenes';
import logotypeDark from 'static/images/stacked-logo-dark.svg';
import logotypeLight from 'static/images/stacked-logo-light.svg';

import { Container, TopSpacedContainer, Logo } from './common.styled';

const AuthWrapper = ({ children }) => {
  const theme = useTheme();
  const location = useLocation();
  const authApiContext = useContext(AuthState);

  useEffect(() => {
    authApiContext.clearAuthenticateError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (authApiContext.state.token) {
    const homePage = DEFAULT_VIEW_MAP[authApiContext?.state?.user?.defaultView] || DEFAULT_VIEW_MAP.home;

    const { redirect_to } = urlUtils.parseQueryString(location.search);
    // if redirect_to is not a valid specific app path, it means user _just_ logged in, so we should respect defaultView settings instead
    const ssoRedirect = redirect_to === '' || redirect_to === '/' || redirect_to === '/login' ? false : redirect_to;
    const from = location?.state?.from?.pathname === '/user/logout' ? false : location?.state?.from;
    // we're logged in, but we're still fetching current user
    return authApiContext.state.user ? <Navigate to={from || ssoRedirect || homePage} /> : <PageLoader />;
  }

  return (
    <Container className="flex justify-center">
      <TopSpacedContainer className="mt-8 color-text-primary">
        <div className="flex justify-center">
          <Logo src={theme.name === 'Light' ? logotypeDark : logotypeLight} alt="MixMode.ai logo" />
        </div>
        <UnsupportedBrowserAlert />

        {children}
      </TopSpacedContainer>
    </Container>
  );
};

export default AuthWrapper;

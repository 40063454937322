import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ErrorMessageComponent } from 'components/common/ErrorMessage';

export const Container = styled.div`
  background: ${({ theme }) => (theme.name === 'Light' ? 'white' : theme.colors.background.default)};
  height: 100vh;
  width: 100%;
`;

export const TopSpacedContainer = styled.div`
  width: 420px;
`;

export const Logo = styled.img`
  width: auto;
  height: 180px;
`;

export const AuthLink = styled(Link)`
  font-size: 14px;
  color: unset;

  &:hover {
    text-decoration: underline;
  }
`;

export const CenteredErrorMessage = styled(ErrorMessageComponent)`
  margin: 12px 0 0 !important;
  text-align: center;
`;

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { useCurrentUser } from 'services/auth';
import { useNotifications } from 'services/notification';
import { saveNotificationSeenTsToLocalStorage } from 'utils/localStorage';

import { ChevronsRightIcon, BroomIcon } from 'components/common/Icons';
import Scrollbar from 'components/common/Scrollbar';
import SimpleNotification from 'components/common/SimpleNotification';
import MixmodeLogoLoader from 'components/common/Loader/MixModeLogoLoader';

const Container = styled.div`
  min-height: 460px;
  margin-bottom: -8px;
`;

const scrollbarStyle = {
  height: 424,
  width: '100%',
};

const Footer = styled.div`
  display: flex;
  height: 26px;
  margin: 4px;
  justify-content: center;
  font-size: 14px;
  align-items: center;
  cursor: pointer;
  border-top: 1px solid ${({ theme }) => theme.colors.border.default};
`;

const NoNotifications = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.text.subtle};
  svg {
    margin-right: 8px;
  }
`;

const DropdownContent = () => {
  const navigate = useNavigate();
  const { user } = useCurrentUser();
  const queryClient = useQueryClient();

  const notificationsQuery = useNotifications({
    timerange: 'today',
    onSuccess: () => {
      saveNotificationSeenTsToLocalStorage(user?.username, new Date().getTime());

      queryClient.setQueryData(['newNotifications'], (oldData) => {
        return { ...oldData, hasNewNotifications: false };
      });
    },
  });
  const earlierNotifications = useNotifications({
    timerange: `from 7 days ago to today`,
  });

  if (notificationsQuery.isLoading || earlierNotifications.isLoading) {
    return (
      <Container>
        <MixmodeLogoLoader top={100} width={150} />
      </Container>
    );
  }

  return (
    <Container>
      <Scrollbar style={scrollbarStyle}>
        <div className="font-semibold mr-3 ml-3">New</div>
        {notificationsQuery.data?.length > 0 ? (
          notificationsQuery.data.map((notification) => (
            <SimpleNotification key={notification.id} {...notification} inDropdown />
          ))
        ) : (
          <NoNotifications>
            <BroomIcon /> Nothing new today.
          </NoNotifications>
        )}
        {earlierNotifications.data?.length > 0 && (
          <>
            <div className="font-semibold mt-3 mr-3 ml-3">Earlier</div>
            {earlierNotifications.data.map((notification) => (
              <SimpleNotification key={notification.groupId} {...notification} inDropdown />
            ))}
          </>
        )}
      </Scrollbar>
      <Footer onClick={() => navigate('/notifications')}>
        Browse all notifications <ChevronsRightIcon />
      </Footer>
    </Container>
  );
};

export default DropdownContent;

import React from 'react';
import styled from 'styled-components';
import Link from 'components/common/Link';
import Button from 'components/common/Button';

const ButtonElement = styled(Button)`
  padding: 0;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  height: inherit;
  padding: 0 8px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.text.primary};
`;

// TODO: make justify content properly
const ButtonLink = React.forwardRef(
  ({ children, to, left = null, transparent, ['aria-label']: ariaLabel, ...props }, ref) => (
    <ButtonElement {...props} $transparent={transparent} ref={ref}>
      <StyledLink
        to={to || '#'}
        aria-label={ariaLabel}
        style={{
          justifyContent: left ? 'flex-start' : 'center',
        }}
      >
        {children}
      </StyledLink>
    </ButtonElement>
  ),
);

export default ButtonLink;

import styled, { useTheme } from 'styled-components';
import { fade, reverseFade } from 'components/styled/animations';
import * as PropTypes from 'prop-types';

import lightLogoOnlyWhite from 'static/images/light-logo-only-white.svg';
import lightLogoOnlyOrange from 'static/images/light-logo-only-orange.svg';
import darkLogoOnlyGrey from 'static/images/dark-logo-only-grey.svg';
import darkLogoOnlyOrange from 'static/images/dark-logo-only-orange.svg';

const AnimatedLogo = styled.div`
  width: auto;
  position: absolute;
  top: ${({ $top }) => $top || 170}px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({ $width }) => $width || 180}px;

  #white {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(12.5%, -11%);
    animation: ${fade} 1000ms ease-in-out alternate infinite;
    opacity: 0.2;
  }

  #orange {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-12.5%, 11%);
    animation: ${reverseFade} 1000ms ease-in-out alternate infinite;
    opacity: 0.8;
  }
`;

const MixmodeLogoLoader = ({ variant, top, width, ...props }) => {
  const theme = useTheme();

  const logoVariant = variant || theme.name === 'Dark' ? 'Light' : 'Dark';

  return (
    <AnimatedLogo className="transform-gpu" {...props} $top={top} $width={width} data-test-id="mixmode-logo-loader">
      <img id="white" src={logoVariant === 'Light' ? lightLogoOnlyWhite : darkLogoOnlyGrey} />
      <img id="orange" src={logoVariant === 'Light' ? lightLogoOnlyOrange : darkLogoOnlyOrange} />
    </AnimatedLogo>
  );
};

MixmodeLogoLoader.propTypes = {
  variant: PropTypes.oneOf(['Light', 'Dark']),
};

export default MixmodeLogoLoader;

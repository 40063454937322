import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { mountStoreDevtool } from 'simple-zustand-devtools';

import createSearchQuery from './searchQuery';
import createUserSettings from './userSettings';
import createSearchRegistry from './searchRegistry';
import createDashboardDraft from './customDashboardDraft';
import createDashboardConfiguration from './dashboardConfiguration';

// use when custom comparisson fn is required
export const useStoreBase = createWithEqualityFn((set, get) => ({
  searchQuery: { ...createSearchQuery(set, get) },
  userSettings: { ...createUserSettings(set, get) },
  searchRegistry: { ...createSearchRegistry(set, get) },
  dashboardDraft: { ...createDashboardDraft(set, get) },
  dashboardConfiguration: { ...createDashboardConfiguration(set, get) },
}));

// use shallow as default comparisson fn
export const useStore = (selector) => useStoreBase(selector, shallow);

if (import.meta.env.DEV) {
  mountStoreDevtool('Store', useStoreBase);
}

export default useStore;

import React, { useEffect } from 'react';
import _maxBy from 'lodash/maxBy';
import addDays from 'date-fns/addDays';

import { CheckSquareIcon, SquareIcon } from 'components/common/Icons';
import { useCheckSensorStatus, useSensorList } from 'services/sensor';
import log from 'services/log';

import AiPredictionAlert from './AiPredictionAlert';
import {
  SensorToggleCell,
  InfoCell,
  InfoCellWrapper,
  SelectableRow,
  SmallNSubtle,
  SelectOnlyCell,
} from './common.styled';
import InTrainingIndication from './InTrainingIndication';

const SensorItem = ({ sensor, isSelected, hasAlert, onClick, onSelectOnly, disabled }) => {
  const { sensorList } = useSensorList();
  const { sensorStatuses } = useCheckSensorStatus(sensorList);

  const displayLabels = sensor.labels?.filter((l) => l) || [];

  const sensorStatus = sensorStatuses.find((s) => s.sensorId === sensor.id);

  useEffect(() => {
    if (!sensorStatus) {
      log({
        message: 'Sensor status not found for sensor id',
        type: 'warning',
        origin: 'SensorSelector-SensorItem',
        info: {
          sensorId: sensor.id,
        },
      });
    }
  }, [sensor, sensorStatus]);

  const isOperational = sensorStatus?.operationalStatus === true;
  const inTraining =
    sensorStatus?.aiOperatorState.reduce((acc, operator) => {
      if (operator.state === 'learning') {
        acc.push(operator);
      }
      return acc;
    }, []) || [];
  const isInTraining = inTraining.length > 0;
  const informedDate = addDays(_maxBy(inTraining, 'createdAt')?.createdAt, 7);

  const handleClick = (e) => {
    if (!disabled) {
      onClick(e);
    }
  };

  const handleSelectOnly = (e) => {
    e.stopPropagation();
    if (!disabled) {
      onSelectOnly(sensor.id);
    }
  };

  return (
    <SelectableRow
      key={`sensor-${sensor.id}`}
      onClick={handleClick}
      data-test-id={`select-sensor-${sensor.name}`}
      data-test-selected={isSelected}
      $disabled={disabled}
    >
      <SensorToggleCell className="sensor" $selected={isSelected} $disabled={disabled}>
        {isSelected ? <CheckSquareIcon /> : <SquareIcon />}
      </SensorToggleCell>

      <InfoCell $selected={isSelected} $disabled={disabled}>
        <InfoCellWrapper $selected={isSelected} $color={sensor.color} $disabled={disabled}>
          <div className="leading-relaxed font-semibold">{sensor.name}</div>
          {sensor.description && <SmallNSubtle>{sensor.description}</SmallNSubtle>}
        </InfoCellWrapper>
      </InfoCell>
      <SmallNSubtle as="td" className="pt-1 old-leading-xtight font-semibold">
        {displayLabels.length > 0 && <>{displayLabels.join(', ')}</>}
      </SmallNSubtle>
      <td>
        {hasAlert && <AiPredictionAlert />}
        {!hasAlert && !isOperational && <div className="color-alert">No data</div>}
      </td>
      <td>{isInTraining && <InTrainingIndication informedDate={informedDate} />}</td>
      <td className="text-right">{sensor.mode}</td>
      <SelectOnlyCell width="30px" onClick={handleSelectOnly}>
        Only
      </SelectOnlyCell>
    </SelectableRow>
  );
};

export default React.memo(SensorItem);

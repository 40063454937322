import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthState } from 'services/auth';
import { useForm } from 'utils/hooks';
import { useIsMounted } from 'utils/hooks';
import ErrorMessage, { ErrorMessageComponent } from 'components/common/ErrorMessage';
import Button from 'components/common/Button';
import Input from 'components/common/Input';

import AuthWrapper from './AuthWrapper';
import { AuthLink } from './common.styled';

const validate = ({ email, password }) => {
  const errors = {};

  if (!email) {
    errors.email = 'E-mail is a required field';
  }

  if (!password || password.length < 5) {
    errors.password = 'Password must be longer than 5 characters.';
  }

  return errors;
};

const LoginForm = ({ ssoBypass }) => {
  const isMounted = useIsMounted();
  const {
    authenticate,
    state: { authenticationError, isForceResetError },
  } = useContext(AuthState);

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate,
    onSubmit: ({ email, password }, { setIsSubmitting }) => {
      setIsSubmitting(true);

      authenticate({ username: email, password }).then(() => {
        if (isMounted()) {
          setIsSubmitting(false);
        }
      });
    },
  });

  if (authenticationError && isForceResetError) {
    return (
      <Navigate
        to={ssoBypass ? '/user/forgot' : '/forgot'}
        state={{
          isForceResetError,
          authenticationError,
        }}
      />
    );
  }

  return (
    <AuthWrapper>
      <div
        className="flex flex-col items-center mt-6"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            form.handleSubmit();
          }
        }}
      >
        <div className="w-full flex flex-col mt-1">
          <label htmlFor="email" className="mb-1">
            Username
          </label>
          <Input
            autoFocus
            id="email"
            name="email"
            autoComplete="email"
            data-test-id="login-email-input"
            value={form.values.email}
            onChange={form.handleChange}
          />
        </div>
        <ErrorMessage {...form} name="email" className="w-full" />
        <div className="w-full flex flex-col mt-1">
          <label htmlFor="password" className="mb-1">
            Password
          </label>
          <Input
            type="password"
            id="password"
            name="password"
            autoComplete="current-password"
            data-test-id="login-password-input"
            value={form.values.password}
            onChange={form.handleChange}
          />
        </div>
        <ErrorMessage {...form} name="password" className="w-full" />
        <div className="flex justify-between w-full">
          <AuthLink data-test-id="forgot-password-link" className="mt-4" to={ssoBypass ? '/user/forgot' : '/forgot'}>
            Forgot password?
          </AuthLink>
          <Button
            data-test-id="login-submit-button"
            className="mt-4"
            color="primary"
            variant="contained"
            type="submit"
            disabled={form.isSubmitting}
            onClick={form.handleSubmit}
          >
            Sign in
          </Button>
        </div>
      </div>

      {authenticationError && (
        <ErrorMessageComponent data-test-id="login-error-message">{authenticationError}</ErrorMessageComponent>
      )}
    </AuthWrapper>
  );
};

export default LoginForm;

import { required, resolveNopeRefs } from './shared';

const min = (length, message) => (value, context) => {
  const resolvedValue = resolveNopeRefs(value, context);

  return resolvedValue && resolvedValue.length <= length && message;
};

const max = (length, message) => (value, context) => {
  const resolvedValue = resolveNopeRefs(value, context);

  return resolvedValue && resolvedValue.length >= length && message;
};

const email = (message) => (value, context) => {
  const emailRegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const resolvedValue = resolveNopeRefs(value, context);

  return !emailRegExp.test(resolvedValue) && message;
};

const includes = (options, message) => (value, context) => {
  const resolvedOptions = resolveNopeRefs(options, context);
  const resolvedValue = resolveNopeRefs(value, context);

  return !resolvedOptions.includes(resolvedValue) && message;
};

class NopeString {
  validationRules = [];

  required(message = 'This field is required') {
    this.validationRules.push(required(message));

    return this;
  }

  max(length, message = `Input can't be longer than ${length} characters`) {
    this.validationRules.push(max(length, message));

    return this;
  }

  min(length, message = `Input can't be shorter than ${length} characters`) {
    this.validationRules.push(min(length, message));

    return this;
  }

  email(message = 'Input must be a valid email') {
    this.validationRules.push(email(message));

    return this;
  }

  oneOf(options, message = `Input must be one of ${options.join(', ')}`) {
    this.validationRules.push(includes(options, message));

    return this;
  }

  test(fn) {
    this.validationRules.push(fn);

    return this;
  }

  validate(entry, context, objectShape) {
    for (let fn of this.validationRules) {
      const error = fn(entry, context, objectShape);

      if (error) {
        return error;
      }
    }
  }
}

export default NopeString;

import NopeRef from './ref';

export const resolveNopeRefs = (value, context) => {
  if (value instanceof NopeRef) {
    return context[value.key];
  } else if (Array.isArray(value)) {
    return value.map((option) => {
      if (option instanceof NopeRef) {
        return context[option.key];
      }

      return option;
    });
  } else {
    return value;
  }
};

export const required = (message) => (value) => {
  const resolvedValue = resolveNopeRefs(value);

  return !resolvedValue && message;
};

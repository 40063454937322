import React from 'react';
import { useTheme } from 'styled-components';

import { useCheckNewNotifications } from 'services/notification';

import { BellIcon } from 'components/shared/Icons';
import NotificationCount from 'components/common/NotificationCount';
import IconButton from 'components/shared/IconButton';
import Dropdown from 'components/common/Dropdown';

import DropdownContent from './DropdownContent';

const notificationDropdownStyle = {
  padding: '8px 4px',
};

const Notifications = () => {
  const theme = useTheme();

  const { data } = useCheckNewNotifications();

  return (
    <Dropdown
      top={50}
      right={-40}
      arrow="near-right"
      align="right"
      width={420}
      style={notificationDropdownStyle}
      trigger={
        <IconButton
          size="4"
          variant="soft"
          color="gray"
          style={data?.hasNewNotifications ? { color: theme.palette.danger[1] } : null}
          aria-label="Notifications"
          data-test-id="notifications-menu-button"
        >
          <BellIcon width="20" height="20" aria-hidden />
          {data?.count > 0 && <NotificationCount type="number" count={data.count} />}
        </IconButton>
      }
    >
      <DropdownContent />
    </Dropdown>
  );
};

export default React.memo(Notifications);

import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { MenuState } from 'services/menu';

const EXTRA_URLS = {
  api: {
    alias: 'api',
    name: 'API',
    icon: 'TerminalIcon',
    items: [
      {
        alias: 'clients',
        name: 'Clients',
        icon: 'TerminalIcon',
      },
      {
        alias: 'mm-tool',
        name: 'MM CLI Tool',
        icon: 'TerminalIcon',
      },
      {
        alias: 'endpoints',
        name: 'Endpoints',
        icon: 'PlayIcon',
      },
      {
        alias: 'documentation',
        name: 'Documentation',
        icon: 'FileTextIcon',
      },
    ],
  },
  notifications: {
    alias: 'notifications',
    name: 'Notifications',
    icon: 'BellIcon',
    items: [
      {
        alias: 'documentation',
        name: 'Documentation',
        icon: 'FileTextIcon',
        hideAction: true,
      },
    ],
  },
  usage: {
    alias: 'usage',
    name: 'Usage',
    icon: 'CreditCardIcon',
    items: [],
  },
  settings: {
    alias: 'settings',
    name: 'Settings',
    icon: 'SettingsIcon',
    items: [
      {
        name: 'Sensors',
        alias: 'sensors',
        icon: 'ActivityIcon',
      },
      {
        name: 'Profiles',
        alias: 'profiles',
        icon: 'CpuIcon',
        hideAction: true,
      },
      {
        name: 'Scheduled Tasks',
        alias: 'tasks',
        icon: 'TaskIcon',
      },
      {
        name: 'Enrichments',
        alias: 'enrichments',
        icon: 'DatabaseIcon',
      },
      {
        name: 'Indicator Severity',
        alias: 'indicator-severity',
        icon: 'AiIcon',
      },
      {
        name: 'Integrations',
        alias: 'integrations',
        icon: 'IntegrationIcon',
      },
      {
        name: 'Tenants',
        alias: 'tenants',
        icon: 'NetworkIcon',
      },
      {
        name: 'Users',
        alias: 'users',
        icon: 'UsersIcon',
      },
    ],
  },
  'user-settings': {
    alias: 'user-settings',
    name: 'User Settings',
    icon: 'UserSettingsIcon',
    items: [],
    description: 'User-specific settings to customize your experience within MixMode.',
  },
};

const usePageName = () => {
  const { category, page, action } = useParams();
  const { state } = useContext(MenuState);

  const allDefinitions = { ...state.menuConfig, ...EXTRA_URLS };

  function getAliasToNameMap() {
    let res = {};
    Object.keys(allDefinitions).forEach((key) => {
      const item = allDefinitions[key];
      res[item.alias] = item.name;

      if (item.items && item.items.length) {
        item.items.forEach((subItem) => {
          res[subItem.alias] = subItem.name;
        });
      }
    });
    return res;
  }

  function getCurrentPageDescription() {
    let currentSection = allDefinitions[category];

    if (page) {
      currentSection = currentSection?.items?.find?.((x) => x.alias === page);
    }

    return currentSection?.description ?? undefined;
  }

  function getNameFromAlias(alias) {
    return getAliasToNameMap()[alias];
  }

  function getMenuObject(category, page) {
    return allDefinitions[category]?.items.find((x) => x.alias === page);
  }

  return {
    category,
    categoryName: category ? getNameFromAlias(category) : '',
    page,
    pageName: page && getNameFromAlias(page),
    action,
    actionName: getMenuObject(category, page)?.hideAction ? null : action,
    description: getCurrentPageDescription(),
  };
};

export default usePageName;

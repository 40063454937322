import usePageName from 'services/menu/usePageName';
import { useSensorList } from 'services/sensor';
import { useCurrentUser } from 'services/auth';

import PHeader from 'components/shared/PageHeader';

import SensorPicker from './SensorPicker';
import Bookmarks from './Bookmarks';
import Notifications from './Notifications';
import UserMenu from './UserMenu';

const PageHeader = () => {
  const { category, categoryName, pageName, description } = usePageName() as unknown as {
    category: string;
    categoryName: string;
    pageName: string;
    description: string;
  };
  const { sensorList } = useSensorList() as unknown as { sensorList: any[] };
  const { user } = useCurrentUser() as unknown as { user: { id: number } };
  const title = pageName || categoryName;

  return (
    <PHeader title={title} description={description}>
      {!['settings', 'api', 'usage'].includes(category) && sensorList && (
        <>
          <SensorPicker />
          <Bookmarks />
        </>
      )}
      {user && user.id > -1 && <Notifications />}
      <UserMenu />
    </PHeader>
  );
};

export default PageHeader;

import { SCENE } from 'consts/scenes';

const filterActiveByAllowedProfiles = (sensorList, activeSensors, pathname) => {
  if (!sensorList) {
    return undefined;
  }

  const sceneKey = Object.keys(SCENE).find((key) => pathname.includes(SCENE[key].path));
  if (!SCENE[sceneKey]) {
    return activeSensors;
  }

  const allowedProfileTypes = SCENE[sceneKey].profileTypes;
  return sensorList
    .filter((s) => allowedProfileTypes.includes(s?.Profile?.profileType) && activeSensors.includes(s.id))
    .map((s) => s.id);
};

const createSearchQuery = (set, get) => ({
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  activeSensors: [],
  activeSensorsByProfile: [],
  lastSearch: {}, // in-memory persistence of a last search query per dashboard
  setActiveSensors: (activeSensors) => {
    set((prev) => ({
      searchQuery: {
        ...prev.searchQuery,
        activeSensors: activeSensors.sort((a, b) => a - b),
      },
    }));
  },
  setActiveSensorsByProfile: (sensorList, pathname) => {
    const activeSensors = get().searchQuery.activeSensors;

    set((prev) => ({
      searchQuery: {
        ...prev.searchQuery,
        activeSensorsByProfile: filterActiveByAllowedProfiles(sensorList, activeSensors, pathname),
      },
    }));
  },
  setLastSearch: (page, query) => {
    set((prev) => ({
      searchQuery: {
        ...prev.searchQuery,
        lastSearch: {
          ...prev.searchQuery.lastSearch,
          [page]: query,
        },
      },
    }));
  },
});

export default createSearchQuery;

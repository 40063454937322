import React from 'react';
import styled from 'styled-components';
import mixmodeLogoText from 'static/images/logo-text-w.png';
import logo from 'static/images/logo-w.png';

const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: 41px;
  margin-top: 8px;
  margin-bottom: -4px;
  cursor: pointer;
`;

const SmallLogo = styled.img`
  width: auto;
  min-height: 31px;
  height: 31px;
`;

const MixmodeLogo = styled.img`
  height: 25px;
  margin-left: 7px;
  transform: translate3d(0, 0, 0);
  visibility: ${(props) => (props.$expanded ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.$expanded ? 1 : 0)};
  transition: opacity 200ms ease-in-out;
`;

const Logo = ({ expanded, onClick }) => (
  <Container onClick={onClick}>
    <SmallLogo alt="MixMode small logo" src={logo} />
    <MixmodeLogo alt="MixMode" src={mixmodeLogoText} $expanded={expanded} />
  </Container>
);

export default React.memo(Logo);

import styled, { css } from 'styled-components';

import { pulseAnimation } from 'components/styled/animations';
import Button from 'components/common/Button';

const getStatusColor = ({ theme, hasAlert, $hasOfflineSensors, $isInTraining }) => {
  if (hasAlert) {
    return theme.palette.alert[0];
  } else if ($hasOfflineSensors) {
    return theme.palette.alert[0];
  } else if ($isInTraining) {
    return null;
  }

  return '#00bb00';
};

const getButtonStyles = ({ theme, $alert, $hasOfflineSensors, $isInTraining }) => {
  if ($alert) {
    return css`
      border-color: ${theme.palette.primary[3]};
      background: #ffcc8329;

      &:hover {
        background: #ffd7b233;
      }

      svg {
        font-size: 24px;
        color: ${theme.palette.primary[2]};
      }
    `;
  } else if ($hasOfflineSensors) {
    return css`
      border-color: ${theme.palette.alert[0]};
      background: #ffecb322;

      &:hover {
        background: #f4e4b333;
      }

      svg {
        font-size: 24px;
        color: ${theme.palette.alert[0]};
      }
    `;
  } else if ($isInTraining) {
    return css`
      border-color: ${theme.colors.border.default};

      svg {
        font-size: 24px;
        color: #4aa4eb;
      }
    `;
  }

  return css`
    border-color: ${theme.palette.success[2]};
    background: #91f69611;

    &:hover {
      background: #91f69622;
    }

    svg {
      font-size: 16px;
      color: ${theme.palette.success[2]};
    }
  `;
};

const getPulsingAnimation = ({ theme, $alert, $isInTraining, $hasOfflineSensors }) => {
  const statusColor = getStatusColor({ theme, hasAlert: $alert, $isInTraining, $hasOfflineSensors });

  if (!statusColor) {
    return null;
  }

  const isFastPulse = Boolean($alert || $hasOfflineSensors);

  return css`
    box-shadow: 0 0 0 0 ${`${statusColor}00`};

    animation: pulsingSensor ${isFastPulse ? 3 : 8}s infinite;

    @keyframes pulsingSensor {
      0% {
        box-shadow: 0 0 0 0 ${statusColor}b3;
      }

      ${() => {
        if (isFastPulse) {
          return css`
            40% {
              box-shadow: 0 0 0 0 ${statusColor}b3;
            }

            80% {
              box-shadow: 0 0 0 12px ${statusColor}00;
            }
          `;
        }

        return css`
          65% {
            box-shadow: 0 0 0 0 ${statusColor}b3;
          }

          80% {
            box-shadow: 0 0 0 12px ${statusColor}00;
          }
        `;
      }}

      100% {
        box-shadow: 0 0 0 0 ${statusColor}00;
      }
    }
  `;
};

export const SensorButton = styled(Button)`
  position: relative;
  border-radius: 4px;
  font-weight: 300;
  height: 3em;
  padding: 0px 14px;
  color: ${({ theme }) => theme.colors.text.primary};

  ${getButtonStyles}

  ${getPulsingAnimation}
`;

export const PredictionWarning = styled.div`
  animation: ${({ $alert }) => ($alert ? pulseAnimation : 'none')};
  width: ${({ $$isInTraining }) => ($$isInTraining ? 108 : 130)}px;
  white-space: pre-wrap;
  font-size: 13px;
  font-weight: 600;
  margin-right: 8px;
`;

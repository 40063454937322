import styled from 'styled-components';

const LetterPressStyled = styled.div`
  text-align: center;
  font-size: ${({ $size }) => $size || 100}px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.letterPress.text};
  text-shadow: 2px 2px ${({ theme }) => theme.colors.letterPress.shadow};
`;

const LetterPress = ({ children, size, ...props }) => (
  <LetterPressStyled {...props} $size={size}>
    {children}
  </LetterPressStyled>
);

export default LetterPress;

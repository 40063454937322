import clsx from 'clsx';
import DocumentTitle from 'components/common/DocumentTitle';

const PageContainer = ({
  title,
  children,
  className,
}: {
  title: string;
  children: React.ReactNode;
  className?: string;
}) => (
  <div className={clsx('w-full color-text-primary pb-6', className)}>
    <DocumentTitle title={title} />
    {children}
  </div>
);

export default PageContainer;

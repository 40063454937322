import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

import useSensorList from 'services/sensor/SensorsProvider/useSensorList';
import { useCheckSensorStatus } from 'services/sensor';
import { SCENE } from 'consts/scenes';

import { NetworkIcon } from 'components/common/Icons';

import SensorItem from './SensorItem';
import AiPredictionAlert from './AiPredictionAlert';
import SensorCheckAlert from './SensorCheckAlert';

import {
  HeaderWrapper,
  Container,
  SearchInput,
  SensorPickerTable,
  SensorToggleCell,
  InfoCell,
  SelectOnlyCell,
  SelectAll,
  SensorLegend,
  DisabledMessageWrapper,
  SmallNSubtle,
} from './common.styled';

const DisabledTitle = () => {
  const location = useLocation();

  const isAIOverview = location.pathname.includes(SCENE.aiOverview.path);
  const isFlowLogs = location.pathname.includes(SCENE.flowlogs.path);
  const isCloud = location.pathname.includes(SCENE.cloudapi.path);
  const isIDM = location.pathname.includes(SCENE.identityManagement.path);

  let message = '';
  if (isAIOverview) {
    message = 'Cloud sensors are not visible in this dashboard.';
  } else if (isFlowLogs) {
    message = 'Sensor selection has been reduced to Flow Log sensors.';
  } else if (isCloud) {
    message = 'Sensor selection has been reduced to Cloud API sensors.';
  } else if (isIDM) {
    message = 'Sensor selection has been reduced to Identity Management sensors.';
  } else {
    message = 'The sensors that are not applicable on this dashboard have been disabled.';
  }

  return (
    <DisabledMessageWrapper>
      <h4>Inapplicable sensors</h4>
      <p>{message}</p>
    </DisabledMessageWrapper>
  );
};

const SensorTable = ({
  tenants,
  selectedIds,
  alerts,
  disabledSensors,
  disabled,
  toggle,
  handleSelectOnlyTenant,
  handleSelectOnlySensor,
}) => {
  return tenants.map((tenant) => {
    const tenantSensorIds = tenant.sensors.map((s) => s.id);
    const tenantSelected = selectedIds.find((sid) => tenantSensorIds.indexOf(sid) !== -1);
    const displayLabels = tenant.labels.filter((l) => l);
    const sortedSensors = tenant.sensors.sort((a, b) => {
      return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
    });
    return (
      <Container key={`tenant-${tenant.id}`}>
        <SensorPickerTable $disabled={disabled}>
          <thead>
            <tr
              onClick={() => toggle('tenant', tenant.id)}
              data-test-id={`select-tenant-${tenant.name}`}
              data-test-selected={!!tenantSelected}
            >
              <SensorToggleCell $selected={tenantSelected && !disabled}>
                <NetworkIcon />
              </SensorToggleCell>
              <InfoCell $selected={tenantSelected}>
                <div className="ml-4 font-semibold">{tenant.name}</div>
              </InfoCell>
              <SmallNSubtle width="150px" as="td" className="pt-1 old-leading-xtight font-semibold">
                {displayLabels.length > 0 && <>{displayLabels.join(', ')}</>}
              </SmallNSubtle>
              <td width="100px" />
              <td width="150px" />
              <td width="70px" />
              <SelectOnlyCell
                className="font-semibold"
                width="40px"
                onClick={(e) => handleSelectOnlyTenant(e, tenant.id)}
              >
                Only
              </SelectOnlyCell>
            </tr>
          </thead>
          <tbody>
            {sortedSensors.map((sensor) => (
              <SensorItem
                key={`sensor-${sensor.id}`}
                sensor={sensor}
                isSelected={selectedIds.indexOf(sensor.id) !== -1}
                hasAlert={alerts && !!alerts[sensor.id]}
                onClick={() => toggle('sensor', sensor.id)}
                onSelectOnly={handleSelectOnlySensor}
                disabled={disabled || disabledSensors.includes(sensor.id)}
              />
            ))}
          </tbody>
        </SensorPickerTable>
      </Container>
    );
  });
};

const SensorSelector = ({ title, description, value = [], alerts = null, onChange, disabledSensors }) => {
  const { tenantList, sensorIds, sensorList } = useSensorList();
  // internal selection state
  const [selectedIds, setSelectedIds] = useState(value);
  const [searchQuery, setSearchQuery] = useState('');
  const { offlineSensorCount, areAnySensorsOffline } = useCheckSensorStatus(sensorList);

  const filteredTenants = useMemo(() => {
    if (!searchQuery || !tenantList) {
      return tenantList;
    }

    const tenants = [];

    const compareProperty = (prop = '') => prop.toLowerCase().includes(searchQuery.toLowerCase());

    for (const tenant of tenantList) {
      if (compareProperty(tenant.name) || compareProperty(tenant.labels.join(', '))) {
        tenants.push(tenant);
        continue;
      }

      // filter by searchQuery in sensors
      const filteredSensors = tenant.sensors.filter((sensor) => {
        const nameMatches = compareProperty(sensor.name);
        const descriptionMatches = compareProperty(sensor.description);
        const labelsMatches = compareProperty(sensor.labels.join(', '));
        const profileMatches = compareProperty(sensor?.Profile?.name);

        return nameMatches || descriptionMatches || labelsMatches || profileMatches;
      });

      if (filteredSensors.length) {
        tenants.push({
          ...tenant,
          sensors: filteredSensors,
        });
      }
    }

    return tenants;
  }, [searchQuery, tenantList]);

  const { tenantsWithEnabledSensors, tenantsWithDisabledSensors } = React.useMemo(() => {
    const sorted = filteredTenants.sort((a, b) => {
      return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
    });

    return {
      tenantsWithEnabledSensors: sorted
        .map((t) => ({
          ...t,
          sensors: t.sensors.filter((s) => !disabledSensors.includes(s.id)),
        }))
        .filter((t) => t.sensors.length > 0),
      tenantsWithDisabledSensors: sorted
        .map((t) => ({
          ...t,
          sensors: t.sensors.filter((s) => disabledSensors.includes(s.id)),
        }))
        .filter((t) => t.sensors.length > 0),
    };
  }, [filteredTenants, disabledSensors]);

  useEffect(() => {
    const selectableSensorIds = sensorIds.filter((s) => !disabledSensors.includes(s));
    const filteredSelectedIds = selectedIds.filter((s) => !disabledSensors.includes(s));

    if (filteredSelectedIds.length === 0 && selectableSensorIds.length !== 0) {
      setSelectedIds((s) => s.concat(selectableSensorIds));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorIds]);

  useEffect(() => {
    onChange && onChange(selectedIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  // generic toggle handler; deals with both sensors and tenants
  // type = ["tenant" | "sensor"]
  // id = tenant or sensor id
  const toggle = (type, id) => {
    if (type === 'tenant') {
      // tenant id toggle
      const tenantSensorIds = filteredTenants.find((tenant) => tenant.id === id).sensors.map((s) => s.id);
      if (selectedIds.some((sId) => tenantSensorIds.find((x) => x === sId))) {
        // some are selected
        const res = selectedIds.filter((sid) => !tenantSensorIds.find((x) => x === sid));
        setSelectedIds(res);
      } else {
        // none are selected
        setSelectedIds(selectedIds.concat(tenantSensorIds));
      }
    } else {
      // type === 'sensor'
      if (selectedIds.indexOf(id) !== -1) {
        setSelectedIds(selectedIds.filter((sId) => sId !== id));
      } else {
        setSelectedIds(selectedIds.concat([id]));
      }
    }
  };

  const handleSelectOnlyTenant = (event, tenantId) => {
    event.stopPropagation();
    const res = filteredTenants.find((t) => t.id === tenantId).sensors.map((s) => s.id);

    setSelectedIds(res);
  };

  const handleSelectOnlySensor = (sensorId) => {
    setSelectedIds([sensorId]);
  };

  const handleSelectAll = () => {
    // select all sensors after filtering
    const filteredSensors = tenantsWithEnabledSensors.reduce((acc, tenant) => {
      return acc.concat(tenant.sensors);
    }, []);
    setSelectedIds(filteredSensors.map((s) => s.id));
  };

  const handleInput = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <HeaderWrapper>
        <div>{title}</div>
        <SearchInput size="small" placeholder="Search..." onChange={handleInput} />
      </HeaderWrapper>
      {description && <div className="old-text-sm">{description}</div>}
      <div className="flex justify-between">
        <div>
          {!!alerts && (
            <SensorLegend>
              <AiPredictionAlert /> - AI sees a probable increase in Level 10 Alerts in the next hour for the sensors
              below.
            </SensorLegend>
          )}
          {areAnySensorsOffline && (
            <SensorLegend>
              <SensorCheckAlert offlineSensorCount={offlineSensorCount} />
            </SensorLegend>
          )}
        </div>
        <SelectAll onClick={handleSelectAll}>Select All</SelectAll>
      </div>
      <div className="mt-2">
        {tenantsWithEnabledSensors.length === 0 && tenantsWithDisabledSensors.length === 0 ? (
          <div>No results found for given query</div>
        ) : (
          <>
            <SensorTable
              tenants={tenantsWithEnabledSensors}
              alerts={alerts}
              disabledSensors={disabledSensors}
              handleSelectOnlyTenant={handleSelectOnlyTenant}
              handleSelectOnlySensor={handleSelectOnlySensor}
              selectedIds={selectedIds}
              toggle={toggle}
            />
            {tenantsWithDisabledSensors.length > 0 && (
              <>
                <DisabledTitle />
                <SensorTable
                  disabled
                  alerts={alerts}
                  tenants={tenantsWithDisabledSensors}
                  selectedIds={selectedIds}
                  disabledSensors={disabledSensors}
                  toggle={toggle}
                  handleSelectOnlyTenant={handleSelectOnlyTenant}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(SensorSelector);

import { useState, useEffect } from 'react';

const DelayRender = ({ time, children }) => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setRender(true);
    }, time);

    return () => {
      clearTimeout(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return render ? children : null;
};

export default DelayRender;

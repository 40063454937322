import styled from 'styled-components';

import MixModeLogoLoader from './MixModeLogoLoader';

const Container = styled.div`
  z-index: 9000;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
`;

const SidebarLoader = () => {
  return (
    <Container>
      <MixModeLogoLoader />
    </Container>
  );
};

export default SidebarLoader;

import { required } from './shared';

class NopeObject {
  validationRules = [];

  shape(shape) {
    this.objectShape = shape;

    return this;
  }

  required(message = 'This field is required') {
    this.validationRules.push(required(message));

    return this;
  }

  validate(entry) {
    if (this.validationRules.length > 0) {
      for (let fn of this.validationRules) {
        const error = fn(entry);

        if (error) {
          return error;
        }
      }
    }

    if (!this.objectShape) {
      return;
    }

    const errors = {};

    for (let [key, value] of Object.entries(entry)) {
      const validator = this.objectShape[key];

      const res = validator?.validate(value, entry, this.objectShape);

      if (res) {
        errors[key] = res;
      }
    }

    return errors;
  }
}

export default NopeObject;

import tinycolor from 'tinycolor2';
import type { DefaultTheme } from 'styled-components';

export function getHexByScore(score: number, theme: DefaultTheme): string {
  if (score > 9) {
    return theme.palette.danger[1];
  } else if (score > 7) {
    return theme.palette.danger[2];
  } else if (score > 5) {
    return theme.palette.alert[0];
  }
  return theme.colors.text.subtle;
}

export function getFileIconTemplateColors(color: string = '#f1961d') {
  return {
    color: color,
    foldColor: tinycolor(color).darken(20).toString(),
    glyphColor: 'rgba(255,255,255,0.4)',
    labelColor: tinycolor(color).darken(10).toString(),
  };
}

export function adjustToReadable(color: string, backgroundColor: string): string {
  if (tinycolor.readability(color, backgroundColor) > 3) {
    return color;
  }
  if (tinycolor(color).toString() === '#000000' || tinycolor(color).toString() === '#ffffff') {
    // we reached the end of the road
    return color;
  }
  const adjustedColor =
    tinycolor(backgroundColor).getLuminance() > 0.4
      ? tinycolor(color).darken(5).toString()
      : tinycolor(color).lighten(5).toString();
  return adjustToReadable(adjustedColor, backgroundColor);
}

const MAX_VARIATIONS = 40;

export const getColorFromSet = (index: number, colors: string[], maxVariations: number = MAX_VARIATIONS): string => {
  const colorIdx = index % colors.length;
  const variancePass = Math.floor(index / maxVariations);
  const passNumber = Math.floor((index - variancePass * maxVariations) / colors.length);

  const colorModifierIntensity = passNumber * 0.15;

  let color = colors[colorIdx];

  if (colorModifierIntensity !== 0) {
    if (passNumber % 2) {
      color = tinycolor(color)
        .darken(colorModifierIntensity * 100)
        .toString();
      if (tinycolor.readability(color, '#19202c') < 1.4) {
        color = tinycolor.mix(color, tinycolor(getColorFromSet(index - 4, colors))).toString();
      }
    } else {
      color = tinycolor(color)
        .lighten(colorModifierIntensity * 100)
        .toString();
      if (tinycolor.readability(color, '#fff') < 1.4) {
        color = tinycolor.mix(color, tinycolor(getColorFromSet(index - 4, colors))).toString();
      }
    }
  }

  return color;
};

export const stringToColorFromSet = (str: string, colors: string[], maxVariations = MAX_VARIATIONS): string => {
  const strAsNumber = str.split('').reduce((xs, x) => xs + Math.floor(x.charCodeAt(0) % 7), 0);
  return getColorFromSet(strAsNumber, colors, maxVariations);
};

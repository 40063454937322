import _isPlainObject from 'lodash/isPlainObject';
import _forEach from 'lodash/forEach';
import _findKey from 'lodash/findKey';
import _has from 'lodash/has';

import { prepareTimerangeQueryWithSeconds } from 'utils/pql';
import { type ValueOf } from 'types/helpers';
import { PAGES } from 'consts/pages';
import { type PROFILE_TYPE } from 'consts/sensors';
import { TEMPLATE_CONFIGURATION } from 'consts/logtype';
import { PROP_NAMES, RECORD_STATUS_MAP } from 'consts/logProperties';
import type { AnyRecord, EventRecord, IndicatorRecord, LogRecord } from 'types/records';

export function getPropName(key: string): string {
  return PROP_NAMES[key] || key;
}

export function getRecordStatus(status: keyof typeof RECORD_STATUS_MAP | null | undefined): string {
  if (!status) {
    // null means open
    return 'Open';
  }

  return RECORD_STATUS_MAP[status] || status;
}

export function getRecordStatusKeys() {
  return Object.keys(RECORD_STATUS_MAP);
}

export function flattenNestedPaths(record: object) {
  const res = {};

  function flatten(obj: object | null | undefined, path = '') {
    _forEach(obj, (value: unknown, key) => {
      if (_isPlainObject(value)) {
        flatten(value as object, `${path}${key}.`);
      } else {
        Object.assign(res, { [`${path}${key}`]: value });
      }
    });
  }

  flatten(record);

  return res;
}

const CODE_TYPE_MAP = {
  [PAGES.events]: 'EV',
  [PAGES.indicators]: 'IND',
  // investigator: 'LOG', // use as fallback
};

export function getTypeFromCode(code: string) {
  return _findKey(CODE_TYPE_MAP, (item) => item === code) || PAGES.investigator;
}

export function getTypeAndId(recordName: string) {
  if (recordName.includes('-')) {
    const [code, id] = recordName.split('-');
    const recordType = getTypeFromCode(code);
    // LOG can have id = "stats-IdNumberString"
    const recordId = recordType === PAGES.investigator ? recordName : id;
    return [recordType, recordId];
  }
  // else its LOG and full `r` parameter is _id
  return [PAGES.investigator, recordName];
}

const checkForType = <T>(record: unknown, prop: string): record is T => {
  return _has(record, prop);
};

export function getRecordTitle(record: AnyRecord): string | undefined {
  if (checkForType<EventRecord>(record, 'eventTitle')) {
    return record.eventTitle;
  }

  if (checkForType<IndicatorRecord>(record, 'indicatorTitle')) {
    return record.indicatorTitle;
  }

  if (checkForType<LogRecord>(record, '_id')) {
    return record._id;
  }

  return undefined;
}

export function getRecordTypePath(record: AnyRecord): string | undefined {
  if (_has(record, 'eventTitle')) {
    return 'events';
  }
  if (_has(record, 'indicatorTitle')) {
    return 'indicators';
  }
  if (_has(record, '_id')) {
    return 'logs';
  }
  return undefined;
}

// helper method for glueing platform indicator psdq
const getPlatformPsdq = (record: IndicatorRecord) => {
  const timerangePql = prepareTimerangeQueryWithSeconds(getRecordTs(record), getRecordTs(record) + 5 * 60 * 1000);

  let directionPql = '';
  // lets construct this until API does it properly
  switch (record.key) {
    case 'inbound':
      directionPql = 'src_geo != "lo" and dest_geo = "lo"';
      break;
    case 'outbound':
      directionPql = 'src_geo = "lo" and dest_geo != "lo"';
      break;
    case 'local':
      directionPql = 'src_geo = "lo" and dest_geo = "lo"';
      break;
    default:
      directionPql = '';
  }

  const pqlContext = `sensorId = ${record.sensorId} tenantId = ${record.tenantId}`;
  return `${timerangePql} ${directionPql} ${pqlContext}`;
};

const isPqsdDefined = (str: string) => str && str !== 'uid = ""' && str.length > 0;

export function getIndicatorPsdq(record: IndicatorRecord) {
  let psdq = record.ps_defining_query;

  if (record.log === 'platform' && !isPqsdDefined(record.ps_defining_query)) {
    // not defined on API side, glue our own psdq
    psdq = getPlatformPsdq(record);
  }

  return psdq;
}

export function getRecordTs(record: AnyRecord) {
  const isLogRecord = (record: AnyRecord): record is LogRecord => 'ts_sensor_millis' in record;
  if (isLogRecord(record)) {
    return record.ts_sensor_millis;
  }

  return record.ts * 1000;
}

export function getTemplateForIndicator(
  record: IndicatorRecord | null | undefined,
  profileType: ValueOf<typeof PROFILE_TYPE> | null | undefined,
) {
  if (!record || !profileType) {
    return null;
  }

  for (const template of Object.keys(TEMPLATE_CONFIGURATION)) {
    for (const configuration of TEMPLATE_CONFIGURATION[template]) {
      if (!configuration.profileTypes.includes(profileType) && configuration.profileTypes.length > 0) {
        continue;
      }

      if (configuration.logs && configuration.logs.includes(record.log)) {
        return template;
      }
    }
  }

  return null;
}

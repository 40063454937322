import { type Page } from 'consts/pages';

export const HISTORY_LIMIT = 20;

export const getPageQueryHistory = (page: Page): string[] => {
  const item = localStorage.getItem(`${page}-history`);

  if (item) {
    return (JSON.parse(item) || []) as string[];
  }

  return [];
};

export const savePageQueryHistory = (page: Page, history: string[]) => {
  localStorage.setItem(`${page}-history`, JSON.stringify(history));
};

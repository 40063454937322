import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: max-content;
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 8px;
  background-color: #9e9e9e;
  border-radius: 8px;
`;

const Slider = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 200ms ease-in-out;
  border-radius: inherit;

  &:before {
    content: '';
    position: absolute;
    bottom: -4px;
    width: 16px;
    height: 16px;
    box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    background-color: white;
    transition: all 200ms ease-in-out;
  }
`;

const FakeInput = styled.input`
  display: none;

  &:checked + ${Switch} ${Slider} {
    background-color: ${({ theme }) => theme.palette.success[3]};

    &:before {
      transform: translateX(18px);
    }
  }

  & + ${Switch} ${Slider} {
    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        background-color: #ccc !important;
      `}
  }
`;

export { Container, Switch, Slider, FakeInput };

import styled from 'styled-components';

import Box from 'components/styled/Box';

const TableStyled = styled(Box).attrs(() => ({
  as: 'table',
}))<{ $lineHeight?: string; $width?: string }>`
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: ${({ $lineHeight }) => $lineHeight || '200%'};
  width: ${({ $width }) => $width || 'auto'};
  tbody,
  td,
  th,
  tfoot,
  thead,
  tr {
    border: inherit;
  }
  caption {
    text-transform: uppercase;
    font-size: 0.9em;
    color: #999;
  }
  td,
  th {
    padding: 0 8px;
    vertical-align: middle;
  }
  th {
    font-family: ${({ theme }) => theme.base.fontHeader};
    font-weight: 600;
    text-align: left;
    font-weight: bold;
    border-bottom: 2px solid ${({ theme }) => theme.colors.default};
  }
  tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
  }
`;

type TableProps = {
  children: React.ReactNode;
  lineHeight?: string;
  width?: string;
};

const Table = ({ children, lineHeight, width, ...props }: TableProps) => {
  return (
    <TableStyled {...props} $lineHeight={lineHeight} $width={width}>
      {children}
    </TableStyled>
  );
};

export default Table;

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 50%;
  margin: 12px;
`;

const BoldLabel = styled.div`
  font-weight: 600;
  min-width: 110px;
`;

const Item = styled.div`
  display: flex;
  margin-bottom: 6px;
  span {
    padding: 0px 4px;
  }
`;

const Help = () => {
  return (
    <Container className="flex flex-col old-text-sm">
      <Item>
        <BoldLabel>Everything</BoldLabel>
        <span> - </span>
        <div>applies to all current and will automatically apply to all future tenants and sensors.</div>
      </Item>
      <Item>
        <BoldLabel>Selecting tenant</BoldLabel>
        <span> - </span>
        <div>applies to all current and will automatically apply to all future sensors under the selected tenant.</div>
      </Item>
      <Item>
        <BoldLabel>Selecting sensor</BoldLabel>
        <span> - </span>
        <div>applies only to selected sensor(s).</div>
      </Item>
    </Container>
  );
};

export default React.memo(Help);

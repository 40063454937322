import styled, { css } from 'styled-components';

const TabLinkStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 24px;
  transition:
    all 150ms ease-in-out,
    font-weight 50ms ease-in-out;
  cursor: pointer;
  color: ${({ theme, $disabled }) => ($disabled ? theme.colors.text.subtle : theme.colors.text.primary)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.default};

  svg {
    margin-right: 8px;
  }

  &:hover {
    box-shadow: ${({ $disabled }) => ($disabled ? 'none' : `inset 0 0 999em rgba(241, 150, 29, 0.07)`)};
  }

  ${({ $active }) =>
    $active &&
    css`
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
      border-bottom: 2px solid;
      border-color: ${({ theme }) => theme.colors.primary};
      //box-shadow: inset 0 0 999em rgba(241, 150, 29, 0.15);

      &:hover {
        box-shadow: inset 0 0 999em rgba(241, 150, 29, 0.15);
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const TabLink = ({ children, active, disabled, ...props }) => (
  <TabLinkStyled {...props} $active={active} $disabled={disabled}>
    {children}
  </TabLinkStyled>
);

import React from 'react';

import { CloseIcon } from 'components/common/Icons';
import DelayRender from 'components/common/DelayRender';
import Backdrop from 'components/common/Backdrop';

import { useLockBodyScroll } from 'utils/hooks';

import NavButton from './NavButton';
import { Container, NavActionsWrapper } from './index.styled';

const Sidebar = React.forwardRef(({ visible = false, additionalActions, onClose, className, children }, ref) => {
  useLockBodyScroll(visible);

  const top = window.scrollY;

  return (
    <>
      <Backdrop data-test-id="sidebar-backdrop" top={top} visible={visible} onClick={onClose} />
      <Container $top={top} $visible={visible} className={className} ref={ref} data-test-id="sidebar-container">
        <NavActionsWrapper>
          {additionalActions && <>{additionalActions}</>}
          {visible && (
            <NavButton onClick={onClose} data-test-id="sidebar-close-button" aria-label="Close">
              <CloseIcon aria-hidden />
            </NavButton>
          )}
        </NavActionsWrapper>

        {visible && <DelayRender time={200}>{children}</DelayRender>}
      </Container>
    </>
  );
});

export default Sidebar;

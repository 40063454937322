import { PAGES } from 'consts/pages';
import { PRESET_OPTIONS } from 'consts/dashboard';
import { INDICATORS_CONFIG } from 'consts/logtype';

export const DASHBOARDS = [
  PAGES.events,
  PAGES.indicators,
  PAGES.investigator,
  PAGES.cloudapi,
  PAGES.identityManagement,
];

// these are exceptions because INDICATORS_CONFIG has `indicator.key` as keys and not `indicator.log`
// since we don't have ET and platform defined with `log` in the config, we need to add them manually here
const ADDITIONAL_INDICATORS = ['entity-track', 'platform'];
const toKeys = (agg, logType) => ({ ...agg, [logType]: [] });

const indicatorColumnsConfig = Object.keys(INDICATORS_CONFIG).concat(ADDITIONAL_INDICATORS).reduce(toKeys, {});

export const CONFIG_VERSION = 2;

export const INITIAL_CONFIG = {
  version: CONFIG_VERSION,
  bookmarks: [],
  pql: [],
  columnsConfig: {
    log: indicatorColumnsConfig,
    ...DASHBOARDS.reduce((acc, x) => ({ ...acc, [x]: [] }), {}),
  },
  presets: Object.keys(PRESET_OPTIONS).reduce(
    (acc, x) => ({
      ...acc,
      [x]: PRESET_OPTIONS[x].reduce((acc, preset) => ({ ...acc, [preset.value]: preset.defaultWidgets }), {}),
    }),
    {},
  ),
  customDashboards: [],
};

/** INDICATOR LOG TYPES **/

import { PROFILE_TYPE, AZURE_AD_PROFILE_TYPES } from './sensors';

export const INDICATORS_CONFIG = {
  'ai-external-external-bytes': {
    log: 'ai-external-external-bytes',
    key: 'ai-external-external-bytes',
    direction: null,
    entityWatchValue: 'Abnormal external to external traffic',
    alertTypeId: null,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'initial-access': {
    log: 'initial-access',
    key: 'initial-access',
    direction: null,
    entityWatchValue: 'Potential initial access',
    alertTypeId: 0,
    profileTypes: [PROFILE_TYPE.cloudtrail, PROFILE_TYPE.awsCloudtrail],
  },
  'user-management': {
    log: 'user-management',
    key: 'user-management',
    direction: null,
    entityWatchValue: 'Abnormal User Management Event Activity',
    alertTypeId: 1,
    profileTypes: [PROFILE_TYPE.cloudtrail, PROFILE_TYPE.awsCloudtrail],
  },
  'aggregate-user-activity': {
    log: 'aggregate-user-activity',
    key: 'aggregate-user-activity',
    direction: null,
    entityWatchValue: 'Abnormal entity user activity',
    alertTypeId: 2,
    profileTypes: [PROFILE_TYPE.cloudtrail, PROFILE_TYPE.awsCloudtrail],
  },
  inbound: {
    log: 'platform',
    key: 'inbound',
    direction: 'inbound',
    entityWatchValue: 'Potential initial access',
    alertTypeId: 3,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  local: {
    log: 'platform',
    key: 'local',
    direction: 'local',
    entityWatchValue: 'Potential lateral movement',
    alertTypeId: 4,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  outbound: {
    log: 'platform',
    key: 'outbound',
    direction: 'outbound',
    entityWatchValue: 'Potential exfiltration',
    alertTypeId: 5,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'fl-rejected': {
    log: 'fl-rejected',
    key: 'fl-rejected',
    direction: 'inbound',
    entityWatchValue: null,
    alertTypeId: 6,
    profileTypes: [PROFILE_TYPE.awsFlowlogs, PROFILE_TYPE.azureFlowlogs],
  },
  'fl-bytes': {
    log: 'fl-bytes',
    key: 'fl-bytes',
    direction: null,
    entityWatchValue: null,
    alertTypeId: 7,
    profileTypes: [PROFILE_TYPE.awsFlowlogs, PROFILE_TYPE.azureFlowlogs],
  },
  'fl-ingress': {
    log: 'fl-ingress',
    key: 'fl-ingress',
    direction: 'inbound',
    entityWatchValue: null,
    alertTypeId: 8,
    profileTypes: [PROFILE_TYPE.awsFlowlogs, PROFILE_TYPE.azureFlowlogs],
  },
  'fl-egress': {
    log: 'fl-egress',
    key: 'fl-egress',
    direction: 'outbound',
    entityWatchValue: null,
    alertTypeId: 9,
    profileTypes: [PROFILE_TYPE.awsFlowlogs, PROFILE_TYPE.azureFlowlogs],
  },
  // alertTypeId: 10 - obsolete
  'fl-inbound': {
    log: 'inbound',
    key: 'inbound',
    direction: 'inbound',
    entityWatchValue: null,
    alertTypeId: 11,
    profileTypes: [PROFILE_TYPE.awsFlowlogs, PROFILE_TYPE.azureFlowlogs],
  },
  'fl-local': {
    log: 'local',
    key: 'local',
    direction: 'local',
    entityWatchValue: null,
    alertTypeId: 12,
    profileTypes: [PROFILE_TYPE.awsFlowlogs, PROFILE_TYPE.azureFlowlogs],
  },
  'fl-outbound': {
    log: 'outbound',
    key: 'outbound',
    direction: 'outbound',
    entityWatchValue: null,
    alertTypeId: 13,
    profileTypes: [PROFILE_TYPE.awsFlowlogs, PROFILE_TYPE.azureFlowlogs],
  },
  'aggregate-azure-entity-activity': {
    log: 'aggregate-azure-entity-activity',
    key: 'aggregate-azure-entity-activity',
    direction: null,
    entityWatchValue: 'Abnormal entity activity',
    alertTypeId: 17,
    profileTypes: [PROFILE_TYPE.azureActivitylog],
  },
  'aggregate-azure-user-activity': {
    log: 'aggregate-azure-user-activity',
    key: 'aggregate-azure-user-activity',
    direction: null,
    entityWatchValue: 'Abnormal user entity activity',
    alertTypeId: 18,
    profileTypes: [PROFILE_TYPE.azureActivitylog],
  },
  'ai-beaconing': {
    log: 'ai-beaconing',
    key: 'ai-beaconing',
    direction: 'outbound',
    entityWatchValue: 'Potential Beaconing over HTTP/S',
    alertTypeId: 19,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'dns-request-vol-conn-count': {
    log: 'dns-request-vol-conn-count',
    key: 'dns-request-vol-conn-count',
    direction: 'outbound',
    entityWatchValue: 'Potential DNS Request Volume',
    alertTypeId: 20,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'dns-oversized-answers-char-count': {
    log: 'dns-oversized-answers-char-count',
    key: 'dns-oversized-answers-char-count',
    direction: 'outbound',
    entityWatchValue: 'Potential DNS Oversized Answer character count',
    alertTypeId: 21,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'dns-geo-conn-count': {
    log: 'dns-geo-conn-count',
    key: 'dns-geo-conn-count',
    direction: 'outbound',
    entityWatchValue: 'Potential DNS Destination Geo',
    alertTypeId: 22,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'dns-oversized-query-char-count': {
    log: 'dns-oversized-query-char-count',
    key: 'dns-oversized-query-char-count',
    direction: 'outbound',
    entityWatchValue: 'Potential DNS Oversized Query character count',
    alertTypeId: 23,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'dns-oversized-answers-bytes': {
    log: 'dns-oversized-answers-bytes',
    key: 'dns-oversized-answers-bytes',
    direction: 'outbound',
    entityWatchValue: 'Potential DNS Oversized Answers Bytes',
    alertTypeId: 24,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-53-inbound': {
    log: 'ai-port-53-inbound',
    key: 'ai-port-53-inbound',
    direction: 'inbound',
    entityWatchValue: 'Abnormal Inbound Port 53 Traffic',
    alertTypeId: 25,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-53-outbound': {
    log: 'ai-port-53-outbound',
    key: 'ai-port-53-outbound',
    direction: 'outbound',
    entityWatchValue: 'Abnormal Outbound Port 53 Traffic',
    alertTypeId: 26,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-20_21-inbound': {
    log: 'ai-port-20_21-inbound',
    key: 'ai-port-20_21-inbound',
    direction: 'inbound',
    entityWatchValue: 'Abnormal inbound traffic over ports 20 or 21',
    alertTypeId: 27,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-20_21-outbound': {
    log: 'ai-port-20_21-outbound',
    key: 'ai-port-20_21-outbound',
    direction: 'outbound',
    entityWatchValue: 'Abnormal outbound traffic over ports 20 or 21',
    alertTypeId: 28,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-23-inbound': {
    log: 'ai-port-23-inbound',
    key: 'ai-port-23-inbound',
    direction: 'inbound',
    entityWatchValue: 'Abnormal Inbound Port 23 Traffic',
    alertTypeId: 29,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-23-outbound': {
    log: 'ai-port-23-outbound',
    key: 'ai-port-23-outbound',
    direction: 'outbound',
    entityWatchValue: 'Abnormal Outbound Port 23 Traffic',
    alertTypeId: 30,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-s3-data-cloudtrail': {
    log: 'ai-s3-data-cloudtrail',
    key: 'ai-s3-data-cloudtrail',
    direction: null,
    entityWatchValue: 'Abnormal S3 Data API Calls within CloudTrail',
    alertTypeId: 31,
    profileTypes: [PROFILE_TYPE.cloudtrail, PROFILE_TYPE.awsCloudtrail],
  },

  'ai-userevents-okta': {
    log: 'ai-userevents-okta',
    key: 'ai-userevents-okta',
    direction: null,
    entityWatchValue: 'Abnormal User Event Activity within OKTA',
    alertTypeId: 35,
    profileTypes: [PROFILE_TYPE.oktaLogs],
  },
  'ai-logingeo-okta': {
    log: 'ai-logingeo-okta',
    key: 'ai-logingeo-okta',
    direction: null,
    entityWatchValue: 'Abnormal User Login Geo within OKTA',
    alertTypeId: 36,
    profileTypes: [PROFILE_TYPE.oktaLogs],
  },
  'ai-applicationaccess-okta': {
    log: 'ai-applicationaccess-okta',
    key: 'ai-applicationaccess-okta',
    direction: null,
    entityWatchValue: 'Abnormal User Application Activity within OKTA',
    alertTypeId: 37,
    profileTypes: [PROFILE_TYPE.oktaLogs],
  },
  'ai-port-22-inbound': {
    log: 'ai-port-22-inbound',
    key: 'ai-port-22-inbound',
    direction: 'inbound',
    entityWatchValue: 'Abnormal Inbound Port 22 Traffic',
    alertTypeId: 38,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-22-outbound': {
    log: 'ai-port-22-outbound',
    key: 'ai-port-22-outbound',
    direction: 'outbound',
    entityWatchValue: 'Abnormal Outbound Port 22 Traffic',
    alertTypeId: 39,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-22-local': {
    log: 'ai-port-22-local',
    key: 'ai-port-22-local',
    direction: 'local',
    entityWatchValue: 'Abnormal Local Port 22 Traffic',
    alertTypeId: 40,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-25-inbound': {
    log: 'ai-port-25-inbound',
    key: 'ai-port-25-inbound',
    direction: 'inbound',
    entityWatchValue: 'Abnormal Inbound Port 25 Traffic',
    alertTypeId: 41,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-25-outbound': {
    log: 'ai-port-25-outbound',
    key: 'ai-port-25-outbound',
    direction: 'outbound',
    entityWatchValue: 'Abnormal Outbound Port 25 Traffic',
    alertTypeId: 42,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-s3-bytes-cloudtrail': {
    log: 'ai-s3-bytes-cloudtrail',
    key: 'ai-s3-bytes-cloudtrail',
    direction: null,
    entityWatchValue: 'Abnormal S3 Byte Transfer within CloudTrail',
    alertTypeId: 43,
    profileTypes: [PROFILE_TYPE.cloudtrail, PROFILE_TYPE.awsCloudtrail],
  },
  'ai-port-3389-inbound': {
    log: 'ai-port-3389-inbound',
    key: 'ai-port-3389-inbound',
    direction: 'inbound',
    entityWatchValue: 'Abnormal Inbound Port 3389 Traffic',
    alertTypeId: 44,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-3389-outbound': {
    log: 'ai-port-3389-outbound',
    key: 'ai-port-3389-outbound',
    direction: 'outbound',
    entityWatchValue: 'Abnormal Outbound Port 3389 Traffic',
    alertTypeId: 45,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-3389-local': {
    log: 'ai-port-3389-local',
    key: 'ai-port-3389-local',
    direction: 'local',
    entityWatchValue: 'Abnormal Local Port 3389 Traffic',
    alertTypeId: 46,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-80_8080-inbound': {
    log: 'ai-port-80_8080-inbound',
    key: 'ai-port-80_8080-inbound',
    direction: 'inbound',
    entityWatchValue: 'Abnormal Inbound Traffic on Port 80 or 8080',
    alertTypeId: 47,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-80_8080-outbound': {
    log: 'ai-port-80_8080-outbound',
    key: 'ai-port-80_8080-outbound',
    direction: 'outbound',
    entityWatchValue: 'Abnormal Outbound Traffic on Port 80 or 8080',
    alertTypeId: 48,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-role-count-cloudtrail': {
    log: 'ai-role-count-cloudtrail',
    key: 'ai-role-count-cloudtrail',
    direction: null,
    entityWatchValue: 'Abnormal Role Activity within CloudTrail',
    alertTypeId: 49,
    profileTypes: [PROFILE_TYPE.cloudtrail, PROFILE_TYPE.awsCloudtrail],
  },
  'ai-port-443_8443-inbound': {
    log: 'ai-port-443_8443-inbound',
    key: 'ai-port-443_8443-inbound',
    direction: 'inbound',
    entityWatchValue: 'Abnormal Inbound Traffic on Port 443 or 8443',
    alertTypeId: 50,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-443_8443-outbound': {
    log: 'ai-port-443_8443-outbound',
    key: 'ai-port-443_8443-outbound',
    direction: 'outbound',
    entityWatchValue: 'Abnormal Outbound Traffic on Port 443 or 8443',
    alertTypeId: 51,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-137_138_139_445-inbound': {
    log: 'ai-port-137_138_139_445-inbound',
    key: 'ai-port-137_138_139_445-inbound',
    direction: 'inbound',
    entityWatchValue: 'Abnormal inbound traffic on Port 137, 138, 139 or 445',
    alertTypeId: 52,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-137_138_139_445-outbound': {
    log: 'ai-port-137_138_139_445-outbound',
    key: 'ai-port-137_138_139_445-outbound',
    direction: 'outbound',
    entityWatchValue: 'Abnormal outbound traffic on Port 137, 138, 139 or 445',
    alertTypeId: 53,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-137_138_139_445-local': {
    log: 'ai-port-137_138_139_445-local',
    key: 'ai-port-137_138_139_445-local',
    direction: 'local',
    entityWatchValue: 'Abnormal local traffic on Port 137, 138, 139 or 445',
    alertTypeId: 54,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-1433_1434_3306-inbound': {
    log: 'ai-port-1433_1434_3306-inbound',
    key: 'ai-port-1433_1434_3306-inbound',
    direction: 'inbound',
    entityWatchValue: 'Abnormal inbound traffic on Port 1433, 1434, or 3306',
    alertTypeId: 55,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-1433_1434_3306-outbound': {
    log: 'ai-port-1433_1434_3306-outbound',
    key: 'ai-port-1433_1434_3306-outbound',
    direction: 'outbound',
    entityWatchValue: 'Abnormal outbound traffic on Port 1433, 1434, or 3306',
    alertTypeId: 56,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-port-1433_1434_3306-local': {
    log: 'ai-port-1433_1434_3306-local',
    key: 'ai-port-1433_1434_3306-local',
    direction: 'local',
    entityWatchValue: 'Abnormal local traffic on Port 1433, 1434, or 3306',
    alertTypeId: 57,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
  'ai-client_uri_count-apigw': {
    log: 'ai-client_uri_count-apigw',
    key: 'ai-client_uri_count-apigw',
    direction: null,
    entityWatchValue: 'Abnormal APIGW Client URI Request Count',
    alertTypeId: 58,
    profileTypes: [PROFILE_TYPE.awsApiGatewayLogs],
  },
  'ai-client_method_count-apigw': {
    log: 'ai-client_method_count-apigw',
    key: 'ai-client_method_count-apigw',
    direction: null,
    entityWatchValue: 'Abnormal APIGW Client HTTP Method Request Count',
    alertTypeId: 59,
    profileTypes: [PROFILE_TYPE.awsApiGatewayLogs],
  },
  'ai-client_method_bytes-apigw': {
    log: 'ai-client_method_bytes-apigw',
    key: 'ai-client_method_bytes-apigw',
    direction: null,
    entityWatchValue: 'Abnormal APIGW Client HTTP Method Request Bytes',
    alertTypeId: 60,
    profileTypes: [PROFILE_TYPE.awsApiGatewayLogs],
  },
  'ai-applicationaccess-azure-ad': {
    log: 'ai-applicationaccess-azure-ad',
    key: 'ai-applicationaccess-azure-ad',
    direction: null,
    entityWatchValue: 'Abnormal User Application Access within Azure AD',
    alertTypeId: 61,
    profileTypes: [...AZURE_AD_PROFILE_TYPES],
  },
  'ai-logingeo-azure-ad': {
    log: 'ai-logingeo-azure-ad',
    key: 'ai-logingeo-azure-ad',
    direction: null,
    entityWatchValue: 'Abnormal User Login Geo within Azure AD',
    alertTypeId: 62,
    profileTypes: [...AZURE_AD_PROFILE_TYPES],
  },

  scheduled_task: {
    log: 'rule',
    key: null,
    direction: null,
    entityWatchValue: null,
    alertTypeId: 10000,
    profileTypes: Object.keys(PROFILE_TYPE),
  },
  psfile_analytics: {
    log: 'psfile_analytics',
    key: null,
    direction: null,
    entityWatchValue: null,
    alertTypeId: 20000,
    profileTypes: [PROFILE_TYPE.zeek, PROFILE_TYPE.zeekV2],
  },
};

export const DNS_INDICATORS = [
  INDICATORS_CONFIG['dns-geo-conn-count'],
  INDICATORS_CONFIG['dns-oversized-answers-bytes'],
  INDICATORS_CONFIG['dns-oversized-answers-char-count'],
  INDICATORS_CONFIG['dns-oversized-query-char-count'],
  INDICATORS_CONFIG['dns-request-vol-conn-count'],
];

export const INBOUND_PORT_INDICATORS = [
  INDICATORS_CONFIG['ai-port-20_21-inbound'],
  INDICATORS_CONFIG['ai-port-22-inbound'],
  INDICATORS_CONFIG['ai-port-23-inbound'],
  INDICATORS_CONFIG['ai-port-25-inbound'],
  INDICATORS_CONFIG['ai-port-53-inbound'],
  INDICATORS_CONFIG['ai-port-3389-inbound'],
  INDICATORS_CONFIG['ai-port-137_138_139_445-inbound'],
  INDICATORS_CONFIG['ai-port-80_8080-inbound'],
  INDICATORS_CONFIG['ai-port-443_8443-inbound'],
  INDICATORS_CONFIG['ai-port-1433_1434_3306-inbound'],
];

export const OUTBOUND_PORT_INDICATORS = [
  INDICATORS_CONFIG['ai-port-20_21-outbound'],
  INDICATORS_CONFIG['ai-port-22-outbound'],
  INDICATORS_CONFIG['ai-port-23-outbound'],
  INDICATORS_CONFIG['ai-port-25-outbound'],
  INDICATORS_CONFIG['ai-port-53-outbound'],
  INDICATORS_CONFIG['ai-port-3389-outbound'],
  INDICATORS_CONFIG['ai-port-137_138_139_445-outbound'],
  INDICATORS_CONFIG['ai-port-80_8080-outbound'],
  INDICATORS_CONFIG['ai-port-443_8443-outbound'],
  INDICATORS_CONFIG['ai-port-1433_1434_3306-outbound'],
];

export const LOCAL_PORT_INDICATORS = [
  INDICATORS_CONFIG['ai-port-22-local'],
  INDICATORS_CONFIG['ai-port-3389-local'],
  INDICATORS_CONFIG['ai-port-1433_1434_3306-local'],
  INDICATORS_CONFIG['ai-port-137_138_139_445-local'],
];

export const INBOUND_INDICATORS = Object.values(INDICATORS_CONFIG).filter(
  (indicator) => indicator.direction === 'inbound',
);

export const OUTBOUND_INDICATORS = Object.values(INDICATORS_CONFIG).filter(
  (indicator) => indicator.direction === 'outbound',
);

export const LOCAL_INDICATORS = Object.values(INDICATORS_CONFIG).filter((indicator) => indicator.direction === 'local');

export const FTP_INDICATORS = [INDICATORS_CONFIG['ai-port-20_21-inbound'], INDICATORS_CONFIG['ai-port-20_21-outbound']];

export const SSH_INDICATORS = [
  INDICATORS_CONFIG['ai-port-22-inbound'],
  INDICATORS_CONFIG['ai-port-22-local'],
  INDICATORS_CONFIG['ai-port-22-outbound'],
];

export const TELNET_INDICATORS = [INDICATORS_CONFIG['ai-port-23-inbound'], INDICATORS_CONFIG['ai-port-23-outbound']];

export const SMTP_INDICATORS = [INDICATORS_CONFIG['ai-port-25-inbound'], INDICATORS_CONFIG['ai-port-25-outbound']];

export const RDP_INDICATORS = [
  INDICATORS_CONFIG['ai-port-3389-inbound'],
  INDICATORS_CONFIG['ai-port-3389-outbound'],
  INDICATORS_CONFIG['ai-port-3389-local'],
];

export const BEACONING_INDICATORS = [INDICATORS_CONFIG['ai-beaconing']];

export const PORT_INDICATORS = [...INBOUND_PORT_INDICATORS, ...OUTBOUND_PORT_INDICATORS, ...LOCAL_PORT_INDICATORS];

export const IDENTITY_MANAGEMENT_INDICATORS = [
  INDICATORS_CONFIG['ai-logingeo-okta'],
  INDICATORS_CONFIG['ai-userevents-okta'],
  INDICATORS_CONFIG['ai-applicationaccess-okta'],
];

export const API_GATEWAY_INDICATORS = [
  INDICATORS_CONFIG['ai-client_uri_count-apigw'],
  INDICATORS_CONFIG['ai-client_method_count-apigw'],
  INDICATORS_CONFIG['ai-client_method_bytes-apigw'],
];

export const PLATFORM_INDICATORS = [
  'platform',
  'ai-beaconing',
  'ai-s3-data-cloudtrail',
  'ai-s3-bytes-cloudtrail',
  ...DNS_INDICATORS.map((i) => i.log),
  ...PORT_INDICATORS.map((i) => i.log),
];

export const NONDIRECTIONAL_INDICATORS = ['initial-access', 'user-management', 'entity-track'];

export const AGG_USER_ACTIVITY = ['aggregate-user-activity', 'aggregate-azure-user-activity'];

export const AGG_ENTITY_ACTIVITY = ['aggregate-azure-entity-activity'];

export const MALWARE_INDICATORS = ['psfile_analytics'];

export const TEMPLATE_OPTIONS = {
  platform: 'platform',
  cloudtrailRole: 'cloudtrailRole',
  cloudtrailPrincipalID: 'cloudtrailPrincipalID',
  nonDirectional: 'nonDirectional',
  idmOkta: 'idmOkta',
  azureAD: 'azureAD',
  apigwUriCount: 'apigwUriCount',
  apigwMethod: 'apigwMethod',
  defaultDns: 'defaultDns',
  dnsOversizedAnswersBytes: 'dnsOversizedAnswersBytes',
  aiBeaconing: 'aiBeaconing',
};

export const TEMPLATE_CONFIGURATION = {
  [TEMPLATE_OPTIONS.platform]: [
    {
      profileTypes: [],
      logs: ['platform', 'ai-external-external-bytes', ...PORT_INDICATORS.map((i) => i.log)],
    },
  ],
  [TEMPLATE_OPTIONS.cloudtrailPrincipalID]: [
    {
      profileTypes: [],
      logs: [
        'ai-s3-data-cloudtrail',
        'ai-s3-bytes-cloudtrail',
        'initial-access',
        'user-management',
        'aggregate-user-activity',
      ],
    },
    {
      profileTypes: [PROFILE_TYPE.awsCloudtrail, PROFILE_TYPE.cloudtrail],
      logs: ['entity-track'],
    },
  ],
  [TEMPLATE_OPTIONS.cloudtrailRole]: [
    {
      profileTypes: [PROFILE_TYPE.awsCloudtrail, PROFILE_TYPE.cloudtrail],
      logs: ['ai-role-count-cloudtrail'],
    },
  ],
  [TEMPLATE_OPTIONS.nonDirectional]: [
    {
      profileTypes: [],
      logs: ['aggregate-azure-user-activity', 'aggregate-azure-entity-activity'],
    },
  ],
  [TEMPLATE_OPTIONS.idmOkta]: [
    {
      profileTypes: [],
      logs: IDENTITY_MANAGEMENT_INDICATORS.map((i) => i.log),
    },
  ],
  [TEMPLATE_OPTIONS.azureAD]: [
    {
      profileTypes: [],
      logs: ['ai-logingeo-azure-ad', 'ai-applicationaccess-azure-ad'],
    },
  ],
  [TEMPLATE_OPTIONS.apigwUriCount]: [
    {
      profileTypes: [],
      logs: ['ai-client_uri_count-apigw'],
    },
  ],
  [TEMPLATE_OPTIONS.apigwMethod]: [
    {
      profileTypes: [],
      logs: ['ai-client_method_bytes-apigw', 'ai-client_method_count-apigw'],
    },
  ],
  [TEMPLATE_OPTIONS.defaultDns]: [
    {
      profileTypes: [],
      logs: [
        'dns-geo-conn-count',
        'dns-oversized-answers-char-count',
        'dns-oversized-query-char-count',
        'dns-request-vol-conn-count',
      ],
    },
  ],
  [TEMPLATE_OPTIONS.dnsOversizedAnswersBytes]: [
    {
      profileTypes: [],
      logs: ['dns-oversized-answers-bytes'],
    },
  ],
  [TEMPLATE_OPTIONS.aiBeaconing]: [
    {
      profileTypes: [],
      logs: ['ai-beaconing'],
    },
  ],
};

export const INDICATOR_NAME = {
  'initial-access': 'Access Denied Activities',
  'aggregate-user-activity': 'Aggregate User Activities',
  'ai-applicationaccess-azure-ad': 'User Application Access',
  'ai-applicationaccess-okta': 'User Application Access',
  'ai-beaconing': 'HTTP/S Beaconing',
  'dns-geo-conn-count': 'DNS Geo',
  'dns-oversized-answers-bytes': 'DNS Answer Bytes',
  'dns-oversized-answers-char-count': 'DNS Oversized Answer',
  'dns-oversized-query-char-count': 'DNS Oversized Query',
  'dns-request-vol-conn-count': 'DNS Request Volume',
  'aggregate-azure-entity-activity': 'Entity Activities',
  'fl-egress': 'Egress Bytes',
  'fl-ingress': 'Ingress Bytes',
  'fl-inbound': 'Inbound Bytes',
  'ai-port-20_21-inbound': 'Inbound Bytes',
  'ai-port-22-inbound': 'Inbound Bytes',
  'ai-port-23-inbound': 'Inbound Bytes',
  'ai-port-25-inbound': 'Inbound Bytes',
  'ai-port-3389-inbound': 'Inbound Bytes',
  'ai-port-443_8443-inbound': 'Inbound Bytes',
  'ai-port-53-inbound': 'Inbound Bytes',
  'ai-port-80_8080-inbound': 'Inbound Bytes',
  'ai-port-137_138_139_445-inbound': 'Inbound Bytes',
  'ai-port-1433_1434_3306-inbound': 'Inbound Bytes',
  inbound: 'Inbound Bytes',
  'ai-role-count-cloudtrail': 'IAM Role Activities',
  'ai-port-22-local': 'Local Bytes',
  'ai-port-3389-local': 'Local Bytes',
  'ai-port-137_138_139_445-local': 'Local Bytes',
  'ai-port-1433_1434_3306-local': 'Local Bytes',
  'fl-local': 'Local Bytes',
  local: 'Local Bytes',
  'ai-port-20_21-outbound': 'Outbound Bytes',
  'ai-port-22-outbound': 'Outbound Bytes',
  'ai-port-23-outbound': 'Outbound Bytes',
  'ai-port-25-outbound': 'Outbound Bytes',
  'ai-port-3389-outbound': 'Outbound Bytes',
  'ai-port-443_8443-outbound': 'Outbound Bytes',
  'ai-port-53-outbound': 'Outbound Bytes',
  'ai-port-80_8080-outbound': 'Outbound Bytes',
  'ai-port-137_138_139_445-outbound': 'Outbound Bytes',
  'ai-port-1433_1434_3306-outbound': 'Outbound Bytes',
  'fl-outbound': 'Outbound Bytes',
  outbound: 'Outbound Bytes',
  'fl-rejected': 'Rejected Connections',
  'ai-s3-bytes-cloudtrail': 'S3 Data Movement',
  'ai-s3-data-cloudtrail': 'S3 Data Movement',
  'fl-bytes': 'Total Bytes',
  'aggregate-azure-user-activity': 'User Activities',
  'ai-userevents-okta': 'User Authentication Activities',
  'ai-logingeo-azure-ad': 'User Authentication Geo',
  'ai-logingeo-okta': 'User Authentication Geo',
  'user-management': 'User Management Activities',
  'ai-external-external-bytes': 'External Bytes',
};

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApi } from 'services/api';

const getPayload = ({ bookmark, type, values }) => {
  if (type === 'id') {
    return {
      name: values.name,
      description: values.description || '',
      labels: values.labels && typeof values.labels === 'string' ? values.labels.split(',').map((l) => l.trim()) : [],
      objectId: bookmark.objectId,
      searchType: bookmark.searchType,
    };
  }

  return {
    name: values.name,
    description: values.description || '',
    labels: values.labels && typeof values.labels === 'string' ? values.labels.split(',').map((l) => l.trim()) : [],
    query: values.query,
    searchType: bookmark.searchType,
  };
};

const useEditBookmark = () => {
  const api = useApi();
  const client = useQueryClient();

  return useMutation(
    async ({ values, bookmark, type }) => {
      const params = getPayload({ bookmark, type, values });

      const editedBookmark = await api.post(`/bookmarks/${type}/${bookmark.id}`, params);

      const records = client.getQueryData(['bookmarks', type]) || [];

      client.setQueryData(
        ['bookmarks', type],
        records.map((b) => (b.id === bookmark.id ? editedBookmark : b)),
      );
    },
    {
      enabled: !!api,
    },
  );
};

export default useEditBookmark;

import styled, { css } from 'styled-components';

export const titleStyle = css`
  font-family: ${({ theme }) => theme.base.fontHeader};
  font-weight: 600;
  color: ${({ theme, $primary }) => ($primary ? theme.colors.primary : theme.colors.text.primary)};
  margin-top: ${({ $marginTop }) => $marginTop || '8px'};
  margin-bottom: ${({ $marginBottom }) => $marginBottom || '8px'};
  margin-left: 0px;
  margin-right: 0px;
`;

const TitleStyled = styled.h1`
  ${titleStyle}
  font-size: 24px;
  padding: 4px 0 16px 0;
`;

export const Title = ({ children, primary, marginTop, marginBottom, ...props }) => (
  <TitleStyled {...props} $primary={primary} $marginTop={marginTop} $marginBottom={marginBottom}>
    {children}
  </TitleStyled>
);

const SubtitleStyled = styled.h2`
  ${titleStyle}
  font-weight: 500;
  font-size: 18px;
`;

export const Subtitle = ({ children, primary, marginTop, marginBottom, ...props }) => (
  <SubtitleStyled {...props} $primary={primary} $marginTop={marginTop} $marginBottom={marginBottom}>
    {children}
  </SubtitleStyled>
);

export const Error = styled.div`
  color: ${({ theme }) => theme.palette.danger[2]};
`;

export const Paragraph = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const CodeLine = styled.code`
  font-weight: bold;
  padding: 2px 4px;
  border: 1px solid ${({ theme }) => theme.colors.text.subtle};
  border-radius: 2px;
  font-size: 14px;
`;

import React from 'react';
import PropTypes from 'prop-types';
import tinycolor from 'tinycolor2';
import styled, { css, useTheme } from 'styled-components';

import Spinner from 'components/common/Spinner';
import BaseButton from './BaseButton';

const getSizeStyles = (size) => {
  switch (size) {
    case 'small':
      return css`
        height: 24px;
        font-size: 12px;
        padding: 0 8px;
        min-width: 24px;
      `;
    case 'medium':
      return css`
        font-size: 14px;
        height: 32px;
        padding: 4px 8px;
      `;
    case 'large':
    default:
      return css`
        font-size: 16px;
        height: 36px;
        padding: 8px 8px;
      `;
  }
};

// specific variant overwrites
const getVariantStyles = (theme, variant, color) => {
  const isLight = theme.name === 'Light';
  switch (variant) {
    case 'outlined':
      if (color === 'secondary') {
        return css`
          color: ${({ theme }) => theme.palette.secondary[isLight ? 2 : 3]};
          border-color: ${({ theme }) => theme.palette.secondary[isLight ? 2 : 3]};
          background-color: transparent;
        `;
      }
      if (color === 'danger') {
        return css`
          color: ${({ theme }) => theme.palette.danger[isLight ? 2 : 3]};
          border-color: ${({ theme }) => theme.palette.danger[isLight ? 2 : 3]};
          background-color: transparent;
        `;
      }
      return css`
        background-color: transparent;
      `;
    case 'text':
      return css`
        border-color: transparent;
        background-color: transparent;
      `;
    case 'accented':
      if (color === 'secondary') {
        return css`
          color: ${({ theme }) => theme.palette.secondary[isLight ? 2 : 3]};
          border-color: ${({ theme }) => theme.colors.border.default};
          background-color: transparent;
        `;
      }
      return css`
        border-color: ${({ theme }) => theme.colors.border.default};
        background-color: transparent;
      `;
    case 'contained':
      if (color === 'default') {
        return css`
          border-color: ${({ theme }) => theme.colors.text.primary};
          color: ${({ theme }) => theme.colors.text.primary};
          background-color: 'transparent';
        `;
      }
      if (color === 'secondary') {
        return css`
          border-color: ${({ theme }) => theme.palette.secondary[isLight ? 2 : 0]};
          background-color: ${({ theme }) => theme.palette.secondary[isLight ? 2 : 0]};
        `;
      }
      if (color === 'danger') {
        return css`
          border-color: ${({ theme }) => theme.palette.danger[isLight ? 2 : 1]};
          background-color: ${({ theme }) => theme.palette.danger[isLight ? 2 : 1]};
        `;
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return css`
        color: ${({ theme }) => theme.palette.white};
      `;
  }
};

const _getColorFromKey = (colorKey) => {
  const theme = useTheme();
  const isLight = theme.name === 'Light';
  const colors = {
    primary: theme.palette.primary[2],
    secondary: theme.palette.secondary[2],
    danger: theme.palette.danger[2],
    success: theme.palette.success[2],
    warning: theme.palette.alert[isLight ? 0 : 1],
  };
  return colors[colorKey];
};

const getColorStyles = (color) => {
  const baseColor = _getColorFromKey(color);
  if (color === 'default') {
    return css`
      color: ${({ theme }) => theme.colors.text.primary};
      border-color: ${({ theme }) => theme.colors.text.primary};
      background-color: transparent;
    `;
  }
  if (baseColor === undefined) {
    // color is defined by a custom string
    return css`
      color: ${({ theme, $variant }) => ($variant === 'contained' ? theme.palette.white : color)};
      border-color: ${color};
      background-color: ${color};
    `;
  }
  return css`
    color: ${({ theme, $variant }) => ($variant === 'contained' ? theme.palette.white : baseColor)};
    border-color: ${baseColor};
    background-color: ${baseColor};
  `;
};

const getHoverStyles = (variant, color) => {
  const baseColor = _getColorFromKey(color) || color;
  const rgba = tinycolor(baseColor).toRgb();
  if (variant === 'contained' && color !== 'default') {
    const rgba = tinycolor(baseColor).darken(8).toRgb();
    return css`
      &:hover,
      &:focus {
        box-shadow: inset 0 0 999em rgba(${rgba.r}, ${rgba.g}, ${rgba.b});
      }
    `;
  }
  if (color === 'default') {
    return css`
      &:hover,
      &:focus {
        background-color: ${({ theme }) =>
          theme.name === 'Dark'
            ? tinycolor(theme.colors.background.default).brighten(10).toString()
            : tinycolor(theme.colors.background.default).darken(2).setAlpha(0.1).toString()};
      }
    `;
  }
  return css`
    &:hover,
    &:focus {
      box-shadow: inset 0 0 999em rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, 0.1);
    }
  `;
};

const StyledButton = styled(BaseButton)`
  border: 1px solid;
  ${({ $size }) => getSizeStyles($size)};
  ${({ $color }) => getColorStyles($color)};
  ${({ theme, $variant, $color }) => getVariantStyles(theme, $variant, $color)};
  ${({ $variant, $color }) => getHoverStyles($variant, $color)};
  font-weight: 400;
  width: ${({ $width }) => ($width ? $width : 'auto')};

  ${({ $hasLeftIcon }) =>
    $hasLeftIcon &&
    css`
      svg {
        margin-right: ${({ $size }) => ($size === 'large' ? '8px' : '4px')};
      }
    `}

  ${({ $hasRightIcon }) =>
    $hasRightIcon &&
    css`
      svg {
        margin-left: ${({ $size }) => ($size === 'large' ? '8px' : '4px')};
      }
    `}

  &:disabled {
    pointer-events: unset;
    cursor: not-allowed;
    opacity: 0.8;
  }
`;

const Button = React.forwardRef(
  (
    {
      isLoading,
      children,
      size,
      variant,
      width,
      color,
      leftIcon = null,
      rightIcon = null,
      active,
      isDisabled,
      ...props
    },
    ref,
  ) => {
    if (isLoading) {
      return (
        <StyledButton
          {...props}
          ref={ref}
          $size={size}
          $variant={variant}
          $color={color}
          $width={width}
          $hasLeftIcon={!!leftIcon}
          $hasRightIcon={!!rightIcon}
          $active={active}
          $isDisabled={isDisabled}
        >
          <Spinner $color="currentColor" />
        </StyledButton>
      );
    }
    return (
      <StyledButton
        {...props}
        ref={ref}
        $size={size}
        $variant={variant}
        $color={color}
        $width={width}
        $hasLeftIcon={!!leftIcon}
        $hasRightIcon={!!rightIcon}
        $active={active}
        $isDisabled={isDisabled}
      >
        {leftIcon}
        {children}
        {rightIcon}
      </StyledButton>
    );
  },
);

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'accented', 'text']),
  color: PropTypes.oneOfType([
    PropTypes.oneOf(['default', 'primary', 'secondary', 'danger', 'warning', 'success']),
    PropTypes.string,
  ]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  width: PropTypes.string,
  className: PropTypes.string,
};

Button.defaultProps = {
  size: 'large',
  variant: 'outlined',
  color: 'default',
  isLoading: false,
  disabled: false,
  leftIcon: null,
  rightIcon: null,
  width: null,
  className: null,
};

export default Button;

import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownContentWrapperStyled = styled.div`
  position: absolute;
  ${({ $align }) => {
    switch ($align) {
      case 'left':
        return 'left: 0px;';
      case 'right':
        return 'right: 0px;';
      default:
        return '';
    }
  }}
  top: ${({ $top }) => $top}px;
  left: ${({ $left }) => ($left ? `${$left}px` : 'auto')};
  right: ${({ $right }) => ($right ? `${$right}px` : 'auto')};
  width: 100%;
  max-width: ${({ $width }) => ($width ? `${$width}px` : 'auto')};
  min-width: ${({ $width }) => ($width ? `${$width}px` : '200px')};
  background: ${({ theme }) => theme.colors.background.alt2};
  border-radius: 2px;
  box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 1200;
`;

const DropdownContentWrapper = forwardRef(({ children, align, top, left, right, width, ...props }, ref) => (
  <DropdownContentWrapperStyled
    ref={ref}
    {...props}
    $align={align}
    $top={top}
    $left={left}
    $right={right}
    $width={width}
  >
    {children}
  </DropdownContentWrapperStyled>
));

const Content = styled.div``;
const FitContent = styled.div``;

const arrowPosition = ({ $arrow }) => {
  switch ($arrow) {
    case 'left':
      return css`
        left: 10px;
      `;
    case 'middle':
      return css`
        left: calc(50% - 10px);
      `;
    case 'right':
      return css`
        right: 10px;
      `;
    case 'near-right':
      return css`
        right: 50px;
      `;
    default:
      return null;
  }
};

const arrowStyles = css`
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    ${arrowPosition};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent ${({ theme }) => theme.colors.background.alt2} transparent;
  }
`;

const DropdownContentStyled = styled.div`
  & > ${Content} {
    padding: 12px;
  }
  & > ${FitContent} {
    padding: 0;
  }

  ${({ $arrow }) => $arrow !== 'none' && arrowStyles}
`;

const DropdownContent = ({ children, arrow, ...props }) => (
  <DropdownContentStyled {...props} $arrow={arrow}>
    {children}
  </DropdownContentStyled>
);

export { DropdownContainer, DropdownContentWrapper, DropdownContent, Content, FitContent };

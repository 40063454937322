import React from 'react';
import styled from 'styled-components';
import ThemeProvider from 'services/theme';

import { hingeAnimation } from 'components/styled/animations';
import logo from 'static/images/logo-text.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  color: ${({ theme }) => theme.colors.text.primary};
  background: ${({ theme }) => theme.colors.background.default};
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  overflow: hidden;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
  }

  a:visited,
  a:active,
  a:link {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const AdmissionOfGuilt = styled.div`
  font-size: 32px;
  line-height: 38px;
  padding: 8px 0;
`;

export const Hinge = styled.div`
  animation: ${hingeAnimation};
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
`;

const Logo = styled.img`
  width: auto;
`;

const CrashAndBurnPage = () => (
  <ThemeProvider>
    <Container data-testid="error-boundary-container" data-test-id="error-boundary-container">
      <Hinge>
        <Logo alt="MixMode logo" src={logo} />
      </Hinge>
      <AdmissionOfGuilt>Oops. We broke something...</AdmissionOfGuilt>
      <div>Developers have been notified about their horrible mistake.</div>
      <div>
        <a href="/" alt="Try something else">
          Click here to try something else
        </a>
      </div>
    </Container>
  </ThemeProvider>
);

export default CrashAndBurnPage;

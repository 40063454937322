import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';
import { motion } from 'framer-motion';

import { FULL_DATE_FORMAT, DAY_IN_YEAR_FORMAT } from 'consts/datetime';
import InlineLoader from 'components/common/InlineLoader';
import Badge from 'components/common/Badge';
import { Error } from 'components/styled/Typography';
import { useSensorList } from 'services/sensor';
import { useAcknowledgeNotificationGroup } from 'services/notification';
import { updateQueryStringMultiple } from 'utils/url';
import { formatUTC } from 'utils/datetime';

import NotificationActions from './NotificationActions';
import SeverityInfo from './SeverityInfo';
import { Container, Body, Timestamp, Count, Footer } from './index.styled';

const SimpleNotification = ({
  sensorId,
  typeDescription,
  severityId,
  severityName,
  categoryDescription,
  count,
  createdAt,
  day,
  groupId,
  active,
  inDropdown,
}) => {
  const navigate = useNavigate();
  const [showActions, setShowActions] = useState(inDropdown);
  const { findSensorById } = useSensorList();
  const acknowledgeNotificationGroup = useAcknowledgeNotificationGroup();

  const sensor = sensorId && findSensorById(sensorId);

  useEffect(() => {
    if (acknowledgeNotificationGroup.isError) {
      acknowledgeNotificationGroup.reset();
      setShowActions(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acknowledgeNotificationGroup.isError]);

  const gotoDetails = () => {
    const newQs = {
      group: groupId,
    };
    if (inDropdown) {
      newQs.q = day ? formatUTC(new Date(day), DAY_IN_YEAR_FORMAT) : 'today';
      newQs.ack = '0';
    }
    navigate(updateQueryStringMultiple(newQs, '/notifications'));
  };

  const handleAck = () => {
    setShowActions(false);

    acknowledgeNotificationGroup.mutate({ groupId, count });
  };

  return (
    <Container key={sensorId + severityId + groupId} as={motion.div} layout onClick={gotoDetails} $active={active}>
      <SeverityInfo severityId={severityId} severityName={severityName} categoryDescription={categoryDescription} />
      <Body>
        <Timestamp>
          {createdAt ? (
            <div>
              {count > 1 && <>Last trigger: </>}
              {format(new Date(createdAt), FULL_DATE_FORMAT)}
            </div>
          ) : (
            <div>{formatUTC(new Date(day), DAY_IN_YEAR_FORMAT)}</div>
          )}
          {count > 1 ? <Count>x{count}</Count> : <div />}
        </Timestamp>
        <div>{typeDescription}</div>
        {sensor && (
          <div className="mt-1">
            <Badge variant="accented" color={sensor.color} value={sensor.name} />
          </div>
        )}
        <Footer>
          {acknowledgeNotificationGroup.isLoading && <InlineLoader msg="Loading..." subtle />}
          {acknowledgeNotificationGroup.isError && <Error>Failed to acknowledge notification.</Error>}
          {showActions && <NotificationActions onAck={handleAck} groupId={groupId} displayText={typeDescription} />}
        </Footer>
      </Body>
    </Container>
  );
};

export default React.memo(SimpleNotification);

import React from 'react';
import PropTypes from 'prop-types';
import ClickOutside from 'components/common/ClickOutside';
import { useToggle } from 'utils/hooks';

import { DropdownContainer, DropdownContentWrapper, DropdownContent, Content, FitContent } from './index.styled';

const Dropdown = ({
  children,
  trigger,
  align,
  width,
  open,
  top = 40,
  right,
  left,
  arrow = 'none',
  style,
  className,
  openDropdown,
  closeDropdown,
}) => {
  const openToggle = useToggle();

  const handleClick = (ev) => {
    ev.stopPropagation();
    if (openDropdown) {
      openDropdown();
    } else {
      openToggle.toggle();
    }
  };

  const handleCloseDropdown = (ev) => {
    ev.stopPropagation();
    if (closeDropdown) {
      closeDropdown();
    } else {
      openToggle.setOff();
    }
  };

  const fixPosition = (wrapperDomElement) => {
    if (!wrapperDomElement) {
      return;
    }

    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    const bounding = wrapperDomElement.getBoundingClientRect();
    if (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.right <= viewportWidth &&
      bounding.bottom <= viewportHeight
    ) {
      // all good
      return;
    }

    const horizontalMargin = 20;

    const rightOverflow = bounding.right - viewportWidth;
    if (rightOverflow > 0) {
      wrapperDomElement.style.left = `-${rightOverflow + horizontalMargin}px`;
    }

    // Add other cases as needed (left, bottom, top)
  };

  return (
    <DropdownContainer>
      {trigger && (
        <div className="inline-flex" onClick={handleClick}>
          {trigger}
        </div>
      )}
      {(openToggle.isOn || open) && (
        <ClickOutside onClickOutside={handleCloseDropdown}>
          <DropdownContentWrapper
            top={top}
            right={right}
            ref={fixPosition}
            align={align}
            left={left}
            width={width}
            style={style}
            className={className}
          >
            <DropdownContent arrow={arrow} onClick={handleCloseDropdown}>
              {children}
            </DropdownContent>
          </DropdownContentWrapper>
        </ClickOutside>
      )}
    </DropdownContainer>
  );
};

Dropdown.propTypes = {
  openDropdown: PropTypes.func,
  closeDropdown: PropTypes.func,
  open: PropTypes.bool,
  top: PropTypes.number,
  right: PropTypes.number,
  arrow: PropTypes.oneOf(['none', 'left', 'middle', 'near-right', 'right']),
};

export { Dropdown as default, Content, FitContent };

import styled, { css } from 'styled-components';
import Link from 'components/common/Link';

export const ItemContainer = styled.div`
  width: 100%;
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.mainmenu.itemActive : theme.colors.mainmenu.background};
  border: 1px solid
    ${({ theme, $isActive }) => ($isActive ? theme.colors.mainmenu.divider : theme.colors.mainmenu.background)};
  color: ${({ theme, $isEnabled }) => ($isEnabled ? theme.colors.mainmenu.text : theme.colors.mainmenu.textDisabled)};
  cursor: ${({ $isEnabled }) => ($isEnabled ? 'pointer' : 'not-allowed')};

  &:hover {
    ${({ theme, $isEnabled }) =>
      $isEnabled &&
      css`
        color: ${theme.colors.mainmenu.textAccent};
      `}
  }
`;

export const ItemWrapperLink = styled(Link)`
  display: flex;
  align-items: center;
  padding-left: 10px;
  min-height: 36px;
  width: 100%;
  color: inherit;

  ${({ $isEnabled }) =>
    !$isEnabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
    `}

  & > svg {
    flex: 1 0 16px;
    max-width: 16px;
  }
`;

export const ItemText = styled.div`
  margin-left: 12px;
  font-size: 14px;
  color: ${({ $expanded }) => ($expanded ? 'inherit' : 'transparent')};
  white-space: nowrap;
  transition: color 200ms ease-in-out;
  pointer-events: none;
`;

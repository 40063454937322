import React, { useReducer } from 'react';

import { useApi } from 'services/api';
import { extractErrorMessage } from 'utils/api';
import { ACTIVE_PROFILE_TYPE } from 'consts/sensors';
import { useFeatureFlag } from 'services/debug';

const initialState = {
  profiles: null,
  loading: false,
  settingProfile: false,
  settingError: null,
};

export const ProfilesState = React.createContext({
  state: {
    ...initialState,
  },
  getSensorProfiles: () => {},
  setSensorProfile: () => {},
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'GET_SENSOR_PROFILES':
      return { ...state, profiles: null, loading: true };
    case 'GET_SENSOR_PROFILES_SUCCESS':
      return {
        ...state,
        loading: false,
        profiles: action.profiles,
      };
    case 'GET_SENSOR_PROFILES_FAIL':
      return {
        ...state,
        loading: false,
        profiles: [],
        loadError: action.error,
      };
    case 'SET_SENSOR_PROFILE':
      return {
        ...state,
        settingProfile: true,
      };
    case 'SET_SENSOR_PROFILE_SUCCESS':
      return {
        ...state,
        settingProfile: false,
      };
    case 'SET_SENSOR_PROFILE_FAIL':
      return {
        ...state,
        settingProfile: false,
        settingError: action.error,
      };
    default:
      return state;
  }
};

export function ProfilesStateProvider({ children }) {
  const isCrowdstrikeFDREnabled = useFeatureFlag('crowdstrikeFDR');
  const [state, dispatch] = useReducer(reducer, initialState);

  const profileTypes = Object.values({
    ...ACTIVE_PROFILE_TYPE,
    ...(isCrowdstrikeFDREnabled ? { crowdstrikeV2: 'crowdstrike_v2' } : {}),
  });

  const api = useApi();

  const getSensorProfiles = (tenant) => {
    dispatch({ type: 'GET_SENSOR_PROFILES' });

    return api
      .get(`/tenants/${tenant.id}/smf/profiles`, {
        params: {
          QueryConstraint: JSON.stringify({
            profileType: {
              $in: profileTypes,
            },
          }),
        },
      })
      .then(({ items }) => {
        dispatch({ type: 'GET_SENSOR_PROFILES_SUCCESS', profiles: items });
      })
      .catch((error) => {
        dispatch({ type: 'GET_SENSOR_PROFILES_FAIL', error: extractErrorMessage(error) });
      });
  };

  const setSensorProfile = (sensor) => {
    dispatch({ type: 'SET_SENSOR_PROFILE' });

    return api
      .post(`/tenants/${sensor.Tenant.id}/smf/sensors/${sensor.id}/profile/${sensor.Profile.id}`)
      .then((sensor) => {
        dispatch({ type: 'SET_SENSOR_PROFILE_SUCCESS' });
        return sensor;
      })
      .catch((error) => {
        dispatch({ type: 'SET_SENSOR_PROFILE_FAIL' });
        throw error;
      });
  };

  const value = {
    state,
    getSensorProfiles,
    setSensorProfile,
  };

  return <ProfilesState.Provider value={value}>{children}</ProfilesState.Provider>;
}

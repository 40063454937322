import ReactSelect from 'react-select';
import styled, { css } from 'styled-components';

const cleanFocus = css`
  border-color: ${({ theme }) => theme.colors.border.alt1};
  box-shadow: none;
`;

const defaultColors = css`
  border-color: ${({ theme }) => theme.colors.border.alt1};
  color: ${({ theme }) => theme.colors.text.primary};
  background: ${({ theme }) => theme.colors.background.alt2};

  ${({ $borderless }) =>
    $borderless &&
    css`
      border: none;
    `}
`;

const transparentOverride = css`
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.alt1};
  background: transparent;
  cursor: pointer;
`;

export const StyledSelect = styled(ReactSelect)`
  min-width: ${({ $minWidth }) => $minWidth || '120px'};
  font-size: 14px;

  .rsmm__control,
  .rsmulti__control {
    border-radius: ${({ $borderRadius }) => $borderRadius || 2}px;
    min-height: 32px;
    ${defaultColors}

    &:hover {
      ${cleanFocus}
    }

    ${({ $transparent }) => $transparent && transparentOverride}
  }

  .rsmulti__control {
    border: 1px solid ${({ theme }) => theme.colors.border.default};
    background-color: ${({ theme }) => theme.colors.background.alt1};
  }

  .rsmm__control--is-focused,
  .rsmulti__control--is-focused,
  .rsmm__control--menu-is-open,
  .rsmulti__control--menu-is-open {
    ${cleanFocus}

    &:hover {
      ${cleanFocus}
    }
  }

  ${({ isClearable }) =>
    isClearable
      ? css`
          .rsmm__indicator {
            display: none;
          }

          .rsmm__indicator-separator {
            display: none;
          }

          .rsmm__clear-indicator {
            display: flex;
          }
        `
      : css`
          .rsmm__indicators {
            display: none;
          }
        `}

  .rsmm__value-container {
    ${({ height }) =>
      height &&
      css`
        height: ${({ height }) => height};
      `}
  }

  .rsmm__single-value {
    color: ${({ theme }) => theme.colors.text.primary};
  }

  .rsmm__menu,
  .rsmulti__menu {
    margin-top: 2px;
    min-width: 100%;
    width: max-content;
    ${defaultColors}
  }

  .rsmm__menu-list {
    min-width: 100%;
    width: max-content;
  }

  .rsmm__option {
    width: max-content;
    min-width: 100%;
  }

  .rsmm__option.rsmm__option--is-selected.rsmm__option--is-focused,
  .rsmulti__option.rsmulti__option--is-selected.rsmulti__option--is-focused,
  .rsmm__option.rsmm__option--is-selected {
    color: ${({ theme }) => theme.colors.text.primary};
    background: ${({ theme }) => theme.colors.background.selected};
  }
  .rsmm__option.rsmm__option--is-focused,
  .rsmulti__option.rsmulti__option--is-focused {
    background: ${({ theme }) => theme.colors.background.default};
  }

  .rsmm__multi-value__remove {
    color: ${({ theme }) => theme.palette.danger[3]};
    transition: color 100ms ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.palette.danger[1]};
    }
  }

  .rsmm__multi-value {
    margin: 0 2px;
    border-radius: 5px;
    background: transparent;

    ${({ $isDisabled }) =>
      $isDisabled &&
      css`
        background: transparent;
      `}
  }

  .rsmulti__multi-value {
    background: ${({ theme }) => theme.colors.background.default};
  }

  .rsmm__multi-value__label {
    color: ${({ theme }) => theme.colors.text.primary};
    font-size: 14px;

    ${({ $isDisabled }) =>
      $isDisabled &&
      css`
        padding: 0;
      `}
  }

  .rsmulti__multi-value__label {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.text.primary};
  }

  .rsmm__input-container {
    color: ${({ theme }) => theme.colors.text.primary};
    font-size: 14px;
  }

  .rsmm__multi-value__remove {
    padding: 2px;

    &:hover {
      background: inherit;
    }
  }
`;

export const Label = styled.label`
  pointer-events: none;
  position: absolute;
  transition: all 200ms ease;

  ${({ $isFloating, theme }) =>
    $isFloating
      ? css`
          left: 10px;
          top: -12px;
          font-size: 12px;
          color: ${theme.colors.text.primary};
        `
      : css`
          left: 10px;
          top: 6px;
          font-size: 14px;
          color: #808080;
        `}
`;

import React, { useReducer, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import _uniq from 'lodash/uniq';
import _endsWith from 'lodash/endsWith';

import { last } from 'utils/array';
import { extractErrorMessage } from 'utils/api';
import { getPageQueryHistory } from 'utils/pqlHistory';
import { updateQueryString, parseQueryString } from 'utils/url';
import { transformStringToKey } from 'utils/pql';

import { PAGES } from 'consts/pages';

import useStore from 'services/store';
import { useApi } from 'services/api';

import PqlState, { initialState } from './PqlState';
import { SEARCH_TYPE } from './consts';

const BAD_PQL_PROPS = ['tenantName', 'sensorName'];
const OPERATORS = ['=', '!=', '>', '>=', '<', '<=', 'like', 'not like'];

export const PqlDispatch = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'TRIGGER_QUERY':
      const now = new Date().getTime();
      // dont trigger within 1.5 second
      // I do not recall why we have this time check, but was initially set at 4 seconds
      // might be due to reruning *same* queries due to some effect hook refiring (ui3/cp-2051)
      if (!state[action.page].lastSearchTs || now - state[action.page].lastSearchTs > 1500) {
        return {
          ...state,
          [action.page]: {
            ...state[action.page],
            lastPqlQuery: action.pqlQuery,
            lastSearchTs: new Date().getTime(),
          },
        };
      } else return state;
    case 'SET_METADATA':
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          lastSearchTimerange: action.lastSearchTimerange,
          timerange: action.timerange,
          t1: action.t1,
          t2: action.t2,
          lastSearch: {
            ...state[action.page].lastSearch,
            [state[action.page].lastSearchTs]: {
              timerange: action.timerange,
            },
          },
        },
      };
    case 'SET_ERROR_METADATA':
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          lastSearchTimerange: action.lastSearchTimerange,
          timerange: action.timerange,
          t1: action.t1,
          t2: action.t2,
          lastSearch: {
            ...state[action.page].lastSearch,
            [state[action.page].lastSearchTs]: {
              error: action.error,
            },
          },
        },
      };
    case 'VALIDATE_START':
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          validating: true,
        },
      };
    case 'VALIDATE_SUCCESS':
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          validating: false,
          timerange: action.timerange,
        },
      };
    case 'VALIDATE_FAIL':
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          validating: false,
        },
      };
    case 'FETCH_SUGGESTIONS_START':
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          cancelSrc: action.cancelSrc,
        },
      };
    case 'SET_SUGGESTIONS':
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          suggestions: action.data,
          cancelSrc: null,
        },
      };
    case 'SET_ATTRIBUTE':
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          lastAttribute: action.lastAttribute,
        },
      };
    case 'SET_POSSIBLE_KEYS':
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          possibleKeys: action.keys,
        },
      };
    case 'RESET_SUGGESTIONS':
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          suggestions: [],
        },
      };
    default:
      return state;
  }
};

// returns an array of timeranges that match input string
const compileTrSuggestions = (everything, input) => {
  return _uniq(everything.filter((x) => x.indexOf(input) !== -1));
};

//
const compileAttrSuggestions = (history, input, props) => {
  let list = history.filter((x) => x.indexOf(input) !== -1);

  props &&
    props.forEach((prop) => {
      // only append props that are not already part of the input string
      if (input.indexOf(prop) === -1 && !BAD_PQL_PROPS.includes(prop)) {
        list.push(`${input.trim()} ${prop}`);
      }
    });

  return _uniq(list);
};

// adds history and combinations of input with array of `newValues`
const compileSuggestions = (history, input, newValues, wrap = false) => {
  let list = history.filter((x) => x.indexOf(input) !== -1);

  newValues.forEach((x) => {
    const str = wrap ? `${input.trim()} "${x}"` : `${input.trim()} ${x}`;
    list.push(str);
  });

  return _uniq(list);
};

const endsWithOperator = (input) => !!OPERATORS.find((o) => _endsWith(input, o));
const endsWithAttribute = (attrs, input) => attrs && attrs.includes(last(input.split(' ')));

export const PqlStateProvider = ({ children }) => {
  const api = useApi();
  const [state, dispatch] = useReducer(reducer, initialState);
  const timezone = useStore((state) => state.searchQuery.timezone);

  const validateOnApi = (page, query) => {
    dispatch({ type: 'VALIDATE_START', page });
    const opts = {
      context: [],
      searchType: SEARCH_TYPE[page],
    };
    return api
      .get('/search/suggest', {
        params: { query, timezone, ...opts },
      })
      .then((result) => {
        dispatch({
          type: 'VALIDATE_SUCCESS',
          timerange: result.compiled.daterange,
          pqlInput: result.compiled.input,
          t1: result.compiled.t1 * 1000,
          t2: result.compiled.t2 * 1000,
          page,
        });
        return result;
      })
      .catch(() => {
        dispatch({ type: 'VALIDATE_FAIL', page });
      });
  };

  const fetchAttrValues = (page, attr) => {
    // cancel previous search if in progress
    if (state[page].cancelSrc) {
      state[page].cancelSrc.cancel();
    }

    // setup cancel token
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    dispatch({ type: 'FETCH_SUGGESTIONS_START', cancelSrc: source, page });

    const opts = {
      context: [],
      searchType: SEARCH_TYPE[page],
    };
    const pqlString = `${state[page].timerange} | countby ${attr} limit 12`;
    return api.get('/search/query', {
      params: { query: pqlString, timezone, ...opts },
    });
  };

  const getSuggestions = (page, input) => {
    const history = getPageQueryHistory(page);

    const alreadyValidatedTimerange = input?.indexOf(state[page].timerange) !== -1;
    if (!state[page].validating && !alreadyValidatedTimerange) {
      validateOnApi(page, input);
    }

    const hasTimerange = state[page].timerange && input?.indexOf(state[page].timerange) === 0;

    // first suggest timerange
    if (!hasTimerange) {
      dispatch({
        type: 'SET_SUGGESTIONS',
        data: compileTrSuggestions([...state[page].timeranges, ...history], input),
        page,
      });
      return;
    }

    if (endsWithOperator(input.trim())) {
      state[page].lastAttribute &&
        fetchAttrValues(page, state[page].lastAttribute)
          .then((res) => {
            let values = [];
            res.results.forEach((item) => {
              if (page === PAGES.investigator) {
                // response is different
                if (item.key && item.key.length !== '') {
                  values.push(item.key);
                }
              } else if (item[state[page].lastAttribute] && item[state[page].lastAttribute] !== '') {
                values.push(item[state[page].lastAttribute]);
              } else if (item.unnest && item.unnest !== '') {
                // and then sometimes from postgres, we get "unnest" as a key
                values.push(item.unnest);
              }
            });
            // list of prop VALUES
            dispatch({
              type: 'SET_SUGGESTIONS',
              data: compileSuggestions(history, input, values, true),
              page,
            });
          })
          .catch(() => {});
      return;
    }

    // if the last "word" is a prop, suggest an operator
    if (endsWithAttribute(state[page].possibleKeys, input.trim())) {
      dispatch({
        type: 'SET_SUGGESTIONS',
        data: compileSuggestions(history, input, OPERATORS),
        page,
      });
      dispatch({ type: 'SET_ATTRIBUTE', lastAttribute: last(input.trim().split(' ')), page });
      return;
    }

    // fell through, start a new attribute suggestion
    if (hasTimerange && last(input) === ' ') {
      // if (hasTimerange) {
      // compile the list of suggestions appending to timerange
      dispatch({
        type: 'SET_SUGGESTIONS',
        data: compileAttrSuggestions(history, input, state[page].logProperties),
        page,
      });
      return;
    }

    // dont change suggestion list anymore, let it use what's compiled
  };

  const extractPqlTimerange = async (query) => {
    const res = await api.get('/search/suggest', {
      params: { query, timezone },
    });

    return res.compiled.daterange;
  };

  // reset
  const resetSuggestions = (page) => {
    dispatch({ type: 'RESET_SUGGESTIONS', page });
  };

  // should not contain any nonfunction other than `state`
  const value = {
    state,
    validateOnApi,
    getSuggestions,
    // addSuggestionsFromSearchResults,
    resetSuggestions,
    extractPqlTimerange,
  };

  return (
    <PqlDispatch.Provider value={dispatch}>
      <PqlState.Provider value={value}>{children}</PqlState.Provider>
    </PqlDispatch.Provider>
  );
};

export const usePqlActions = (page) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useContext(PqlDispatch);
  const queryClient = useQueryClient();

  // updates query string in url and
  const triggerPqlQuery = React.useCallback(
    /**
     * @param {string} query - MQL query
     * @param {boolean} [force=false] - If set, reset the search cache before executing the query
     */
    (query, force = false) => {
      if (force) {
        queryClient.resetQueries(transformStringToKey(window.location.pathname));
      }

      const currentQuery = parseQueryString(location.search).q;

      if (currentQuery) {
        if (query !== currentQuery) {
          // TODO: wrapping this in setTimeout removes the warning from react-router-dom
          // https://github.com/remix-run/react-router/issues/7460
          setTimeout(() => {
            navigate(updateQueryString('q', query));
          }, 0);
        }
      } else {
        // If there is no query it's an intermediary
        // URL. So we dont want to push to history to preserve
        // the "back-button" functionality
        setTimeout(() => {
          navigate(updateQueryString('q', query), {
            replace: true,
          });
        }, 0);
      }
      if (query && query.length > 0) {
        dispatch({ type: 'TRIGGER_QUERY', page, pqlQuery: query });
      }
    },
    [dispatch, navigate, location.search, page, queryClient],
  );

  const setSearchResultMetadata = React.useCallback(
    (result) => {
      dispatch({
        type: 'SET_METADATA',
        lastSearchTimerange: result.compiled.daterange,
        timerange: result.compiled.daterange,
        pqlInput: result.compiled.input,
        t1: result.compiled.t1 * 1000,
        t2: result.compiled.t2 * 1000,
        page,
      });
    },
    [dispatch, page],
  );

  const setSearchErrorMetadata = React.useCallback(
    (error) => {
      dispatch({
        type: 'SET_ERROR_METADATA',
        error: extractErrorMessage(error),
        page,
      });
    },
    [dispatch, page],
  );

  return {
    triggerPqlQuery,
    setSearchResultMetadata,
    setSearchErrorMetadata,
  };
};

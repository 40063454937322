import { useState, useEffect, useRef, useLayoutEffect, useReducer, useCallback, useMemo } from 'react';
import _debounce from 'lodash/debounce';
import _uniq from 'lodash/uniq';

import { getWindowWidth } from 'utils/window';

// make sure to run only once
export const useConstant = (fn) => {
  const ref = useRef();
  if (!ref.current) {
    ref.current = { v: fn() };
  }
  return ref.current.v;
};

/**
 * const prevProp = usePrevious(prop)
 */
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const windowSizeEvents = new Set();
const onResize = () => windowSizeEvents.forEach((fn) => fn());

export const useDebouncedWindowSize = (debounceTime) => {
  const [size, setSize] = useState(
    useConstant(() => ({
      width: getWindowWidth(),
      height: window.innerHeight,
    })),
  );

  const handler = _debounce(() => {
    setSize({
      width: getWindowWidth(),
      height: window.innerHeight,
    });
  }, debounceTime);

  useEffect(() => {
    if (windowSizeEvents.size === 0) {
      window.addEventListener('resize', onResize);
    }
    windowSizeEvents.add(handler);

    return () => {
      windowSizeEvents.delete(handler);

      if (windowSizeEvents.size === 0) {
        window.removeEventListener('resize', onResize);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return size;
};

// wrapping js setInterval
// why? https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useTimeout(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
  }, [delay]);
}

// debouncing a value
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useEffectOnMount(callback) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const cleanup = savedCallback.current();
    if (typeof cleanup === 'function') {
      return cleanup;
    }
  }, []);
}

export const useUpdateReason = (name, props) => {
  const previousProps = useRef();

  useEffect(() => {
    if (previousProps.current) {
      const keysTable = {};

      for (const key in previousProps.current) {
        keysTable[key] = true;
      }
      for (const key in props) {
        keysTable[key] = true;
      }

      const allKeys = Object.keys(keysTable);
      const changesTable = {};

      for (const key of allKeys) {
        if (previousProps.current[key] !== props[key]) {
          changesTable[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      }

      if (Object.keys(changesTable).length) {
        // eslint-disable-next-line no-console
        console.log('%c [Update Reason]', 'font-weight: bold', name, changesTable);
        // eslint-disable-next-line no-console
        console.count(name);
      }
    }

    previousProps.current = props;
  });
};

export const useToggle = (initialState = false) => {
  const [isOn, _setIsOn] = useState(initialState);

  return useMemo(
    () => ({
      isOn,
      set: (s) => _setIsOn(s),
      toggle: () => _setIsOn((s) => !s),
      setOn: () => _setIsOn(true),
      setOff: () => _setIsOn(false),
    }),
    [isOn],
  );
};

// https://usehooks.com/useOnClickOutside/
export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export function useLockBodyScroll(active = true) {
  useLayoutEffect(() => {
    if (active) {
      const tmp = document.body.dataset.lockStack;
      const newVal = tmp ? +tmp + 1 : 1;
      document.body.dataset.lockStack = newVal;

      if (newVal > 0) {
        document.body.classList.add('scroll-lock');
      }
    }

    return () => {
      if (!active) {
        return;
      }

      const tmp = document.body.dataset.lockStack;
      const newVal = tmp ? +tmp - 1 : 0;
      document.body.dataset.lockStack = newVal;

      if (newVal < 1) {
        document.body.classList.remove('scroll-lock');
      }
    };
  }, [active]);
}

export function useBlockHorizontalOverscroll(active = true) {
  useLayoutEffect(() => {
    if (active) {
      document.body.classList.add('block-horizontal-overscroll');
    }

    return () => {
      document.body.classList.remove('block-horizontal-overscroll');
    };
  }, [active]);
}

const formReducer = (state, action) => {
  if (action.type === 'RESET') {
    return action.payload || {};
  }

  return {
    ...state,
    [action.key]: action.value,
  };
};

const touchedReducer = (state, action) => {
  if (action.type === 'RESET') {
    return {};
  }

  return {
    ...state,
    [action.key]: true,
  };
};

export const useForm = ({ initialValues = {}, validationSchema, validate, onSubmit }) => {
  const [state, dispatch] = useReducer(formReducer, initialValues);
  const [touched, dispatchTouched] = useReducer(touchedReducer, {});
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = useCallback(() => {
    if (validate) {
      return validate(state);
    }
    if (validationSchema) {
      return validationSchema.validate(state);
    }
  }, [state, validate, validationSchema]);

  useEffect(() => {
    if (!submitted || !(validationSchema || validate)) {
      return;
    }

    setErrors(validateForm());
  }, [state, submitted, validate, validateForm, validationSchema]);

  const handleChange = useCallback((eventOrValue, name) => {
    let key, value;
    if (eventOrValue?.nativeEvent instanceof Event) {
      const event = eventOrValue;
      event.persist?.();

      key = event.target.name;
      value = event.target.value;
    } else {
      if (name === undefined) {
        throw new TypeError('Invalid handleChange usage: name is undefined');
      }
      key = name;
      value = eventOrValue;
    }

    dispatchTouched({ key });
    dispatch({ key, value });
  }, []);

  const submitForm = useCallback(
    (data) => {
      setSubmitted(true);

      const errors = validateForm() || {};

      if (Object.keys(errors).length > 0) {
        setErrors(errors);

        return;
      }

      onSubmit(state, { setIsSubmitting, data });
    },
    [validateForm, onSubmit, state, setIsSubmitting],
  );

  const validateField = (value, field) => {
    const error = validationSchema.validate({ [field]: value });

    if (!value) {
      setErrors((prev) => ({ ...prev, ...error }));
      return;
    }

    setErrors((prev) => {
      const { [field]: _, ...rest } = prev;
      return rest;
    });
  };

  const resetForm = (defaultValues = {}) => {
    const resetValues = { ...initialValues, ...defaultValues };
    dispatch({ type: 'RESET', payload: resetValues });
    dispatchTouched({ type: 'RESET' });
    setErrors({});
    setSubmitted(false);
    setIsSubmitting(false);
  };

  return {
    handleChange,
    values: state,
    touched,
    errors,
    submitted,
    handleSubmit: submitForm,
    isSubmitting,
    validateField,
    resetForm,
  };
};

/**
 * simple hook for adding and removing objects shaped { propKey, value } from an array
 * useful for quick filter lists
 */
export const useToggleableCollection = (initialState = []) => {
  const [state, setState] = useState(initialState);

  const toggleItem = ({ propKey, value }) => {
    const tmpState = state.filter((x) => x.propKey !== propKey || x.value !== value);
    if (tmpState.length === state.length) {
      // nothing lost on filter
      tmpState.push({ propKey, value });
    }
    setState(tmpState);
  };

  return [state, toggleItem];
};

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}

/**
 * Wrapper around the useReducer hook
 * Useful when using local state handlers
 */
export function useSafeReducer(...args) {
  const [state, internalDispatch] = useReducer(...args);
  const isMounted = useIsMounted();

  const dispatch = useCallback(
    (...args) => {
      if (isMounted()) {
        internalDispatch(...args);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [internalDispatch],
  );

  return [state, dispatch];
}

/**
 * Acts as a multiselect actions and data provider with internal storage
 *
 * @param {Array} items - Array of item IDs
 */
export const useMultiselect = (items) => {
  const [selected, setSelected] = useState([]);

  const selectAll = useCallback(() => {
    setSelected([...items]);
  }, [items]);

  const deselectAll = useCallback(() => {
    setSelected([]);
  }, []);

  const selectItems = useCallback((itemIds) => {
    setSelected((prev) => _uniq(prev.concat(itemIds)));
  }, []);

  const deselectItems = useCallback((itemIds) => {
    setSelected((prev) => prev.filter((id) => !itemIds.includes(id)));
  }, []);

  const isItemSelected = useCallback(
    (itemId) => {
      return selected.includes(itemId);
    },
    [selected],
  );

  const toggleItem = useCallback(
    (itemId) => {
      if (isItemSelected(itemId)) {
        setSelected((prev) => prev.filter((id) => id !== itemId));
      } else {
        setSelected((prev) => prev.concat(itemId));
      }
    },
    [isItemSelected],
  );

  const value = useMemo(
    () => ({
      totalItems: items.length || 0,
      selected,
      selectedCount: selected.length,
      areAllItemsSelected: selected.length === (items?.length || 0),
      selectItems,
      deselectItems,
      selectAll,
      deselectAll,
      isItemSelected,
      toggleItem,
    }),
    [items.length, selected, selectItems, deselectItems, selectAll, deselectAll, isItemSelected, toggleItem],
  );

  return value;
};

export const useKeyboardShortcut = (callback, { key, shiftKey = false, metaKey = false, ctrlKey = false }) => {
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (
        e.key &&
        e.key.toLowerCase() === key.toLowerCase() &&
        e.shiftKey === shiftKey &&
        e.metaKey === metaKey &&
        e.ctrlKey === ctrlKey
      ) {
        callback();
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [callback, key, shiftKey, metaKey, ctrlKey]);
};

export const useResizeObserver = () => {
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setDimensions({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      });
    });

    if (observeTarget) {
      resizeObserver.observe(observeTarget);
    }

    return () => {
      if (observeTarget) {
        resizeObserver.unobserve(observeTarget);
      }
    };
  }, [ref]);

  /**
   * @typedef {React.MutableRefObject<HTMLDivElement>} Ref
   * @typedef {{ width: number, height: number }} Dimensions
   * @typedef {[Ref, Dimensions]} Result
   * @type {Result}
   */
  const result = [ref, dimensions];

  // In today's episode of Tips, Trix, and Hax:
  // if we just do return [ref, dimensions] it will cause a TS error...
  return result;
};

import { css, keyframes } from 'styled-components';

/**
 * animation keyframes
 **/
export const blink = keyframes`
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
`;

export const pulse = keyframes`
  0% { opacity: 0.4; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0.4; }
`;

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

export const hinge = keyframes`
  0% {
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    transform: rotate3d(0, 0, 1, 80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  40%,
  80% {
    transform: rotate3d(0, 0, 1, 60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
`;

export const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const fadeInLeft = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const fade = keyframes`
  from {
    opacity: 0.2;
  }
  to {
    opacity: 0.8;
  }
`;

export const reverseFade = keyframes`
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0.2;
  }
`;

/**
 * animations
 **/

export const blinkAnimation = css`
  ${blink} 1s linear infinite;
`;

export const pulseAnimation = css`
  ${pulse} 1.6s ease-in-out infinite;
`;

export const slowSpinAnimation = css`
  ${spin} 2s linear infinite;
`;

export const fastSpinAnimation = css`
  ${spin} 0.75s linear infinite;
`;

export const hingeAnimation = css`
  ${hinge} 3s;
`;

export const fadeInDownAnimation = css`
  ${fadeInDown} 0.3s;
`;

import React, { useReducer } from 'react';
import _findIndex from 'lodash/findIndex';
import _pullAt from 'lodash/pullAt';
import _concat from 'lodash/concat';
import _forEach from 'lodash/forEach';

import log from 'services/log';
import { useApi } from 'services/api';
import * as searchUtils from 'services/search/utils';
import { SEARCH_TYPE } from 'services/search';

import { extractErrorMessage } from 'utils/api';
import { prepareTimerangeQuery, getRecordMQLTimeBoudaries } from 'utils/pql';
import { getTypeAndId, getRecordTitle, getRecordTs } from 'utils/records';
import { EVENT_GENERATION_BUCKET, MAX_CONNECTED_IND_COUNT, MAX_CONNECTED_LOG_COUNT } from 'consts/config';
import { PAGES } from 'consts/pages';

const initialState = {
  record: null,
  recordId: null,
  recordType: null,
  loadingIndicators: false,
  indicators: [],
  indicatorsCountData: null, // { count: number, psdq: string }
  error: null,
  indicatorsData: {},
  //`file-${uid}`: []
  //`count-${flowId}-${propKey}`: []
  //`${logsQuery}`: []
};

export const RecordState = React.createContext({
  ...initialState,
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INDICATORS_START':
      return {
        ...state,
        indicators: {
          ...state.indicators,
          [action.eventId]: {
            ...(state.indicators?.[action.eventId] || {}),
            loading: true,
            error: null,
          },
        },
      };
    case 'FETCH_INDICATORS_SUCCESS':
      return {
        ...state,
        indicators: {
          ...state.indicators,
          [action.eventId]: {
            ...state.indicators?.[action.eventId],
            loading: false,
            error: null,
            data: action.data,
          },
        },
      };
    case 'FETCH_INDICATORS_FAIL':
      return {
        ...state,
        indicators: {
          ...state.indicators,
          [action.eventId]: {
            ...state.indicators?.[action.eventId],
            loading: false,
            error: action.error,
          },
        },
      };
    case 'FETCH_INDICATORS_COUNT_SUCCESS':
      return {
        ...state,
        indicators: {
          ...state.indicators,
          [action.eventId]: {
            ...(state.indicators?.[action.eventId] || {}),
            count: action.count,
            psdq: action.psdq,
          },
        },
      };
    case 'FETCH_LOGS_START':
      return {
        ...state,
        [action.flowId]: {
          ...state[action.flowId],
          loadingLogs: true,
          error: null,
          logs: [],
        },
      };
    case 'FETCH_LOGS_SUCCESS':
      return {
        ...state,
        [action.flowId]: {
          ...state[action.flowId],
          loadingLogs: false,
          error: null,
          logs: action.result,
        },
      };
    case 'FETCH_LOGS_COUNT_SUCCESS':
      return {
        ...state,
        [action.flowId]: {
          ...state[action.flowId],
          count: action.count,
          psdq: action.psdq,
        },
      };
    case 'FETCH_LOGS_FAIL':
      return {
        ...state,
        [action.flowId]: {
          ...state[action.flowId],
          loadingLogs: false,
          error: action.error,
          logs: [],
        },
      };
    case 'FETCH_FILE_START':
      return {
        ...state,
        [`file-${action.filename}`]: {
          loadingFile: true,
          error: null,
          logs: [],
        },
      };
    case 'FETCH_FILE_SUCCESS':
      return {
        ...state,
        [`file-${action.filename}`]: {
          loadingFile: false,
          error: null,
          logs: action.payload,
        },
      };
    case 'FETCH_FILE_FAIL':
      return {
        ...state,
        [`file-${action.filename}`]: {
          loadingFile: false,
          error: action.error,
          logs: [],
        },
      };
    case 'FETCH_PROPCOUNT_SUCCESS':
      return {
        ...state,
        [`count-${action.flowId}-${action.propKey}`]: {
          propKey: action.propKey,
          logs: action.payload,
          error: null,
        },
      };
    case 'FETCH_PROPCOUNT_FAIL':
      return {
        ...state,
        [`count-${action.flowId}-${action.propKey}`]: {
          propKey: action.propKey,
          logs: [],
          error: action.error,
        },
      };

    case 'LOAD_RECORD':
      return {
        ...state,
        [action.recordName]: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case 'FOCUS_RECORD':
    case 'LOAD_RECORD_SUCCESS':
    case 'UPDATE_RECORD':
      return {
        ...state,
        [action.recordName]: {
          loading: false,
          data: action.data,
          error: null,
        },
      };
    case 'LOAD_RECORD_FAIL':
      return {
        ...state,
        [action.recordName]: {
          loading: false,
          data: null,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

// we use this in log timeline
const reorderLogs = (logs) => {
  let list = logs;
  // push conn at the end
  const connIndex = _findIndex(logs, (log) => log.log === 'conn');
  if (connIndex > -1) {
    const connLog = _pullAt(logs, connIndex);
    list = _concat(logs, connLog);
  }

  return list;
};

const getIndicatorTimerangeQuery = (ts) => {
  return prepareTimerangeQuery(ts, ts + EVENT_GENERATION_BUCKET);
};

export function RecordProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const api = useApi();

  const fetchRecordDetails = (recordName, recordTimestamp) => {
    dispatch({ type: 'LOAD_RECORD', recordName });

    const [recordType, id] = getTypeAndId(recordName);

    const opts = {
      searchType: SEARCH_TYPE[recordType],
    };

    let pqlString = `id="${id}"`;

    if (recordTimestamp) {
      const ts = +recordTimestamp * 1000;

      const dateRange = getRecordMQLTimeBoudaries(ts);

      pqlString = `${dateRange} ${pqlString}`;
    }

    api
      .get('/search/query', { params: { query: pqlString, timezone, ...opts } })
      .then((response) => {
        if (response.results.length) {
          const processedResults = searchUtils.processResults(SEARCH_TYPE[recordType], response.results);
          dispatch({ type: 'LOAD_RECORD_SUCCESS', recordName, data: processedResults[0] });
        } else {
          log({
            type: 'warning',
            origin: 'RecordProvider - LOAD_RECORD_SUCCESS',
            message: 'Query for record returned empty response',
            info: {
              href: window.location.href,
            },
          });

          dispatch({ type: 'LOAD_RECORD_FAIL', recordName, error: 'NO_RESULT_ERROR' });
        }
      })
      .catch((error) => {
        dispatch({ type: 'LOAD_RECORD_FAIL', recordName, error: extractErrorMessage(error) });
      });
  };

  const fetchIndicators = (record, pql, limit = null, context = []) => {
    const ts = getRecordTs(record);

    dispatch({ type: 'FETCH_INDICATORS_START', eventId: record.eventId });

    const timerangePql = getIndicatorTimerangeQuery(ts);
    const opts = {
      context,
      searchType: SEARCH_TYPE.indicators,
    };
    const pqlString = limit ? `${timerangePql} ${pql} limit ${limit}` : `${timerangePql} ${pql}`;

    api
      .get('/search/query', { params: { query: pqlString, timezone, ...opts } })
      .then((response) => {
        const processedResults = searchUtils.processResults(SEARCH_TYPE.indicators, response.results);

        if (response.results.length === 0) {
          // it is weird to not get results back
          log({
            type: 'warning',
            origin: 'RecordProvider - FETCH_INDICATORS_SUCCESS',
            message: 'Indicator call returned empty response',
            info: {
              pql: pqlString,
            },
          });
        }

        dispatch({
          type: 'FETCH_INDICATORS_SUCCESS',
          eventId: record.eventId,
          data: processedResults,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_INDICATORS_FAIL',
          eventId: record.eventId,
          error: extractErrorMessage(error),
        });
      });
  };

  // limits the response, but fetches total count
  const fetchIndicatorsLimited = (record, pql) => {
    const ts = getRecordTs(record);
    const timerangePql = getIndicatorTimerangeQuery(ts);
    const opts = {
      searchType: SEARCH_TYPE.indicators,
    };
    const pqlString = `${timerangePql} ${pql} sensorId = ${record.sensorId} | countby sensorId`;

    api
      .get('/search/query', { params: { query: pqlString, timezone, ...opts } })
      .then((response) => {
        const count = response.results[0].count;
        dispatch({
          type: 'FETCH_INDICATORS_COUNT_SUCCESS',
          eventId: record.eventId,
          count: count,
          psdq: `${timerangePql} ${pql} sensorId = ${record.sensorId}`,
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('error', error);
      });

    fetchIndicators(record, pql, MAX_CONNECTED_IND_COUNT, [record.sensorId]);
  };

  const fetchLogsLimited = (uid, psdq = null, ts = null) => {
    const flowId = psdq || uid;
    if (state[flowId]) {
      // we already fetched this
      return;
    }

    const opts = {
      // context: state.sensors || [],
      searchType: SEARCH_TYPE.investigator,
    };
    let pql = psdq || `uid="${uid}" or conn_uids="${uid}"`;

    if (!psdq && ts) {
      const dateRange = getRecordMQLTimeBoudaries(ts * 1000);
      pql = `${dateRange} ${pql}`;
    }

    const pqlString = `${pql} | countby sensorId`;

    api
      .get('/search/query', { params: { query: pqlString, timezone, ...opts } })
      .then((response) => {
        const count = response.results[0].doc_count;
        dispatch({ type: 'FETCH_LOGS_COUNT_SUCCESS', flowId, count, psdq: `${pql}` });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('error', error);
      });
    // now fetch logs
    fetchLogs(uid, psdq, MAX_CONNECTED_LOG_COUNT, ts);
  };

  const fetchLogs = (uid, psdq = null, limit = null, ts = null) => {
    const flowId = psdq || uid;
    dispatch({ type: 'FETCH_LOGS_START', flowId });

    const opts = {
      // context: state.sensors || [],
      searchType: SEARCH_TYPE.investigator,
    };

    let pql = psdq || `uid="${uid}" or conn_uids="${uid}"`;

    if (!psdq && ts) {
      const dateRange = getRecordMQLTimeBoudaries(ts * 1000);
      pql = `${dateRange} ${pql}`;
    }

    const pqlString = limit ? `${pql} limit ${limit}` : `${pql}`;

    api
      .get('/search/query', { params: { query: pqlString, timezone, ...opts } })
      .then((response) => {
        const processedResults = searchUtils.processResults(SEARCH_TYPE.investigator, response.results);

        const reorderedLogs = reorderLogs(processedResults);

        if (response.results.length === 0) {
          // it is weird to not get results back
          log({
            type: 'warning',
            origin: 'RecordProvider - FETCH_LOGS_SUCCESS',
            message: 'Log call returned empty response',
            info: {
              pql: pqlString,
            },
          });
        }
        dispatch({ type: 'FETCH_LOGS_SUCCESS', flowId, result: reorderedLogs });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_LOGS_FAIL', flowId, error: extractErrorMessage(error) });
      });
  };

  const fetchFile = (filename, sensorId) => {
    dispatch({ type: 'FETCH_FILE_START', filename });
    const opts = {
      context: [sensorId], // limit to exact sensor
      searchType: SEARCH_TYPE.investigator,
    };

    const pqlString = `log=psfile_analytics filename="${filename}"`;
    api
      .get('/search/query', { params: { query: pqlString, timezone, ...opts } })
      .then((response) => {
        const processedResults = searchUtils.processResults(SEARCH_TYPE.investigator, response.results);
        if (response.results.length !== 1) {
          log({
            type: 'warning',
            origin: 'RecordProvider - FETCH_FILE_SUCCESS',
            message: `Files call returned ${response.results.length} results`,
            info: { pql: pqlString },
          });
        }
        dispatch({ type: 'FETCH_FILE_SUCCESS', filename, payload: processedResults });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FILE_FAIL', filename, error: extractErrorMessage(error) });
      });
  };

  // digs through the state to see if we ever fetched the log in full
  const findRecordById = (recordType, recordId) => {
    let record = null;
    _forEach(state, (col, key) => {
      if (key === PAGES.indicators) {
        // look in indicators
        record = col.find((r) => r.id.toString() === recordId.toString());
      } else {
        // look through files
        // look through logs fetched by ps_defining_query
        record =
          col && col.logs && col.logs.length
            ? col.logs.find((r) => r._id && recordId && r._id.toString() === recordId.toString())
            : null;
      }
      if (record) {
        // exit early
        return false;
      }
    });
    return record;
  };

  // flowId = psdq || uid
  const fetchPropCount = (flowId, propKey) => {
    const opts = {
      searchType: 'O_SENSOR',
    };
    const pqlString = `${flowId} | countby ${propKey}`;
    api
      .get('/search/query', { params: { query: pqlString, timezone, ...opts } })
      .then((response) => {
        dispatch({
          type: 'FETCH_PROPCOUNT_SUCCESS',
          flowId,
          propKey,
          payload: response.results,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FETCH_PROPCOUNT_FAIL',
          flowId,
          propKey,
          error: extractErrorMessage(error),
        });
      });
  };

  const getRecordByName = (recordName, recordTimestamp) => {
    if (!recordName) {
      return {
        loading: false,
        data: null,
        error: null,
      };
    }
    if (!state[recordName]) {
      fetchRecordDetails(recordName, recordTimestamp);
      return {
        loading: true,
        error: null,
        data: null,
      };
    }

    return state[recordName];
  };

  const updateRecord = (record) => {
    dispatch({ type: 'UPDATE_RECORD', data: record, recordName: getRecordTitle(record) });
  };

  const value = {
    ...state,
    dispatch,
    findRecordById,
    fetchRecordDetails,
    fetchIndicatorsLimited,
    fetchLogs,
    fetchLogsLimited,
    fetchFile,
    fetchPropCount,
    getRecordByName,
    updateRecord,
  };

  return <RecordState.Provider value={value}>{children}</RecordState.Provider>;
}

export default RecordProvider;

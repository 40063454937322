import React, { useReducer, useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';

import log from 'services/log';
import * as http from 'services/http';
import { useApi, getApiHttpPath } from 'services/api';
import filterSensorsByDebugFlag from 'services/sensor/filterSensorsByDebugFlag';
import { getColorFromSet } from 'utils/colors';
import { extractErrorMessage } from 'utils/api';

const initialState = {
  fetchedSensors: null,
  creating: false,
  created: null,
  createError: null,
  loading: false,
  loadError: null,
  settingMode: false,
  setModeError: null,
  upserting: false,
  upsertError: null,
  deletingSensor: false,
  deleteError: null,
  downloadError: null,
};

export const SensorsDispatch = React.createContext();

export const SensorsApiContext = React.createContext({
  state: {
    ...initialState,
  },
  createSensor: () => {},
  getSensors: () => {},
  setSensorMode: () => {},
  upsertSensor: () => {},
  deleteSensor: () => {},
  getSensorFileProxy: () => {},
  getSensorPcap: () => {},
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'CREATE_SENSOR':
      return { ...state, creating: true, createError: null };
    case 'CREATE_SENSOR_SUCCESS':
      return {
        ...state,
        creating: false,
        created: action.newSensor.id,
      };
    case 'CREATE_SENSOR_FAIL':
      return {
        ...state,
        creating: false,
        createError: action.error,
      };
    case 'GET_SENSORS':
      return { ...state, loading: true };
    case 'GET_SENSORS_SUCCESS':
      return {
        ...state,
        loading: false,
        fetchedSensors: action.fetchedSensors,
      };
    case 'GET_SENSORS_FAIL':
      return {
        ...state,
        loading: false,
        fetchedSensors: null,
        loadError: action.error,
      };
    case 'SET_MODE':
      return {
        ...state,
        settingMode: true,
      };
    case 'SET_MODE_SUCCESS':
      const currentSensorId = state.fetchedSensors.findIndex((s) => s.id === action.sensor.id);

      return {
        ...state,
        settingMode: false,
        fetchedSensors: [
          ...state.fetchedSensors.slice(0, currentSensorId),
          {
            ...action.sensor,
            mode: action.mode,
          },
          ...state.fetchedSensors.slice(currentSensorId + 1),
        ],
      };
    case 'SET_MODE_FAIL':
      return {
        ...state,
        settingMode: false,
        setModeError: action.error,
      };
    case 'UPSERT_SENSOR':
      return {
        ...state,
        upserting: true,
        upsertError: null,
      };
    case 'UPSERT_SENSOR_SUCCESS':
      return {
        ...state,
        upserting: false,
        fetchedSensors: state.fetchedSensors.map((sensor) => {
          return sensor.id === action.sensor.id ? action.sensor : sensor;
        }),
      };
    case 'UPSERT_SENSOR_FAIL':
      return {
        ...state,
        upserting: false,
        upsertError: action.error,
      };
    case 'DELETE_SENSOR':
      return {
        ...state,
        deletingSensor: true,
        deleteError: null,
      };
    case 'DELETE_SENSOR_SUCCESS':
      return {
        ...state,
        deletingSensor: false,
        fetchedSensors: state.fetchedSensors.filter((sensor) => sensor.id !== action.sensor.id),
      };
    case 'DELETE_SENSOR_FAIL':
      return {
        ...state,
        deletingSensor: false,
        deleteError: action.error,
      };
    case 'DOWNLOAD_ERROR':
      return {
        ...state,
        downloadError: action.message,
      };
    case 'RESET_DOWNLOAD_ERROR':
      return {
        ...state,
        downloadError: null,
      };
    case 'CLEAR_ERRORS':
      return {
        ...state,
        createError: null,
        upsertError: null,
        deleteError: null,
      };
    default:
      return state;
  }
};

export function SensorsStateProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const theme = useContext(ThemeContext);
  const api = useApi();

  const createSensor = async (sensor, tenant, profile, onSuccess) => {
    dispatch({ type: 'CREATE_SENSOR' });

    try {
      const newSensor = await api.post(`/tenants/${tenant.id}/smf/sensors`, {
        ...sensor,
        mode: 'stopped', // api default value is "running"
        labels: sensor.labels.split(' '),
      });

      await api.post(`/tenants/${tenant.id}/smf/sensors/${newSensor.id}/profile/${profile.id}`);

      !!onSuccess && (await onSuccess(newSensor));

      dispatch({ type: 'CREATE_SENSOR_SUCCESS', newSensor });

      return newSensor;
    } catch (error) {
      dispatch({ type: 'CREATE_SENSOR_FAIL', error: extractErrorMessage(error) });

      return Promise.reject(error);
    }
  };

  const getSensors = () => {
    if (state.loading) {
      return Promise.resolve();
    }

    dispatch({ type: 'GET_SENSORS' });

    return api
      .get('/sensors', {})
      .then((response) => {
        setFetchedSensors(response);
      })
      .catch((error) => {
        dispatch({ type: 'GET_SENSORS_FAIL', error: extractErrorMessage(error) });
        log({
          type: 'error',
          origin: 'SensorsProvider',
          message: JSON.stringify(error),
        });
      });
  };

  const setFetchedSensors = ({ items }) => {
    for (const sensor of items) {
      if (!sensor.Profile?.profileType) {
        log({
          type: 'error',
          origin: 'SensorsProvider',
          message: 'ProfileType not defined for sensor',
          info: JSON.stringify(sensor),
        });
      }
    }

    const coloredSensors = items.map((x, idx) => ({
      ...x,
      color: getColorFromSet(idx, theme.colors.source),
    }));

    dispatch({ type: 'GET_SENSORS_SUCCESS', fetchedSensors: filterSensorsByDebugFlag(coloredSensors) });
  };

  const setSensorMode = (sensor, mode) => {
    dispatch({ type: 'SET_MODE' });

    return api
      .post(`/tenants/${sensor.Tenant.id}/smf/sensors/${sensor.id}/mode/${mode}`)
      .then(() => {
        dispatch({ type: 'SET_MODE_SUCCESS', sensor, mode });
      })
      .catch((error) => {
        dispatch({ type: 'SET_MODE_FAIL', error: extractErrorMessage(error) });
      });
  };

  const upsertSensor = (sensor) => {
    dispatch({ type: 'UPSERT_SENSOR' });

    return api
      .post(`/tenants/${sensor.Tenant.id}/smf/sensors/${sensor.id}`, sensor)
      .then(() => {
        dispatch({ type: 'UPSERT_SENSOR_SUCCESS', sensor });
      })
      .catch((error) => {
        dispatch({ type: 'UPSERT_SENSOR_FAIL', error: extractErrorMessage(error) });
        throw error;
      });
  };

  const deleteSensor = (sensor) => {
    dispatch({ type: 'DELETE_SENSOR' });

    return api
      .delete(`/tenants/${sensor.Tenant.id}/smf/sensors/${sensor.id}`)
      .then(() => dispatch({ type: 'DELETE_SENSOR_SUCCESS', sensor }))
      .catch((error) => {
        dispatch({ type: 'DELETE_SENSOR_FAIL', error: extractErrorMessage(error) });
        return Promise.reject(error);
      });
  };

  const getSensorFileProxy = ({ tenantId, sensorId, filename }) => {
    const basePath = getApiHttpPath();
    const path = `${basePath}/tenants/${tenantId}/smf/sensors/${sensorId}/file/download?path=${filename}`;

    http.get(path, {}, 'blob', `${filename}.gz`).catch((error) => {
      let message;
      if (error.status === 404) {
        message = 'File not found.';
      } else if (error.status === 401) {
        message = "You don't have the permission to download this file.";
      } else {
        message = error.message;
      }
      dispatch({ type: 'DOWNLOAD_ERROR', message: message });

      setTimeout(() => {
        dispatch({ type: 'RESET_DOWNLOAD_ERROR' });
      }, 3000);
    });
  };

  const getSensorPcap = ({ tenantId, sensorId, payload }) => {
    const basePath = getApiHttpPath();
    const path = `${basePath}/tenants/${tenantId}/smf/sensors/${sensorId}/reachback/ndr`;

    http.post(path, payload, {}, 'blob', `${payload.name}.pcap`).catch((error) => {
      let message;
      if (error.status === 404) {
        message = 'PCAP is not available.';
      } else if (error.status === 401) {
        message = "Download failed: You don't have the permission to download this file.";
      } else {
        message = `Download failed: ${error.message}`;
      }
      dispatch({ type: 'DOWNLOAD_ERROR', message: message });

      setTimeout(() => {
        dispatch({ type: 'RESET_DOWNLOAD_ERROR' });
      }, 3000);
    });
  };

  const clearErrors = () => {
    dispatch({ type: 'CLEAR_ERRORS' });
  };

  const validSensors = useMemo(() => {
    if (!state.fetchedSensors) {
      return state.fetchedSensors;
    }

    return state.fetchedSensors.filter((s) => {
      return s.Profile?.profileType;
    });
  }, [state]);

  const value = {
    state: {
      ...state,
      sensors: validSensors,
      fetchedSensors: state.fetchedSensors,
    },
    createSensor,
    getSensors,
    setSensorMode,
    upsertSensor,
    deleteSensor,
    getSensorFileProxy,
    getSensorPcap,
    clearErrors,
  };

  const dispatchValue = {
    dispatch,
    setFetchedSensors,
  };

  return (
    <SensorsDispatch.Provider value={dispatchValue}>
      <SensorsApiContext.Provider value={value}>{children}</SensorsApiContext.Provider>
    </SensorsDispatch.Provider>
  );
}

import { useContext } from 'react';

import ApiState from 'services/auth/ApiState';

const useApi = () => {
  const api = useContext(ApiState);
  return api;
};

export default useApi;

/**
 * decides if menu item should be visible
 * collection of objects with path and filter cb
 */
export const maybeShowMenuItems = (menuConfig, collection) => {
  let newConfig = menuConfig;
  collection.forEach((entry) => {
    const [, category, alias] = entry.path.split('/');
    if (entry.check) {
      newConfig = _enableItem(newConfig, category, alias);
    } else if ('check' in entry && !entry.check) {
      newConfig = _disableItem(newConfig, category, alias);
    }
  });
  return newConfig;
};

const _enableItem = (menuConfig, category, alias) => {
  let config = menuConfig;
  if (menuConfig[category].items.find((x) => x.alias === alias)?.hidden === true) {
    config = {
      ...menuConfig,
      [category]: {
        ...menuConfig[category],
        items: menuConfig[category].items.map((item) => {
          if (item.alias === alias) {
            item.hidden = false;
          }
          return item;
        }),
      },
    };
  }
  return config;
};

const _disableItem = (menuConfig, category, alias) => {
  let config = menuConfig;
  if (menuConfig[category].items.find((x) => x.alias === alias)?.hidden === false) {
    config = {
      ...menuConfig,
      [category]: {
        ...menuConfig[category],
        items: menuConfig[category].items.map((item) => {
          if (item.alias === alias) {
            item.hidden = true;
          }
          return item;
        }),
      },
    };
  }
  return config;
};

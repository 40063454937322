import { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import useStore from 'services/store';
import { useApi } from 'services/api';
import { extractErrorMessage } from 'utils/api';
import { transformStringToKey } from 'utils/pql';

const transformKey = transformStringToKey;

const useSearchAlternated = ({
  pqlQuery,
  pqlAlternation,
  searchType,
  context,
  registryGroupKey, // optional key for grouping searches
  skipRegistry = false, // optional skip registering search
  transformResponse, // optional transform fn
  onSearchSuccess, // optional onSuccess hook
  onSearchFail, // optional onFail hook
}) => {
  const api = useApi();
  const { activeSensorsByProfile, timezone, registerComponent, deregisterComponent } = useStore((state) => ({
    activeSensorsByProfile: state.searchQuery.activeSensorsByProfile,
    timezone: state.searchQuery.timezone,
    registerComponent: state.searchRegistry.registerComponent,
    deregisterComponent: state.searchRegistry.deregisterComponent,
  }));
  const isEnabled = !!pqlQuery && !!pqlAlternation;

  const pqlQueryAlternated = `${pqlQuery || ''} ${pqlAlternation || ''}`;
  const groupKey = registryGroupKey || transformKey(window.location.pathname);

  const opts = {
    context: context || activeSensorsByProfile || [],
    searchType,
  };

  const { isLoading, data, status, error, ...rest } = useQuery(
    [groupKey, transformKey(pqlQuery), transformKey(pqlAlternation), opts],
    ({ signal }) => {
      return api.get('/search/query', {
        params: { query: pqlQueryAlternated, timezone, ...opts },
        signal,
      });
    },
    {
      enabled: isEnabled,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 15 * 60 * 1000, // 15 minutes before garbage collection
      onSuccess: (data) => {
        return onSearchSuccess ? onSearchSuccess(data) : data;
      },
      onError: (error) => {
        onSearchFail?.(error);
      },
      onSettled: () => deregisterComponent(groupKey, pqlQueryAlternated),
    },
  );

  useEffect(() => {
    return () => {
      !skipRegistry && deregisterComponent(groupKey, pqlQueryAlternated);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupKey, pqlQueryAlternated]);

  useEffect(() => {
    if (isEnabled && status === 'loading' && !skipRegistry) {
      registerComponent(groupKey, pqlQueryAlternated);
    }
  }, [isEnabled, status, skipRegistry, registerComponent, groupKey, pqlQueryAlternated]);

  const processedResults = useMemo(
    () => (transformResponse && data ? transformResponse(data?.results) : data?.results),
    [data, transformResponse],
  );

  return {
    loading: isLoading,
    results: processedResults,
    compiled: data?.compiled,
    timerange: data?.compiled?.daterange,
    error: error ? extractErrorMessage(error) : null,
    ...rest,
  };
};

export default useSearchAlternated;

import { startsWith } from 'utils/string';

export type LogType = 'ERROR' | 'WARNING' | 'INFO';

export function consoleColor(type: LogType) {
  switch (type) {
    case 'ERROR':
      return '#f44336';
    case 'WARNING':
      return '#F1961D';
    case 'INFO':
      return '#617FC8';
    default:
      return '#212121';
  }
}

export function consoleLog(type: LogType, ...args: any[]) {
  const bgColor = consoleColor(type);
  // eslint-disable-next-line no-console, @typescript-eslint/no-unsafe-argument
  console.info(`%cMM ${type}`, `padding: 0 5px; background-color: ${bgColor}; color: white;`, ...args);
}

export function isDevBuild(hostname: string) {
  return ['ui-dev', 'qa-', 'tv-', 'ch-', 'aa-', 'mp-', 'ak-', 'bl-', 'tm-', 'staging-'].some((item) =>
    startsWith(hostname, item),
  );
}

export const shouldInitSentry = import.meta.env.VITE_DEPLOYMENT_TYPE !== 'enterprise' && import.meta.env.PROD;

import { useQuery } from '@tanstack/react-query';

import { useApi } from 'services/api';
import useStore from 'services/store';

import { groupNotifs } from './utils';
import { COMMON_DROPDOWN_MQL } from './config';

const useNotifications = ({ timerange, ...options }) => {
  const api = useApi();
  const timezone = useStore((state) => state.searchQuery.timezone);

  return useQuery({
    queryKey: ['notifications', timezone, timerange],
    queryFn: async () => {
      const response = await api.get('/search/query', {
        params: {
          query: `${timerange} ${COMMON_DROPDOWN_MQL} | countby createdAt, id, sensorId, typeId, typeDescription, severityId, severityName, categoryDescription, acknowledged, @day`,
          context: [],
          searchType: 'O_NOTIFICATIONS',
          timezone,
        },
      });

      return groupNotifs(response.results);
    },
    ...options,
  });
};

export default useNotifications;

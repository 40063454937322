import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

const SectionTitleContainer = styled.div`
  display: flex;
  flex: 1 0 100%;
  font-family: ${({ theme }) => theme.base.fontHeader};
  font-weight: 500;
  align-items: flex-end;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.text.primary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.text.primary};
  height: 30px;
  padding: 10px 0 4px 0;
`;

const SectionTitle = ({ title, children, className }) => {
  const classNames = clsx('old-text-lg mx-0 mb-2 mt-3', className);
  return (
    <SectionTitleContainer className={classNames} data-test-id={`title-${title}`}>
      {title && <div className="flex">{title}</div>}
      {children}
    </SectionTitleContainer>
  );
};

export default SectionTitle;

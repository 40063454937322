import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Container = styled.div`
  border-radius: 50%;
  position: absolute;
  bottom: 1px;
  right: 4px;
  width: 1.1em;
  height: 1.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $type }) =>
    $type === 'solid' &&
    css`
      background-color: ${({ theme }) => theme.palette.danger[1]};
      color: #ffffff;
    `}
  ${({ $type }) =>
    $type === 'number' &&
    css`
      background-color: transparent;
      color: ${({ theme }) => theme.palette.danger[1]};
    `}
`;

const Count = styled.div`
  display: flex;
  font-size: 11px;
  font-weight: 600;
`;

const NotificationCount = ({ count = 0, type = 'solid' }) => (
  <Container $type={type}>
    <Count>{count > 99 ? '99+' : count}</Count>
  </Container>
);

NotificationCount.propTypes = {
  count: PropTypes.number,
  type: PropTypes.oneOf(['solid', 'number']),
};

export default NotificationCount;

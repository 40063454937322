import React, { useContext, useReducer } from 'react';
import { useLocation } from 'react-router';

import { useApi } from 'services/api';
import { usePqlActions } from 'services/search';
import { parseQueryString } from 'utils/url';
import { PAGES } from 'consts/pages';

import { RecordState } from './RecordProvider';

const initialState = {
  loading: false,
  record: null,
};

export const EventIrwState = React.createContext({
  ...initialState,
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_RECORD_START':
      return { ...state, loading: true, record: null };
    case 'UPDATE_RECORD_SUCCESS':
      return { ...state, loading: false, record: action.payload };
    case 'UPDATE_RECORD_FAIL':
      return { ...state, loading: false };
    default:
      return state;
  }
};

export function EventIrwProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const api = useApi();
  const location = useLocation();
  const { updateRecord } = useContext(RecordState);
  const { triggerPqlQuery } = usePqlActions(PAGES.events);

  const forceTriggerPql = () => {
    const qs = parseQueryString(location.search);

    triggerPqlQuery(qs.q, true);
  };

  const setStatus = (record, status) => {
    dispatch({ type: 'UPDATE_RECORD_START' });
    return api
      .post(`/tenants/${record.tenantId}/events/${record.eventId}/status/${status}`)
      .then((response) => {
        dispatch({ type: 'UPDATE_RECORD_SUCCESS', payload: response });

        // also update record in memory
        // warning: API inconsistent - returned event isnt the same as returned by pql search
        updateRecord({ ...record, status: response.status }, PAGES.events);

        // force trigger the pql query on events dashboard
        // so that the assigned/status values update in the table
        forceTriggerPql();
      })
      .catch(() => {
        dispatch({ type: 'UPDATE_RECORD_FAIL' });
      });
  };

  const assignUser = (record, username) => {
    dispatch({ type: 'UPDATE_RECORD_START' });
    return api
      .post(`/tenants/${record.tenantId}/events/${record.eventId}/assign/${username}`)
      .then((response) => {
        dispatch({ type: 'UPDATE_RECORD_SUCCESS', payload: response });

        // also update record in memory
        // warning: API inconsistent - returned event isnt the same as returned by pql search
        updateRecord({ ...record, assigned: response.assigned }, PAGES.events);

        // force trigger the pql query on events dashboard
        // so that the assigned/status values update in the table
        forceTriggerPql();
      })
      .catch(() => {
        dispatch({ type: 'UPDATE_RECORD_FAIL' });
      });
  };

  const value = {
    ...state,
    setStatus,
    assignUser,
  };

  return <EventIrwState.Provider value={value}>{children}</EventIrwState.Provider>;
}

export default EventIrwProvider;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useToggle } from 'utils/hooks';
import IconButton from 'components/shared/IconButton';
import Modal, { ModalHeader, ModalContent } from 'components/common/Modal';
import { StarIcon } from 'components/shared/Icons';

import BookmarksData from './BookmarksData';

const Bookmarks = () => {
  const bookmarksModal = useToggle();
  const location = useLocation();

  useEffect(() => {
    bookmarksModal.setOff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  return (
    <>
      <Modal top="45%" open={bookmarksModal.isOn} onClose={bookmarksModal.setOff} dataTestId="bookmarks-modal">
        {bookmarksModal.isOn && (
          <>
            <ModalHeader>Bookmarks</ModalHeader>
            <ModalContent>
              <BookmarksData />
            </ModalContent>
          </>
        )}
      </Modal>

      <IconButton
        size="4"
        variant="soft"
        color="gray"
        onClick={bookmarksModal.setOn}
        aria-label="Bookmarks"
        data-test-id="open-bookmarks-button"
      >
        <StarIcon width="20" height="20" aria-hidden />
      </IconButton>
    </>
  );
};

export default React.memo(Bookmarks);

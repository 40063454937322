window.zESettings = {
  webWidget: {
    contactForm: {
      title: {
        '*': 'Send Support Ticket / Feature Request',
      },
    },
  },
};

//type cb as any function
export const injectZendesk = (cb: (...args: unknown[]) => void) => {
  if (document.getElementById('zendesk')) {
    if (cb) {
      cb();
    }

    return;
  }

  const script = document.createElement('script');
  script.setAttribute('id', 'zendesk');
  script.src = '/static/js/zendesk.js';
  document.getElementsByTagName('head')[0].appendChild(script);

  script.onload = () => {
    if (cb) {
      const interval = setInterval(() => {
        if (window?.$zopim?.livechat) {
          clearInterval(interval);
          cb();
        }
      }, 100);
    }
  };
};

export const openSupportChat = (email: string) => {
  if (window?.$zopim) {
    window.$zopim(function () {
      window.$zopim?.livechat.setName(email);
      window.$zopim?.livechat.setEmail(email);
      window.$zopim?.livechat.window.show();
    });
  }
};

export const sendMessage = (message: string) => {
  if (window?.$zopim) {
    window.$zopim(function () {
      window.$zopim?.livechat.say(message);
    });
  }
};

export const openSupportChatWithAMessage = (username: string, message: string) => {
  injectZendesk(() => {
    openSupportChat(username);
    sendMessage(message);
  });
};

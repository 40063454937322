import React from 'react';
import { motion } from 'framer-motion';

import Portal from 'components/common/Portal';
import { CloseIcon } from 'components/common/Icons';
import { useLockBodyScroll } from 'utils/hooks';

import {
  Backdrop,
  ModalBody,
  CloseWrapper,
  ModalHeader,
  ModalContent,
  ModalContentFit,
  ModalFooter,
} from './index.styled';

const Modal = React.forwardRef(
  ({ backdropRef, open, top = '40%', size, onClose, children, dataTestId, ...props }, ref) => {
    useLockBodyScroll(open);

    const variants = {
      closed: {
        top: '60%',
      },
      open: {
        top,
        transition: {
          type: 'spring',
          duration: 0.5,
        },
      },
    };

    const handleBodyClick = (e) => {
      e.stopPropagation();
    };

    const handleCloseModal = () => {
      if (onClose) {
        onClose();
      }
    };

    return (
      <Portal>
        <Backdrop ref={backdropRef} onClick={handleCloseModal} $open={open} />

        <ModalBody
          ref={ref}
          onClick={handleBodyClick}
          as={motion.div}
          $size={size}
          animate={open ? 'open' : 'closed'}
          variants={variants}
          initial="closed"
          $top={top}
          data-test-id={dataTestId || 'modal'}
          {...props}
        >
          {onClose && open && (
            <CloseWrapper
              onClick={onClose}
              aria-label="Close"
              data-test-id={dataTestId ? `${dataTestId}-close-button` : 'close-modal-button'}
            >
              <CloseIcon aria-hidden />
            </CloseWrapper>
          )}

          {open && children}
        </ModalBody>
      </Portal>
    );
  },
);

export { ModalHeader, ModalContent, ModalContentFit, ModalFooter };

export default Modal;

import React, { useContext } from 'react';

import { BadUrlPage } from 'components/scenes/ErrorPages';
import PageContainer from 'components/common/PageContainer';
import SectionTitle from 'components/common/SectionTitle';

import { DebugState, getListOfFeatures } from 'services/debug';
import { ThemeState } from 'services/theme';
import { AuthState } from 'services/auth';
import { FEATURES } from 'consts/config';

import ToggleItem from './ToggleItem';
import RuntimeDetails from './RuntimeDetails';
import SelectProfileTypes from './SelectProfileTypes';

const DebugPage = () => {
  const debugState = useContext(DebugState);
  const themeState = useContext(ThemeState);
  const authState = useContext(AuthState);

  if (!debugState.debug) {
    return <BadUrlPage />;
  }

  const features = getListOfFeatures();

  const runtimeDetails = [
    { name: 'Theme', value: themeState.themeName },
    { name: 'Environment', value: import.meta.env.MODE },
    { name: 'Release', value: import.meta.env.VITE_RELEASE_VERSION },
    { name: 'Deployment', value: import.meta.env.VITE_DEPLOYMENT_TYPE },
    { name: 'Commit', value: import.meta.env.VITE_COMMIT_HASH },
    { name: 'User', value: authState.state.user && authState.state.user.username },
  ];

  return (
    <PageContainer>
      <RuntimeDetails details={runtimeDetails} token={authState.state.token} />

      <SectionTitle title="Debug" />
      <div className="mb-4">
        <ToggleItem
          name="Log to console"
          description="Dump sentry logs to console"
          checked={debugState.consolelog}
          onChange={debugState.toggleLogToConsole}
        />
      </div>
      <div className="mb-6">
        <SelectProfileTypes />
      </div>

      <SectionTitle title="Features" />
      <div className="mb-6">
        {Object.keys(debugState.feature)
          .filter((fKey) => FEATURES[fKey].showInDebug)
          .map((fKey) => {
            return (
              <div className="mt-2" key={fKey}>
                <ToggleItem
                  name={fKey}
                  description={features[fKey].description}
                  checked={debugState.feature[fKey]}
                  onChange={() => debugState.featureToggle(fKey)}
                />
              </div>
            );
          })}
      </div>
    </PageContainer>
  );
};

export default DebugPage;

import ReactSelect from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';
import styled, { css } from 'styled-components';

import { StyledSelect } from 'components/common/Select/index.styled';
import Badge from 'components/common/Badge';

const StyledMultiSelect = styled(StyledSelect)`
  .rsmm__value-container {
    padding: 8px;
    gap: 4px;

    ${({ $height }) =>
      $height &&
      css`
        min-height: ${({ $height }) => $height};
        height: initial;
      `}
  }

  .rsmm__menu,
  .rsmm__menu-list {
    width: 100%;
  }
`;

const MultiValue = ({ data, removeProps }) => {
  return (
    <Badge shape="square" value={data.value} color={data.color || '#cacaca'} onRemove={removeProps.onClick}>
      {data.chipLabel || data.label}
    </Badge>
  );
};

const defaultComponents = {
  MultiValue,
};

const MultiBadgeSelect = ({ height, borderless, ...props }) => {
  return (
    <StyledMultiSelect
      as={props.isCreatable ? ReactSelectCreatable : ReactSelect}
      isMulti
      isDisabled={props.disabled}
      transparent={props.transparent}
      classNamePrefix="rsmm"
      components={defaultComponents}
      $height={height}
      $borderless={borderless}
      {...props}
    />
  );
};

export default MultiBadgeSelect;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import _uniqBy from 'lodash/uniqBy';
import _intersection from 'lodash/intersection';

import { parseQueryString } from 'utils/url';
import useStore from 'services/store';
import { useAiPrediction } from 'services/search';
import { SensorsApiContext } from 'services/sensor/SensorsProvider';
import { useSensorList, useCheckSensorStatus } from 'services/sensor';
import { EVENTS_ENABLED_PROFILE_TYPES, PATH_PROFILE_TYPES, SCENE } from 'consts/scenes';

import { PlayIcon, AlertTriangleIcon, AlertOctagonIcon } from 'components/common/Icons';
import SensorSelector from 'components/common/SensorSelector';
import Modal, { ModalContent } from 'components/common/Modal';
import Scrollbar from 'components/common/Scrollbar';

import { SensorButton, PredictionWarning } from './index.styled';

const scrollbarStyle = { width: 820, height: 510 };
const scrollbarStyleHigh = { width: 820, height: 700 };

const getSensorsByIds = (idList, sensorList) => {
  if (!idList) {
    // null == all sensors
    return sensorList;
  }
  return sensorList.filter((x) => idList.indexOf(x.id) > -1);
};

const SensorPickerContent = ({ hasAlerts, isInTraining }) => {
  const { sensorList } = useSensorList();
  const { areAnySensorsOffline } = useCheckSensorStatus(sensorList);

  if (hasAlerts) {
    return (
      <>
        <PredictionWarning alert={hasAlerts}>AI: Probable increase in alerts in next hour</PredictionWarning>
        <AlertTriangleIcon aria-hidden />
      </>
    );
  }

  if (isInTraining) {
    return (
      <>
        <PredictionWarning $isInTraining={isInTraining}>
          <div>Initial AI Learning</div>
          <div className="font-normal">in Progress</div>
        </PredictionWarning>
        <AlertTriangleIcon aria-hidden />
      </>
    );
  }

  if (areAnySensorsOffline) {
    return <AlertOctagonIcon aria-hidden />;
  }

  return <PlayIcon aria-hidden />;
};

const isSelectable = (sensor, pathname, queryParams) => {
  const profileType = sensor.Profile?.profileType;

  const sceneProfileTypes = PATH_PROFILE_TYPES[pathname];

  if (pathname === SCENE.detections.path && queryParams?.view === 'intel-events') {
    return EVENTS_ENABLED_PROFILE_TYPES.includes(profileType);
  }

  if (sceneProfileTypes && profileType) {
    return sceneProfileTypes.includes(profileType);
  }

  return true;
};

const SensorPicker = () => {
  const [open, setOpen] = useState(false);
  const sensorContext = useContext(SensorsApiContext);
  const sensorList = sensorContext.state.sensors;
  const { areAnySensorsOffline, areAnySensorsInTraining } = useCheckSensorStatus(sensorList);
  const { activeSensors, setActiveSensors } = useStore((state) => ({
    activeSensors: state.searchQuery.activeSensors,
    setActiveSensors: state.searchQuery.setActiveSensors,
  }));
  const predictionIndicators = useAiPrediction();

  const [draftList, setDraftList] = useState(() => activeSensors || []);
  const location = useLocation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setActiveSensors(draftList);
    setOpen(false);
  };

  const { nonSelectableSensors, selectableSensors, selectedSensors, selectableTenants, selectedTenants } =
    useMemo(() => {
      if (!sensorList) {
        return {};
      }

      const selectedSensorList = getSensorsByIds(activeSensors, sensorList || []);
      const queryParams = parseQueryString(location.search);

      const selectableSensors = sensorList.filter((s) => isSelectable(s, location.pathname, queryParams));
      const selectedSensors = selectedSensorList.filter((s) => selectableSensors.find((ns) => ns.id === s.id));
      return {
        nonSelectableSensors: sensorList.filter((s) => !isSelectable(s, location.pathname, queryParams)),
        selectableSensors,
        selectedSensors,
        selectableTenants: _uniqBy(
          selectableSensors.map((s) => s.Tenant.id),
          (x) => x,
        ),
        selectedTenants: _uniqBy(
          selectedSensors.map((s) => s.Tenant.id),
          (x) => x,
        ),
      };
    }, [sensorList, activeSensors, location.search, location.pathname]);

  useEffect(() => {
    if (sensorList) {
      const sensorListIds = sensorList.map((s) => s.id);
      const activeSensorSelectionCheck =
        activeSensors?.length > 0 ? _intersection(activeSensors, sensorListIds) : sensorListIds;
      // intersection can be empty if underlying sensor ids changed
      let activeSensorSelection = activeSensorSelectionCheck?.length > 0 ? activeSensorSelectionCheck : sensorListIds;

      if (activeSensorSelection.filter((sid) => !nonSelectableSensors.find((s) => s.id === sid)).length === 0) {
        activeSensorSelection = activeSensorSelection.concat(selectableSensors.map((s) => s.id));
      }

      setActiveSensors(activeSensorSelection);
      setDraftList(activeSensorSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorList, location.pathname]);

  if (!sensorList) {
    return null;
  }

  return (
    <>
      <SensorButton
        aria-label="Sensor picker"
        onClick={handleOpen}
        $alert={!!predictionIndicators}
        $isInTraining={areAnySensorsInTraining}
        $hasOfflineSensors={areAnySensorsOffline}
        data-test-id="sensor-picker-button"
      >
        <SensorPickerContent hasAlerts={!!predictionIndicators} isInTraining={areAnySensorsInTraining} />

        <div className="ml-2 old-text-sm">
          <div
            className="flex"
            data-test-id="sensor-picker-tenant-data"
          >{`${selectedTenants.length}/${selectableTenants.length} tenants`}</div>
          <div
            className="flex"
            data-test-id="sensor-picker-sensor-data"
          >{`${selectedSensors.length}/${selectableSensors.length} sensors`}</div>
        </div>
      </SensorButton>

      <Modal size="large" open={open} onClose={handleClose}>
        {open && (
          <ModalContent>
            <Scrollbar style={sensorList.length > 10 ? scrollbarStyleHigh : scrollbarStyle}>
              <SensorSelector
                title="Select sensors you wish to query for data."
                value={draftList}
                onChange={setDraftList}
                alerts={predictionIndicators}
                disabledSensors={nonSelectableSensors.map((s) => s.id)}
              />
            </Scrollbar>
          </ModalContent>
        )}
      </Modal>
    </>
  );
};

export default SensorPicker;

import React, { useState } from 'react';
import styled from 'styled-components';
import _intersection from 'lodash/intersection';
import _groupBy from 'lodash/groupBy';

import { useBookmarks } from 'services/bookmark';
import { AlertIcon, IndicatorIcon, ClipboardIcon, LogIcon } from 'components/common/Icons';
import Tabs from 'components/common/Tabs';
import { Subtitle } from 'components/common/PageList';
import { SearchInput } from 'components/common/PageList';
import BookmarkItem, { NoBookmarks } from 'components/common/BookmarkItem';
import Scrollbar from 'components/common/Scrollbar';
import { filterBookmarks } from 'utils/bookmarks';

const scrollbarStyle = {
  height: 400,
};

const SectionHeader = styled(Subtitle)`
  margin-top: 0;
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
  margin-top: ${({ $hasBookmarks }) => ($hasBookmarks ? -42 : 0)}px;
`;

const SectionContainer = styled.div`
  &:not(:first-of-type) {
    margin-top: 18px;
  }
`;

const SectionIcon = ({ searchType }) => {
  switch (searchType) {
    case 'O_ALERTS':
      return <AlertIcon />;
    case 'O_EVENTS':
      return <ClipboardIcon />;
    case 'O_INDICATORS':
      return <IndicatorIcon />;
    default:
      return <LogIcon />;
  }
};

const sectionTitles = {
  id: {
    O_ALERTS: 'Alerts',
    O_EVENTS: 'Events',
    O_INDICATORS: 'Indicators',
    O_SENSOR: 'Logs',
  },
  pql: {
    O_EVENTS: 'Event queries',
    O_INDICATORS: 'Indicator queries',
    O_SENSOR: 'Log queries',
  },
};

const BookmarkSection = React.memo(({ bookmarks, bookmarkType, searchType }) => {
  return (
    <SectionContainer className="pr-2">
      <SectionHeader className="flex items-center imr-2 mt-1">
        <SectionIcon searchType={searchType} />
        {sectionTitles[bookmarkType][searchType]}
      </SectionHeader>
      {bookmarks.map((b) => (
        <BookmarkItem key={b.id} type={bookmarkType} bookmark={b} />
      ))}
    </SectionContainer>
  );
});

const BookmarksTab = ({ type }) => {
  const bookmarks = useBookmarks(type);

  const [filter, setFilter] = useState();

  const groupedBookmarks = bookmarks.data ? _groupBy(filterBookmarks(bookmarks.data, filter), 'searchType') : {};
  const availableSearchTypes = _intersection(
    ['O_ALERTS', 'O_EVENTS', 'O_INDICATORS', 'O_SENSOR'],
    Object.keys(groupedBookmarks),
  );

  return (
    <div className="mt-3">
      <SearchWrapper $hasBookmarks={!!bookmarks.data?.length}>
        <SearchInput collection={bookmarks.data} placeholder="search..." onChange={setFilter} />
      </SearchWrapper>
      <Scrollbar style={scrollbarStyle}>
        {bookmarks.data?.length ? (
          availableSearchTypes.length === 0 ? (
            <NoBookmarks title="No results" description="No bookmarks were found for the given query." />
          ) : (
            availableSearchTypes.map((searchType) => (
              <BookmarkSection
                key={searchType}
                bookmarkType={type}
                bookmarks={groupedBookmarks[searchType]}
                searchType={searchType}
              />
            ))
          )
        ) : (
          <NoBookmarks
            title="No bookmarks yet"
            description="Your bookmarked records and queries will be displayed here."
          />
        )}
      </Scrollbar>
    </div>
  );
};

const tabs = [
  {
    tabName: 'Records',
    alias: 'id',
    component: BookmarksTab,
  },
  {
    tabName: 'Searches',
    alias: 'pql',
    component: BookmarksTab,
  },
];

const BookmarksData = () => {
  return (
    <Tabs
      components={tabs.map((tab) => ({
        ...tab,
        component: (props) => <tab.component {...props} type={tab.alias} />,
      }))}
    />
  );
};

export default React.memo(BookmarksData);

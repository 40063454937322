import * as React from 'react';

import * as tokenUtils from 'utils/token';

type User = {
  id: number;
  cn: number;
  permissions: { name: string }[];
  defaultView: 'home' | 'detections' | 'executive_summary';
  username: string;
  verified: boolean;
  pwdReset: boolean;
};

export const initialState = {
  token: tokenUtils.rehydrateToken(),
  user: null as User | null,
  authenticationError: null,
  userDetailsError: null,
  unauthenticationError: null,
  passwordResetError: null,
  passwordUpdateError: null,
};

type AuthState = {
  state: typeof initialState;
  unauthenticate: () => void;
  getUserDetails: () => void;
};

const AuthState = React.createContext({
  state: { ...initialState },
} as AuthState);

export default AuthState;

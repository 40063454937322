import React from 'react';
import PropTypes from 'prop-types';

import ToggleSwitch from 'components/common/ToggleSwitch';

const ToggleItem = ({ name, description, checked, onChange }) => {
  const isChecked = checked || false;

  return (
    <div className="flex">
      <ToggleSwitch id={name} checked={isChecked} onChange={onChange} />
      <div className="w-[240px] font-semibold pl-5">{name}</div>
      <div>{description || ''}</div>
    </div>
  );
};

ToggleItem.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default ToggleItem;

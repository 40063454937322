import React from 'react';
import styled from 'styled-components';

import { getSeverityColor } from 'services/notification/utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 2px solid ${({ theme, $id }) => getSeverityColor($id, theme)};
  color: ${({ theme, $id }) => getSeverityColor($id, theme)};
`;

const Category = styled.div`
  display: flex;
  align-self: baseline;
  font-size: 11px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.subtle};
  margin-left: 12px;
`;

const Severity = styled.div`
  width: 96px;
  margin: 12px 0 0 12px;
  font-size: 14px;
  text-transform: uppercase;
  align-self: baseline;
`;

const SeverityInfo = ({ severityId, severityName, categoryDescription }) => {
  return (
    <Container $id={severityId}>
      <Category>{categoryDescription}</Category>
      <Severity>{severityName === 'Informational' ? 'Info' : severityName}</Severity>
    </Container>
  );
};

export default SeverityInfo;

import styled from 'styled-components';
import Button from 'components/common/Button';

export const AssignButton = styled(Button)`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: 0px 10px;
  width: 140px;

  span {
    font-size: 12px;
    line-height: 1.4;
  }
`;

export const TSCountWrapper = styled.div`
  font-size: 14px;
  display: flex;

  div {
    display: flex;
    align-items: center;
    padding: 0 4px;
  }

  svg {
    font-size: 16px;
    padding: 0 2px;
  }
`;

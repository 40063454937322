import styled from 'styled-components';

import { fadeInLeft } from 'components/styled/animations';
import { EXPANDED_WIDTH, COLLAPSED_WIDTH } from 'services/menu/config';

export const Container = styled.nav`
  position: fixed;
  height: var(--app-height);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translate3d(0, 0, 0);
  width: ${({ $expanded }) => ($expanded ? EXPANDED_WIDTH : COLLAPSED_WIDTH)}px;
  min-width: ${({ $expanded }) => ($expanded ? EXPANDED_WIDTH : COLLAPSED_WIDTH)}px;
  z-index: 100;
  transition: all 200ms ease-in-out;
  padding: 12px;
  background: ${({ theme }) => theme.colors.mainmenu.background};
  box-shadow: 1px 0 9px 2px rgba(17, 16, 16, 0.43);
`;

export const Separator = styled.div`
  margin: 14px 0;
  width: 100%;
  min-height: 1px;
  background-color: ${({ theme, $transparent }) =>
    $transparent ? theme.colors.mainmenu.background : theme.colors.mainmenu.divider};
`;

export const GroupTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.text.subtle};
  text-transform: uppercase;
  height: 29px;
  padding-top: 14px;
  overflow: hidden;

  animation: ${fadeInLeft} 0.3s;
`;

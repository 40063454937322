import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router';

import useStore from 'services/store';
import { useCurrentUser } from 'services/auth';
import { saveActiveSensorsToLocalStorage, getActiveSensorsFromLocalStorage } from 'utils/localStorage';
import { SensorsApiContext } from 'services/sensor/SensorsProvider';

/**
 * Handles computed fields in the searchQuery store and caching of activeSensors
 **/
export function SearchQueryStateProvider({ children }) {
  const { user } = useCurrentUser();
  const sensorContext = useContext(SensorsApiContext);

  const { setActiveSensors, activeSensors, setActiveSensorsByProfile } = useStore((state) => ({
    setActiveSensors: state.searchQuery.setActiveSensors,
    activeSensors: state.searchQuery.activeSensors,
    setActiveSensorsByProfile: state.searchQuery.setActiveSensorsByProfile,
  }));

  const { pathname } = useLocation();

  useEffect(() => {
    if (user?.username) {
      saveActiveSensorsToLocalStorage(user.username, activeSensors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(activeSensors)]);

  useEffect(() => {
    const activeSensors = getActiveSensorsFromLocalStorage(user?.username);
    setActiveSensors(activeSensors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.username]);

  useEffect(() => {
    setActiveSensorsByProfile(sensorContext.state.sensors, pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, JSON.stringify(activeSensors), JSON.stringify(sensorContext.state.sensors)]);

  return children;
}

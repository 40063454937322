const DEFAULT_DRAFT = {
  type: 'table',
  timerange: 'global',
  title: '',
  query: '',
  searchType: 'O_SENSOR',
  countby: null,
  sensors: [{ value: 'global', label: 'Dashboard selection' }],
  sort: { value: 'desc', label: 'desc' },
  limit: 50,
};

const createDashboardDraft = (set, get) => ({
  defaultDraft: DEFAULT_DRAFT,
  setDraft: (dashboardId, draft) => {
    set((prev) => ({
      dashboardDraft: {
        ...prev.dashboardDraft,
        [dashboardId]: draft,
      },
    }));
  },
  setDefaultDraft: (dashboardId) => {
    set((prev) => ({
      dashboardDraft: {
        ...prev.dashboardDraft,
        [dashboardId]: {
          config: DEFAULT_DRAFT,
        },
      },
    }));
  },
  getDraft: (dashboardId) => {
    return get().dashboardDraft[dashboardId];
  },
  removeDraft: (dashboardId) => {
    set((prev) => {
      const draft = prev.dashboardDraft[dashboardId];
      if (!draft) {
        return prev;
      }
      const { [dashboardId]: _, ...rest } = prev.dashboardDraft;
      return {
        dashboardDraft: rest,
      };
    });
  },
});

export default createDashboardDraft;

import React, { useReducer, useContext, type ReactNode } from 'react';

import { hasFeature } from 'utils/env';
import { getDebugFlag } from 'utils/url';
import { getCookie, setCookie, setOrRemoveCookie } from 'utils/cookie';
import { useKeyboardShortcut } from 'utils/hooks';

import { DEBUG_KEY, COOKIE_DEBUG_CONSOLE_LOG, FEATURES } from 'consts/config';

type AvailableFeatures = keyof typeof FEATURES;

const getInitialFeatureState = () => {
  return Object.keys(FEATURES).reduce(
    (acc, key) => {
      const featureKey = key as AvailableFeatures;

      acc[featureKey] = Boolean(getCookie(`mm_ft_${featureKey}`)) || FEATURES[featureKey].default;

      return acc;
    },
    {} as Record<AvailableFeatures, boolean>,
  );
};

const hasDebugEnabled = () => {
  if (hasFeature('debug')) {
    return true;
  }

  const isDebugInQueryParams = getDebugFlag(window.location.search);

  if (isDebugInQueryParams) {
    window.sessionStorage.setItem(DEBUG_KEY, 'true');

    return true;
  }

  return window.sessionStorage ? window.sessionStorage.getItem(DEBUG_KEY) : false;
};

const initialState = {
  debug: hasDebugEnabled(),
  consolelog: Boolean(getCookie(COOKIE_DEBUG_CONSOLE_LOG)) || false,
  feature: getInitialFeatureState(),
};

type State = {
  debug: string | boolean | null;
  consolelog: boolean;
  feature: Record<AvailableFeatures, boolean>;
};

type ProviderValue = State & {
  toggleLogToConsole: () => void;
  featureToggle: (feature: AvailableFeatures) => void;
};

export const DebugState = React.createContext<ProviderValue>({
  ...initialState,
  toggleLogToConsole: () => {},
  featureToggle: () => {},
});

export const getListOfFeatures = () => FEATURES;

type Actions =
  | { type: 'DEBUG_ALLOW' }
  | { type: 'LOG_TO_CONSOLE' }
  | { type: 'FEATURE_TOGGLE'; payload: AvailableFeatures };

const reducer = (state: State, action: Actions) => {
  switch (action.type) {
    case 'DEBUG_ALLOW':
      return { ...state, debug: true };
    case 'LOG_TO_CONSOLE':
      return { ...state, consolelog: !state.consolelog };
    case 'FEATURE_TOGGLE':
      return {
        ...state,
        feature: {
          ...state.feature,
          [action.payload]: !state.feature[action.payload],
        },
      };
    default:
      return state;
  }
};

export default function DebugStateProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const toggleLogToConsole = () => {
    setCookie(COOKIE_DEBUG_CONSOLE_LOG, String(!state.consolelog));
    dispatch({ type: 'LOG_TO_CONSOLE' });
  };

  const featureToggle = (feature: AvailableFeatures) => {
    // remove cookie with false value
    setOrRemoveCookie(`mm_ft_${feature}`, String(!state.feature[feature]));
    dispatch({ type: 'FEATURE_TOGGLE', payload: feature });
  };

  useKeyboardShortcut(
    () => {
      dispatch({ type: 'DEBUG_ALLOW' });
    },
    {
      key: 'd',
      shiftKey: true,
      metaKey: true,
      ctrlKey: true,
    },
  );

  const value = {
    ...state,
    toggleLogToConsole,
    featureToggle,
  };

  return <DebugState.Provider value={value}>{children}</DebugState.Provider>;
}

export const useFeatureFlag = (feature: AvailableFeatures) => {
  const ctx = useContext(DebugState);

  const featureList = Object.keys(getListOfFeatures());
  if (!featureList.includes(feature)) {
    // eslint-disable-next-line no-console
    console.info(`Feature "${feature}" does not exist. Available options: ${featureList.join(', ')}`);
  }

  return ctx.feature[feature] ?? false;
};

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const LabelStyle = styled.label`
  display: inline-block;
  color: ${({ theme }) => theme.colors.text.primary};

  ${({ $required }) =>
    $required &&
    css`
      &::after {
        content: ' *';
        color: ${({ theme }) => theme.palette.danger[1]};
      }
    `}
`;

const Label = ({ children, required, ...props }) => {
  return (
    <LabelStyle {...props} $required={required}>
      {children}
    </LabelStyle>
  );
};

Label.propTypes = {
  requred: PropTypes.bool,
};

export default Label;

import React from 'react';
import styled from 'styled-components';

import { useSensorList } from 'services/sensor';

import Spinner from 'components/common/Spinner';
import { NetworkIcon, ActivityIcon, CheckCircleIcon } from 'components/common/Icons';
import { Title } from 'components/styled/Typography';
import { Separator, Divider } from 'components/styled/Layout';

import SidebarHelp from './SidebarHelp';
import useApplyOnSensors from './useApplyOnSensors';

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};
  margin-left: 12px;

  h1 {
    margin-bottom: 0px;
    padding-bottom: 8px;
  }
`;

const ListWrapper = styled.div`
  width: 50%;
  margin: 12px;
`;

const Item = styled.div`
  display: flex;
  justify-items: center;
  cursor: pointer;
  padding: 8px 4px;
  font-weight: ${({ $isSelected }) => $isSelected && 700};
  color: ${({ $isSelected, theme }) => ($isSelected ? theme.colors.text.primary : theme.colors.text.subtle)};

  svg {
    margin-right: 18px;
    color: ${({ $isSelected, theme }) => $isSelected && theme.palette.success[1]};
  }
`;

const TenantItem = styled(Item)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
`;

const SidebarAssignment = ({ resource, draft }) => {
  const { tenantList } = useSensorList();

  const {
    isLoading,
    isAssignedToEverything,
    resetAssignments,
    isResetting,
    isTenantAssigned,
    isSensorAssigned,
    toggleTenant,
    toggleSensor,
    isCurrentlyLoadingTenant,
    isCurrentlyLoadingSensor,
  } = useApplyOnSensors({
    resource,
    id: draft.id,
  });

  return (
    <Wrapper>
      <Title>{draft.name}</Title>
      <div>{draft.description}</div>
      <Separator />

      <div className="flex">
        <ListWrapper>
          {isLoading ? (
            <div>
              <Spinner className="mr-6" /> Loading...
            </div>
          ) : (
            <>
              <Item $isSelected={isAssignedToEverything} onClick={() => resetAssignments(draft.id)}>
                {isResetting ? <Spinner className="mr-6" /> : <CheckCircleIcon aria-hidden />} Everything
              </Item>
              <Divider height="12px" />
              {tenantList.map((tenant) => {
                const isTenantSelected = isTenantAssigned(tenant.id);
                const isTenantAssigning = isCurrentlyLoadingTenant(tenant.id);
                return (
                  <React.Fragment key={`tenant-${tenant.id}`}>
                    <TenantItem
                      $isSelected={isTenantSelected}
                      onClick={() => toggleTenant({ id: draft.id, tenantId: tenant.id })}
                    >
                      {isTenantAssigning ? <Spinner className="mr-6" /> : <NetworkIcon aria-hidden />} {tenant.name}
                    </TenantItem>

                    {tenant.sensors?.map((sensor) => {
                      const isSensorSelected = isSensorAssigned(sensor.id);
                      const isSensorAssigning = isCurrentlyLoadingSensor(sensor.id);
                      return (
                        <Item
                          key={`sensor-${sensor.id}`}
                          $isSelected={isSensorSelected}
                          onClick={() => toggleSensor({ id: draft.id, sensorId: sensor.id })}
                          data-test-id={`sensor-${sensor.name}`}
                        >
                          {isSensorAssigning ? <Spinner className="mr-6" /> : <ActivityIcon aria-hidden />}
                          <div className="flex flex-col">
                            {sensor.name}
                            <div className="color-text-subtle old-text-xs font-normal">
                              Profile: {sensor.Profile ? sensor.Profile.name : 'n/a'}
                            </div>
                          </div>
                        </Item>
                      );
                    })}
                    <Divider height="12px" />
                  </React.Fragment>
                );
              })}
            </>
          )}
        </ListWrapper>
        <SidebarHelp />
      </div>
    </Wrapper>
  );
};

export default SidebarAssignment;

import _set from 'lodash/fp/set';

import { PRESET_OPTIONS, getDefaultColumns } from 'consts/dashboard';

import { DASHBOARDS, INITIAL_CONFIG } from './config';

export const mapColumnsConfig = (config) => {
  const dashboardConfigs = DASHBOARDS.reduce(
    (xs, dash) => ({
      ...xs,
      [dash]:
        config?.columnsConfig?.[dash] && config.columnsConfig?.[dash].length > 0
          ? config.columnsConfig[dash]
          : getDefaultColumns({ page: dash }),
    }),
    {},
  );

  dashboardConfigs.log = Object.keys(INITIAL_CONFIG.columnsConfig.log).reduce(
    (xs, logType) => ({
      ...xs,
      [logType]: !!config?.columnsConfig?.log?.[logType]?.length
        ? config.columnsConfig.log[logType]
        : getDefaultColumns({ log: logType }),
    }),
    {},
  );

  return dashboardConfigs;
};

export const addMissingDefaultPresets = (config) => {
  let newConfig = config;

  for (const page in PRESET_OPTIONS) {
    for (const preset of PRESET_OPTIONS[page]) {
      if (!newConfig.presets[page][preset.value]) {
        newConfig = _set(`presets.${page}.${preset.value}`, preset.defaultWidgets, newConfig);
      }
    }
  }

  return newConfig;
};

export const setPagePresetConfig = (state, page, preset, pageConfig) => {
  return _set(`dashboardConfiguration.config.presets.${page}.${preset}`, pageConfig, state);
};

export const setColumnsConfig = (state, page, log, columns) => {
  if (log) {
    return _set(`dashboardConfiguration.config.columnsConfig.log.${log}`, columns, state);
  }

  return _set(`dashboardConfiguration.config.columnsConfig.${page}`, columns, state);
};

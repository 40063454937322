import { useQuery } from '@tanstack/react-query';

import { useApi } from 'services/api';
import useStore from 'services/store';
import { useCurrentUser } from 'services/auth';
import { getNotificationSeenTsFromLocalStorage } from 'utils/localStorage';

import { COMMON_DROPDOWN_MQL } from './config';

const useCheckNewNotifications = () => {
  const api = useApi();
  const timezone = useStore((state) => state.searchQuery.timezone);
  const { user } = useCurrentUser();

  return useQuery({
    queryKey: ['newNotifications'],
    queryFn: async () => {
      const opts = {
        context: [],
        searchType: 'O_NOTIFICATIONS',
        timezone,
      };

      const queries = [
        { query: `from 7 days ago to now ${COMMON_DROPDOWN_MQL} | countby severityId`, ...opts },
        { query: 'last hour | include createdAt | orderby createdAt desc limit 1', ...opts },
      ];

      let count = null;
      let hasNewNotifications = null;

      try {
        const res = await api.post('/search/bulk', { queries });

        if (!res.errors[0] && !res.errors[1]) {
          const results = res.results[0].results;
          count = results.length > 0 ? results.reduce((acc, cur) => acc + cur.count, 0) : 0;
          const lastNotifTs = res.results[1].results[0].createdAt;

          if (lastNotifTs && count > 0) {
            const seenTs = getNotificationSeenTsFromLocalStorage(user?.username);
            hasNewNotifications = new Date(lastNotifTs).getTime() > new Date(seenTs).getTime();
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('CHECK_PERIODIC_FAIL', err);
      }

      return {
        count,
        hasNewNotifications,
      };
    },
    refetchInterval: 5 * 60 * 1000,
  });
};

export default useCheckNewNotifications;

import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useConstant } from 'utils/hooks';
import { parseQueryString, updateQueryString } from 'utils/url';

import { TabLink } from './index.styled';

const Tab = ({ shouldHideOnOneTab = false, queryParam, components, defaultTabIndex = 0 }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const defaultTabIdx = defaultTabIndex && defaultTabIndex < components.length ? defaultTabIndex : 0;
  const [currentTabState, setCurrentTabState] = useState(
    useConstant(() => components.length > 0 && components[defaultTabIdx].alias),
  );

  const params = parseQueryString(location.search) || {};
  const queryParamSelectedTab = queryParam && params[queryParam];
  const currentTab = queryParam ? queryParamSelectedTab : currentTabState;

  useEffect(() => {
    if (queryParamSelectedTab && !components.find((component) => component.alias === queryParamSelectedTab)) {
      navigate(updateQueryString(queryParam, components[defaultTabIdx].alias), {
        replace: true,
      });
    }
  }, [components, defaultTabIdx, navigate, queryParam, queryParamSelectedTab]);

  const currentTabElement = useMemo(() => {
    const CurrentOpenTab = components.find((component) => component.alias === currentTab)?.component;

    return CurrentOpenTab ? <CurrentOpenTab /> : null;
  }, [components, currentTab]);

  if (!currentTabElement) {
    return null;
  }

  const handleLinkClick = (tab, event) => {
    event.stopPropagation();

    if (queryParam) {
      navigate(updateQueryString(queryParam, tab));
    } else {
      setCurrentTabState(tab);
    }
  };

  return (
    <>
      {(!shouldHideOnOneTab || components.length > 1) && (
        <div className="flex">
          {components.map((component) => (
            <TabLink
              active={currentTab === component.alias}
              data-test-is-active={(currentTab === component.alias).toString()}
              onClick={(event) => handleLinkClick(component.alias, event)}
              key={component.alias}
              data-test-id={`tab-${component.alias}`}
            >
              {component.tabName}
            </TabLink>
          ))}
        </div>
      )}
      <div>{currentTabElement}</div>
    </>
  );
};

export default Tab;

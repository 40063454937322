import React from 'react';
import { AlertTriangleIcon } from 'components/common/Icons';
import styled from 'styled-components';

import { pulseAnimation } from 'components/styled/animations';

const Wrapper = styled.div`
  min-width: 110px;
  animation: ${pulseAnimation};
  font-weight: 600;
  color: ${({ theme }) => theme.palette.danger[2]};
  svg {
    margin-right: 4px;
  }
`;

const AiPredictionAlert = () => (
  <Wrapper>
    <AlertTriangleIcon /> AI Warning
  </Wrapper>
);

export default AiPredictionAlert;

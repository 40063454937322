import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';

import { formatUTC } from 'utils/datetime';

import { DAY_IN_YEAR_FORMAT } from 'consts/datetime';

function getDate(timestamp) {
  return formatUTC(new Date(timestamp), DAY_IN_YEAR_FORMAT);
}

export function groupNotifs(collection) {
  const withIds = processAggNotifs(collection);
  const groupedByGroup = Object.values(_groupBy(withIds, (x) => x.groupId));

  const result = [];

  for (const group of groupedByGroup) {
    const entry = _sortBy(group, (x) => x.createdAt)[0];

    result.push({
      ...entry,
      count: group.reduce((xs, x) => xs + x.count, 0),
    });
  }

  return result;
}

export function processAggNotifs(collection) {
  const res = collection.map((bucket) => ({
    ...bucket,
    groupId: `${getDate(bucket.day)}-${bucket.typeId}-${bucket.sensorId || 0}`,
  }));
  return _sortBy(res, (x) => x.day).reverse();
}

export function getQueryFromGroupId(groupId) {
  const [date, typeId, sensorId] = groupId.split('-');
  let query = `${date} typeId=${typeId}`;
  if (sensorId && parseInt(sensorId, 10) > 0) {
    query += ` sensorId=${sensorId}`;
  }
  return query;
}

export function getSeverityColor(id, theme) {
  switch (id) {
    case 0:
      // emergency
      return theme.palette.danger[0];
    case 1:
      // alert
      return theme.palette.danger[1];
    case 2:
      // critical
      return theme.palette.danger[1];
    case 3:
      // error
      return theme.palette.danger[2];
    case 4:
      // warning
      return theme.name === 'Light' ? theme.palette.orangeYellow : theme.palette.alert[2];
    case 5:
      // notice
      return theme.colors.text.primary;
    case 6:
      // informational
      return theme.colors.text.primary;
    case 7:
      // debug
      return theme.palette.secondary[2];
    default:
      return theme.palette.alert[0];
  }
}

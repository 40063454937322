import { useContext } from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';

import DebugStateProvider from 'services/debug';
import { useApi, getBasePath } from 'services/api';
import { AuthState } from 'services/auth';

import { getTokenFromUrl } from 'utils/url';
import { DEFAULT_VIEW_MAP } from 'consts/scenes';
import 'css/importCss';

import RoutesWrapper from 'components/common/RoutesWrapper';
import ScrollToTop from 'components/common/ScrollToTop';
import Loader from 'components/common/Loader';
import LoginPage from 'components/scenes/AuthPage/Login';
import ResetPasswordPage from 'components/scenes/AuthPage/ResetPassword';
import ForgotPasswordPage from 'components/scenes/AuthPage/ForgotPassword';
import LogoutPage from 'components/scenes/LogoutPage';
import { BadUrlPage } from 'components/scenes/ErrorPages';
import DebugPage from 'components/scenes/DebugPage';
import FullAppLayout from 'components/scenes/FullAppLayout';
import Main from 'components/scenes/Main';

const RequireAuth = ({ children }) => {
  const {
    state: { token, user },
  } = useContext(AuthState);
  const api = useApi();
  const location = useLocation();

  const urlToken = getTokenFromUrl(location.search);

  if (!token && !urlToken) {
    if (import.meta.env.VITE_SSO_ENABLED === 'true') {
      const redirectUrl = encodeURIComponent(`${location.pathname}${location.search}`);

      // make a full request that hits the server
      window.location.href = `${getBasePath()}/login?redirect_to=${redirectUrl}&origin=${window.location.origin}`;
      return null;
    } else {
      return <Navigate to="/login" state={{ from: location }} />;
    }
  }

  if (!api) {
    return <Loader inside="page" />;
  }

  if (location.pathname === '/') {
    const homePage = DEFAULT_VIEW_MAP[user?.defaultView] || DEFAULT_VIEW_MAP.home;
    return <Navigate to={homePage} />;
  }

  return (
    <DebugStateProvider>
      <FullAppLayout>{children}</FullAppLayout>
    </DebugStateProvider>
  );
};

const Root = () => {
  return (
    <ScrollToTop>
      <RoutesWrapper>
        <Route
          exact
          path="/login"
          element={import.meta.env.DEV ? <LoginPage /> : <Navigate to={`${getBasePath()}/login`} />}
        />
        <Route
          exact
          path="/forgot"
          element={import.meta.env.DEV ? <ForgotPasswordPage /> : <Navigate to={`${getBasePath()}/forgot`} />}
        />
        <Route
          exact
          path="/reset"
          element={import.meta.env.DEV ? <ResetPasswordPage /> : <Navigate to={`${getBasePath()}/forgot`} />}
        />
        <Route exact path="/user/login" element={<LoginPage ssoBypass={true} />} />
        <Route exact path="/user/forgot" element={<ForgotPasswordPage ssoBypass={true} />} />
        <Route exact path="/login/reset" element={<ResetPasswordPage />} />
        <Route exact path="/logout" element={<LogoutPage />} />
        <Route
          exact
          path="/"
          element={
            <RequireAuth>
              <Navigate to="/alerting/overview" />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/debug"
          element={
            <RequireAuth>
              <DebugPage />
            </RequireAuth>
          }
        />

        <Route element={<BadUrlPage />} />

        <Route
          path="/:category/:page/*"
          element={
            <RequireAuth>
              <Main />
            </RequireAuth>
          }
        />

        <Route
          path="/:category/*"
          element={
            <RequireAuth>
              <Main />
            </RequireAuth>
          }
        />
      </RoutesWrapper>
    </ScrollToTop>
  );
};

export default Root;

import styled from 'styled-components';

import Input from 'components/common/Input';

const FlatInputStyled = styled(Input)`
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.text.primary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.text.subtle};
  width: ${({ $width }) => $width || '200px'};

  &::placeholder {
    font-weight: 300;
  }
`;

const FlatInput = ({ width, ...props }) => <FlatInputStyled {...props} $width={width} />;

export default FlatInput;

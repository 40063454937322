import { getApiHttpPath } from 'services/api';
import { consoleLog } from 'services/log/utils';

type LocalStorageItem = {
  key: string;
  values: string[] | '*';
  defaultValue: string;
};

/**
 * used to keep control of things we save into local storage
 */

export const LS_KEYS = {
  theme: {
    // defines theme
    key: 'pstheme',
    values: ['light', 'dark'],
    defaultValue: 'light',
  },
  alertsLastUrl: {
    key: 'alertsLastUrl',
    values: '*',
    defaultValue: '?view=all',
  },
  alertViewMode: {
    // defines view mode on alerts overview page
    key: 'alertViewMode',
    values: ['detailed', 'compact'],
    defaultValue: 'compact',
  },
  allAlertsSortBy: {
    key: 'allAlertsSortBy',
    values: ['priority', 'epochLastSeen', 'severity', 'entityEnrichmentSeverity'],
    defaultValue: 'epochLastSeen',
  },
  allAlertsGroupedSortBy: {
    key: 'allAlertsGroupedSortBy',
    values: ['maxPriority', 'lastSeen', 'alertCount', 'childCount', 'severity', 'entityEnrichmentSeverity'],
    defaultValue: 'lastSeen',
  },
  allAlertsSearchString: {
    key: 'allAlertsSearchString',
    values: '*',
    defaultValue: 'q=Last%202%20hours&status=open&status=under_review&groupby=alertTypeName',
  },
  mitreAlertsSearchString: {
    key: 'mitreAlertsSearchString',
    values: '*',
    defaultValue: 'q=Last%2024%20hours',
  },
  scheduledTasksViewMode: {
    key: 'scheduledTasksViewMode',
    values: ['list', 'groupedByTags'],
    defaultValue: 'list',
  },
  severityOverridesViewMode: {
    key: 'severityOverridesViewMode',
    values: ['list', 'groupedByTags'],
    defaultValue: 'list',
  },
  executiveSummaryDisplayRiskSwitch: {
    key: 'executiveSummaryDisplayRiskSwitch',
    values: ['true', 'false'],
    defaultValue: 'true',
  },
  executiveSummaryDisplayRiskInsteadDeviation: {
    key: 'executiveSummaryDisplayRiskInsteadDeviation',
    values: ['true', 'false'],
    defaultValue: 'true',
  },
} as const satisfies Record<string, LocalStorageItem>;

const _verifyValue = (keyObject: LocalStorageItem, value: string) => {
  if (keyObject?.values === '*') {
    return true;
  }
  if (keyObject?.values.includes(value)) {
    return true;
  }
  consoleLog('ERROR', `${keyObject.key} value "${value}" is not valid`);
  return false;
};

export const getDefaultForKey = (key: keyof typeof LS_KEYS) => {
  const value = LS_KEYS[key]?.defaultValue;
  if (!value) {
    consoleLog('ERROR', `getDefaultForKey: key ${key} not found`);
  }
  return value;
};

// returns key object for given key
const _getUsedKey = (key: string) => {
  const usedKey = Object.values(LS_KEYS).find((x) => x.key === key);
  if (!usedKey) {
    consoleLog('ERROR', `_getUserKey: localstorage key ${key} is not valid`);
  }
  return usedKey;
};

export const setLocalItem = (key: string, value: string) => {
  const usedKey = _getUsedKey(key);
  if (usedKey && !_verifyValue(usedKey, value)) {
    consoleLog('ERROR', `setLocalItem: value ${value} is not valid for key ${key}`);
    return false;
  }

  window.localStorage && localStorage.setItem(key, value);
  return true;
};

export const getLocalItem = (key: string) => {
  const usedKey = _getUsedKey(key);
  if (usedKey) {
    return window.localStorage && localStorage.getItem(usedKey.key);
  }
  consoleLog('ERROR', `getLocalItem: key ${key} is not valid`);
  return undefined;
};

export const removeLocalItem = (key: string) => {
  return window.localStorage && localStorage.removeItem(key);
};

/**
 * saving and fetching active sensor selection
 */
const LS_DYNAMIC_SUFFIX = ['activeSensors', 'notifSeenTs'];

const _getCacheKey = (username: string, cacheType: string) => {
  if (LS_DYNAMIC_SUFFIX.includes(cacheType)) {
    return `${getApiHttpPath()}::${username}::${cacheType}`;
  } else {
    throw new Error(`_getCacheKey: cacheType ${cacheType} is not valid`);
  }
};

// active sensors
export const saveActiveSensorsToLocalStorage = (username: string, activeSensors: number[]) => {
  if (username?.length === 0) {
    consoleLog('ERROR', 'saveActiveSensorsToLocalStorage: username is empty');
    return false;
  }
  const cacheKey = _getCacheKey(username, 'activeSensors');
  window.localStorage && localStorage.setItem(cacheKey, JSON.stringify(activeSensors));
  return true;
};

export const getActiveSensorsFromLocalStorage = (username: string) => {
  if (username?.length === 0) {
    return [];
  }
  const cacheKey = _getCacheKey(username, 'activeSensors');
  let activeSensors: number[] = [];
  try {
    activeSensors = (JSON.parse(localStorage.getItem(cacheKey) || (null as unknown as string)) as number[]) || [];
  } catch (_) {
    activeSensors = [];
  }
  return activeSensors;
};

// notifications
export const saveNotificationSeenTsToLocalStorage = (username: string, notifSeenTs: number) => {
  if (username?.length === 0) {
    consoleLog('ERROR', 'saveNotificationSeenTsToLocalStorage: username is empty');
    return false;
  }
  const cacheKey = _getCacheKey(username, 'notifSeenTs');
  window.localStorage && localStorage.setItem(cacheKey, notifSeenTs.toString());
  return true;
};

export const getNotificationSeenTsFromLocalStorage = (username: string) => {
  if (username?.length === 0) {
    return undefined;
  }
  const cacheKey = _getCacheKey(username, 'notifSeenTs');
  return window.localStorage && Number(localStorage.getItem(cacheKey));
};

const getPageSelectedPresetCacheKey = (page: string) => {
  return `${getApiHttpPath()}::selectedPreset::${page}`;
};

export const saveSelectedPresetForPage = (page: string, preset: string) => {
  if (!page || !preset) {
    consoleLog('ERROR', 'saveSelectedPresetForPage: page or preset is empty');
    return false;
  }

  const cacheKey = getPageSelectedPresetCacheKey(page);

  window.localStorage && localStorage.setItem(cacheKey, preset);

  return true;
};

export const getSavedSelectedPresetForPage = (page: string) => {
  if (!page) {
    return undefined;
  }

  const cacheKey = getPageSelectedPresetCacheKey(page);

  return window.localStorage && localStorage.getItem(cacheKey);
};

// pql history - annoying exception
// utils/pqlHistory.js

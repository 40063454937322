import React from 'react';
import styled from 'styled-components';

import Box from 'components/styled/Box';

const handleKeyPress = (e) => {
  if (e.charCode === 32 || e.charCode === 13) {
    e.preventDefault();
    e.target.click();
  }
};

const Button = styled(Box)`
  display: inline-flex;
  position: relative;
  flex: none;
  appearance: none;
  user-select: none;
  outline: none;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.03);
  height: 2.5em;
  min-width: 2.5em;
  padding: 0 0.68em;
  border-radius: 0.25em;
  &:hover,
  &:focus {
    box-shadow: inset 0 0 999em rgba(0, 0, 0, 0.1);
  }
  &:active,
  &.active {
    box-shadow: inset 0 0 999em rgba(0, 0, 0, 0.2);
  }
  &:after {
    display: none;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    background-color: ${({ theme }) =>
      theme.name === 'Dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(255, 255, 255, 0.35)'};
  }
  &[disabled] {
    pointer-events: none;
    &:after {
      display: block;
    }
  }
  &:not(button):not(select):not(input) {
    display: inline-grid;
    grid-gap: 0.68em;
    grid-auto-flow: column;
    align-content: center;
  }
`;

Button.defaultProps = {
  role: 'button',
  tabIndex: 0,
  onKeyPress: handleKeyPress,
};

export default React.forwardRef((props, ref) => <Button as="button" {...props} ref={ref} />);

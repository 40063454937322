import './polyfills';
import '@radix-ui/themes/styles.css';
import './css/radix-style-overrides.css';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import setupLocatorUI from '@locator/runtime';

import ErrorBoundary from 'components/common/ErrorBoundary';
import { isDevBuild, shouldInitSentry } from 'services/log/utils';

import ThemeProvider from 'services/theme';
import { queryClient } from 'services/api';
import { TasksProvider } from 'services/tasks';
import { AuthStateProvider } from 'services/auth';
import SensorServiceProvider from 'services/sensor';
import { SearchStateProvider } from 'services/search';
import { RecordStateProvider } from 'services/record';
import { UsersApiProvider } from 'services/users';

import Root from './Root';

if (import.meta.env.DEV) {
  setupLocatorUI();
}

if (shouldInitSentry) {
  Sentry.init({
    dsn: 'https://81b5d0e064354a39bd40740e072d04bd@o228377.ingest.sentry.io/1384246',
    environment: isDevBuild(window.location.hostname) ? 'development' : import.meta.env.MODE,
    release: `${import.meta.env.VITE_RELEASE_VERSION}`,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay({
        // https://docs.sentry.io/platforms/javascript/session-replay/privacy/#privacy-configuration
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.4,
    autoSessionTracking: true,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      /^ResizeObserver.*/,
      'Non-Error promise rejection', // seems like outlook safe-links spam
    ],
  });
}

const App = () => {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <ErrorBoundary>
          <ThemeProvider>
            <AuthStateProvider>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools position="bottom-right" />
                <TasksProvider>
                  <UsersApiProvider>
                    <SensorServiceProvider>
                      <SearchStateProvider>
                        <RecordStateProvider>
                          <Root />
                        </RecordStateProvider>
                      </SearchStateProvider>
                    </SensorServiceProvider>
                  </UsersApiProvider>
                </TasksProvider>
              </QueryClientProvider>
            </AuthStateProvider>
          </ThemeProvider>
        </ErrorBoundary>
      </HelmetProvider>
    </BrowserRouter>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

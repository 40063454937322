import React from 'react';

import Button from 'components/common/Button';
import ButtonsWrapper from 'components/common/ButtonsWrapper';
import ErrorMessage from 'components/common/ErrorMessage';
import Label from 'components/common/Label';
import Input from 'components/common/Input';
import { Error } from 'components/styled/Typography';

import Nope from 'utils/nope';
import { useForm } from 'utils/hooks';
import { extractErrorMessage } from 'utils/api';

const validationSchema = Nope.object().shape({
  name: Nope.string().required('Name is a required field').max(256, 'Name must be shorter than 256 characters'),
  description: Nope.string().max(1024, 'Description must be shorter than 1024 characters'),
  labels: Nope.string().max(1024, 'Labels must be shorter than 1024 characters'),
  query: Nope.string(),
});

const handleContainerClick = (e) => {
  e.stopPropagation();
  e.preventDefault();
};

const validate = (values) => {
  return validationSchema.validate(values);
};

const BookmarkForm = ({
  type,
  error,
  loading,
  bookmark,
  defaultName,
  defaultDescription,
  query,
  onClose,
  onSubmit,
}) => {
  const form = useForm({
    validate,
    initialValues: {
      name: bookmark.name || defaultName || query || '',
      description: bookmark.description || defaultDescription || '',
      labels: bookmark.labels ? bookmark.labels.join(', ') : '',
      ...(type === 'pql' ? { query: bookmark.query || query } : {}),
    },
    onSubmit,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    form.handleSubmit();
  };

  return (
    <div className="cursor-default" onClick={handleContainerClick}>
      <Label className="mb-1 mt-2" required htmlFor="bookmark-name">
        Name
      </Label>
      <Input
        id="bookmark-name"
        required
        name="name"
        value={form.values.name}
        onChange={form.handleChange}
        data-test-id="add-bookmark-name"
      />
      <ErrorMessage {...form} name="name" />

      <Label className="mb-1 mt-2">Description</Label>
      <Input
        name="description"
        value={form.values.description}
        onChange={form.handleChange}
        data-test-id="add-bookmark-description"
      />
      <ErrorMessage {...form} name="description" />

      <Label className="mb-1 mt-2">Labels</Label>
      <Input name="labels" value={form.values.labels} onChange={form.handleChange} data-test-id="add-bookmark-labels" />
      <ErrorMessage {...form} name="labels" />

      {type === 'pql' && (
        <>
          <Label className="mb-1 mt-2">Query</Label>
          <Input
            name="query"
            value={form.values.query}
            onChange={form.handleChange}
            data-test-id="add-bookmark-query"
          />
        </>
      )}
      {error && <Error>{extractErrorMessage(error)}</Error>}

      <ButtonsWrapper className="mt-6 justify-end">
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          isLoading={loading}
          data-test-id="add-bookmark-submit"
        >
          Submit
        </Button>
      </ButtonsWrapper>
    </div>
  );
};

export default React.memo(BookmarkForm);

import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import _intersection from 'lodash/intersection';

import { useCurrentUser } from 'services/auth';
import { useSensorList } from 'services/sensor';
import { SCENE } from 'consts/scenes';

import UnstyledLink from 'components/common/Link';
import Modal, { ModalHeader, ModalContent } from 'components/common/Modal';
import { InfoIcon } from 'components/common/Icons';
import { Subtitle } from 'components/styled/Typography';
import { Divider } from 'components/styled/Layout';

const Link = styled(UnstyledLink)`
  color: ${({ theme }) => theme.palette.primary[2]};
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.text.subtle};
  svg {
    margin-right: 4px;
  }
`;

const SCENES = Object.values(SCENE);

/**
 * we dont want to start loading dashboards if no sensors are present on the instance
 *
 **/
const SensorCheckWrapper = ({ children }) => {
  const { category, page } = useParams();
  const { sensorList } = useSensorList();
  const { user } = useCurrentUser();

  if (
    category &&
    ['dashboards', 'baseline', 'alerting', 'investigation', 'reporting'].includes(category) &&
    sensorList?.length === 0
  ) {
    return (
      <>
        <Modal open>
          <ModalHeader>
            <Subtitle>There are no sensors connected to your instance.</Subtitle>
          </ModalHeader>
          <ModalContent>
            <div className="font-semibold">Let's get started!</div>
            <p>
              The first step is to create a <Link to="/settings/tenants">Tenant</Link>, and then a{' '}
              <Link to="/settings/sensors">Sensor</Link> to receive data.
              <br />
              <Info>
                <InfoIcon /> There is no limit to the number of sensors you can assign to a tenant.
              </Info>
            </p>
            <p>
              1. <Link to="/settings/tenants">Click here</Link> to create a tenant.
            </p>
            <p>
              2. <Link to="/settings/sensors/new">Click here</Link> to create a sensor.
            </p>
            {user && user.username === 'provider' && (
              <>
                <hr />
                <p>You should also invite a user to an instance</p>
                <p>
                  3. <Link to="/settings/users/new">Click here</Link> to invite a new user.
                </p>
              </>
            )}
            <Divider height="24px" />
            <p>Reach out to support@mixmode.ai for help.</p>
          </ModalContent>
        </Modal>
      </>
    );
  }

  /**
   * CHECK to see if our available sensors are even applicable for dashboard we're trying to load
   * currently really only serves if user ONLY has flowlogs sensors installed
   */
  const availableSensorProfileTypes = sensorList?.reduce((acc, cur) => {
    acc.push(cur.Profile.profileType);
    return acc;
  }, []);
  const scene = SCENES.find((s) => s.path === `/${category}/${page}`);

  if (
    availableSensorProfileTypes &&
    scene &&
    _intersection(availableSensorProfileTypes, scene.profileTypes).length === 0
  ) {
    return (
      <>
        <Modal open>
          <ModalHeader>
            <Subtitle>No applicable sensors installed.</Subtitle>
          </ModalHeader>
          <ModalContent>
            <p>
              This dashboard supports sensors with profile types{' '}
              <span className="font-semibold">{scene.profileTypes.join(', ')}</span>.
            </p>
          </ModalContent>
        </Modal>
      </>
    );
  }

  return children;
};

export default SensorCheckWrapper;

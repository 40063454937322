import { rehydrateToken } from 'utils/token';

function handleDownload(blob, filename) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export class ApiError extends Error {
  constructor(message, status) {
    super(message);

    Error.captureStackTrace(this, ApiError);

    this.status = status;
    this.message = message;
  }
}

const handleResponse = (response, responseType, fileName) => {
  if (!response.ok) {
    throw new ApiError(response.statusText, response.status);
  }

  switch (responseType) {
    case 'json':
      return response.json();
    case 'blob':
      return response.blob().then((blob) => {
        handleDownload(blob, fileName);
      });
    default:
      return response;
  }
};

export const get = (url, headers = {}, responseType = 'json', fileName) => {
  return fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'x-mixmode-api-token': rehydrateToken(),
      ...headers,
    }),
  }).then((response) => handleResponse(response, responseType, fileName));
};

export const post = (url, data, headers = {}, responseType = 'json', fileName) => {
  return fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'x-mixmode-api-token': rehydrateToken(),
      ...headers,
    }),
    body: JSON.stringify(data),
  }).then((response) => handleResponse(response, responseType, fileName));
};

export const head = (url, headers = {}) => {
  return fetch(url, {
    method: 'HEAD',
    headers: new Headers({
      'Content-Type': 'application/json',
      'x-mixmode-api-token': rehydrateToken(),
      ...headers,
    }),
  }).then((response) => handleResponse(response, null));
};

import * as tokenUtils from 'utils/token';

const API_GLOBAL_VERSION = 'v1';

const API_PATH = import.meta.env.VITE_API_PATH as string | null | undefined;
const API_PORT = import.meta.env.VITE_API_PORT as string | null | undefined;
const API_ORIGIN = import.meta.env.VITE_API_ORIGIN as string | null | undefined;
const API_PROTOCOL = import.meta.env.VITE_API_PROTOCOL as string | null | undefined;
export const getHostname = () => {
  return API_PATH || window.location.hostname;
};

// read README.md for more info
function getPort() {
  if (import.meta.env.VITE_API_PORT != null) {
    // if empty string, return null
    return API_PORT || null;
  }

  return window.location.port || null;
}

export function getOrigin() {
  if (import.meta.env.DEV) {
    return API_ORIGIN;
  }

  const hostname = getHostname();
  const protocol = API_PROTOCOL || window.location.protocol.slice(0, -1) || 'https';

  const port = getPort();
  const portPart = port ? `:${port}` : '';

  return `${protocol}://${hostname}${portPart}`;
}

export const getBasePath = () => {
  return `${getOrigin()}/${API_GLOBAL_VERSION}`;
};

export const getApiHttpPath = () => {
  if (import.meta.env.DEV) {
    // local development proxy
    return `http://localhost:3300/${API_GLOBAL_VERSION}`;
  }

  return getBasePath();
};

export const getGqlPath = () => {
  return `${getOrigin()}/v1/graphql`;
};

export const getApiVersion = () => API_GLOBAL_VERSION;

export const logout = (token: string) => {
  tokenUtils.clearToken();

  window.location.href = `${getBasePath()}/logout?token=${token}&origin=${window.location.origin}`;
};

export { default as useApi } from './useApi';
export { default as useData } from './useData';
export { default as useGqlQuery } from './useGqlQuery';
export { default as useGqlMutation } from './useGqlMutation';
export { default as useLazyGqlQuery } from './useLazyGqlQuery';
export { default as queryClient } from './queryClient';

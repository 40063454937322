import { Helmet } from 'react-helmet-async';

const DocumentTitle = ({ title }: { title: string }) => {
  return (
    <Helmet defaultTitle="MixMode.ai" titleTemplate="%s - MixMode.ai">
      <title>{title}</title>
    </Helmet>
  );
};

export default DocumentTitle;

import styled from 'styled-components';

const ButtonsWrapperStyle = styled.div`
  display: flex;

  button:not(:first-of-type) {
    margin-left: ${({ $spacing }) => $spacing || '12px'};
  }
`;

const ButtonsWrapper = ({ children, spacing, ...props }) => {
  return (
    <ButtonsWrapperStyle {...props} $spacing={spacing}>
      {children}
    </ButtonsWrapperStyle>
  );
};

export default ButtonsWrapper;

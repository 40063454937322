import _isNil from 'lodash/isNil';
import _isEqual from 'lodash/isEqual';
import _some from 'lodash/some';
import _pipe from 'lodash/fp/pipe';
import _omit from 'lodash/omit';

import { flattenNestedPaths } from 'utils/records';

import { SEARCH_TYPE } from './consts';

export const applyAllFilters = (data, filterList, applyAi) => {
  let filteredData = data;
  const itemCount = filterList.length;
  if (itemCount > 0) {
    for (var i = 0; i < itemCount; i++) {
      let { propKey, value } = filterList[i];
      filteredData = applyNewFilter({ propKey, value }, filteredData);
    }
  }
  return applyAi ? filterWithAi(filteredData) : filteredData;
};

export const applyNewFilter = ({ propKey, value }, filteredData) => {
  return filteredData.filter((item) => {
    if (Array.isArray(item[propKey])) {
      return _some(item[propKey], (val) =>
        _isEqual(
          Array.isArray(value) ? value[0].toString() : value.toString(),
          !_isNil(val) ? val.toString() : null, // some old records can get `affectedHosts: [null]`
        ),
      );
    }

    return _isEqual(String(item[propKey]), String(value));
  });
};

/**
 * processing functions
 **/

const pullDataFromLog = (records) => {
  // pull record from "data" object, disregard unwanted attributes
  return records.map((item) => {
    const root = _omit(item, ['data', 'ts_es_created_millis', 'ts_sensor_millis', 'providerId']);
    const data = _omit(item.data, ['providerId', 'ps_family', 'ps_proto']);

    return { ...data, ...root };
  });
};

// const omitNilsFromKeyInArray = (key, array) => {
//   return array.map(item => ({
//     ...item,
//     [key]: item[key].filter(subItem => subItem !== null && subItem !== undefined),
//   }));
// };

// should be removed after API starts normalizing these scores
// const normalizeScore = (scoreKey, records) => {
//   return records.map(item => {
//     if (Number.isInteger(item[scoreKey])) {
//       let newValue = item[scoreKey];
//       if (item[scoreKey] > 10) {
//         newValue = 10;
//       } else if (item[scoreKey] < 1) {
//         newValue = 1;
//       }
//       return Object.assign(item, { [scoreKey]: newValue });
//     }
//     return item;
//   });
// };

// inject severity by reverse calculating from impact_score
export const injectSeverity = (records) => {
  return records.map((item) => {
    const severity =
      item['impact_score'] && item['deviation_score'] ? (10 * item['impact_score']) / item['deviation_score'] : null;
    return Object.assign(item, { severity: severity });
  });
};

const setDefaultEventStatus = (records) => {
  return records.map((item) => {
    const status = item.status || 'open';
    const assigned = item.assigned || 'unassigned';
    return Object.assign(item, { status: status, assigned: assigned });
  });
};

// stringify object paths in logs, ie "reputation.malware_presence.status"
const flattenDeepObjectPaths = (records) => {
  return records.map((item) => flattenNestedPaths(item));
};

// process fetched records
export function processResults(searchType, results) {
  if (searchType === SEARCH_TYPE.events) {
    return _pipe(injectSeverity, setDefaultEventStatus)(results);
  }

  if (searchType === SEARCH_TYPE.investigator) {
    return _pipe(pullDataFromLog, flattenDeepObjectPaths)(results);
  }

  return results;
}

export const isReputationRecord = (item) => item.labels && item.labels.includes('reputation');

export const reputationSeverityTen = (item) => isReputationRecord(item) && item['severity'] > 9;

export function filterWithAi(collection) {
  return collection.filter((item) => {
    // AI criteria
    if (item['deviation_score'] > 9 || reputationSeverityTen(item)) {
      return true;
    }

    return false;
  });
}

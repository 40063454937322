import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import isValid from 'date-fns/isValid';
import { required, resolveNopeRefs } from './shared';

const isValidDate = (message) => (value, context) => {
  const resolvedDate = resolveNopeRefs(value, context);

  return !isValid(new Date(resolvedDate)) && message;
};

const before = (date, message) => (value, context) => {
  const resolvedDate = resolveNopeRefs(date, context);

  return !isBefore(new Date(value), new Date(resolvedDate)) && message;
};

const after = (date, message) => (value, context) => {
  const resolvedDate = resolveNopeRefs(date, context);

  return !isAfter(new Date(value), new Date(resolvedDate)) && message;
};

class NopeDate {
  validationRules = [];

  required(message = 'This field is required') {
    this.validationRules.push(required(message));

    return this;
  }

  valid(message = 'Input is not a valid date') {
    this.validationRules.push(isValidDate(message));

    return this;
  }

  before(date, message = `Date must be before ${date}`) {
    this.validationRules.push(before(date, message));

    return this;
  }

  after(date, message = `Date must be after ${date}`) {
    this.validationRules.push(after(date, message));

    return this;
  }

  validate(entry, context, objectShape) {
    for (let fn of this.validationRules) {
      const error = fn(entry, context, objectShape);

      if (error) {
        return error;
      }
    }
  }
}

export default NopeDate;

import { API_TOKEN_STORAGE_KEY } from 'consts/config';

export function persistToken(token: string) {
  if (window.sessionStorage) {
    window.sessionStorage.setItem(API_TOKEN_STORAGE_KEY, token);
  }
}

export function clearToken() {
  if (window.sessionStorage) {
    window.sessionStorage.removeItem(API_TOKEN_STORAGE_KEY);
  }
}

export function rehydrateToken() {
  if (window.sessionStorage) {
    return window.sessionStorage.getItem(API_TOKEN_STORAGE_KEY);
  }
  return null;
}

export function decodeAuthToken<
  T = {
    id: number;
    iat: number;
    exp: number;
    dn: number;
  },
>(token: string): T {
  return JSON.parse(atob(token.split('.')[1])) as T;
}

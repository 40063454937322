// wrapper around jsCookie

import jsCookie from 'js-cookie';

export function getCookie(name: string): string | boolean | undefined {
  const res = jsCookie.get(name);
  return res === 'true' ? true : res === 'false' ? false : res;
}

export type CookieOptions = {
  expires?: number;
  path?: string;
};

// default expiration is 7 days
export function setCookie(name: string, value: string, options: CookieOptions = { expires: 7 }): void {
  jsCookie.set(name, value, options);
}

export function removeCookie(name: string): void {
  jsCookie.remove(name);
}

export function setOrRemoveCookie(name: string, value: string | null | undefined, expires: number = 7): void {
  if (value == null) {
    removeCookie(name);
  } else {
    setCookie(name, value, { expires });
  }
}

/**
 * wrapper for icons used in the project
 * all icons should be consumed through the wrapper, instead of directly importing from package
 **/

import AiIconLocal from './AiIcon';
import ExtendableFileIcon from './FileIcon/components';
import * as ReactIcons from './ReactIcons';
export * from './ReactIcons';

export const FileIcon = ExtendableFileIcon;
export const AiIcon = AiIconLocal;
import ExternalEntity from './assets/externalEntity.svg?react';
import LocalEntity from './assets/localEntity.svg?react';

export { ExternalEntity, LocalEntity };

export const iconMap = {
  AiIcon: ReactIcons.Codesandbox,
  HomeIcon: ReactIcons.HomeIcon,
  ActivityIcon: ReactIcons.ActivityIcon,
  CpuIcon: ReactIcons.CpuIcon,
  CrosshairIcon: ReactIcons.CrosshairIcon,
  DataRecorderIcon: ReactIcons.DataRecorderIcon,
  ExternalLinkIcon: ReactIcons.ExternalLinkIcon,
  FileTextIcon: ReactIcons.FileTextIcon,
  TaskIcon: ReactIcons.FilterIcon,
  IndicatorIcon: ReactIcons.IndicatorIcon,
  GlobeIcon: ReactIcons.GlobeIcon,
  GridIcon: ReactIcons.GridIcon,
  NetworkIcon: ReactIcons.NetworkIcon,
  TerminalIcon: ReactIcons.TerminalIcon,
  UsersIcon: ReactIcons.UsersIcon,
  PlayIcon: ReactIcons.PlayIcon,
  BaselineIcon: ReactIcons.BaselineIcon,
  CisoIcon: ReactIcons.LayersIcon,
  SettingsIcon: ReactIcons.SettingsIcon,
  CloudServerIcon: ReactIcons.FlowlogsIcon,
  CloudIcon: ReactIcons.CloudIcon,
  DatabaseIcon: ReactIcons.DatabaseIcon,
  IDMIcon: ReactIcons.IDMIcon,
  AppIcon: ReactIcons.AppIcon,
  ServerIcon: ReactIcons.ServerIcon,
  UnknownIcon: ReactIcons.UnknownIcon,
  IntegrationIcon: ReactIcons.IntegrationIcon,
};

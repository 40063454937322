import { palette as p } from './utils';
import defaultTheme from './reakit-theme-default';

const commonTheme = {
  base: {
    fontHeader: `'IBM Plex Sans', sans-serif`,
    fontText: `'IBM Plex Sans', sans-serif`,
    fontCode: `'IBM Plex Mono', monospace`,
  },
  colors: {
    primary: '#f1961d',
    secondary: '#7697e7',
    source: ['#617FC8', '#ca5fd0', '#66bb6a', '#ffca28', '#ef5350', '#0BC5EA', '#B794F4', '#fa744f'],
    loader: {
      primary: p('white'),
      secondary: p('primary, 2'),
      pageGradientFrom: 'rgba(20, 20, 20, 0.8)',
      pageGradientTo: 'rgba(0, 0, 0, 0.8)',
      pageSpinnerColor: 'rgba(255, 255, 255, 0.75)',
      subtlePageSpinnerColor: 'rgba(100, 100, 100, 0.75)',
    },
    mainmenu: {
      background: '#1b1b1b',
      divider: '#a3a3a3',
      text: '#d2d2d2',
      textDisabled: '#5f5f5f',
      textAccent: p('white'),
      itemActive: 'rgba(255, 165, 0, 0.4)',
    },
  },
  // extending reakit palette
  palette: {
    ...defaultTheme.palette,
    // default primary => primary[2]
    // https://hihayk.github.io/scale/#2/2/30/50/0/0/20/14/F1961D/241/150/29
    primary: ['#B36B0A', '#D38012', '#F1961D', '#FCB14E', '#FFCC83'],
    primaryText: [p('white'), p('white'), p('white'), p('black'), p('black')],

    // default secondary => secondary[2]
    // https://hihayk.github.io/scale/#2/2/30/50/0/0/20/14/7697E7/118/151/231
    secondary: ['#4D67A7', '#617FC8', '#7697E7', '#94AFF1', '#B5C9F9', '#dde7fd'],
    secondaryText: [p('white'), p('white'), p('black'), p('black'), p('black')],

    // https://coolors.co/f44336-ef5350-e57373-ef9a9a-ffcdd2
    danger: ['#f44336', '#ef5350', '#e57373', '#ef9a9a', '#ffcdd2'],
    dangerText: [p('white'), p('white'), p('black'), p('black'), p('black')],

    // https://coolors.co/ffc107-ffca28-ffd54f-ffe082-ffecb3
    alert: ['#ffc107', '#ffca28', '#ffd54f', '#ffe082', '#ffecb3'],
    alertText: [p('black'), p('black'), p('black'), p('black'), p('black')],

    orangeYellow: '#F5B800',

    // https://coolors.co/4caf50-66bb6a-81c784-a5d6a7-c8e6c9
    success: ['#4caf50', '#66bb6a', '#81c784', '#a5d6a7', '#c8e6c9'],
    successText: [p('white'), p('white'), p('white'), p('black'), p('black')],

    white: '#ffffff',
    whiteText: p('black'),

    black: '#1d1d1d',
    blackText: p('white'),
  },

  recharts: {
    tooltip: {
      cursor: {
        fill: 'rgba(120, 120, 120, 0.3)',
      },
    },
  },
};

export default commonTheme;

import styled from 'styled-components';

import MixModeLogoLoader from './MixModeLogoLoader';

const Container = styled.div`
  z-index: 9000;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: relative;
  height: 400px;
`;

const ModalLoader = () => {
  return (
    <Container>
      <MixModeLogoLoader top={70} />
    </Container>
  );
};

export default ModalLoader;

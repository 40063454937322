import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthState } from 'services/auth';
import { useForm } from 'utils/hooks';
import Button from 'components/common/Button';
import ButtonLink from 'components/common/ButtonLink';
import Input from 'components/common/Input';
import ErrorMessage, { ErrorMessageComponent } from 'components/common/ErrorMessage';

import AuthWrapper from './AuthWrapper';
import { AuthLink } from './common.styled';

const validate = ({ email }) => {
  const errors = {};

  if (!email) {
    errors.email = 'Please enter a valid email address.';
  }

  return errors;
};

const ForgotPasswordPage = ({ ssoBypass }) => {
  const {
    resetPassword,
    state: { passwordResetError },
  } = useContext(AuthState);
  const location = useLocation();

  // grab these from the location state because the auth wrapper will clear errors on page load
  const { authenticationError, isForceResetError } = location.state || {};

  const [successMessage, setSuccessMessage] = useState(null);

  const form = useForm({
    initialValues: { email: '' },
    validate,
    onSubmit: ({ email }, { setIsSubmitting }) => {
      setIsSubmitting(true);

      resetPassword(email)
        .then(() => {
          setIsSubmitting(false);
          setSuccessMessage(`Email with reset password link sent to ${email} `);
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    },
  });

  if (successMessage && !passwordResetError) {
    return (
      <AuthWrapper>
        <p className="mt-6 text-center">
          {successMessage}
          <ButtonLink color="primary" variant="text" className="mt-6" to={ssoBypass ? '/user/login' : '/login'}>
            Back to login
          </ButtonLink>
        </p>
      </AuthWrapper>
    );
  }

  return (
    <AuthWrapper>
      <div className="flex flex-col items-center mt-6">
        <p className="mb-2">
          Enter the email address for the account you are having trouble logging in to. An email will be sent with a
          password reset link.
        </p>
        <div className="flex flex-col w-full">
          <label htmlFor="email" className="mb-1">
            Email
          </label>
          <Input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            data-test-id="forgot-password-email-input"
            onChange={form.handleChange}
            value={form.values.email}
          />
        </div>
        <ErrorMessage {...form} name="email" className="w-full" />
        <div className="flex justify-between w-full">
          <AuthLink data-test-id="back-to-login-link" className="mt-4" to={ssoBypass ? '/user/login' : '/login'}>
            Back to login
          </AuthLink>
          <Button
            data-test-id="forgot-password-submit-button"
            className="mt-4"
            color="primary"
            variant="contained"
            type="submit"
            disabled={form.isSubmitting}
            onClick={form.handleSubmit}
          >
            Reset Password
          </Button>
        </div>
      </div>

      {isForceResetError && authenticationError && <ErrorMessageComponent>{authenticationError}</ErrorMessageComponent>}
      {passwordResetError && <ErrorMessageComponent>{passwordResetError}</ErrorMessageComponent>}
    </AuthWrapper>
  );
};

export default ForgotPasswordPage;

import React from 'react';
import styled, { css } from 'styled-components';

import Button from 'components/common/Button';
import { PlayIcon, DisabledIcon, FastForwardIcon } from 'components/common/Icons';
import Tooltip from 'components/common/Tooltip';

const StatusContainer = styled(Button)`
  min-width: 100px;
  margin-right: 12px;
`;

const StatusColor = css`
  ${({ $status, theme }) => {
    switch ($status) {
      case 'executing':
        return theme.palette.primary[2];
      case 'running':
      case 'active':
        return theme.palette.success[0];
      case 'disabled':
        return theme.palette.danger[2];
      default:
        return theme.colors.text.subtle;
    }
  }};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${StatusColor};
  svg {
    margin-right: 4px;
  }
`;

// translates api values to ui text
export const getStatusText = (status) => {
  switch (status) {
    case 'running':
    case 'idle':
      return 'active';
    default:
      return status;
  }
};

const StatusIcon = {
  executing: FastForwardIcon,
  active: PlayIcon,
  disabled: DisabledIcon,
};

const StatusButton = ({ status, onToggle, isLoading, disabled }) => {
  const Icon = StatusIcon[status];
  return (
    <Tooltip placement="right" overlay="Toggle status">
      <StatusContainer
        data-test-id="toggle-status-button"
        variant="text"
        onClick={onToggle}
        disabled={disabled || isLoading}
        isLoading={isLoading}
      >
        <IconContainer $status={status}>
          <Icon aria-hidden /> {getStatusText(status)}
        </IconContainer>
      </StatusContainer>
    </Tooltip>
  );
};

export default StatusButton;

import React from 'react';
import styled, { css } from 'styled-components';

import { slowSpinAnimation } from 'components/styled/animations';
import { LoaderIcon } from 'components/common/Icons';

const Wrapper = styled.div<{
  $subtle?: boolean;
}>`
  display: flex;
  font-size: inherit;
  align-items: center;

  ${({ $subtle }) =>
    $subtle &&
    css`
      color: ${({ theme }) => theme.colors.text.subtle};
    `}

  svg {
    animation: ${slowSpinAnimation};
    margin-right: 0.5em;
  }
`;

type InlineLoaderProps = {
  msg: string;
  subtle?: boolean;
};

const InlineLoader = ({ msg, subtle, ...rest }: InlineLoaderProps) => (
  <Wrapper $subtle={subtle} {...rest}>
    <LoaderIcon /> {msg}
  </Wrapper>
);

export default React.memo(InlineLoader);

import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import ActionsMenu from 'components/common/ActionsMenu';
import { Subtitle as DefaultSubtitle } from 'components/styled/Typography';

export { default as SearchInput } from './SearchInput';
export { default as SensorAssignmentButton } from './SensorAssignmentButton';
export { default as StatusButton } from './StatusButton';
export { default as useSearchFilter } from './useSearchFilter';

export const ActionsMenuWithSearch = styled(ActionsMenu)`
  justify-content: space-between;
`;

export const ButtonGroup = styled.div`
  display: flex;
  button {
    margin-right: 12px;
    svg {
      margin-right: 4px;
    }
  }
`;

export const Subtitle = styled(DefaultSubtitle)`
  margin-top: 20px;
`;

export const StatusColor = css`
  ${({ $status, theme }) => {
    switch ($status) {
      case 'executing':
        return theme.palette.primary[2];
      case 'running':
      case 'active':
        return theme.palette.success[0];
      case 'disabled':
        return theme.palette.danger[2];
      default:
        return theme.colors.text.subtle;
    }
  }};
`;

const ItemContainerStyled = styled(motion.div)`
  display: flex;
  cursor: ${({ $hasOnClickAttached }) => ($hasOnClickAttached ? 'pointer' : 'default')};
  border: 1px solid ${({ theme }) => theme.colors.border.alt1};
  border-radius: 2px;
  padding: 6px 12px;
  margin-bottom: 4px;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;

  svg {
    font-size: 18px;
  }

  &:hover {
    ${({ $hasOnClickAttached }) =>
      $hasOnClickAttached &&
      css`
        border-color: ${({ theme }) => theme.colors.border.default};
      `}
  }

  &:last-child {
    margin-bottom: 64px;
  }
`;

export const ItemContainer = ({ children, onClick, ...props }) => (
  <ItemContainerStyled {...props} $hasOnClickAttached={!!onClick} onClick={onClick}>
    {children}
  </ItemContainerStyled>
);

export const IconContainer = styled.div`
  display: flex;
  margin: 0 36px 0 12px;
  color: ${({ theme }) => theme.colors.text.primary};
  svg {
    font-size: 20;
  }
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${(props) => props.$minWidth || '120px'};
`;

export const DataWrapperSpread = styled(DataWrapper)`
  flex: 1;
`;

export const Name = styled.div`
  font-weight: 600;
`;

export const SmallText = styled.div`
  font-size: 12px;
  line-height: 1.5;
`;

export const ErrorContainer = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  color: ${({ theme }) => theme.palette.danger[2]};
`;

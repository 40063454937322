import { useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import _uniq from 'lodash/uniq';
import _isEqual from 'lodash/isEqual';
import { useQueryClient } from '@tanstack/react-query';

import log from 'services/log';
import { useApi } from 'services/api';
import { useStore, useStoreBase } from 'services/store';
import { SensorsDispatch } from 'services/sensor/SensorsProvider';
import filterSensorsByDebugFlag from 'services/sensor/filterSensorsByDebugFlag';
import { extractErrorMessage } from 'utils/api';

const selector = (state) => ({
  setLoading: state.dashboardConfiguration.setLoading,
  setInitialDashboardConfig: state.dashboardConfiguration.setInitialDashboardConfig,
});

const InitialBootstrap = () => {
  const api = useApi();
  const { setInitialDashboardConfig, setLoading } = useStore(selector);
  const sensorsDispatchCtx = useContext(SensorsDispatch);
  const queryClient = useQueryClient();

  useEffect(() => {
    return useStoreBase.subscribe((newState, previousState) => {
      if (
        // skip when the axios instance isn't instantiated yet
        !api ||
        // skip when the config is the same
        _isEqual(newState.dashboardConfiguration.config, previousState.dashboardConfiguration.config) ||
        // skip when the config is loading
        newState.dashboardConfiguration.isLoading ||
        // skip when the config is initially loaded
        (newState.dashboardConfiguration.isLoading === false && previousState.dashboardConfiguration.isLoading === true)
      ) {
        return;
      }

      api
        .post('/dashboards', {
          config: JSON.stringify(newState.dashboardConfiguration.config),
        })
        .catch((error) => {
          log({
            type: 'error',
            message: error.message,
            origin: 'InitialBootstrap',
            info: 'failed config save',
          });
        });
    });
  }, [api]);

  useEffect(() => {
    ReactDOM.unstable_batchedUpdates(() => {
      sensorsDispatchCtx.dispatch({ type: 'GET_SENSORS' });
      setLoading(true);
    });

    Promise.allSettled([api.get('/sensors'), api.get('/dashboards'), api.get('/integrations')]).then(
      ([sensorsResponse, dashboardsResponse, integrationsResponse]) => {
        ReactDOM.unstable_batchedUpdates(() => {
          if (sensorsResponse.status === 'fulfilled') {
            sensorsDispatchCtx.setFetchedSensors(sensorsResponse.value);
          } else {
            sensorsDispatchCtx.dispatch({
              type: 'GET_SENSORS_FAIL',
              error: extractErrorMessage(sensorsResponse.value),
            });
            if (sensorsDispatchCtx.reason?.config?.status !== 401) {
              log({
                type: 'error',
                origin: 'InitialBootstrap',
                message: JSON.stringify(sensorsResponse),
              });
            }
          }

          const sensors = sensorsResponse.value?.items || [];

          const sensorProfileTypes = _uniq(
            filterSensorsByDebugFlag(sensors)
              .map((s) => s?.Profile?.profileType)
              .filter(Boolean),
          );

          setInitialDashboardConfig({ config: dashboardsResponse.value?.config || '{}', sensorProfileTypes });

          if (integrationsResponse.status === 'fulfilled') {
            queryClient.setQueryData(['integrations'], integrationsResponse.value);
          }
        });
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default InitialBootstrap;

export const getPageSelectedPresetName = (state, page) => {
  const { selectedPreset } = state.dashboardConfiguration;

  return selectedPreset[page];
};

export const getPageCustomConfigurationPreset = (state, page) => {
  const { config } = state.dashboardConfiguration;

  return config.presets[page].custom;
};

export const getPageSelectedPresetConfiguration = (state, page) => {
  const { config, selectedPreset } = state.dashboardConfiguration;

  return config.presets[page][selectedPreset[page]];
};

export const getColumnsConfig = (state, page, log) => {
  const { columnsConfig } = state.dashboardConfiguration.config;

  if (log) {
    return columnsConfig.log[log] || [];
  }

  return columnsConfig[page] || [];
};

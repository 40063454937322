import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Box from 'components/styled/Box';

const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const getHeight = ({ size }) => {
  switch (size) {
    case SIZES.SMALL:
      return '30px';
    case SIZES.LARGE:
      return '60px';
    case SIZES.MEDIUM:
    default:
      return '40px';
  }
};

const StyledInput = styled(Box)`
  display: block;
  width: 100%;
  padding: 0 0.5em;
  height: 2.5em;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.border.contrast};

  &[type='checkbox'],
  &[type='radio'] {
    display: inline-block;
    width: auto;
    height: auto;
    padding: 0;
  }

  &::placeholder {
    color: currentcolor;
    opacity: 0.5;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  textarea & {
    padding: 0.5em;
    height: auto;
  }

  height: ${getHeight};
  width: ${({ $fullWidth }) => $fullWidth && '100%'};
  color: ${({ theme }) => theme.colors.text.primary};
  background-color: ${({ theme }) => theme.colors.background.alt2};
`;

StyledInput.propTypes = {
  size: PropTypes.oneOf([SIZES.SMALL, SIZES.MEDIUM, SIZES.LARGE]),
  $fullWidth: PropTypes.bool,
};

export default React.forwardRef(({ fullWidth, ...props }, ref) => (
  <StyledInput {...props} ref={ref} as="input" type={props.type || 'text'} $fullWidth={fullWidth} />
));

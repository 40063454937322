import { useMemo } from 'react';

import { useGqlQuery } from 'services/api';
import log from 'services/log';

const SENSOR_STATUS_DATA = `
  query GetSensorStatuses($sensors: [BigInt!]!) {
    SensorsStatus(sensors: $sensors) {
      operationalStatus
      sensorId
      aiOperatorState {
        operatorId,
        createdAt,
        state,
      }
    }
  }
`;

/**
 * @param {Array} sensors - List of sensor objects
 */
const useCheckSensorStatus = (sensors) => {
  const variables = {
    sensors: sensors?.map((s) => s.id).sort((a, b) => a - b),
  };

  const { data, isLoading } = useGqlQuery(SENSOR_STATUS_DATA, {
    variables,
    queryKey: ['GetSensorStatuses', variables],
    enabled: sensors != null,
    refetchInterval: 10 * 60 * 1000,
  });

  const result = useMemo(() => {
    const sensorStatuses = data?.SensorsStatus || [];
    const offlineSensorCount = sensorStatuses.filter((s) => !s?.operationalStatus).length;

    const inTraining = sensorStatuses.reduce((acc, sensor) => {
      for (let index = 0; index < sensor.aiOperatorState.length; index++) {
        const operator = sensor.aiOperatorState[index];
        if (operator.state === 'learning') {
          acc.push({
            ...operator,
            sensorId: sensor.sensorId,
            operationalStatus: sensor.operationalStatus,
          });
        }

        if (operator.state === 'unknown') {
          // bad stuff, should never happen
          log({
            type: 'warning',
            message: `AI Operator ${operator.operatorId} is in an unknown state`,
            origin: 'useCheckSensorStatus',
            info: {
              sensorId: sensor.sensorId,
              operatorId: operator.operatorId,
            },
          });
        }
      }
      return acc;
    }, []);

    return {
      isLoading,
      sensorStatuses: data?.SensorsStatus || [],
      offlineSensorCount,
      areAnySensorsOffline: offlineSensorCount > 0,
      areAnySensorsInTraining: inTraining.length > 0,
    };
  }, [data, isLoading]);

  return result;
};

export default useCheckSensorStatus;

import Button from 'components/common/Button';

import { ButtonGroup } from './index.styled';

const NotificationActions = ({ onAck, groupId }) => {
  const handleAck = (e) => {
    e.stopPropagation();
    onAck(groupId);
  };

  return (
    <ButtonGroup>
      <Button size="small" variant="accented" color="success" onClick={handleAck}>
        Acknowledge
      </Button>
    </ButtonGroup>
  );
};

export default NotificationActions;

import React from 'react';
import ScrollbarCustom from 'react-scrollbars-custom';

export const scrollbarDefaultProps = {
  removeTracksWhenNotUsed: true,
  trackYProps: {
    style: {
      width: 7,
    },
  },
  trackXProps: {
    style: {
      height: 7,
    },
  },
};

const Scrollbar = (props) => {
  return (
    <ScrollbarCustom
      removeTracksWhenNotUsed={scrollbarDefaultProps.removeTracksWhenNotUsed}
      trackYProps={scrollbarDefaultProps.trackYProps}
      trackXProps={scrollbarDefaultProps.trackXProps}
      {...props}
    />
  );
};

export default Scrollbar;

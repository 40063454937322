import styled, { css } from 'styled-components';

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 200ms ease-in-out;

  ${({ $open }) =>
    $open
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}
`;

export const ModalContainer = styled.div`
  /* display: ${({ open }) => (open ? 'block' : 'none')}; */
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: none;
  overflow-x: hidden;
  z-index: 10000;
`;

const getModalWidth = ({ $size }) => {
  switch ($size) {
    case 'small':
      return 480;
    case 'large':
      return 900;
    case 'extraLarge':
      return 1100;
    case 'normal':
    default:
      return 700;
  }
};

export const ModalBody = styled.div`
  position: fixed;
  top: ${({ $top }) => $top};
  left: 50%;
  transform: translate(-50%, -${({ $top }) => $top});
  width: ${getModalWidth}px;
  background-color: ${({ theme }) => theme.colors.background.modal};
  z-index: 10000;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const CloseWrapper = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 16px;
  right: 16px;
  transform: scale(1.5);
  cursor: pointer;
  color: ${({ theme }) => theme.colors.default};
`;

export const ModalHeader = styled.div`
  font-family: ${({ theme }) => theme.base.fontHeader};
  font-size: 24px;
  font-weight: 600;
  padding: 24px 40px 0;
`;

export const ModalContent = styled.div`
  padding: 24px 40px;
`;

export const ModalContentFit = styled.div`
  padding: 24px 0;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 40px 24px;

  button:not(:last-of-type) {
    margin-right: 8px;
  }
`;

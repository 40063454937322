import styled from 'styled-components';
import Button from 'components/common/Button';

const NavButton = styled(Button)`
  display: flex;
  align-items: center;
  min-width: 24px;
  height: 24px;
  font-size: 24px;
  border: none;
  background: 'red';

  &:visited {
    color: ${({ theme }) => theme.colors.default};
  }

  &:hover {
    background: transparent;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export default NavButton;

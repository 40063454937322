import styled, { css } from 'styled-components';

import Portal from 'components/common/Portal';

import MixModeLogoLoader from './MixModeLogoLoader';

const backgroundGradientCSS = css`
  background: radial-gradient(
    ${({ theme }) => theme.colors.loader.pageGradientFrom},
    ${({ theme }) => theme.colors.loader.pageGradientTo}
  );
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 90;
  top: 0;
  left: 0;
  ${backgroundGradientCSS}
`;

const PageLoader = (props) => {
  return (
    <Portal>
      <LoaderContainer {...props}>
        <MixModeLogoLoader variant="Light" />
      </LoaderContainer>
    </Portal>
  );
};

export default PageLoader;

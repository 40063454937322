import Flex from 'components/shared/Flex';
import Heading from 'components/shared/Heading';
import Text from 'components/shared/Text';

type TitleProps = {
  title: string;
  description?: string;
};

const Title = ({ title, description }: TitleProps) => {
  return (
    <Flex direction="column" align="start">
      <Heading size="5" weight="regular">
        {title}
      </Heading>
      <Text size="3" className="text-gray-11 h-6">
        {description || null}
      </Text>
    </Flex>
  );
};

type PageHeaderProps = {
  title: string;
  description?: string;
  children?: React.ReactNode;
};

const PageHeader = ({ children, title, description }: PageHeaderProps) => {
  return (
    <Flex align="center" justify="between" width="100%" pt="4" pb="4">
      <Title title={title} description={description} />
      <Flex align="center" gap="4">
        {children}
      </Flex>
    </Flex>
  );
};

export default PageHeader;

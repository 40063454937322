import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { usePrevious } from 'utils/hooks';

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);
  useEffect(() => {
    if (pathname?.split?.('/')?.[1] !== prevPathname?.split?.('/')?.[1]) {
      window.scrollTo(0, 0);
    }
  }, [pathname, prevPathname]);

  return children;
};

export default ScrollToTop;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: ${({ $top }) => $top}px;
  left: 0;
  height: 100vh;
  overflow: auto;
  z-index: 1050;
  color: ${({ theme }) => theme.colors.text.primary};
  background-color: ${({ theme }) => theme.colors.background.alt1};
  transform: translate3d(0, 0, 0);
  transition:
    all 200ms linear,
    top 0ms;

  ${({ $visible }) =>
    $visible
      ? css`
          width: 75%;
          min-width: 25%;
          padding: 32px 16px 16px;
        `
      : css`
          width: 0%;
        `}
`;

export const NavActionsWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 14px;
  right: 16px;
`;

import styled from 'styled-components';

const ActionsMenu = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  width: 100%;
  height: 40px;
`;

export default ActionsMenu;

import { LS_KEYS, getDefaultForKey, getLocalItem, setLocalItem } from 'utils/localStorage';

const shouldDisplaySwitchDefaultValue =
  (getLocalItem(LS_KEYS.executiveSummaryDisplayRiskSwitch.key) ||
    getDefaultForKey(LS_KEYS.executiveSummaryDisplayRiskSwitch.key)) === 'true';

const createDashboardDraft = (set) => ({
  executiveSummaryDisplayRiskSwitch: shouldDisplaySwitchDefaultValue,
  toggleRiskSwitchOnExecutiveSummary: () => {
    return set((prev) => {
      const newShouldExecutiveSummaryDisplayRisk = !(prev.userSettings.executiveSummaryDisplayRiskSwitch === true);

      setLocalItem(LS_KEYS.executiveSummaryDisplayRiskSwitch.key, String(newShouldExecutiveSummaryDisplayRisk));

      return {
        userSettings: {
          ...prev.userSettings,
          executiveSummaryDisplayRiskSwitch: newShouldExecutiveSummaryDisplayRisk,
          ...(newShouldExecutiveSummaryDisplayRisk ? {} : { executiveSummaryDisplayRiskInsteadDeviation: false }),
        },
      };
    });
  },

  executiveSummaryDisplayRiskInsteadDeviation: shouldDisplaySwitchDefaultValue
    ? (getLocalItem(LS_KEYS.executiveSummaryDisplayRiskInsteadDeviation.key) ||
        getDefaultForKey(LS_KEYS.executiveSummaryDisplayRiskInsteadDeviation.key)) === 'true'
    : false,
  toggleShowRiskInsteadDeviationOnExecutiveSummary: () => {
    return set((prev) => {
      const newShouldExecutiveSummaryDisplayRisk = !(
        prev.userSettings.executiveSummaryDisplayRiskInsteadDeviation === true
      );

      setLocalItem(
        LS_KEYS.executiveSummaryDisplayRiskInsteadDeviation.key,
        String(newShouldExecutiveSummaryDisplayRisk),
      );

      return {
        userSettings: {
          ...prev.userSettings,
          executiveSummaryDisplayRiskInsteadDeviation: newShouldExecutiveSummaryDisplayRisk,
        },
      };
    });
  },
});

export default createDashboardDraft;

import { ProfilesStateProvider } from './ProfilesProvider';
import { TenantsStateProvider } from './TenantsProvider';
import { SensorsStateProvider } from './SensorsProvider';

//
export { TenantsState, TenantsStateProvider } from './TenantsProvider';
export { ProfilesState } from './ProfilesProvider';

// accessors
export { useTenantsState } from './TenantsProvider';
export { default as useSensorList } from './SensorsProvider/useSensorList';
export { default as useCheckSensorStatus } from './SensorsProvider/useCheckSensorStatus';
export { default as useGetSensorConfiguration } from './useGetSensorConfiguration';
export { default as useSensorPackages } from './useSensorPackages';
export { default as useTenantProfiles } from './useTenantProfiles';
export { default as useProfileSubmodules } from './useProfileSubmodules';
export { default as useCheckPcap } from './useCheckPcap';

const SensorsProvider = ({ children }) => (
  <TenantsStateProvider>
    <SensorsStateProvider>
      <ProfilesStateProvider>{children}</ProfilesStateProvider>
    </SensorsStateProvider>
  </TenantsStateProvider>
);

export default SensorsProvider;

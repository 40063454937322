import React, { useContext, useReducer } from 'react';

import { useApi } from 'services/api';
import { useEffectOnMount } from 'utils/hooks';
import { extractErrorMessage } from 'utils/api';

const initialState = {
  tenants: null,
  loading: false,
  loadError: null,
  creating: false,
  createError: null,
  updating: false,
  updateError: null,
  deleting: false,
  deleteError: null,
};

export const TenantsState = React.createContext({
  state: {
    ...initialState,
  },
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'GET_TENANTS':
      return { ...state, loading: true };
    case 'GET_TENANTS_SUCCESS':
      return {
        ...state,
        loading: false,
        tenants: action.tenants,
      };
    case 'GET_TENANTS_FAIL':
      return {
        ...state,
        loading: false,
        tenants: [],
        loadError: action.error,
      };
    case 'CREATE_TENANT':
      return {
        ...state,
        creating: true,
        createError: null,
      };
    case 'CREATE_TENANT_SUCCESS':
      return {
        ...state,
        creating: false,
        tenants: state.tenants.concat(action.tenant),
      };
    case 'CREATE_TENANT_FAIL':
      return {
        ...state,
        creating: false,
        createError: action.error,
      };
    case 'UPDATE_TENANT':
      return {
        ...state,
        updating: true,
        updateError: null,
      };
    case 'UPDATE_TENANT_SUCCESS':
      return {
        ...state,
        updating: false,
        tenants: state.tenants.map((t) => (t.id === action.tenant.id ? action.tenant : t)),
      };
    case 'UPDATE_TENANT_FAIL':
      return {
        ...state,
        updating: false,
        updateError: action.error,
      };
    case 'DELETE_TENANT':
      return {
        ...state,
        deleting: true,
        deleteError: null,
      };
    case 'DELETE_TENANT_SUCCESS':
      return {
        ...state,
        deleting: false,
        tenants: state.tenants.filter((tenant) => tenant.id !== action.tenant.id),
      };
    case 'DELETE_TENANT_FAIL':
      return {
        ...state,
        deleting: false,
        deleteError: action.error,
      };
    case 'REMOVE_DELETION_ERROR':
      return {
        ...state,
        deleteError: null,
      };
    case 'REMOVE_UPSERT_ERRORS':
      return {
        ...state,
        createError: null,
        updateError: null,
      };
    default:
      return state;
  }
};

export function TenantsStateProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const api = useApi();

  const getTenants = () => {
    dispatch({ type: 'GET_TENANTS' });

    return api
      .get('/tenants', {})
      .then(({ items }) => {
        dispatch({ type: 'GET_TENANTS_SUCCESS', tenants: items });
      })
      .catch((error) => {
        dispatch({ type: 'GET_TENANTS_FAIL', error: extractErrorMessage(error) });
      });
  };

  const createTenant = async (tenant) => {
    dispatch({ type: 'CREATE_TENANT' });

    try {
      const createdTenant = await api.post('/tenants', tenant);

      dispatch({ type: 'CREATE_TENANT_SUCCESS', tenant: createdTenant });
    } catch (error) {
      dispatch({ type: 'CREATE_TENANT_FAIL', error: extractErrorMessage(error) });

      return Promise.reject(error);
    }
  };

  const updateTenant = async (tenant) => {
    dispatch({ type: 'UPDATE_TENANT' });

    try {
      const updatedTenant = await api.post(`/tenants/${tenant.id}`, tenant);

      dispatch({ type: 'UPDATE_TENANT_SUCCESS', tenant: updatedTenant });
    } catch (error) {
      dispatch({ type: 'UPDATE_TENANT_FAIL', error: extractErrorMessage(error) });

      return Promise.reject(error);
    }
  };

  const deleteTenant = (tenant) => {
    dispatch({ type: 'DELETE_TENANT' });

    return api
      .delete(`/tenants/${tenant.id}`)
      .then(() => {
        dispatch({ type: 'DELETE_TENANT_SUCCESS', tenant });
      })
      .catch((error) => {
        dispatch({ type: 'DELETE_TENANT_FAIL', error: extractErrorMessage(error) });
      });
  };

  const removeDeletionError = () => {
    dispatch({ type: 'REMOVE_DELETION_ERROR' });
  };

  const removeUpsertErrors = () => {
    dispatch({ type: 'REMOVE_UPSERT_ERRORS' });
  };

  const value = {
    state,
    getTenants,
    createTenant,
    updateTenant,
    deleteTenant,
    removeDeletionError,
    removeUpsertErrors,
  };

  return <TenantsState.Provider value={value}>{children}</TenantsState.Provider>;
}

export const useTenantsState = () => {
  const { state, getTenants } = useContext(TenantsState);

  useEffectOnMount(() => {
    if (!state.tenants) {
      getTenants();
    }
  });

  return { ...state };
};

/**
 * wrapper for icons used in the project
 * all icons should be consumed through the wrapper, instead of directly importing from package
 **/

import {
  FiRefreshCw,
  FiActivity,
  FiAlertCircle,
  FiAlertOctagon,
  FiAlertTriangle,
  FiArchive,
  FiArrowLeft,
  FiArrowRight,
  FiArrowDown,
  FiBell,
  FiCalendar,
  FiCheckCircle,
  FiCheckSquare,
  FiChevronDown,
  FiChevronsDown,
  FiChevronLeft,
  FiChevronsLeft,
  FiChevronRight,
  FiChevronsRight,
  FiChevronUp,
  FiClipboard,
  FiCloud,
  FiCpu,
  FiCreditCard,
  FiCodesandbox,
  FiCopy,
  FiCrosshair,
  FiDatabase,
  FiDownload,
  FiEdit3,
  FiExternalLink,
  FiFastForward,
  FiFileText,
  FiFilePlus,
  FiFilter,
  FiFrown,
  FiGitPullRequest,
  FiGlobe,
  FiGrid,
  FiHardDrive,
  FiHome,
  FiInfo,
  FiKey,
  FiLifeBuoy,
  FiLink,
  FiLock,
  FiLoader,
  FiLogOut,
  FiMaximize2,
  FiMessageSquare,
  FiMinusSquare,
  FiMonitor,
  FiMoon,
  FiPlay,
  FiPause,
  FiPlus,
  FiPlusSquare,
  FiSearch,
  FiSettings,
  FiSlash,
  FiSun,
  FiSquare,
  FiTerminal,
  FiTrash2,
  FiTrendingUp,
  FiUploadCloud,
  FiUser,
  FiUsers,
  FiX,
  FiXSquare,
  FiCornerUpRight,
  FiCornerDownRight,
  FiBookOpen,
  FiClock,
  FiSave,
  FiLayers,
  FiServer,
  FiSidebar,
} from 'react-icons/fi';
import { FaNetworkWired, FaUser, FaStar, FaRegStar, FaCircle, FaUsersCog, FaUserShield } from 'react-icons/fa';
import { GiCoffeeCup, GiBroom, GiSmartphone, GiTablet } from 'react-icons/gi';
import { BsQuestionSquare } from 'react-icons/bs';
import { GoLightBulb, GoPlug, GoBrowser } from 'react-icons/go';
import {
  AiOutlineAudit,
  AiOutlineCloudServer,
  AiOutlineFile,
  AiFillFolderOpen,
  AiFillFolder,
  AiOutlineQuestionCircle,
  AiOutlineExpand,
} from 'react-icons/ai';
import { DiGit, DiGitMerge } from 'react-icons/di';
import { BiStation } from 'react-icons/bi';
import { HiOutlineChevronDoubleDown, HiOutlineChevronDoubleUp } from 'react-icons/hi';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import { MdOutlineIntegrationInstructions } from 'react-icons/md';
import { LuSlidersHorizontal } from 'react-icons/lu';

export const ActivityIcon = FiActivity;
export const AlertCircleIcon = FiAlertCircle;
export const AlertOctagonIcon = FiAlertOctagon;
export const AlertTriangleIcon = FiAlertTriangle;
export const AppIcon = GoBrowser;
export const ArchiveIcon = FiArchive;
export const ArrowLeftIcon = FiArrowLeft;
export const ArrowRightIcon = FiArrowRight;
export const ArrowDownIcon = FiArrowDown;
export const AuditIcon = AiOutlineAudit;
export const BellIcon = FiBell;
export const BroomIcon = GiBroom;
export const CalendarIcon = FiCalendar;
export const CheckCircleIcon = FiCheckCircle;
export const SquareIcon = FiSquare;
export const CheckSquareIcon = FiCheckSquare;
export const ChevronDownIcon = FiChevronDown;
export const ChevronsDownIcon = FiChevronsDown;
export const ChevronRightIcon = FiChevronRight;
export const ChevronsRightIcon = FiChevronsRight;
export const ChevronUpIcon = FiChevronUp;
export const ChevronLeftIcon = FiChevronLeft;
export const ChevronsLeftIcon = FiChevronsLeft;
export const CloseIcon = FiX;
export const CloseSquareIcon = FiXSquare;
export const CloudIcon = FiCloud;
export const CoffeeIcon = GiCoffeeCup;
export const Codesandbox = FiCodesandbox;
export const CommentIcon = FiMessageSquare;
export const CopyIcon = FiCopy;
export const CpuIcon = FiCpu;
export const CreditCardIcon = FiCreditCard;
export const CrosshairIcon = FiCrosshair;
export const DatabaseIcon = FiDatabase;
export const DataRecorderIcon = FiHardDrive;
export const DeleteIcon = FiTrash2;
export const DownloadIcon = FiDownload;
export const DisabledIcon = FiSlash;
export const EditIcon = FiEdit3;
export const ExternalLinkIcon = FiExternalLink;
export const FastForwardIcon = FiFastForward;
export const FileIconOutline = AiOutlineFile;
export const FileTextIcon = FiFileText;
export const FilterIcon = FiFilter;
export const FlowIcon = FiLink;
export const FlowlogsIcon = AiOutlineCloudServer;
export const FrownIcon = FiFrown;
export const FullCircleIcon = FaCircle;
export const FeatureToggleIcon = FiGitPullRequest;
export const GlobeIcon = FiGlobe;
export const GridIcon = FiGrid;
export const HomeIcon = FiHome;
export const InfoIcon = FiInfo;
export const KeyIcon = FiKey;
export const LayersIcon = FiLayers;
export const LifeBuoyIcon = FiLifeBuoy;
export const LoaderIcon = FiLoader;
export const LogoutIcon = FiLogOut;
export const MoonIcon = FiMoon;
export const NetworkIcon = FaNetworkWired;
export const NewFileIcon = FiFilePlus;
export const PlayIcon = FiPlay;
export const PauseIcon = FiPause;
export const PlugIcon = GoPlug;
export const PlusIcon = FiPlus;
export const PlusSquareIcon = FiPlusSquare;
export const RefreshIcon = FiRefreshCw;
export const SearchIcon = FiSearch;
export const ServerIcon = FiServer;
export const SettingsIcon = FiSettings;
export const StarIcon = FaRegStar;
export const StarFullIcon = FaStar;
export const SunIcon = FiSun;
export const TerminalIcon = FiTerminal;
export const BaselineIcon = FiTrendingUp;
export const UserIcon = FiUser;
export const UserIconAlt = FaUser;
export const UsersIcon = FiUsers;
export const ClipboardIcon = FiClipboard;
export const MinusSquareIcon = FiMinusSquare;
export const CornerUpRightIcon = FiCornerUpRight;
export const CornerDownRightIcon = FiCornerDownRight;
export const UploadIcon = FiUploadCloud;
export const LockIcon = FiLock;
export const DocumentationIcon = FiBookOpen;
export const ClockIcon = FiClock;
export const RoleIcon = FaUsersCog;
export const ComputerIcon = FiMonitor;
export const SmartphoneIcon = GiSmartphone;
export const TabletIcon = GiTablet;
export const UnknownIcon = BsQuestionSquare;
export const AlertIcon = BiStation;
export const SaveIcon = FiSave;
export const GroupbyIcon = DiGitMerge;
export const ExpandIcon = HiOutlineChevronDoubleDown;
export const CollapseIcon = HiOutlineChevronDoubleUp;
export const MaximizeIcon = FiMaximize2;
export const IDMIcon = FaUserShield;
export const QuestionIcon = AiOutlineQuestionCircle;
export const SidebarIcon = FiSidebar;
export const ZoomOutIcon = AiOutlineExpand;
export const IntegrationIcon = MdOutlineIntegrationInstructions;
export const CustomIntegrationIcon = HiOutlineWrenchScrewdriver;
export const UserSettingsIcon = LuSlidersHorizontal;
// aliases
export const SensorIcon = ActivityIcon;
export const TenantIcon = NetworkIcon;
export const IndicatorIcon = GoLightBulb;
export const LogIcon = CrosshairIcon;
export const TaskIcon = FilterIcon;
export const ClosedFolderIcon = AiFillFolder;
export const OpenFolderIcon = AiFillFolderOpen;
export const FolderFileIcon = AiOutlineFile;
export const GitIcon = DiGit;
export const EntityIcon = ComputerIcon;

import React from 'react';
import PropTypes from 'prop-types';
import tinycolor from 'tinycolor2';
import styled, { css } from 'styled-components';

import { stringToColorFromSet, adjustToReadable } from 'utils/colors';
import commonTheme from 'services/theme/commonTheme';
import { CloseIcon } from 'components/common/Icons';

const getVariantStyles = (variant, color, theme) => {
  switch (variant) {
    case 'outlined':
      const readableColor = adjustToReadable(color, theme.colors.background.default);
      return css`
        color: ${readableColor};
        border: 1px solid ${readableColor};
        background-color: inherit;
      `;
    case 'accented':
      const backgroundColor = tinycolor(color).lighten(24).toString();
      return css`
        color: ${adjustToReadable(color, backgroundColor)};
        border-color: ${backgroundColor};
        background-color: ${backgroundColor};
      `;
    case 'text':
      return css`
        color: ${adjustToReadable(color, theme.colors.background.default)};
        border-color: transparent;
        background-color: transparent;
        font-weight: 600;
      `;
    case 'contained':
    default:
      const baseColor = tinycolor(color).lighten(5).toString();
      return css`
        color: ${tinycolor
          .mostReadable(baseColor, [theme.palette.white, theme.colors.text.primary, '#252525'], {
            includeFallBackColors: true,
            level: 'AAA',
          })
          .toHexString()};
        border-color: ${baseColor};
        background-color: ${baseColor};
      `;
  }
};

const getHoverStyles = (variant, color, theme) => {
  return getVariantStyles(variant === 'contained' ? 'accented' : 'contained', color, theme);
};

const StyledBadge = styled.div`
  display: inline-flex;
  text-align: center;
  min-width: min-content;
  max-width: max-content;
  border-radius: ${({ $shape }) => ($shape === 'pill' ? '0.725em' : '0.25em')};
  cursor: ${({ $hasOnClick }) => ($hasOnClick ? 'pointer' : 'default')};
  font-size: ${({ $fontSize }) => $fontSize || '12px'};
  overflow: hidden;

  ${({ $variant, $color, theme }) => getVariantStyles($variant, $color, theme)}
  &:hover {
    ${({ $variant, $color, theme, $onClick }) => !!$onClick && getHoverStyles($variant, $color, theme)}
  }
`;

const CloseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.125em 0.25em;
  cursor: pointer;

  &:hover {
    background-color: rgba(180, 180, 180, 0.5);
  }
`;

const BadgeContent = styled.div`
  width: ${({ $width }) => ($width ? $width : 'max-content')};
  padding: ${({ $hasOnRemove }) => ($hasOnRemove ? '0.125em 0.25em 0.125em 0.75em' : '0.125em 0.75em')};
`;

const Badge = ({
  variant,
  color,
  shape,
  fontSize,
  width,
  className,
  value = undefined,
  onClick,
  onRemove,
  dataTestId = undefined,
  style = undefined,
  children,
}) => {
  const appliedColor = color || stringToColorFromSet(value, commonTheme.colors.source, 0);

  return (
    <StyledBadge
      $variant={variant}
      $shape={shape}
      $color={appliedColor}
      $fontSize={fontSize}
      className={className}
      $hasOnClick={!!onClick}
      onClick={onClick}
      data-test-id={dataTestId}
      style={style}
    >
      <BadgeContent $width={width} $hasOnRemove={!!onRemove}>
        {children || value}
      </BadgeContent>
      {onRemove && (
        <CloseWrapper
          role="button"
          aria-label="Remove"
          onClick={(e) => {
            e.stopPropagation();
            onRemove(e);
          }}
        >
          <CloseIcon />
        </CloseWrapper>
      )}
    </StyledBadge>
  );
};

Badge.propTypes = {
  variant: PropTypes.oneOf(['contained', 'outlined', 'accented', 'text']),
  color: PropTypes.string,
  shape: PropTypes.oneOf(['pill', 'square']),
  fontSize: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
};

Badge.defaultProps = {
  variant: 'contained',
  shape: 'pill',
  fontSize: '12px',
};

export default React.memo(Badge);

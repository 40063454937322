const filterSensorsByDebugFlag = (sensors) => {
  let debugProfileTypes = [];
  try {
    debugProfileTypes = JSON.parse(sessionStorage.getItem('mm_debugProfileTypes')) || [];
  } catch (_) {
    debugProfileTypes = [];
  }

  if (!debugProfileTypes?.length) {
    return sensors;
  }

  return sensors.filter((sensor) => debugProfileTypes.includes(sensor.Profile?.profileType));
};

export default filterSensorsByDebugFlag;

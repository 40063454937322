// main menu items
export const EXPANDED_WIDTH = 240;
export const COLLAPSED_WIDTH = 60;

const menu = () => ({
  alerting: {
    alias: 'alerting',
    name: 'Alerting',
    icon: null,
    items: [
      {
        alias: 'overview',
        name: 'Home',
        icon: 'HomeIcon',
        path: '/overview',
      },
      {
        alias: 'detections',
        name: 'Detections',
        icon: 'GridIcon',
        editable: false,
        hidden: false,
      },
      {
        alias: 'ai-overview',
        name: 'AI Overview',
        icon: 'AiIcon',
        editable: false,
        hidden: true,
      },
      {
        alias: 'cloudapi',
        name: 'Cloud API',
        icon: 'CloudIcon',
        editable: false,
        hidden: true,
      },
      {
        alias: 'flowlogs',
        name: 'Cloud Flow Logs',
        icon: 'CloudServerIcon',
        editable: false,
        hidden: true,
      },
      {
        alias: 'identity-management',
        name: 'Identity Management',
        icon: 'IDMIcon',
        editable: false,
        hidden: true,
      },
    ],
  },
  investigation: {
    alias: 'investigation',
    name: 'Investigation',
    icon: null,
    items: [
      {
        alias: 'indicators',
        name: 'Indicators',
        icon: 'IndicatorIcon',
        editable: false,
        hidden: true,
      },
      {
        alias: 'logs',
        name: 'Logs',
        icon: 'CrosshairIcon',
        editable: false,
        hidden: true,
      },
      {
        alias: 'packet-captures',
        name: 'Packet Captures',
        icon: 'DataRecorderIcon',
        editable: false,
        hidden: true,
      },
    ],
  },
  reporting: {
    alias: 'reporting',
    name: 'Reporting',
    icon: null,
    items: [
      {
        alias: 'baseline',
        name: 'Network Baseline',
        icon: 'BaselineIcon',
        editable: false,
        hidden: true,
      },
      {
        alias: 'executive-summary',
        name: 'Executive Summary',
        icon: 'CisoIcon',
        hidden: true,
      },
    ],
  },
});

export default menu;

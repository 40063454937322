import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/fp/set';

import { getSavedSelectedPresetForPage } from 'utils/localStorage';
import { getDefaultColumns, PRESET_OPTIONS } from 'consts/dashboard';

import { INITIAL_CONFIG } from './config';
import { migrate } from './migrations';
import { getColumnsConfig, getPageSelectedPresetName, getPageSelectedPresetConfiguration } from './selectors';
import { setColumnsConfig, mapColumnsConfig, setPagePresetConfig, addMissingDefaultPresets } from './utils';

const createDashboardConfiguration = (set) => ({
  isLoading: true,
  config: INITIAL_CONFIG,
  sensorProfileTypes: [],
  selectedPreset: Object.keys(INITIAL_CONFIG.presets).reduce(
    (selectedPresetMap, page) => ({ ...selectedPresetMap, [page]: getSavedSelectedPresetForPage(page) || 'custom' }),
    {},
  ),
  setLoading: (isLoading) => {
    set((prev) => ({
      ...prev,
      dashboardConfiguration: {
        ...prev.dashboardConfiguration,
        isLoading,
      },
    }));
  },
  setInitialDashboardConfig: ({ config, sensorProfileTypes }) => {
    const parsedConfig = JSON.parse(config);

    const newConfig = addMissingDefaultPresets(
      _isEmpty(parsedConfig)
        ? { ...INITIAL_CONFIG, columnsConfig: mapColumnsConfig(INITIAL_CONFIG) }
        : migrate({ ...parsedConfig, columnsConfig: mapColumnsConfig(parsedConfig) }),
    );

    set((prev) => ({
      ...prev,
      dashboardConfiguration: {
        ...prev.dashboardConfiguration,
        isLoading: false,
        config: newConfig,
        sensorProfileTypes,
      },
    }));
  },

  setWidgetPropForSelectedPreset: (page, index, prop) => {
    set((state) => {
      const selectedPagePreset = getPageSelectedPresetName(state, page);
      const selectedPagePresetsConfig = getPageSelectedPresetConfiguration(state, page);

      const newConfig = selectedPagePresetsConfig.map((conf, i) =>
        i === index ? { type: selectedPagePresetsConfig[index].type, dataKey: prop } : conf,
      );

      return setPagePresetConfig(state, page, selectedPagePreset, newConfig);
    });
  },
  setWidgetTypeForSelectedPreset: (page, index, type) => {
    set((state) => {
      const selectedPagePreset = getPageSelectedPresetName(state, page);
      const selectedPagePresetsConfig = getPageSelectedPresetConfiguration(state, page);

      const newConfig = selectedPagePresetsConfig.map((conf, i) =>
        i === index ? { type: type, dataKey: selectedPagePresetsConfig[index].dataKey } : conf,
      );

      return setPagePresetConfig(state, page, selectedPagePreset, newConfig);
    });
  },
  removeWidgetFromSelectedPreset: (page, index) => {
    set((state) => {
      const selectedPagePreset = getPageSelectedPresetName(state, page);
      const selectedPagePresetsConfig = getPageSelectedPresetConfiguration(state, page);
      const newConfig = selectedPagePresetsConfig.slice(0, index).concat(selectedPagePresetsConfig.slice(index + 1));
      return setPagePresetConfig(state, page, selectedPagePreset, newConfig);
    });
  },
  addWidgetToSelectedPreset: (page, type, dataKey) => {
    set((state) => {
      const selectedPagePreset = getPageSelectedPresetName(state, page);
      const selectedPagePresetsConfig = getPageSelectedPresetConfiguration(state, page);
      const newConfig = selectedPagePresetsConfig.concat({ type, dataKey });

      return setPagePresetConfig(state, page, selectedPagePreset, newConfig);
    });
  },

  removeColumn: (page, property, log) => {
    set((state) => {
      const columns = getColumnsConfig(state, page, log).filter((k) => k !== property);

      return setColumnsConfig(state, page, log, columns);
    });
  },
  addColumn: (page, property, log) => {
    set((state) => {
      const columns = getColumnsConfig(state, page, log).concat(property);

      return setColumnsConfig(state, page, log, columns);
    });
  },
  resetColumns: (page, log) => {
    set((state) => setColumnsConfig(state, page, log, getDefaultColumns({ page, log })));
  },
  reorderColumns: (page, log, sourceIndex, destinationIndex) => {
    set((state) => {
      const columnsConfig = getColumnsConfig(state, page, log);

      const copiedColumnsConfig = [...columnsConfig];
      const [removed] = copiedColumnsConfig.splice(sourceIndex, 1);
      copiedColumnsConfig.splice(destinationIndex, 0, removed);

      return setColumnsConfig(state, page, log, copiedColumnsConfig);
    });
  },
  persistLayout: (id, newLayout) => {
    set((state) => {
      const { customDashboards } = state.dashboardConfiguration.config;
      const newDashboardConfig = { id, layout: newLayout };

      const configToPersist = customDashboards.find((c) => c.id === id)
        ? customDashboards.map((c) => (c.id === id ? newDashboardConfig : c))
        : customDashboards.concat(newDashboardConfig);

      return _set(`dashboardConfiguration.config.customDashboards`, configToPersist, state);
    });
  },

  setSelectedPreset: (page, preset) => {
    set((state) => _set(`dashboardConfiguration.selectedPreset.${page}`, preset, state));
  },
  resetSelectedPreset: (page) => {
    set((state) => {
      const selectedPreset = getPageSelectedPresetName(state, page);

      const presetDefaultWidgets = PRESET_OPTIONS[page].find((p) => p.value === selectedPreset).defaultWidgets;

      return _set(`dashboardConfiguration.config.presets.${page}.${selectedPreset}`, presetDefaultWidgets, state);
    });
  },
  removeInvalidWidgetsFromSelectedPreset: (propList, page) => {
    set((state) => {
      const selectedPreset = getPageSelectedPresetName(state, page);
      const widgetConfig = getPageSelectedPresetConfiguration(state, page);

      const defaultWidgetsForPage = PRESET_OPTIONS[page].map((p) => p.defaultWidgets).flat();

      const updatedConfig = widgetConfig.filter((widget) => {
        if (defaultWidgetsForPage.some((defaultWidget) => defaultWidget.dataKey === widget.dataKey)) {
          return true;
        }

        return propList.includes(widget.dataKey);
      });

      if (widgetConfig.length !== updatedConfig.length) {
        return _set(`dashboardConfiguration.config.presets.${page}.${selectedPreset}`, updatedConfig, state);
      }

      return state;
    });
  },
});

export default createDashboardConfiguration;

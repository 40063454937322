import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import log from 'services/log';

import LetterPress from 'components/styled/LetterPress';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.background.default};
  justify-content: center;
  align-items: center;
`;

const Description = styled.div`
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.text.subtle};

  a {
    color: ${({ theme }) => theme.colors.text.subtle};
    text-decoration: underline;
  }
`;

const BadUrlPage = ({ className }) => {
  log({
    type: 'error',
    origin: 'BadUrlPage',
    message: '404',
    info: {
      url: window.location.href,
    },
  });
  return (
    <Container className={className}>
      <LetterPress>404</LetterPress>
      <Description>These aren't the droids you're looking for.</Description>
      <Description>
        <Link to="/">Return to homepage</Link>
      </Description>
    </Container>
  );
};

export default BadUrlPage;

import { PAGES } from 'consts/pages';

// whitelist of log properties to be used as facets
export const FACETS = {
  // O_EVENTS
  [PAGES.events]: [
    'killchain',
    'affectedHosts',
    'labels',
    'impact',
    'tenantName',
    'sensorName',
    'status',
    'assigned',
    'files',
    'deviation_score',
    'impact_score',
  ],
  // O_INDICATORS
  [PAGES.indicators]: [
    'sensorName',
    'tenantName',
    'log',
    'severity',
    'killchain',
    'entity',
    'geo',
    'key',
    'value',
    'deviation_score',
  ],
  // O_SENSOR
  [PAGES.investigator]: [],
};

export const CHART_FACETS = {
  [PAGES.events]: ['impact_score', 'deviation_score'],
  [PAGES.indicators]: [],
  [PAGES.investigator]: [],
};

// blacklist of log properties, used for grouped counts in investigator dash
export const EXCLUSION_LIST = {
  [PAGES.events]: ['deviation_score', 'impact_score'], // shown as a bar chart
  [PAGES.indicators]: ['indicatorId', 'indicatorTitle'],
  [PAGES.investigator]: [
    'ts',
    'tenantId',
    'sensorId',
    'sensor_id',
    'providerId',
    'uid',
    'duration',
    'logId',
    'logTitle',
    'conn_uids',
    'fuid',
    'resp_fuids',
    '_id',
    'trans_id',
    'es_sensor_schema_ver',
    'filebuf',
  ],
};

export const PROP_NAMES: Record<string, string> = {
  affectedHosts: 'Affected Hosts',
  assigned: 'Assigned',
  entity: 'Entity',
  files: 'Logs with files',
  geo: 'Geo',
  impact_score: 'Rank score',
  impact: 'Impact',
  key: 'Key',
  killchain: 'Killchain',
  labels: 'Labels',
  log: 'Log',
  provider: 'Provider',
  deviation_score: `Deviation score`,
  sensorId: 'Sensor ID',
  sensorName: 'Sensor',
  severity: 'Severity',
  status: 'Status',
  tenantId: 'Tenant ID',
  tenantName: 'Tenant',
  value: 'Value',
};

export const RECORD_STATUS_MAP = {
  open: 'Open',
  under_review: 'Under Review',
  acknowledged: 'Acknowledged',
  closed: 'Closed',
};

// export const NIL_VALUE_MQL = 'not like /.+/';
export const NOT_NIL_VALUE_MQL = 'is not null';
export const NIL_VALUE_MQL = 'is null';

/**
 * general app config constants
 *
 **/

// how often indicators are aggregated into events on the API (in seconds)
export const EVENT_GENERATION_BUCKET = 60 * 60 * 1000; // 1 hour

// maximum connected indicators to show on record details
export const MAX_CONNECTED_IND_COUNT = 100;
// maximum connected logs to show in a flow
export const MAX_CONNECTED_LOG_COUNT = 100;

/**
 * CLIENT Feature Flags
 * cookie is created for each feature in a form of `mm_ft_${key}`
 **/
export const FEATURES = {
  debugInformation: {
    default: false,
    description: 'Provide additional information for debugging purposes',
    showInDebug: true,
  },
  cloudApiRedesign: {
    default: true,
    description: 'Enables the new Cloud API design',
    showInDebug: true,
  },
  crowdstrikeFDR: {
    default: false,
    description: 'Enables CrowdStrike FDR profile type',
    showInDebug: true,
  },
  showAIOverview: {
    default: false,
    description: 'Shows AI Overview page',
    showInDebug: false,
  },
} as const satisfies Record<
  string,
  {
    default: boolean;
    description: string;
    showInDebug: boolean;
  }
>;

/**
 * SESSION STORAGE keys
 **/
export const API_TOKEN_STORAGE_KEY = 'pstk';
export const DEBUG_KEY = 'psdbg';

/**
 * COOKIES list
 * + features defined in services/debug/config.js create a cookie for each feature toggle
 **/
export const COOKIE_DEBUG_CONSOLE_LOG = 'mm_dbg_clog';

// Min screen width in pixels per device
export const breakpoints = {
  mobile: 320,
  largeMobile: 480,
  tablet: 768,
  desktop: 1280,
  largeDesktop: 1600,
  veryLargeDesktop: 2560,
};

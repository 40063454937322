import format from 'date-fns/format';

import { NIL_VALUE_MQL, NOT_NIL_VALUE_MQL } from 'consts/logProperties';
import { PAGES } from 'consts/pages';
import type { SEARCH_TYPES } from 'consts/search';

const PQL_DATE_FORMAT = 'MM/dd/yyyy';
const PQL_TIME_FORMAT = `${PQL_DATE_FORMAT} h:mma`;
const PQL_FULL_TIME_FORMAT = `${PQL_DATE_FORMAT} h:mm:ssa`;

export function preparePlatformIndicatorTimerange(ts: Date | number) {
  const date = new Date(ts);

  return prepareTimerangeQueryWithSeconds(
    date,
    // add 5 minutes
    +date + 5 * 60000,
  );
}

export function preparePqlDateQuery(date: Date) {
  return format(date, PQL_DATE_FORMAT);
}

export function prepareTimerangeQuery(from: Date | number, to: Date | number) {
  const t1 = format(new Date(from), PQL_TIME_FORMAT);
  const t2 = format(new Date(to), PQL_TIME_FORMAT);

  return `from ${t1} to ${t2}`;
}

export function prepareTimerangePartWithSeconds(part: Date | number) {
  return format(new Date(part), PQL_FULL_TIME_FORMAT);
}

export function prepareTimerangeQueryWithSeconds(from: Date | number, to: Date | number) {
  const t1 = prepareTimerangePartWithSeconds(from);
  const t2 = prepareTimerangePartWithSeconds(to);

  return `from ${t1} to ${t2}`;
}

export function escapePropValue(value: string | null | undefined) {
  return value?.replace?.(/\\/g, '\\\\').replace(/"/g, '\\"') || value;
}

export function truncateProperty(prop: string, maxLength = 40) {
  if (prop.length > maxLength && prop.indexOf('.') !== -1) {
    const parts = prop.split('.');

    const first = parts[0];
    const penultimate = parts[parts.length - 2];
    const last = parts[parts.length - 1];
    const truncatedWPen = `${first}...${penultimate}.${last}`;
    return truncatedWPen.length > maxLength ? `${first}...${last}` : truncatedWPen;
  }

  return prop;
}

export function getRecordDirection(src: string, dest: string) {
  if (src === 'lo' && dest === 'lo') {
    return 'local';
  } else if (src === 'lo') {
    return 'outbound';
  }

  return 'inbound';
}

export function addToQuery(
  page: keyof typeof PAGES,
  pqlQuery: string,
  propKey: string,
  value: string | number | null | undefined,
  keyType: string,
  inverse = false,
) {
  if (value === null || value === undefined) {
    const nonExistancePql =
      page === PAGES.investigator
        ? `${pqlQuery} @attribute ${inverse ? '=' : '!='} ${propKey} or ${propKey} ${
            inverse ? NOT_NIL_VALUE_MQL : NIL_VALUE_MQL
          }`
        : `${pqlQuery} ${propKey} ${inverse ? NOT_NIL_VALUE_MQL : NIL_VALUE_MQL}`;

    return nonExistancePql;
  }

  if (value === '') {
    return `${pqlQuery} ${propKey} ${inverse ? 'like' : 'not like'} /.+/`;
  }

  let newPql = `${pqlQuery} ${propKey} ${inverse ? '!=' : '='} `;

  const isNumberType = (type: string, value: string | number): value is number =>
    ['long', 'float', 'boolean', 'integer'].includes(type);

  if (isNumberType(keyType, value)) {
    newPql = newPql + `${value}`;
  } else if (['ip'].includes(keyType)) {
    newPql = newPql + `[${value}]`;
  } else if (keyType === 'date') {
    newPql = newPql + value;
  } else {
    // text, keyword
    newPql = newPql + `"${escapePropValue(value)}"`;
  }

  return newPql;
}

export function hasInvalidSyntax(mql: string) {
  if (!mql) {
    return false;
  }

  const orderby = new RegExp(`\\sorderby(\\s\\w+(,\\s\\w+)*)`, 'gi');
  const countby = new RegExp(`\\scountby(\\s\\w+(,\\s\\w+)*)`, 'gi');
  const pipe = new RegExp(`\\s\\|(?=\\s)`, 'gi');

  return orderby.test(mql) || countby.test(mql) || pipe.test(mql);
}

export const transformStringToKey = (query: string | null | undefined) => {
  if (typeof query !== 'string') {
    return undefined;
  }
  return query.trim().replace(/\s+/g, '-');
};

export function getRecordMQLTimeBoudaries(ts: number | null | undefined) {
  if (!ts) {
    return null;
  }

  const TWENTY_FOUR_HOURS_IN_MILLIS = 24 * 60 * 60 * 1000;

  return prepareTimerangeQuery(ts - TWENTY_FOUR_HOURS_IN_MILLIS, ts + TWENTY_FOUR_HOURS_IN_MILLIS);
}

// ES allows for specific intervals like 5m
// PG has minutes/hour/day... intervals
export const getApiInterval = (
  t1: number | null | undefined,
  t2: number | null | undefined,
  searchType: (typeof SEARCH_TYPES)[number],
  pointInterval: number,
) => {
  if (!t1 || !t2) {
    return null;
  }

  const minutesDiff = (t2 - t1) / 60000;
  let interval = 'minute';
  let division = 60000;
  let shortInterval = 'm';

  if (minutesDiff > 7 * 24 * 60 * 60) {
    interval = 'week';
    division = 60000 * 60 * 24;
    shortInterval = 'd';
  } else if (minutesDiff > 24 * 60 * 60) {
    interval = 'day';
    division = 60000 * 60 * 24;
    shortInterval = 'd';
  } else if (minutesDiff > 60 * 60) {
    interval = 'hour';
    division = 60000 * 60;
    shortInterval = 'h';
  }
  return searchType === 'O_SENSOR' ? `${pointInterval / division}${shortInterval}` : interval;
};

import { AlertOctagonIcon } from 'components/common/Icons';
import { maybePlural } from 'utils/string';

type SensorCheckAlertProps = {
  offlineSensorCount: number;
};

const SensorCheckAlert = ({ offlineSensorCount }: SensorCheckAlertProps) => {
  return (
    <div className="flex items-center font-semibold color-alert imr-1">
      <AlertOctagonIcon /> {offlineSensorCount} {maybePlural(offlineSensorCount, 'sensor')}{' '}
      {offlineSensorCount > 1 ? 'have' : 'has'} reported no data during the prior period.
    </div>
  );
};

export default SensorCheckAlert;

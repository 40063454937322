type Bookmark = {
  UserId: number;
  createdAt: string;
  description: string;
  id: number;
  labels: string[];
  name: string;
  objectId: string;
  searchType: string;
  updatedAt: string;
};

export const filterBookmarks = (bookmarks: Bookmark[] = [], filter: string | undefined): Bookmark[] => {
  if (!filter) {
    return bookmarks;
  }
  const lowercasedFilter = filter.toLowerCase();

  return bookmarks.filter(
    (b) =>
      b.name.toLowerCase().includes(lowercasedFilter) ||
      b.description?.toLowerCase().includes(lowercasedFilter) ||
      b.labels?.join(',').toLowerCase().includes(lowercasedFilter),
  );
};

import { useContext, useEffect } from 'react';

import { logout } from 'services/api';
import { AuthStateProvider } from 'services/auth';

const LogoutPage = () => {
  const { state } = useContext(AuthStateProvider);

  useEffect(() => {
    logout(state.token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default LogoutPage;
